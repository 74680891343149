// import React from "react";

// const InAppNotification = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default InAppNotification;

import React from "react";
import Send from "../../buttons/Send";
import AssignMembers from "../tast/AssignMembers";

const InAppNotification = ({ onclose }) => {
  return (
    <div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center ">
      <div className=" bg-white w-[700px] h-auto rounded-md p-5 ">
        <div className=" flex items-center justify-between">
          <p className="text-xl font-Kumbh font-bold ">Send Notification</p>
          <p onClick={onclose} className=" text-lg font-bold cursor-pointer">
            &times;
          </p>
        </div>

        <div className=" mt-5 space-y-3">
          <div className=" space-y-2">
            <label className=" font-Nunito text-sm text-[#121212]" htmlFor="">
              Message Title
            </label>
            <input
              className=" w-full h-12 border border-[#D0D0D0] rounded-lg px-2 placeholder:text-sx placeholder-[#D0D0D0]"
              placeholder="Title"
              type="text"
            />
          </div>

          <div className=" space-y-2">
            <label className=" font-Nunito text-sm text-[#121212]" htmlFor="">
              Description
            </label>
            <textarea
              className=" w-full h-28 border border-[#D0D0D0] rounded-lg px-2 placeholder:text-sx placeholder-[#D0D0D0]"
              placeholder="Description"
              name=""
              id=""
            ></textarea>
          </div>

          <div>
            <AssignMembers />
          </div>
          {/* <div className=" space-y-2">
            <label className=" font-Nunito text-sm text-[#121212]" htmlFor="">
              Enter Recipient’s {Recipien}
            </label>
            <textarea
              className=" w-full h-28 border border-[#D0D0D0] rounded-lg px-2 placeholder:text-sx placeholder-[#D0D0D0]"
              placeholder="Description"
              name=""
              id=""
            ></textarea>
          </div> */}
        </div>
        <div className=" mt-5">
          <Send />
        </div>
      </div>
    </div>
  );
};

export default InAppNotification;
