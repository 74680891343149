import React from "react";

const Reports = () => {
  return (
    <div className="bg-gray-200 p-4 rounded-md mt-4">
      <p>This is the Reports card</p>
    </div>
  );
};

export default Reports;
