import React from "react";
import PagesList from "../../../components/pages-list/PagesList";
import Navbar from "../../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { ToolsAssetManagement } from "../onboarding";

const ToolsManagement = () => {
	const tableHead = ["", "Name", "Employee ID", "Tools Assigned"];
	const tableData = [
		{
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",

			ToolsAssigned: "Laptop, Printer, Stationeries, Office Gadgets",
		},
		{
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",

			ToolsAssigned: "No Tools Assigned",
		},
		{
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",

			ToolsAssigned: "Laptop, Printer",
		},
		{
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",

			ToolsAssigned: "Laptop, Printer",
		},
		{
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",

			ToolsAssigned: "Laptop, Printer",
		},
		{
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",

			ToolsAssigned: "No Tools Assigned",
		},
		{
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",

			ToolsAssigned: "No Tools Assigned",
		},
	];
	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<ToolsAssetManagement />

				<div className=" mx-12">
					<div className=" bg-white p-5 mt-5  ">
						<div className=" flex items-center justify-between">
							<div className=" flex items-center gap-5">
								<div className=" flex items-center">
									<Link
										to="assign-tools"
										className=" Satoshi cursor-pointer animate-pulse font-normal text-sm text-[#000]">
										Assign Tools
									</Link>
								</div>
								<div>
									<Link
										to="remove-tools"
										className=" cursor-pointer text-sm text-[#FF5353] Satoshi animate-pulse">
										Remove Tools
									</Link>
								</div>

								<div className=" flex items-center gap-3">
									<p className=" text-[#121212]  text-sm font-light font-Nunito">
										Search staff
									</p>
									<div className="relative">
										<input
											className="w-48 h-9 pr-10 pl-3 outline-none bg-[#F5F7F8] rounded-md"
											type="text"
										/>
										<div className="absolute inset-y-0 right-3 flex items-center pl-3">
											<FaSearch className="text-gray-500" />
										</div>
									</div>
								</div>
							</div>
							<div className=" cursor-pointer">
								<Link
									to="add-tools"
									className=" bg-black text-white grid justify-center items-center rounded-md w-28 h-8 Satoshi">
									All Tools
								</Link>
							</div>
						</div>
					</div>
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50 ">
							<tr>
								{tableHead.map((head, index) => (
									<th
										key={index}
										className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
										{head}
									</th>
								))}
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{tableData.map((rowData, rowIndex) => (
								<tr
									key={rowIndex}
									className="border-t-[0.5px] border-t-gray-200">
									<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										<Link>
											{" "}
											<input type="checkbox" />
										</Link>
									</td>
									<td className="px-4 py-4 flex items-center gap-3 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										<Link className=" border border-[#0811D4] grid justify-center items-center w-6 h-6 rounded-full">
											<p className=" Satoshi text-[11px] text-[#000]">CJ</p>
										</Link>
										{rowData?.Name}
									</td>
									<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										{rowData?.EmployeeID}
									</td>
									<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										{rowData?.ToolsAssigned}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default ToolsManagement;
