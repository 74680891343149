import { CelebrantsComponent } from "../birthday-celebrants";

const AnniversaryPage = () => (
	<CelebrantsComponent
		event="weddingAnniversaryMonth"
		picker="weddingAnniversary"
		title="Anniversary"
	/>
);

export default AnniversaryPage;
