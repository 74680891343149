import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import { useLocation, useNavigate } from "react-router-dom";
import useDepartmentStore from "../../../data/Stores/DepartmentStore";
import usePositionStore from "../../../data/Stores/PositionStore";
import { v4 } from "uuid";
import { Button } from "../../../components/buttons/Send";
import { toast } from "react-toastify";
import useErrorStore from "../../../data/Stores/errorstore";
import { apiCall } from "../../../data/useGenFetcher";
import { usePerformanceStore } from "../../../data/Stores/LoggerStore";

const Department = () => {
	// const [cards, setCards] = useState([{ id: 1, inputCount: 4 }]);

	// const handleAddCard = () => {
	// 	const newCardId = cards.length + 1;
	// 	setCards([...cards, { id: newCardId, inputCount: 2 }]);
	// };

	// const handleRemoveCard = cardId => {
	// 	if (cards.length > 1) {
	// 		setCards(cards.filter(card => card.id !== cardId));
	// 	}
	// };
	// const handleRemoveLastCard = () => {
	// 	if (cards.length > 1) {
	// 		setCards(cards.slice(0, -1)); // Remove the last card
	// 	}
	// };

	// const handleIncreaseInput = cardId => {
	// 	setCards(
	// 		cards.map(card =>
	// 			card.id === cardId ? { ...card, inputCount: card.inputCount + 1 } : card
	// 		)
	// 	);
	// };

	// const handleDecreaseInput = cardId => {
	// 	setCards(
	// 		cards.map(card =>
	// 			card.id === cardId && card.inputCount > 1
	// 				? { ...card, inputCount: card.inputCount - 1 }
	// 				: card
	// 		)
	// 	);
	// };

	let items = useLocation()?.state,
		department = useDepartmentStore(),
		position = usePositionStore(),
		[state, setState] = useState({}),
		textChange = e => {
			let { name, value } = e?.target;
			setState(prev => {
				let de = { ...prev, [name]: value };
				return de;
			});
		},
		init = {
			kpiId: v4(),
		},
		{ returnErrors } = useErrorStore(),
		[loading, setLoading] = useState(false),
		navigate = useNavigate(),
		{ addLogger, updateLogger } = usePerformanceStore();

	const [kpi] = useState([init]);
	const [formInfo, setFormInfo] = useState([
		{ title: "", subTitle: kpi, queId: v4() },
	]);

	const addKpi = formIndex => {
		const newForm = [...formInfo];
		newForm[formIndex].subTitle.push(init);
		setFormInfo(newForm);
	};

	const removeKpi = (formIndex, kpiIndex) => {
		console.log(formIndex, kpiIndex);
		const newForm = [...formInfo];
		newForm[formIndex]?.subTitle?.splice(kpiIndex, 1);
		setFormInfo(newForm);
	};

	const handleKpi = (event, formIndex, kpiIndex) => {
		const newInputs = [...formInfo];
		let { name, value } = event.target;
		// newInputs[formIndex].subTitle[kpiIndex][name] = value;
		let clonedObject = { ...newInputs[formIndex].subTitle[kpiIndex] };
		clonedObject = { ...clonedObject, [name]: value };

		let arr1 = [
			...newInputs[formIndex].subTitle?.map((it, i) =>
				i === kpiIndex ? clonedObject : it
			),
		];
		// Set the value for the kpiname property
		let total3 = arr1?.reduce((ac, i) => (ac += Number(i?.weight || 0)), 0);
		// Update the state with the modified newInputs
		// setKpi(newInputs);
		let arr2 = [
			...newInputs?.map((it, i) =>
				i === formIndex
					? {
							...it,
							subTitle: arr1,
							weight: total3,
					  }
					: it
			),
		];
		setFormInfo(arr2);
		// }
	};
	const handleFormTitle = (event, formIndex) => {
		const newFormInput = [...formInfo];
		let { name, value } = event.target;
		newFormInput[formIndex][name] = value;
		setFormInfo(newFormInput);
	};

	const addForm = () => {
		setFormInfo([...formInfo, { title: "", subTitle: [init], queId: v4() }]);
	};
	const removeForm = formIndex => {
		console.log(formIndex);
		const newFormTitle = [...formInfo];
		newFormTitle.splice(formIndex, 1);
		setFormInfo(newFormTitle);
	};

	useEffect(() => {
		if (items?.department)
			setState(prev => {
				let de = { ...prev, department: items?.department };
				return de;
			});
		if (items?.position)
			setState(prev => {
				let de = { ...prev, position: items?.position };
				return de;
			});
		if (items?.cloner) {
			setFormInfo(items?.cloner?.sections);
			setState(prev => {
				let de = {
					...prev,
					name: items?.cloner?.name,
					reviewPeriod: items?.cloner?.reviewPeriod,
				};
				return de;
			});
		}
		if (items?.edit) {
			let item = items?.edit;
			if (item) {
				setFormInfo(item?.sections);
				setState({
					...state,
					name: item?.name,
					reviewPeriod: item?.reviewPeriod,
					department: item?.department?._id,
					position: item?.position?._id,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	const handleSubmit = async event => {
		event.preventDefault();

		let errArr2 = [];

		if (formInfo?.length === 0) return toast.info("Questions required");

		// if (!state?.name)
		// 	errArr.push({
		// 		message: `Evaluation title is required`,
		// 		path: "title",
		// 	});
		if (!state?.reviewPeriod)
			errArr2.push({
				message: `Evaluation review Period is required`,
				path: "reviewPeriod",
			});

		for (let e = 0; e < formInfo.length; e++) {
			let element = formInfo?.[e];
			if (!element?.title)
				errArr2.push({
					message: `Question ${e + 1} is required`,
					path: "title",
				});
			if (!element?.subTitle)
				errArr2.push({
					message: `Question ${e + 1} sub evaluation is required`,
					path: "subTitle",
				});
			if (element?.subTitle?.length === 0)
				errArr2.push({
					message: `Question ${e + 1} sub evaluation is required`,
					path: "subTitle",
				});

			// console.log({ element });
			for (let o = 0; o < element?.subTitle.length; o++) {
				let elementOption = element?.subTitle?.[o];
				if (!elementOption?.kpiname)
					errArr2.push({
						message: `Question ${e + 1} Sub Evaluation title ${
							o + 1
						} is required`,
						path: "title",
					});
				if (!elementOption?.weight)
					errArr2.push({
						message: `Question ${e + 1} Sub Evaluation weight ${
							o + 1
						} is required`,
						path: "weight",
					});
			}
		}

		console.log({ formInfo, state, errArr2 });
		if (errArr2?.length > 0) return returnErrors({ error: errArr2 });

		// return;
		setLoading(true);
		let datum = {
			...state,
			active: true,
			sections: formInfo,
		};
		console.log({ datum });

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/performance/${items?.edit ? items?.edit?._id : ""}`,
			type: items?.edit ? "put" : "post",
			data: datum,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			items?.edit ? updateLogger(response) : addLogger(response);
			setState(init);
			navigate("/performance");
			return;
		}
		setLoading(false);
	};

	// console.log({ items, state });

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">Add New</p>
				</div>

				<div className=" bg-gradient-to-r from-[#EFF6FC] to-[#D6ECFF] mx-12 py-10 px-5 mt-5">
					<div className=" flex justify-between items-center pl-5">
						<img
							className=" w-24 h-24"
							src={require("../../../assets/hrmsLogo.png")}
							alt=""
						/>
						<p className=" text-xl font-Kumbh text-[#000] font-bold">
							HRMS LIMITED
						</p>
					</div>
					<div className=" pl-5 mt-3">
						<p className=" text-xl font-Kumbh text-[#000] font-bold">
							{state?.name}
						</p>
					</div>
					<div className=" flex justify-between items-center pl-5 mt-3">
						<p className="text-base font-Kumbh text-[#000] font-light">
							DEPARTMENT NAME -{" "}
							{
								department?.all?.docs?.find(it => it?._id === state?.department)
									?.name
							}
						</p>
						<p className="text-base font-Kumbh text-[#000] font-medium">
							POSITION NAME -{" "}
							{
								position?.data?.docs?.find(it => it?._id === state?.position)
									?.name
							}
						</p>
					</div>
					<PerformanceHeader state={state} textChange={textChange} />
				</div>

				<div className=" mt-5 bg-white py-10 pl-10 pr-5 mx-12">
					{/* Card section */}
					{formInfo?.map((card, ind) => (
						<div key={ind} className="bg-white shadow-lg rounded-lg p-5 mt-5">
							<div className="grid gap-2 mt-4">
								<div className="flex items-center gap-5 mb-3">
									<p className="Satoshi text-sm text-[#000]">{ind + 1}.</p>
									<input
										placeholder={`Enter KPI ${ind + 1} Title`}
										className="border border-[#CCCCCC] w-[580px] h-10 px-2 rounded-md outline-none"
										type="text"
										name="title"
										onChange={event => handleFormTitle(event, ind)}
										value={card?.title}
									/>
									<div className="flex items-center gap-3">
										<input
											placeholder={`2`}
											className="border border-[#CCCCCC] w-16 h-10 px-2 rounded-md outline-none"
											type="tel"
											readOnly
											name="weight" // Unique name attribute
											onChange={event => handleFormTitle(event, ind)}
											value={card?.weight}
										/>
									</div>
								</div>
								<div className="grid items-center gap-5 pl-5">
									{card?.subTitle?.map((kpi, i) => (
										<React.Fragment key={i}>
											<div className="flex items-center gap-5">
												<p className="Satoshi text-sm text-[#000]">{i + 1}.</p>
												<input
													placeholder={`Enter KPI ${i + 1}`}
													className="border border-[#CCCCCC] w-[560px] h-10 px-2 rounded-md outline-none"
													type="text"
													name="kpiname" // Unique name attribute
													onChange={event => handleKpi(event, ind, i)}
													value={kpi?.kpiname}
												/>
												<div className="flex items-center gap-3">
													<input
														placeholder={`2`}
														className="border border-[#CCCCCC] w-16 h-10 px-2 rounded-md outline-none"
														type="number"
														name="weight" // Unique name attribute
														onChange={event => handleKpi(event, ind, i)}
														value={kpi?.weight}
														min={1}
													/>
												</div>
											</div>
											<div className="justify-end flex items-center gap-4">
												{i === card?.subTitle?.length - 1 && (
													<img
														className="w-6 h-6 cursor-pointer"
														src={require("../../../assets/pluse.png")}
														alt=""
														onClick={() => addKpi(ind)}
													/>
												)}
												{card?.subTitle?.length > 1 && (
													<div
														className="w-6 h-6 bg-black cursor-pointer text-white grid justify-center items-center font-bold rounded-full"
														onClick={() => removeKpi(ind, i)}>
														<p className="relative bottom-[1px]">-</p>
													</div>
												)}
											</div>
										</React.Fragment>
									))}
								</div>
							</div>

							{/* Action buttons */}
							<div className="justify-end flex items-center gap-4 mt-5">
								{ind === formInfo?.length - 1 && (
									<img
										className="w-9 h-9 cursor-pointer"
										src={require("../../../assets/Group41.png")}
										alt=""
										onClick={addForm}
									/>
								)}
								{/* Single remove button */}
								{formInfo?.length > 1 && (
									<img
										className="w-9 h-9 cursor-pointer"
										src={require("../../../assets/Group42.png")}
										alt=""
										onClick={() => removeForm(ind)} // Use a function to remove the last card
									/>
								)}
							</div>
						</div>
					))}
					<div className="flex items-center justify-center py-4">
						<Button
							css={`text-sm Satoshi flex justify-center items-center text-white bg-black rounded-md w-32 h-9 animate-pulse`}
							children={items?.edit ? "Update" : "Create"}
							onClick={handleSubmit}
							loading={loading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Department;

export const PerformanceHeader = ({ state, textChange }) => {
	return (
		<>
			<div className="w-full bg-white p-8 rounded-xl mt-3">
				<h2 className="text-xl manrope text-[#013468] font-semibold">
					Performance Header
				</h2>
				<div className="w-4/5 grid grid-cols-2 gap-6 mt-6">
					{/* <Input
						name="name"
						label={"Title"}
						type={"text"}
						value={state?.name}
						onChange={textChange}
					/>
					<Input
						name="reviewPeriod"
						label={"Review Period"}
						type={"text"}
						value={state?.reviewPeriod}
						onChange={textChange}
					/> */}
					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Title
						</label>
						<input
							placeholder="Title"
							className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={state?.name}
							onChange={textChange}
							name={"name"}
						/>
					</div>
					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Review Period
						</label>
						<input
							placeholder="Review Period"
							className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={state?.reviewPeriod}
							onChange={textChange}
							name={"reviewPeriod"}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
