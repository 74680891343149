import React, { useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import BasicInformation from "../../../components/cards/BasicInformation";
import HiringDetails from "../../../components/cards/HiringDetails";
import ReviewSubmit from "../../../components/cards/ReviewSubmit";
import { useJobStore } from "../../../data/Stores/LoggerStore";
import useCompanyStore from "../../../data/Stores/CompanyStore";
import useErrorStore from "../../../data/Stores/errorstore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiCall } from "../../../data/useGenFetcher";
import { Button } from "../../../components/buttons/Send";

const NewJob = () => {
	const [activeStep, setActiveStep] = useState(1);

	const handleStepClick = step => {
		if (activeStep !== step) {
			setActiveStep(step);
		}
	};

	let init = {},
		[state, setState] = useState({}),
		[loading, setLoading] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		handleTextChange = (value, type) => {
			setState({ ...state, [type || "type"]: value });
		},
		{ addLogger } = useJobStore(),
		{ currentSelected } = useCompanyStore(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate();

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.name) return toast.info("Department name is required");
		let newState = state;

		if (state?.type) newState.type = state?.type?.toLowerCase();
		if (state?.level)
			newState.level = state?.level?.trim()?.replace(" ", "-")?.toLowerCase();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/job/${currentSelected}`,
			type: "post",
			data: newState,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			addLogger(response);
			setState(init);
			navigate(-1);
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">New Job</p>
				</div>

				<div className=" bg-white mx-12 py-5 mt-5 px-16 flex justify-center gap-10">
					<div className=" w-1/5">
						<div className=" grid">
							{/*  */}
							<div
								onClick={loading ? null : () => handleStepClick(1)}
								className=" flex gap-2 cursor-pointer items-center">
								<div
									className={`w-6 h-6 rounded-full font-Nunito grid justify-center items-center text-sm ${
										activeStep === 1
											? "text-[#fff] bg-[#F7AC25]"
											: "text-white bg-[#EDF0F6]"
									}`}
									onClick={loading ? null : () => handleStepClick(1)}>
									1
								</div>
								<p
									className={`font-Nunito text-sm ${
										activeStep === 1
											? "text-[#000] bg-[#fff]"
											: "text-black bg-[#fff]"
									}`}>
									{" "}
									Basic Information
								</p>
							</div>
							<div
								className={`w-1 h-9 bg-[#EDF0F6] ml-[10px] ${
									activeStep === 2
										? "text-[#fff] bg-[#F7AC25]"
										: "text-white bg-[#EDF0F6]"
								}`}></div>

							{/*  */}
							<div
								onClick={loading ? null : () => handleStepClick(2)}
								className=" flex cursor-pointer gap-2 items-center">
								<div
									className={`w-6 h-6 rounded-full font-Nunito grid justify-center items-center text-sm ${
										activeStep === 2
											? "text-[#fff] bg-[#F7AC25]"
											: "text-white bg-[#EDF0F6]"
									}`}
									onClick={loading ? null : () => handleStepClick(2)}>
									2
								</div>
								<p
									className={`font-Nunito text-sm ${
										activeStep === 2
											? "text-[#F7AC25] bg-[#fff]"
											: "text-black bg-[#fff]"
									}`}>
									{" "}
									Hiring Details
								</p>
							</div>
							<div
								className={`w-1 h-9 bg-[#EDF0F6] ml-[10px] ${
									activeStep === 3
										? "text-[#fff] bg-[#F7AC25]"
										: "text-white bg-[#EDF0F6]"
								}`}></div>
							{/*  */}
							<div
								onClick={loading ? null : () => handleStepClick(3)}
								className=" flex gap-2 items-center cursor-pointer">
								<div
									className={`w-6 h-6 rounded-full  font-Nunito grid justify-center items-center text-sm ${
										activeStep === 3
											? "text-[#fff] bg-[#F7AC25]"
											: "text-white bg-[#EDF0F6]"
									}`}
									onClick={loading ? null : () => handleStepClick(3)}>
									3
								</div>
								<p
									className={`font-Nunito text-sm ${
										activeStep === 3
											? "text-[#F7AC25] bg-[#fff]"
											: "text-black bg-[#fff]"
									}`}>
									Review & Submit
								</p>
							</div>
						</div>
					</div>
					<div className=" w-4/5">
						{activeStep === 1 && (
							<BasicInformation
								textChange={textChange}
								state={state}
								setState={setState}
								handleTextChange={handleTextChange}
							/>
						)}
						{activeStep === 2 && (
							<HiringDetails
								textChange={textChange}
								state={state}
								setState={setState}
								handleTextChange={handleTextChange}
							/>
						)}
						{activeStep === 3 && (
							<ReviewSubmit
								textChange={textChange}
								state={state}
								setState={setState}
								handleTextChange={handleTextChange}
								handleStepClick={handleStepClick}
							/>
						)}
					</div>
				</div>
				<div className=" flex justify-between items-center bg-white mx-12 py-10 px-16">
					<button
						className="rounded-md w-44 h-12 text-[#5608D4] border border-[#5608D4] font-Nunito text-sm"
						disabled={activeStep === 1 || loading}
						onClick={() => {
							handleStepClick(activeStep - 1);
						}}>
						Previous
					</button>
					{/* <button className=" text-white font-Nunito text-sm bg-gradient-to-r from-[#5608D4] to-[#04096E] rounded-md w-44 h-12 animate-pulse">
						Save and continue
					</button> */}
					<Button
						css={
							" text-white font-Nunito text-sm bg-gradient-to-r from-[#5608D4] to-[#04096E] rounded-md w-44 h-12 animate-pulse"
						}
						children={activeStep === 3 ? "Save and Continue" : "Next"}
						onClick={
							activeStep === 3
								? handleSubmit
								: () => {
										handleStepClick(activeStep + 1);
								  }
						}
						loading={loading}
					/>
				</div>
			</div>
		</div>
	);
};

export default NewJob;
