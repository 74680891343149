import React, { useState } from "react";
import ActionCompleted from "../cards/ActionCompleted";

const DownloadProfile = () => {
  const [isAction, setIsAction] = useState(false);

  const handleAction = () => {
    setIsAction(!isAction);
  };
  const closeAction = () => {
    setIsAction(false);
  };
  return (
    <div>
      <div
        onClick={handleAction}
        className=" cursor-pointer border-[0.5px] border-[#0811D4] w-40 h-11 rounded-xl flex items-center justify-center gap-2"
      >
        <img
          className=" w-5 h-5"
          src={require("../../assets/iconamoon_download-thin.png")}
          alt=""
        />
        <p className=" text-[#0811D4] text-sm font-Nunito">Download Profile</p>
      </div>
      {isAction && <ActionCompleted onClose={closeAction} />}
    </div>
  );
};

export default DownloadProfile;
