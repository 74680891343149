import React, { useState } from "react";
import Delete from "../cards/Delete";
import { useParams } from "react-router-dom";
// import ActionCompleted from "../cards/ActionCompleted";
// import CreateDepartment from "../cards/CreateDepartment"; // Import the card component

const DeleteEditDetails = ({ editDetailsCard, title, deleteFunc, loading }) => {
	const [showDelete, setShowDelete] = useState(false);
	const [showEditDetails, setShowEditDetails] = useState(false),
		{ id } = useParams();

	const handleDelete = () => {
		setShowDelete(!showDelete);
	};

	const handleEditDetails = () => {
		setShowEditDetails(!showEditDetails);
	};

	const closeDelete = () => {
		setShowDelete(false);
	};

	const closeEditDetails = () => {
		setShowEditDetails(false);
	};

	return (
		<div>
			<div className="flex items-center gap-5 mt-40">
				<button
					onClick={handleDelete}
					className="w-44 h-12 rounded-md font-Nunito text-sm border border-[#D4082D] text-[#D4082D]">
					Delete {id || "department"}
				</button>
				{showDelete && (
					<Delete
						deleteFunc={deleteFunc || closeDelete}
						onClose={closeDelete}
						title={title}
						loading={loading}
					/>
				)}
				<button
					onClick={handleEditDetails}
					className="w-44 h-12 rounded-md bg-[#5608D4] text-white text-sm font-Nunito">
					Edit details
				</button>
				{showEditDetails &&
					React.cloneElement(editDetailsCard, { onClose: closeEditDetails })}
			</div>
		</div>
	);
};

export default DeleteEditDetails;
