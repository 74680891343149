import React, { useContext, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/CeHRlogo.png";
import useAuthStore from "../../data/Stores/Authstore";
import {
	usePerformanceReviewStore,
	usePerformanceStore,
} from "../../data/Stores/LoggerStore";
import {
	Button,
	LoadMore,
	MainPaginate,
	PageLoader,
} from "../../components/buttons/Send";
import Edit from "../../assets/edit.svg";
import DeleteImg from "../../assets/delete.svg";
import Delete from "../../components/cards/Delete";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/useGenFetcher";
import useStaffStore from "../../data/Stores/StaffReducer";
import { createMarkup } from "../../components/cards/ReviewSubmit";
import { GlobalState } from "../../data/Context";
import usePositionStore from "../../data/Stores/PositionStore";

let gradeData = [
		{ minimumScore: 0, maximumScore: 20, grade: "Poor", short: "F" },
		{
			minimumScore: 21,
			maximumScore: 39,
			grade: "Below Expectation",
			short: "E",
		},
		{ minimumScore: 40, maximumScore: 59, grade: "Average", short: "D" },
		{ minimumScore: 60, maximumScore: 79, grade: "Commendable", short: "C" },
		{ minimumScore: 80, maximumScore: 90, grade: "Excellent", short: "B" },
		{ minimumScore: 91, maximumScore: 100, grade: "Outstanding", short: "A" },
	],
	scoreData = [
		{ grade: "Poor", score: 1 },
		{
			grade: "Below Expectation",
			score: 2,
		},
		{ grade: "Average", score: 3 },
		{ grade: "Commendable", score: 4 },
		{ grade: "Excellent", score: 5 },
	],
	recommendationData = [
		{ value: "Promotion" },
		{ value: "Salary Increment" },
		{ value: "Additional Responsibility" },
		{ value: "Others" },
	];

const Performance = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { user } = useAuthStore(),
		{ canManager2, canManager1 } = useContext(GlobalState),
		evalTabList = [
			{
				title: "My Evaluation",
				show: true,
			},
			{
				title: "Evaluations Guide",
				show: true,
			},
			{
				title: "Manage Evaluations",
				show: user?.isAdmin || canManager1 || canManager2,
			},
			{
				title: "Evaluations Lists",
				show: user?.isAdmin,
			},
		],
		[showEval, setShowEval] = useState(evalTabList?.[0]?.title);

	const [topText] = useState("evaluation");

	const handleChangeEvaluation = title => {
		setShowEval(title);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Performance
					</p>
				</div>

				<div className=" bg-white mx-12 mt-5 p-5">
					<div>
						<div className=" bg-white  ">
							<div className=" flex items-center justify-between">
								<div className=" flex items-center gap-5">
									<div className=" flex items-center gap-5">
										{evalTabList
											?.filter(it => it?.show)
											?.map((it, i) => (
												<p
													key={i}
													onClick={() => handleChangeEvaluation(it?.title)}
													className={`Satoshi font-normal cursor-pointer text-sm text-[#000] ${
														showEval === it?.title
															? " text-[#5608D4]"
															: "bg-white text-[#1C1C1C]  "
													}`}>
													{it?.title}
												</p>
											))}
									</div>

									<div className=" flex items-center gap-3 ml-5">
										<p className=" text-[#121212]  text-sm font-light font-Nunito">
											Search {topText}
										</p>
										<div className="relative">
											<input
												placeholder="Search"
												className="w-48 h-9 pr-10 pl-3 placeholder:text-xs outline-none bg-[#F5F7F8] rounded-md"
												type="text"
											/>
											<div className="absolute inset-y-0 right-3 flex items-center pl-3">
												<FaSearch className="text-gray-500" />
											</div>
										</div>
									</div>
								</div>
								<div className=" cursor-pointer">
									<Link
										to="add-new-performance"
										// onClick={handleAction}
										className={`text-sm Satoshi grid justify-center items-center text-white bg-black rounded-md w-32 h-9 animate-pulse ${
											user?.isAdmin ? "" : "hidden"
										}`}>
										Create new
									</Link>
									<div
										className={`flex items-center gap-5 ${
											user?.isAdmin ? "" : "hidden"
										}`}></div>
								</div>
							</div>

							{/* Cards */}
							{showEval === evalTabList?.[2]?.title && (
								<ManageStaffEvaluations />
							)}

							{showEval === evalTabList?.[0]?.title && (
								<EvaluationDetails type={"self_score"} />
							)}

							{showEval === evalTabList?.[3]?.title && (
								<ManageEvaluationsList />
							)}

							{showEval === evalTabList?.[1]?.title && (
								<div className="card">
									<div className="flex items-start mt-8">
										{/* Left Column: Scoring Format */}
										<div className="w-1/2 pr-4">
											<h2 className="text-lg font-bold mb-2">SCORING FORMAT</h2>
											<div className=" flex items-center justify-between">
												<div className=" text-[#000] text-sm">
													<p>
														Lower Band: <strong>1</strong>
													</p>
													<p>
														Upper Band: <strong>5</strong>
													</p>
												</div>
												<div className=" w-[260px]">
													<p className="mt-2 text-gray-600 text-sm">
														All score entries must be between the lower and
														upper band
													</p>
												</div>
											</div>

											{/* Scoring Format Table */}
											<div className="mt-4">
												<table className="w-full">
													<thead className=" bg-[#F9FAFB]">
														<tr className="border-b text-[#667085]">
															<th className="text-left py-2">SCORE</th>
															<th className="text-left py-2">DESCRIPTION</th>
														</tr>
													</thead>
													<tbody y>
														{scoreData?.map((item, i) => (
															<tr
																className="border-b text-sm text-[#667085]"
																key={i}>
																<td className="py-2">{item?.score}</td>
																<td className="py-2">{item?.grade}</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>

										{/* Right Column: Final Grade Summary */}
										<div className="w-1/2 pl-4">
											<h2 className="text-lg font-bold mb-2">
												FINAL GRADE SUMMARY
											</h2>

											{/* Final Grade Summary Table */}
											<div className="mt-4">
												<table className="w-full">
													<thead className=" bg-[#F9FAFB]">
														<tr className="border-b text-[#667085]">
															<th className="text-left py-2">SCORE</th>
															<th className="text-left py-2">DESCRIPTION</th>
															<th className="text-left py-2">GRADE</th>
														</tr>
													</thead>
													<tbody>
														{gradeData?.map((item, i) => (
															<tr
																className="border-b text-sm text-[#667085]"
																key={i}>
																<td className="py-2">
																	{item?.minimumScore} - {item?.maximumScore}
																</td>
																<td className="py-2">{item?.grade}</td>
																<td className="py-2">{item?.short}</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Performance;

export const EvaluationDetails = ({
	type,
	user,
	isEvaluate,
	manager_score,
	manager_score2,
}) => {
	const [formInfo, setFormInfo] = useState(null);
	let [data, setData] = useState(null),
		[updater, setUpdater] = useState(null),
		[mainTotal, setMainTotal] = useState(null),
		[grader, setGrader] = useState(""),
		[managerLength, setManagerLength] = useState(0),
		[formLength, setFormLength] = useState(0);
	const handleKpi = (event, formIndex, kpiIndex) => {
		const newInputs = [...formInfo];
		let { name, value } = event.target;
		// newInputs[formIndex].subTitle[kpiIndex][name] = value;
		let clonedObject = { ...newInputs[formIndex].subTitle[kpiIndex] };
		clonedObject = { ...clonedObject, [name]: value };

		let arr1 = [
			...newInputs[formIndex].subTitle?.map((it, i) =>
				i === kpiIndex ? clonedObject : it
			),
		];
		let newArr = arr1?.map(ww => {
			let we = Number(ww?.manager_score || 0) + Number(ww?.manager_score2 || 0),
				we2 = Number(ww?.manager_score || 0);
			let newW = managerLength > 1 ? we / 2 : we2;
			return { ...ww, weighted_score: newW * ww?.weight };
		});
		let total = newArr?.reduce((ac, i) => (ac += Number(i?.[type] || 0)), 0);
		let total2 = newArr?.reduce(
			(ac, i) => (ac += Number(i?.weighted_score || 0)),
			0
		);
		let total3 = newArr?.reduce((ac, i) => (ac += Number(i?.weight || 0)), 0);
		// let total4 = newArr?.reduce(
		// 	(ac, i) => (ac += Number(i?.self_score || 0)),
		// 	0
		// );
		// console.log({ arr1, total, newArr, total2 });
		let arr2 = [
			...newInputs?.map((it, i) =>
				i === formIndex
					? {
							...it,
							subTitle: newArr,
							[type]: total,
							weighted_score: total2,
							weight: total3,
							// self_score: total4,
					  }
					: it
			),
		];
		setFormInfo(arr2);
	};

	useEffect(() => {
		if (formInfo?.length > 0) {
			setMainTotal({
				weight: formInfo?.reduce?.((ac, i) => (ac += i?.weight || 0), 0),
				self_score: formInfo?.reduce?.(
					(ac, i) => (ac += i?.self_score || 0),
					0
				),
				manager_score: formInfo?.reduce?.(
					(ac, i) => (ac += i?.manager_score || 0),
					0
				),
				manager_score2: formInfo?.reduce?.(
					(ac, i) => (ac += i?.manager_score2 || 0),
					0
				),
				weighted_score: formInfo?.reduce?.(
					(ac, i) => (ac += i?.weighted_score || 0),
					0
				),
			});
			setFormLength(
				formInfo?.reduce((a, c) => (a += Number(c?.subTitle?.length || 0)), 0)
			);
		}
	}, [formInfo]);

	useEffect(() => {
		if (mainTotal) {
			let dd = mainTotal,
				gradeScore = 0;
			if (dd?.weighted_score) {
				gradeScore = (dd?.weighted_score / 500) * 100;

				let finder = gradeData?.find(
					item =>
						Math.floor(gradeScore) >= Math.floor(item?.minimumScore) &&
						Math.floor(gradeScore) <= Math.floor(item?.maximumScore)
				);
				if (finder) setGrader(finder?.grade);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainTotal]);

	// console.log({ mainTotal });

	const handleFormTitle = (event, formIndex) => {
		const newFormInput = [...formInfo];
		let { name, value } = event.target;
		newFormInput[formIndex][name] = value;
	};

	let performance = usePerformanceStore(),
		performanceReview = usePerformanceReviewStore(),
		staff = useStaffStore(),
		auth = useAuthStore(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate(),
		[loading, setLoading] = useState(null),
		[remarks, setRemark] = useState(""),
		[manager1Remark, setManager1Remark] = useState(""),
		[staffRemarkForManager1, setstaffRemarkForManager1] = useState(""),
		[lineManager1Recommendation, setlineManager1Recommendation] = useState(""),
		[staffRemarkForManager2, setstaffRemarkForManager2] = useState(""),
		[lineManager2Recommendation, setlineManager2Recommendation] = useState(""),
		[manager2Remark, setManager2Remark] = useState("");

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/performanceReview?_populate=performance&_populate=createdBy&_populate=createdBy.level&_populate=createdBy.position&_populate=createdBy.department&_limit=0`,
			getter: d => performanceReview?.getLogger(d),
		});
		apiCall({
			type: "get",
			url: `/api/v1/performance?_populate=department&_populate=position&_limit=0`,
			getter: d => performance?.getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (staff?.data) {
			let mainArr = staff?.data?.docs;
			let mainPerfArr = performance?.data?.docs;
			// console.log({ isEvaluate, user });
			let findDept = mainArr
				?.filter(it => it?.gradeForPosition1?.length > 0)
				?.filter(it => {
					let posi = null;
					if (isEvaluate) posi = isEvaluate?.performance?.position;
					else {
						let findPosi = mainPerfArr?.find(it => {
							let dept =
								auth?.user?.profile?.department?._id ||
								auth?.user?.department?._id;
							if (isEvaluate) dept = isEvaluate?.department;
							let posi =
								auth?.user?.profile?.position?._id || auth?.user?.position?._id;
							if (isEvaluate) dept = isEvaluate?.department;
							if (isEvaluate) posi = isEvaluate?.position;
							let dd = it?.department?._id === dept;
							let pp = it?.position?._id === posi;
							// console.log({ dd, dept, isEvaluate }, "performance");
							return dd && pp;
						});
						if (findPosi) {
							// console.log({ findPosi });
							posi = findPosi?.position?._id;
						}
					}
					let pp = it?.gradeForPosition1?.includes(posi);
					console.log(
						{
							posi,
							gradeForPosition1: it?.gradeForPosition1,
							pp,
						},
						"staff"
					);
					return it?.gradeForPosition1?.includes(posi);
				});
			console.log({ findDept });

			if (findDept?.length > 0) {
				setManagerLength(1);
				let findDept2 = mainArr
					?.filter(it => it?.gradeForPosition2?.length > 0)
					?.filter(it => {
						let posi = null;
						if (isEvaluate) posi = isEvaluate?.performance?.position;
						else {
							let findPosi = mainPerfArr?.find(it => {
								let dept =
									auth?.user?.profile?.department?._id ||
									auth?.user?.department?._id;
								if (isEvaluate) dept = isEvaluate?.department;
								let posi =
									auth?.user?.profile?.position?._id ||
									auth?.user?.position?._id;
								if (isEvaluate) dept = isEvaluate?.department;
								if (isEvaluate) posi = isEvaluate?.position;
								let dd = it?.department?._id === dept;
								let pp = it?.position?._id === posi;
								// console.log({ dd, dept, isEvaluate }, "performance");
								return dd && pp;
							});
							if (findPosi) {
								// console.log({ findPosi });
								posi = findPosi?.position?._id;
							}
						}
						let pp2 = it?.gradeForPosition2?.includes(posi);
						console.log(
							{
								posi,
								pp2,
								gradeForPosition2: it?.gradeForPosition2,
							},
							"staff"
						);
						return it?.gradeForPosition2?.includes(posi);
					});
				console.log({ findDept2 });
				if (findDept2?.length > 0) setManagerLength(2);
			}
		}
	}, [auth?.user, isEvaluate, staff?.data, performance?.data]);
	// console.log({ managerLength });

	useEffect(() => {
		if (performance?.data) {
			let mainPerfArr = performance?.data?.docs;
			let mainPerfReviewArr = performanceReview?.data?.docs;

			// console.log({ isEvaluate, user });
			let findDept = mainPerfArr?.find(it => {
				let dept =
					auth?.user?.profile?.department?._id || auth?.user?.department?._id;
				if (isEvaluate) dept = isEvaluate?.department;
				let posi =
					auth?.user?.profile?.position?._id || auth?.user?.position?._id;
				if (isEvaluate) dept = isEvaluate?.department;
				if (isEvaluate) posi = isEvaluate?.position;
				let dd = it?.department?._id === dept;
				let pp = it?.position?._id === posi;
				// console.log({ dd, dept, isEvaluate }, "performance");
				return dd && pp;
			});
			if (findDept) {
				setData(findDept);
				setFormInfo(findDept?.sections);
				let findRef = mainPerfReviewArr?.find(it => {
					let dept =
						auth?.user?.profile?.department?._id || auth?.user?.department?._id;
					if (isEvaluate) dept = isEvaluate?.department;
					let posi =
						auth?.user?.profile?.position?._id || auth?.user?.position?._id;
					if (isEvaluate) dept = isEvaluate?.department;
					if (isEvaluate) posi = isEvaluate?.position;
					let dd = it?.department === dept;
					let pp = it?.position === posi,
						ff = it?.createdBy?._id === auth?.user?._id;
					// console.log({ ff, dd, dept, isEvaluate }, " performanceReview");
					return user ? it?._id === user : dd && ff && pp;
				});
				if (findRef) {
					setUpdater(findRef);

					let mappedSection = [];

					for (let s = 0; s < findDept?.sections.length; s++) {
						const element = findDept?.sections[s];
						let findOther = findRef?.sections?.find(
							it => it?.queId === element?.queId
						);
						if (findOther) {
							let newSubTitle = [];
							for (let ts = 0; ts < element?.subTitle.length; ts++) {
								const elementSub = element?.subTitle[ts];
								let findOtherSub = findOther?.subTitle?.find(
									it => it?.kpiId === elementSub?.kpiId
								);
								if (findOtherSub) {
									newSubTitle?.push({
										...findOtherSub,
										kpiname: elementSub?.kpiname,
										weight: elementSub?.weight,
									});
								} else {
									newSubTitle?.push(elementSub);
								}
							}
							mappedSection?.push({
								...findOther,
								subTitle: newSubTitle,
								title: element?.title,
								weight: element?.weight,
							});
						} else {
							mappedSection?.push(element);
						}
					}
					// console.log({
					// 	mappedSection,
					// 	main: findDept?.sections,
					// 	review: findRef?.sections,
					// });
					setFormInfo(mappedSection);
					setRemark(findRef?.remarks);
					setManager1Remark(findRef?.manager1Remark);
					setstaffRemarkForManager1(findRef?.staffRemarkForManager1);
					setlineManager1Recommendation(findRef?.lineManager1Recommendation);
					setManager2Remark(findRef?.manager2Remark);
					setstaffRemarkForManager2(findRef?.staffRemarkForManager2);
					setlineManager2Recommendation(findRef?.lineManager2Recommendation);
				}
			}
		}
	}, [
		auth?.user,
		user,
		isEvaluate,
		performance?.data,
		performanceReview?.data,
	]);

	const handleRemark = (editor, seti) => {
		let remark1 = editor;
		if (seti) seti(remark1);
		else setRemark(remark1);
	};

	let ref = useRef();
	const handlePrint = useReactToPrint({
		content: () => ref.current,
		documentTitle: `${data?.name || ""}-${moment(
			data?.reviewPeriod || ""
		).format("DD/MM/YYYY")}`,
		bodyClass: "px-4 py-10",
	});

	const handleSubmit = async event => {
		event.preventDefault();

		let errArr2 = [];

		for (let e = 0; e < formInfo.length; e++) {
			let element = formInfo?.[e];

			// console.log({ element });
			for (let o = 0; o < element?.subTitle.length; o++) {
				let elementOption = element?.subTitle?.[o];
				if (!elementOption?.[type])
					errArr2.push({
						message: `Question ${
							element?.title || e + 1 || ""
						} Sub Evaluation (${
							elementOption?.kpiname || o + 1 || ""
						}) ${type?.replace("_", " ")} is required`,
						path: type,
					});
				if (elementOption?.[type]) {
					if (Number(elementOption?.[type]) < Number(1))
						errArr2.push({
							message: `Question ${
								element?.title || e + 1 || ""
							} Sub Evaluation (${
								elementOption?.kpiname || o + 1 || ""
							}) ${type?.replace(
								"_",
								" "
							)} value is less than the required weight(${1})`,
							path: type,
						});
					if (Number(elementOption?.[type]) > Number(5))
						errArr2.push({
							message: `Question ${
								element?.title || e + 1 || ""
							} Sub Evaluation (${
								elementOption?.kpiname || o + 1 || ""
							}) ${type?.replace(
								"_",
								" "
							)} value is greater than the required weight(${5})`,
							path: type,
						});
				}
			}
		}

		// console.log({ returnedData });
		if (errArr2?.length > 0) return returnErrors({ error: errArr2 });

		setLoading(true);
		let datum = {
			sections: formInfo,
			department: data?.department?._id,
			performance: data?._id,
			remarks: remarks || "",
			position: data?.position?._id,
		};
		if (updater) {
			datum = {
				...datum,
				lineManager2Recommendation: lineManager2Recommendation || "",
				lineManager1Recommendation: lineManager1Recommendation || "",
				staffRemarkForManager2: staffRemarkForManager2 || "",
				staffRemarkForManager1: staffRemarkForManager1 || "",
				manager1Remark: manager1Remark || "",
				manager2Remark: manager2Remark || "",
			};
		}
		console.log({ datum });
		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/performanceReview/${updater ? updater?._id : ""}`,
			type: updater ? "put" : "post",
			data: datum,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		if (response) {
			await apiCall({
				type: "get",
				url: `/api/v1/performanceReview/${
					response?.data?.data?._id || response?.data?._id || response?._id
				}?_populate=performance&_populate=createdBy&_populate=createdBy.level&_populate=createdBy.position&_populate=createdBy.department`,
				getter: d =>
					updater
						? performanceReview?.updateLogger(d)
						: performanceReview?.addLogger(d),
			});
			if (isEvaluate) navigate(-1);
			setLoading(false);
			return;
		}
		setLoading(false);
	};

	if (!performance?.data) return <PageLoader />;
	console.log({ type });

	return (
		<>
			<div className="card">
				{!data ? (
					<>
						<h2 className="text-2xl md:text-5xl manrope text-[#013468] font-semibold text-center py-5">
							No evaluation data found
						</h2>
					</>
				) : (
					<div className="p-4 print:p-0" ref={ref}>
						{/* Existing Header Section */}
						<div className="w-full p-4 border-b print:p-0">
							<div className="flex justify-between items-center">
								<div>
									<img src={logo} alt="Company Logo" className="w-20 h-20" />
								</div>
								<div className="text-lg font-semibold">
									<h1>CEPHAS ICT HUB</h1>
								</div>
							</div>

							<div className="my-4">
								<h2 className="text-2xl font-bold">{data?.name}</h2>
								<p>
									Review Period:{" "}
									<span className="font-bold">{data?.reviewPeriod}</span>
								</p>
								{updater && (
									<p className="text-xl">
										Staff:{" "}
										<span className="text-xl font-bold">
											{updater?.createdBy?.profile?.lastName ||
												updater?.createdBy?.lastName}{" "}
											{updater?.createdBy?.profile?.firstName ||
												updater?.createdBy?.firstName}
										</span>
									</p>
								)}
								<div className="flex justify-between items-center">
									<p>DEPT - {data?.department?.name}</p>
									<div className="font-bold text-blue-500">
										ROLE:
										<span className="">
											{updater?.createdBy?.profile?.position?.name ||
												updater?.createdBy?.position?.name ||
												data?.position?.name}{" "}
										</span>
									</div>
								</div>
							</div>
						</div>

						{/* Review Sections */}
						<div className="mt-8">
							{formInfo?.map((item, index) => (
								<div
									key={index}
									className="w-full p-4 mb-6 bg-gray-50 rounded-md page-break">
									{/* Table Headers */}
									<div className="flex justify-between items-center mb-2">
										<div className="w-1/2"></div>
										<div className="flex gap-6 w-1/2 justify-between">
											<div className="w-1/3 text-center font-semibold">
												Weight
											</div>
											<div className="w-1/3 text-center font-semibold">
												Self Score
											</div>
											<div
												className={`w-1/3 text-center font-semibold ${
													managerLength > 0 ? "" : "hidden"
												}`}>
												Manager <span className="print:hidden"> score</span>
												{managerLength > 1 ? " 1" : ""}
											</div>
											<div
												className={`w-1/3 text-center font-semibold ${
													managerLength > 1 ? "" : "hidden"
												}`}>
												Manager <span className="print:hidden"> score</span> 2
											</div>
											<div className="w-1/3 text-center font-semibold">
												Total
											</div>
										</div>
									</div>

									{/* Test Title Row */}
									<div className="flex justify-between items-center pb-2 mb-4 gap-3">
										<h3 className="font-bold text-xl w-1/2">
											{index + 1} {item?.title}
										</h3>

										{/* Inputs for Weight, Self Score, Total Score */}
										<div className="flex gap-6 w-1/2 justify-between">
											{/* Weight Input */}
											<div className="w-1/3">
												<input
													type="tel"
													readOnly
													className="w-full border rounded-md p-2 text-center bg-gray-100"
													name="weight"
													onChange={event => handleFormTitle(event, index)}
													value={item?.weight}
												/>
											</div>
											{/* Self Score Input */}
											<div className="w-1/3">
												<input
													type="tel"
													placeholder="Self Score"
													className="w-full border rounded-md p-2 text-center"
													readOnly
													name="self_score"
													onChange={event => handleFormTitle(event, index)}
													value={item?.self_score}
												/>
											</div>
											{/* Manager 1 Score Input */}
											<div
												className={`w-1/3 ${
													managerLength > 0 ? "" : "hidden"
												}`}>
												<input
													type="tel"
													placeholder="Manager 1"
													className={`w-full border rounded-md p-2 text-center`}
													readOnly
													name="manager_score"
													onChange={event => handleFormTitle(event, index)}
													value={item?.manager_score}
												/>
											</div>
											{/* Manager 2 Score Input */}
											<div
												className={`w-1/3 ${
													managerLength > 1 ? "" : "hidden"
												}`}>
												<input
													type="tel"
													placeholder="Manager 2"
													className={`w-full border rounded-md p-2 text-center`}
													readOnly
													name="manager_score2"
													onChange={event => handleFormTitle(event, index)}
													value={item?.manager_score2}
												/>
											</div>
											{/* Total Score Input */}
											<div className="w-1/3">
												<input
													type="tel"
													placeholder="Total score"
													className="w-full border rounded-md p-2 text-center"
													readOnly
													name="weight_score"
													onChange={event => handleFormTitle(event, index)}
													value={item?.weight_score}
												/>
											</div>
										</div>
									</div>

									{/* Questions */}
									{item?.subTitle?.map((question, qIndex) => (
										<div
											key={qIndex}
											className="flex justify-between items-center mb-4 gap-3 page-break">
											<div className="w-1/2">
												<input
													type="text"
													value={question?.kpiname}
													readOnly
													className="w-full border rounded-md p-2 bg-gray-100"
												/>
											</div>

											<div className="flex gap-6 w-1/2 justify-between">
												<div className="w-1/3">
													<input
														type="tel"
														readOnly
														className="w-full border rounded-md p-2 text-center bg-gray-100"
														name="weight" // Unique name attribute
														onChange={event => handleKpi(event, index, qIndex)} // Call handleKpi when input changes
														value={question?.weight}
													/>
												</div>
												<div className="w-1/3">
													<input
														className="w-full border rounded-md p-2 text-center"
														placeholder="Self Score"
														value={question?.self_score}
														min={1}
														max={5}
														type="number"
														name="self_score" // Unique name attribute
														onChange={event => handleKpi(event, index, qIndex)} // Call handleKpi when input changes
														readOnly={[
															"manager_score",
															"manager_score2",
														]?.includes(type)}
													/>
												</div>
												<div
													className={`w-1/3 ${
														managerLength > 0 ? "" : "hidden"
													}`}>
													<input
														placeholder="Manager 1"
														className={`w-full border rounded-md p-2 text-center`}
														value={question?.manager_score}
														min={1}
														max={5}
														type="number"
														name="manager_score" // Unique name attribute
														onChange={event => handleKpi(event, index, qIndex)} // Call handleKpi when input changes
														readOnly={[
															"self_score",
															"manager_score2",
														]?.includes(type)}
													/>
												</div>
												<div
													className={`w-1/3 ${
														managerLength > 1 ? "" : "hidden"
													}`}>
													<input
														placeholder="Manager 2"
														className={`w-full border rounded-md p-2 text-center`}
														value={question?.manager_score2}
														min={1}
														max={5}
														type="number"
														name="manager_score2" // Unique name attribute
														onChange={event => handleKpi(event, index, qIndex)} // Call handleKpi when input changes
														readOnly={["self_score", "manager_score"]?.includes(
															type
														)}
													/>
												</div>
												<div className="w-1/3">
													<input
														placeholder="Total score"
														className="w-full border rounded-md p-2 text-center"
														value={question?.weighted_score}
														max={Number(question?.weight)}
														type="number"
														readOnly
														name="weighted_score" // Unique name attribute
														onChange={event => handleKpi(event, index, qIndex)} // Call handleKpi when input changes
													/>
												</div>
											</div>
										</div>
									))}
								</div>
							))}
							<div className="mt-4 bg-gray-50 p-4">
								{/* Commulative Row */}
								<div className="flex justify-between items-center py-2 gap-3">
									<span className="font-bold text-xl w-1/2"></span>

									{/* Inputs for Weight, Self Score, Total Score */}
									{mainTotal && (
										<div className="flex gap-6 w-1/2 justify-between">
											{Object.keys(mainTotal)?.map((it, i) => (
												<div
													key={i}
													className={`w-1/3 ${
														["manager_score2"]?.includes(it)
															? it === "manager_score2" && managerLength > 1
																? ""
																: "hidden"
															: ""
													} ${
														["manager_score"]?.includes(it)
															? it === "manager_score" && managerLength > 0
																? ""
																: "hidden"
															: ""
													}`}>
													<input
														type="tel"
														name={it}
														// onChange={event => handleFormTitle(event, formIndex)}
														className={`w-full border rounded-md p-2 text-center print:text-md ${
															["weight"]?.includes(it) ? "bg-gray-100" : ""
														}
											`}
														readOnly
														value={mainTotal?.[it]}
													/>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						</div>

						{/* Additional Features Section (Staff Remarks) */}
						<div className=" flex justify-between gap-10 items-center page-break">
							<div className="">
								<div className="p-4 mt-8 page-break">
									<h3 className="font-bold">Staff Remarks</h3>
									<div className="print:hidden">
										{["self_score"]?.includes(type) && (
											<ReactQuill
												theme="snow"
												value={remarks}
												onChange={event => handleRemark(event, setRemark)}
											/>
										)}
									</div>
									<div
										className={`${
											["self_score"]?.includes(type)
												? "hidden"
												: "block border border-[#CCCCCC] p-4 rounded-xl"
										} print:block print:border print:border-[#CCCCCC] print:p-4 print:rounded-xl`}
										dangerouslySetInnerHTML={createMarkup(remarks)}
									/>
								</div>
								<div
									className={` page-break ${
										managerLength > 0 ? "p-4 mt-8" : "hidden"
									}`}>
									<h3 className="font-bold">Manager 1 Remarks</h3>
									<div className="print:hidden">
										{["manager_score"]?.includes(type) && (
											<>
												<ReactQuill
													theme="snow"
													value={manager1Remark}
													onChange={event =>
														handleRemark(event, setManager1Remark)
													}
												/>
												<div className=" space-y-2">
													<label
														className=" text-[#121212] font-Nunito text-sm"
														htmlFor="">
														Recommendation
													</label>
													<select
														className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
														type="text"
														value={lineManager1Recommendation}
														onChange={setlineManager1Recommendation}
														name={"lineManager1Recommendation"}>
														<option value="">Select One</option>
														{recommendationData?.map((it, i) => (
															<option value={it?.value} key={i}>
																{it?.value}
															</option>
														))}
													</select>
												</div>
											</>
										)}
									</div>
									<div
										className={`${
											["manager_score"]?.includes(type)
												? "hidden"
												: "block border border-[#CCCCCC] p-4 rounded-xl"
										} print:block print:border print:border-[#CCCCCC] print:p-4 print:rounded-xl`}
										dangerouslySetInnerHTML={createMarkup(manager1Remark)}
									/>
									{lineManager1Recommendation && (
										<div
											className={`${
												["manager_score"]?.includes(type)
													? "hidden"
													: "block border border-[#CCCCCC] p-4 rounded-xl my-3"
											} print:block print:border print:border-[#CCCCCC] print:p-4 print:rounded-xl`}>
											Recommendation: {lineManager1Recommendation}
										</div>
									)}
								</div>
								{manager1Remark && (
									<div
										className={`page-break ${
											managerLength > 0 ? "" : "hidden"
										}`}>
										<p className="text-md mt-5">
											Staff Remark To Manager 1 Remarks
										</p>
										<div className="print:hidden">
											{["self_score"]?.includes(type) && (
												<>
													<AcceptReject
														value={staffRemarkForManager1}
														setValue={setstaffRemarkForManager1}
													/>
													{staffRemarkForManager1 !== "Accepted" && (
														<ReactQuill
															theme="snow"
															value={staffRemarkForManager1}
															onChange={event =>
																handleRemark(event, setstaffRemarkForManager1)
															}
														/>
													)}
												</>
											)}
										</div>
										<div
											className={`${
												["self_score"]?.includes(type)
													? "hidden"
													: "block border border-[#CCCCCC] p-4 rounded-xl"
											} print:block print:border print:border-[#CCCCCC] print:p-4 print:rounded-xl`}
											dangerouslySetInnerHTML={createMarkup(
												staffRemarkForManager1
											)}
										/>
									</div>
								)}
								<div
									className={` page-break ${
										managerLength > 1 ? "p-4 mt-8" : "hidden"
									}`}>
									<h3 className="font-bold">Manager 2 Remarks</h3>
									<div className="print:hidden">
										{["manager_score2"]?.includes(type) && (
											<>
												<ReactQuill
													theme="snow"
													value={manager2Remark}
													onChange={event =>
														handleRemark(event, setManager2Remark)
													}
												/>
												<div className=" space-y-2">
													<label
														className=" text-[#121212] font-Nunito text-sm"
														htmlFor="">
														Recommendation
													</label>
													<select
														className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
														type="text"
														value={lineManager2Recommendation}
														onChange={setlineManager2Recommendation}
														name={"lineManager2Recommendation"}>
														<option value="">Select One</option>
														{recommendationData?.map((it, i) => (
															<option value={it?.value} key={i}>
																{it?.value}
															</option>
														))}
													</select>
												</div>
											</>
										)}
									</div>
									<div
										className={`${
											["manager_score2"]?.includes(type)
												? "hidden"
												: "block border border-[#CCCCCC] p-4 rounded-xl"
										} print:block print:border print:border-[#CCCCCC] print:p-4 print:rounded-xl`}
										dangerouslySetInnerHTML={createMarkup(manager2Remark)}
									/>
									{lineManager2Recommendation && (
										<div
											className={`${
												["manager_score2"]?.includes(type)
													? "hidden"
													: "block border border-[#CCCCCC] p-4 rounded-xl my-3"
											} print:block print:border print:border-[#CCCCCC] print:p-4 print:rounded-xl`}>
											Recommendation: {lineManager2Recommendation}
										</div>
									)}
								</div>
								{manager2Remark && (
									<div
										className={`page-break ${
											managerLength > 1 ? "" : "hidden"
										}`}>
										<p className="text-md mt-5">
											Staff Remark To Manager 2 Remarks
										</p>
										<div className="print:hidden">
											{["self_score"]?.includes(type) && (
												<>
													<AcceptReject
														value={staffRemarkForManager2}
														setValue={setstaffRemarkForManager2}
													/>
													{staffRemarkForManager2 !== "Accepted" && (
														<ReactQuill
															theme="snow"
															value={staffRemarkForManager2}
															onChange={event =>
																handleRemark(event, setstaffRemarkForManager2)
															}
														/>
													)}
												</>
											)}
										</div>
										<div
											className={`${
												["self_score"]?.includes(type)
													? "hidden"
													: "block border border-[#CCCCCC] p-4 rounded-xl"
											} print:block print:border print:border-[#CCCCCC] print:p-4 print:rounded-xl`}
											dangerouslySetInnerHTML={createMarkup(
												staffRemarkForManager2
											)}
										/>
									</div>
								)}
							</div>

							<div>
								<div className="flex flex-col gap-2 py-4 px-2">
									<div className="flex justify-between">
										<span>Self Score</span>
										<input
											type="text"
											value={`${Number(
												((mainTotal?.self_score || 0) /
													((formLength || 0) * 5)) *
													100
											).toFixed(2)} %`}
											readOnly
											className="border p-2 text-right w-1/3"
										/>
									</div>
									<div className="flex justify-between">
										<span>Manager Score</span>
										<input
											type="text"
											value={`${Number(
												((mainTotal?.manager_score || 0) /
													((formLength || 0) * 5)) *
													100
											).toFixed(2)} %`}
											readOnly
											className="border p-2 text-right w-1/3"
										/>
									</div>
									{managerLength > 1 && (
										<div className="flex justify-between">
											<span>Manager 2 Score</span>
											<input
												type="text"
												value={`${Number(
													((mainTotal?.manager_score2 || 0) /
														((formLength || 0) * 5)) *
														100
												).toFixed(2)} %`}
												readOnly
												className="border p-2 text-right w-1/3"
											/>
										</div>
									)}
									<div className="flex justify-between">
										<span>Final Score</span>
										<input
											type="text"
											value={`${Number(
												((mainTotal?.weighted_score || 0) / 500) * 100
											).toFixed(2)} %`}
											readOnly
											className="border p-2 text-right w-1/3"
										/>
									</div>
									{grader && (
										<div className="flex justify-between">
											<span>Grade</span>
											<input
												type="text"
												value={`${
													gradeData?.find(it => it?.grade === grader)?.short
												} - ${grader}`}
												readOnly
												className="border p-2 text-right w-1/3"
											/>
										</div>
									)}
								</div>
							</div>
						</div>
						{/*  */}

						{/* Scores and Create Button */}
						<div className="flex justify-center items-center mt-4 printOnlyNone">
							<div className="flex gap-4 items-center">
								<Button
									css={"bg-blue-500 text-white px-4 py-2 rounded-md"}
									children={updater ? "Update" : "Create"}
									onClick={handleSubmit}
									loading={loading}
								/>
								{updater && (
									<button
										onClick={handlePrint}
										className="bg-blue-500 text-white px-4 py-2 rounded-md">
										Download
									</button>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export const ManageStaffEvaluations = () => {
	const tableHead = [
		"S/N",
		"Name",
		"Employee ID",
		"Department",
		"Position",
		"Role",
		"Email address",
		"Phone Number",
		"Action",
	];
	const tableData = [
		{
			SN: "1",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
		{
			SN: "2",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
		{
			SN: "3",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
		{
			SN: "4",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
		{
			SN: "5",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
		{
			SN: "6",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
		{
			SN: "7",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData }),
		{ getLogger, mainSearch, isFound, data, deleteLogger } =
			usePerformanceReviewStore(),
		[isDelete, setDelete] = useState(null),
		[loading, setLoading] = useState(false),
		navigate = useNavigate(),
		{ returnErrors } = useErrorStore(),
		[positionSelect, setPositionSelect] = useState(null),
		[positionSelected, setPositionSelected] = useState(null),
		{ user } = useAuthStore(),
		{ canManager1, canManager2 } = useContext(GlobalState),
		position = usePositionStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else {
			let mainData = data;

			let newDocs = mainData?.docs;
			if (!user?.isAdmin)
				if (canManager1 || canManager2) {
					let g1 =
							user?.gradeForPosition1 || user?.profile?.gradeForPosition1 || [],
						g2 =
							user?.gradeForPosition2 || user?.profile?.gradeForPosition2 || [],
						newGrade = [...new Set([...g1, ...g2])];
					setPositionSelect(newGrade);
					newDocs = newDocs?.filter(it => newGrade?.includes(it?.position));
					newDocs = newDocs?.filter(it => it?.createdBy?._id !== user?._id);
					if (positionSelected)
						newDocs = newDocs?.filter(it => it?.position === positionSelected);
				}
			mainData = {
				...mainData,
				docs: user?.isAdmin ? mainData?.docs : newDocs,
			};
			setDatum(mainData);
		}
	}, [
		data,
		mainSearch,
		isFound,
		canManager1,
		canManager2,
		user,
		positionSelected,
	]);

	let handleSubmit = async () => {
		// e?.preventDefault();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/performanceReview/${isDelete?._id}`,
			type: "delete",
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response || response === "") {
			deleteLogger(isDelete);
			setDelete(null);
			return;
		}
		setLoading(false);
	};

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/performanceReview?_populate=performance&_populate=createdBy&_populate=createdBy.level&_populate=createdBy.position&_populate=createdBy.department&_limit=0`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/performanceReview?_populate=performance&_populate=createdBy&_populate=createdBy.level&_populate=createdBy.position&_populate=createdBy.department&_limit=${
				data?.limit * data?.nextPage
			}`,
			getter: d => getLogger(d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

  console.log({positionSelect, user});

	return (
		<>
			<div className="card">
				<div className="flex justify-end">
					<div className=" space-y-2">
						{positionSelect && <select
							className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={positionSelected}
							onChange={e => setPositionSelected(e?.target?.value)}
							name={"lineManager1Recommendation"}>
							<option value="">Select One</option>
							{[
								...positionSelect?.map(it => {
									return {
										value: it,
										name: (position?.all || position?.data)?.docs?.find(
											ic => ic?._id === it
										)?.name,
									};
								}),
							]?.map((it, i) => (
								<option value={it?.value} key={i}>
									{it?.name}
								</option>
							))}
						</select>}
					</div>
				</div>
				<table className="min-w-full  mt-5">
					<thead className="bg-gray-50 border-b-[0.5px] border-b-gray-200">
						<tr>
							{tableHead.map((head, index) => (
								<th
									key={index}
									className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
									{head}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white ">
						{currentItems?.map((rowData, rowIndex) => (
							<tr key={rowIndex} className="">
								<td className="px-4 py-4  text-xs  text-gray-800">
									{rowIndex + 1}
								</td>
								<td className="px-4 py-4  text-xs  text-gray-800">
									{rowData?.createdBy?.profile?.firstName ||
										rowData?.createdBy?.firstName}{" "}
									{rowData?.createdBy?.profile?.lastName ||
										rowData?.createdBy?.lastName}
								</td>
								<td className="px-4 py-4  text-xs  text-gray-800">
									{rowData?.createdBy?.profile?.employeeId ||
										rowData?.createdBy?.employeeId}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800">
									{rowData?.createdBy?.profile?.department?.name ||
										rowData?.createdBy?.department?.name}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800">
									{rowData?.createdBy?.profile?.position?.name ||
										rowData?.createdBy?.position?.name}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800 capitalize">
									{rowData?.createdBy?.profile?.type ||
										rowData?.createdBy?.type}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800">
									{rowData?.createdBy?.profile?.email ||
										rowData?.createdBy?.email}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800">
									{rowData?.createdBy?.profile?.phone ||
										rowData?.createdBy?.phone}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800 cursor-pointer">
									<div className="flex">
										<img
											onClick={() =>
												navigate("manage-evaluations-details", {
													state: rowData,
												})
											}
											src={Edit}
											alt=""
											className="cursor-pointer"
										/>
										<img
											onClick={() => setDelete(rowData)}
											src={DeleteImg}
											alt=""
											className="cursor-pointer"
										/>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="mt-10 flex justify-center w-44 items-center mx-auto">
					<LoadMore
						next={data?.hasNextPage && data?.limit !== 0}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
				{isDelete && (
					<Delete
						onClose={() => {
							setDelete(null);
						}}
						deleteFunc={() => handleSubmit()}
						title={
							isDelete?.createdBy?.profile?.lastName ||
							isDelete?.createdBy?.lastName
						}
						loading={loading}
					/>
				)}
			</div>
		</>
	);
};

export const ManageEvaluationsList = () => {
	const tablesHead = [
		"S/N",
		"Performance Title",
		"Department",
		"Position",
		"Review Period",
		"Date",
		"Action",
	];
	const tablesData = [
		{
			SN: "1",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
		{
			SN: "2",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
		{
			SN: "3",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
		{
			SN: "4",
			PerformanceEvaluator: "Catherine James",
			Project: "CeHR/001A",
			PerformanceType: "Marketing",
			Performance: "Marketing",
			Task: "Product Manager",
			Emailaddress: "cathyjames@gmail.com",
			Action: "View details",
		},
	];

	let [datum, setDatum] = useState({ docs: tablesData }),
		{ getLogger, mainSearch, isFound, data, deleteLogger } =
			usePerformanceStore(),
		[loading, setLoading] = useState(false),
		navigate = useNavigate(),
		[isDelete, setDelete] = useState(null),
		{ returnErrors } = useErrorStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/performance?_populate=department&_populate=position&_limit=0`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/performance?_populate=department&_populate=position&_limit=${
				data?.limit * data?.nextPage
			}`,
			getter: d => getLogger(d),
		});
		setLoading(false);
	};

	let handleSubmit = async () => {
		// e?.preventDefault();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/performance/${isDelete?._id}`,
			type: "delete",
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response || response === "") {
			deleteLogger(isDelete);
			setDelete(null);
			return;
		}
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<>
			<div className="card">
				<table className="min-w-full  mt-5">
					<thead className="bg-gray-50 border-b-[0.5px] border-b-gray-200">
						<tr>
							{tablesHead.map((head, index) => (
								<th
									key={index}
									className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
									{head}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white ">
						{currentItems?.map((rowData, rowIndex) => (
							<tr key={rowIndex} className="">
								<td className="px-4 py-4  text-xs  text-gray-800">
									{rowIndex + 1}
								</td>
								<td className="px-4 py-4  text-xs  text-gray-800">
									{rowData?.name}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800">
									{rowData?.department?.name}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800">
									{rowData?.position?.name}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800">
									{rowData?.reviewPeriod}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800">
									{moment(rowData?.createdAt).format("DD/MM/YYYY")}
								</td>
								<td className="px-4 py-4 text-xs  text-gray-800 cursor-pointer">
									<div className="flex">
										<img
											onClick={() =>
												navigate("add-performance", {
													state: { edit: rowData },
												})
											}
											src={Edit}
											alt=""
											className="cursor-pointer"
										/>
										<img
											src={DeleteImg}
											alt=""
											onClick={() => setDelete(rowData)}
											className="cursor-pointer"
										/>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="mt-10 flex justify-center w-44 items-center mx-auto">
					<LoadMore
						next={data?.hasNextPage && data?.limit !== 0}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
				{isDelete && (
					<Delete
						onClose={() => {
							setDelete(null);
						}}
						deleteFunc={() => handleSubmit()}
						title={isDelete?.name}
						loading={loading}
					/>
				)}
			</div>
		</>
	);
};

export const AcceptReject = ({ type, setValue, value }) => {
	return (
		<>
			<div className="my-10 md:w-3/4 xl:w-1/2 grid md:grid-cols-2 lg:grid-cols-3 gap-5">
				<button
					type="button"
					onClick={() => setValue("Accepted")}
					className={`hover:bg-black shadow-xl hover:text-white h-12 inter text-sm capitalize font-semibold rounded-lg px-2 ${
						value === "Accepted"
							? "bg-black shadow-xl text-white"
							: "bg-white text-black"
					}`}>
					{value === "Accepted" ? "Accepted" : "Accept"}
				</button>
				<button
					type="button"
					onClick={() => {
						toast.info("Please provide more insight below");
						if (value === "Accepted") setValue("");
					}}
					className={`hover:bg-black shadow-xl hover:text-white bg-white text-black h-12 inter text-sm capitalize font-semibold rounded-lg px-2`}>
					Reject
				</button>
			</div>
		</>
	);
};
