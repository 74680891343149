import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./Authstore";

const StaffStore = set => ({
	data: null,
	status: "",
	getSearchStaff: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchStaff"
		);
	},
	getSearch: payload => {
		set({ data: payload?.data || payload }, false, "getSearch");
	},
	resetStaffSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getStaff: payload => {
		set({ data: payload?.data || payload }, false, "getStaff");
	},
	addStaff: payload => {
		let data = payload?.data || payload;

		set(
			state => ({
				data: {
					...state?.data,
					docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
					totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
					docsTotal: state?.data?.docsTotal ? 1 + state?.data?.docsTotal : 1,
				},
				status: "added",
			}),
			false,
			"addStaff"
		);
	},
	deleteStaff: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: DeleteData(state?.data?.docs, data),
					totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
					docsTotal: state?.data?.docsTotal ? state?.data?.docsTotal - 1 : 0,
				},
				status: "deleted",
			}),
			false,
			"deleteStaff"
		);
	},
	updateStaff: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: EditData(state?.data?.docs, data),
				},
				status: "updated",
			}),
			false,
			"editStaff"
		);
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutStaff: () => {
		set({
			status: "",
			isFound: null,
			data: null,
			mainSearch: null,
			allStaff: null,
		});
	},
});

const useStaffStore = create(
	devtools(
		persist(StaffStore, {
			name: "staff",
		})
	)
);

export default useStaffStore;
