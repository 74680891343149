import React from "react";

const AddNewBtn = ({ text }) => {
  return (
    <div>
      <div className=" flex items-center gap-3 justify-center bg-[#000] w-28 h-9 rounded-xl text-white">
        <img
          className=" w-4 h-4"
          src={require("../../assets/add.png")}
          alt=""
        />
        <p className=" text-sm Satoshi text-white ">{text}</p>
      </div>
    </div>
  );
};

export default AddNewBtn;
