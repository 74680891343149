import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import SmsNotification from "../../components/cards/notification/SmsNotification";
import InAppNotification from "../../components/cards/notification/InAppNotification";
import { Link } from "react-router-dom";

const Notifications = () => {
  const [isCreate, setIsCreate] = useState(false);
  const [showSms, setShowSms] = useState(false);
  const [showInApp, setShowInApp] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const handleCreate = () => {
    setIsCreate(!isCreate);
  };
  const closeCreate = () => {
    setIsCreate(false);
  };

  const handleSms = () => {
    setShowSms(!showSms);
  };
  const closeSms = () => {
    setShowSms(false);
  };
  const handleInApp = () => {
    setShowInApp(!showInApp);
  };
  const closeInApp = () => {
    setShowInApp(false);
  };
  const handleEmail = () => {
    setShowEmail(!showEmail);
  };
  const closeEmail = () => {
    setShowEmail(false);
  };

  const card = [
    {
      id: 1,
      img: require("../../assets/Ellipse16.png"),
      text: "  Your payment invoice request has been approved by Admin",
      time: "3min ago",
      dot: require("../../assets/Options.png"),
    },
    {
      id: 2,
      img: require("../../assets/Ellipse16.png"),
      text: "  Your payment invoice request has been approved by Admin",
      time: "3min ago",
      dot: require("../../assets/Options.png"),
    },
    {
      id: 3,
      img: require("../../assets/Ellipse16.png"),
      text: "  Your payment invoice request has been approved by Admin",
      time: "3min ago",
      dot: require("../../assets/Options.png"),
    },
    {
      id: 4,
      img: require("../../assets/Ellipse16.png"),
      text: "  Your payment invoice request has been approved by Admin",
      time: "3min ago",
      dot: require("../../assets/Options.png"),
    },
    // {
    //   id: 5,
    //   img: require("../../assets/Ellipse16.png"),
    //   text: "  Your payment invoice request has been approved by Admin",
    //   time: "3min ago",
    //   dot: require("../../assets/Options.png"),
    // },
    // {
    //   id: 6,
    //   img: require("../../assets/Ellipse16.png"),
    //   text: "  Your payment invoice request has been approved by Admin",
    //   time: "3min ago",
    //   dot: require("../../assets/Options.png"),
    // },
    // {
    //   id: 7,
    //   img: require("../../assets/Ellipse16.png"),
    //   text: "  Your payment invoice request has been approved by Admin",
    //   time: "3min ago",
    //   dot: require("../../assets/Options.png"),
    // },
  ];

  const offline = [
    {
      id: 1,
      img: require("../../assets/Ellipse16.png"),
      text: "  Your payment invoice request has been approved by Admin",
      time: "3min ago",
      dot: require("../../assets/Options.png"),
    },
    {
      id: 2,
      img: require("../../assets/Ellipse16.png"),
      text: "  Your payment invoice request has been approved by Admin",
      time: "3min ago",
      dot: require("../../assets/Options.png"),
    },
    // {
    //   id: 3,
    //   img: require("../../assets/Ellipse16.png"),
    //   text: "  Your payment invoice request has been approved by Admin",
    //   time: "3min ago",
    //   dot: require("../../assets/Options.png"),
    // },
    // {
    //   id: 4,
    //   img: require("../../assets/Ellipse16.png"),
    //   text: "  Your payment invoice request has been approved by Admin",
    //   time: "3min ago",
    //   dot: require("../../assets/Options.png"),
    // },
  ];
  return (
    <div className=" bg-[#EFF6FC] pb-10">
      <Navbar />
      <PagesList />
      <div className="bg-[#F5F7F8] w-full  ">
        <div className=" mx-12 pt-4 gap-5">
          <p className="  font-Kumbh cursor-pointer text-base font-medium text-[#000]">
            All Notifications
          </p>
          <div className=" flex items-center justify-between bg-[#EFF6FC] p-5 mt-5">
            <div className=" flex items-center gap-10 ">
              <p className=" text-xl font-bold text-[#000]">
                Notifications (3 unread)
              </p>

              <button className=" w-44 h-10 rounded-lg bg-gradient-to-r from-[#5608D4] to-[#04096E] font-Nunito text-sm text-white">
                Mark All As Read
              </button>
            </div>
            <div className=" relative">
              <button
                onClick={handleCreate}
                className=" w-36 h-10 bg-black Satoshi text-sm text-white rounded-lg"
              >
                Create new
              </button>
              {isCreate && (
                <div
                  onClick={closeCreate}
                  className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center"
                >
                  <div className=" bg-white absolute right-28 top-64 w-40 space-y-3 h-auto p-5">
                    <div>
                      <p
                        onClick={handleSms}
                        className=" font-Nunito text-sm text-[#262626] cursor-pointer"
                      >
                        SMS
                      </p>
                    </div>

                    <div className="">
                      <p
                        onClick={handleInApp}
                        className=" font-Nunito text-sm text-[#262626] cursor-pointer"
                      >
                        In-app
                      </p>
                    </div>

                    <div>
                      <p
                        onClick={handleEmail}
                        className=" font-Nunito text-sm text-[#262626] cursor-pointer"
                      >
                        Email
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {showSms && (
                <SmsNotification onclose={closeSms} Recipien="number" />
              )}
              {showInApp && <InAppNotification onclose={closeInApp} />}

              {showEmail && (
                <SmsNotification
                  onclose={closeEmail}
                  Recipien="email address"
                />
              )}
            </div>
          </div>

          <div className=" bg-white p-5 mt-5">
            <div className=" flex items-center justify-between">
              <p className=" text-[#272525] text-sm font-Nunito ">Today</p>
              <div className=" flex items-center gap-2">
                <input type="checkbox" />
                <p className="text-[#272525] text-sm font-Nunito">Select all</p>
              </div>
            </div>

            <div>
              {card.map((i) => (
                <div
                  key={i.id}
                  className=" bg-[#EFF6FC] flex items-center justify-between px-5 py-2 mt-5 rounded-lg"
                >
                  <div className=" flex items-center gap-3">
                    <img className=" w-12 h-12" src={i.img} alt="" />
                    <div className="">
                      <p className=" font-Nunito text-[#272525] font-semibold">
                        {i.text}
                      </p>
                      <p className=" font-Nunito text-xs text-[#515151]">
                        {i.time}
                      </p>
                    </div>
                    <p className=" bg-[#5FCE55] w-2 h-2 rounded-full ml-4"></p>
                  </div>
                  <Link to="/all-notifications">
                    <img
                      className=" w-5 h-5 cursor-pointer"
                      src={i.dot}
                      alt=""
                    />
                  </Link>
                </div>
              ))}
            </div>

            <div className=" flex items-center justify-between mt-5">
              <p className=" text-[#272525] text-sm font-Nunito ">
                Yesterday 04th April, 2024
              </p>
              <div className=" flex items-center gap-2">
                <input type="checkbox" />
                <p className="text-[#272525] text-sm font-Nunito">Select all</p>
              </div>
            </div>
            <div>
              {offline.map((i) => (
                <div
                  key={i.id}
                  className="  flex items-center justify-between px-5 py-2 mt-5 rounded-lg"
                >
                  <div className=" flex items-center gap-3">
                    <img className=" w-12 h-12" src={i.img} alt="" />
                    <div className="">
                      <p className=" font-Nunito text-[#272525] font-semibold">
                        {i.text}
                      </p>
                      <p className=" font-Nunito text-xs text-[#515151]">
                        {i.time}
                      </p>
                    </div>
                    {/* <p className=" bg-[#5FCE55] w-2 h-2 rounded-full ml-4"></p> */}
                  </div>
                  <Link to="/all-notifications">
                    <img
                      className=" w-5 h-5 cursor-pointer"
                      src={i.dot}
                      alt=""
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
