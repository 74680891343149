import React from "react";
import postedby from "../../assets/postedby.png";

const PostedBy = () => {
  return (
    <div>
      <div className=" bg-[#FCF7FF] w-full py-8 px-5">
        <div>
          <small className=" text-sm Satoshi text-[#000]">Posted by</small>
          <div className=" flex gap-3 mt-2 ">
            <img className=" w-12 h-12" src={postedby} alt="" />
            <div>
              <p className=" text-[#000]  Satoshi text-lg font-bold">
                Dave Smith
              </p>
              <p className=" font-medium text-[#000] text-sm">
                CodeRight Company & Co.
              </p>
              <small className=" font-light text-[#262626] satoshi mt-2">
                Last Login: 23/04/2024 12:00:09pm
              </small>
            </div>
          </div>
          <div className=" grid justify-center items-center mt-4">
            <div className=" shadow-lg bg-[#F8ECFF] flex gap-10 p-4 items-center justify-between w-full">
              <div>
                <div className=" grid justify-center items-center">
                  <p className=" font-Nunito text-[#5608D4] text-2xl font-bold">
                    200
                  </p>
                </div>
                <small className=" text-sm font-Nunito text-[#3B5D81]">
                  Job Views
                </small>
              </div>
              <div>
                <div className=" grid justify-center items-center">
                  <p className=" font-Nunito text-[#5608D4] text-2xl font-bold">
                    65
                  </p>
                </div>
                <small className=" text-sm font-Nunito text-[#3B5D81]">
                  Applications
                </small>
              </div>
              <div>
                <div className=" grid justify-center items-center">
                  <p className=" font-Nunito text-[#5608D4] text-2xl font-bold">
                    1324
                  </p>
                </div>
                <small className=" text-sm font-Nunito text-[#3B5D81]">
                  Job Code
                </small>
              </div>
            </div>
          </div>

          <div className=" mt-4 space-y-4">
            <div className="">
              <small className=" text-[#4C4A4D] font-Nunito text-sm ">
                Category
              </small>
              <p className=" font-bold text-base text-[#000] font-Nunito">
                Software Engineering
              </p>
            </div>
            <div className="">
              <small className=" text-[#4C4A4D] font-Nunito text-sm ">
                Functional Area
              </small>
              <p className=" font-bold text-base text-[#000] font-Nunito">
                Software Engineering
              </p>
            </div>
            <div className="">
              <small className=" text-[#4C4A4D] font-Nunito text-sm ">
                Functional Area
              </small>
              <p className=" font-bold text-base text-[#000] font-Nunito">
                Software Engineering
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostedBy;
