import React, { useEffect, useState } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import PagesList from "../../../../components/pages-list/PagesList";
import { Link } from "react-router-dom";

import SearchInput from "../../../../components/cards/SearchInput";
import ApprovalFlow from "../../../../components/cards/ApprovalFlow";
import { useLeaveFlowStore } from "../../../../data/Stores/LoggerStore";
import useCompanyStore from "../../../../data/Stores/CompanyStore";
import { apiCall } from "../../../../data/useGenFetcher";
import { LoadMore, MainPaginate } from "../../../../components/buttons/Send";

export const tableData = [
	{
		SN: "1",
		LeaveType: "Team Lead",

		Approvers: "Lorem ipsum dolor sit amet itil cit.",
		Days: "20 days",

		Actions: "...",
	},
	{
		SN: "2",
		LeaveType: "Team Lead",

		Approvers: "Lorem ipsum dolor sit amet itil cit.",
		Days: "20 days",

		Actions: "...",
	},
	{
		SN: "3",
		LeaveType: "Team Lead",

		Approvers: "Lorem ipsum dolor sit amet itil cit.",
		Days: "20 days",

		Actions: "...",
	},
	{
		SN: "4",
		LeaveType: "Team Lead",

		Approvers: "Lorem ipsum dolor sit amet itil cit.",
		Days: "20 days",

		Actions: "...",
	},
	{
		SN: "5",
		LeaveType: "Team Lead",

		Approvers: "Lorem ipsum dolor sit amet itil cit.",
		Days: "20 days",

		Actions: "...",
	},
	{
		SN: "6",
		LeaveType: "Team Lead",

		Approvers: "Lorem ipsum dolor sit amet itil cit.",
		Days: "20 days",

		Actions: "...",
	},
	{
		SN: "7",
		LeaveType: "Team Lead",

		Approvers: "Lorem ipsum dolor sit amet itil cit.",
		Days: "20 days",

		Actions: "...",
	},
];

export const MainLeaveFlow = ({
	currentItems,
	pageCount,
	handlePageClick,
	handleLoadMore,
	loading,
	data,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [showId, setShowId] = useState(false);

	const handleId = () => {
		setShowId(!showId);
	};
	const closeId = () => {
		setShowId(false);
	};

	const tableHead = ["S/N", "Leave", "Approvers", "Action"];
	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">
					Approval Flows
				</p>
			</div>

			<div className=" mx-12">
				<div className=" bg-white p-5 mt-5  ">
					<div className=" flex items-center justify-between">
						<div className=" flex items-center gap-20">
							<div className=" flex items-center">
								<Link
									to=""
									className=" Satoshi cursor-pointer animate-pulse font-normal text-sm text-[#000]">
									Leave Flow
								</Link>
							</div>

							<div className=" flex gap-2 items-center">
								<p className=" text-[#121212] text-xs font-Nunito">Search </p>
								<SearchInput />
							</div>
						</div>
						<div className=" cursor-pointer">
							<button
								onClick={handleId}
								// onClick={handleAction}
								className=" text-sm Satoshi text-white bg-black rounded-md w-28 h-9 animate-pulse ">
								Create new
							</button>
							{showId && <ApprovalFlow onClose={closeId} />}
							{/* {addAction && <ActionCompleted onClose={closeAction} />}
                </div>
                {addFile && <AddAsset closeFile={closeFile} />} */}
						</div>
						{/* <div className=" cursor-pointer">
              <Link
                to="/tender-resignation"
                className=" bg-black text-white grid justify-center items-center rounded-md w-28 h-8 Satoshi"
              >
                Create new
              </Link>
            </div> */}
					</div>
				</div>
				<table className="min-w-full ">
					<thead className="bg-gray-50 border-b border-b-gray-200 ">
						<tr>
							{tableHead.map((head, index) => (
								<th
									key={index}
									className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
									{head}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white ">
						{currentItems?.map((rowData, rowIndex) => (
							<tr key={rowIndex} className="">
								<td className="px-4 py-4  text-xs border-b-[0.5]">
									{rowIndex + 1}
								</td>
								<td className="px-4 py-4 text-xs border-b-[0.5]">
									{rowData?.level?.name}
								</td>
								<td className="px-4 py-4 text-xs border-b-[0.5]">
									{rowData?.approvers?.map((it, x) => (
										<span key={x}>
											{it?.name}
											{x === rowData?.approvers?.length - 1 ? "" : ", "}
										</span>
									))}
								</td>

								<Link
									to="flow-details"
									state={rowData}
									className="px-4 py-4 cursor-pointer animate-pulse text-[#0811D4] text-xs border-b-[0.5] border-b-gray-200 ">
									...
								</Link>
							</tr>
						))}
					</tbody>
				</table>
				<div className="mt-10 flex justify-center w-44 items-center mx-auto">
					<LoadMore
						next={data?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</div>
	);
};

const LeaveFlow = () => {
	let [datum, setDatum] = useState({ docs: tableData }),
		{ getLogger, mainSearch, isFound, data } = useLeaveFlowStore(),
		{ currentSelected } = useCompanyStore(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/leaveFlow/${currentSelected}?_populate=level&_populate=approvers`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/leaveFlow/${currentSelected}?_populate=level&_populate=approvers&_limit=${
				data?.limit * data?.nextPage
			}`,
			getter: d => getLogger(d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<MainLeaveFlow
			currentItems={currentItems}
			pageCount={pageCount}
			handlePageClick={handlePageClick}
			handleLoadMore={handleLoadMore}
			loading={loading}
			data={datum}
		/>
	);
};

export default LeaveFlow;
