// import React from "react";

// const EditTime = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default EditTime;

import React, { useState } from "react";
import ActionCompleted from "../ActionCompleted";
import GradientBtn from "../../buttons/gradientbtn";

const EditTime = ({ onClose }) => {
  const [activeView, setActiveView] = useState("board");
  const [isAdd, setIsAdd] = useState(false);
  const [addAssigns, setAddAssigns] = useState(false);

  const handleAssigns = () => {
    setAddAssigns(!addAssigns);
  };

  const closeAssigns = () => {
    setAddAssigns(false);
  };

  const closeAdd = () => {
    setIsAdd(false);
  };

  return (
    <div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center z-10">
      <div className=" bg-white w-96 h-auto p-5">
        <div className=" flex items-center justify-between">
          <p className=" font-Kumbh text-xl text-[#000] font-bold">EditTime</p>
          <p
            onClick={onClose}
            className="font-Kumbh text-xl text-[#000] font-bold cursor-pointer"
          >
            X
          </p>
        </div>
        <div className=" space-y-3 mt-5">
          <p className=" text-sm font-Nunito text-[#121212]">18/04/2024</p>
          <p className=" text-sm font-Nunito font-bold text-[#000]">
            Design a Wireframe for App
          </p>
          <div className=" flex items-center gap-2">
            <input
              className=" w-20 h-8 rounded-lg border border-[#bfa2ee] text-[#5608D4] text-center placeholder:text-[11px] placeholder-[#5608D4]"
              placeholder="7:00:00"
              type="text"
            />
            <input
              className=" w-16 h-6 rounded-lg border border-[#CCCCCC] text-[#000] text-center placeholder:text-[11px] placeholder-[#000]"
              placeholder="7:00:00"
              type="text"
            />
            <p className=" font-Nunito text-sm text-[#000]">to</p>
            <input
              className=" w-16 h-6 rounded-lg border border-[#CCCCCC] text-[#000] text-center placeholder:text-[11px] placeholder-[#000]"
              placeholder="7:00:00"
              type="text"
            />
          </div>

          <div className=" space-y-2">
            <label className=" font-Nunito text-sm text-[#121212]" htmlFor="">
              Description
            </label>
            <div className=" flex items-center relative">
              <input
                className=" w-full h-10 border border-[#D0D0D0] rounded-lg pl-2 pr-5 "
                placeholder="Title"
                type="text"
              />
              <img
                className=" w-4 h-4 absolute right-3"
                src={require("../../../assets/iconamoon_arrow-down-2-thin.png")}
                alt=""
              />
            </div>
          </div>
          <div className=" space-y-2">
            <label className=" font-Nunito text-sm text-[#121212]" htmlFor="">
              Select tags
            </label>
            <div className=" flex items-center relative">
              <input
                className=" w-full h-10 border border-[#D0D0D0] rounded-lg pl-2 pr-5 "
                placeholder="Title"
                type="text"
              />
              <img
                className=" w-4 h-4 absolute right-3"
                src={require("../../../assets/iconamoon_arrow-down-2-thin.png")}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className=" mt-10 flex items-center gap-3">
          <button
            onClick={onClose}
            className=" w-44 h-8 rounded-lg border border-[#bca4e2] text-[#5608D4] font-Nunito text-sm"
          >
            Cancel
          </button>
          <div onClick={handleAssigns} className=" w-44">
            <GradientBtn btnText=" Save" />
          </div>
          {addAssigns && <ActionCompleted onClose={closeAssigns} />}
        </div>
      </div>
    </div>
  );
};

export default EditTime;
