import React from "react";

const DeletedSuccessfully = ({ onClose }) => {
  return (
    <div
      onClick={onClose}
      className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center"
    >
      <div className=" bg-white w-72 h-auto rounded-md">
        <div className=" grid justify-center items-center py-10">
          <img
            className=" w-36 h-16"
            src={require("../../assets/Delete.png")}
            alt=""
          />
        </div>
        <p className=" font-Nunito text-[#000] text-base font-bold text-center">
          “Item Type’
        </p>
        <p className=" text-[#000] font-Nunito text-sm text-center pt-2 pb-10 ">
          deleted successfully!
        </p>
      </div>
    </div>
  );
};

export default DeletedSuccessfully;
