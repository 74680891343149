import React, { useState } from "react";
// import Delete from "../cards/Delete";
import ActionCompleted from "../cards/ActionCompleted";
import CancelUpdates from "../buttons/CancelUpdate"; // Import the CancelUpdates component
import { toast } from "react-toastify";
import { apiCall } from "../../data/useGenFetcher";
import useDepartmentStore from "../../data/Stores/DepartmentStore";
import useCompanyStore from "../../data/Stores/CompanyStore";
import useErrorStore from "../../data/Stores/errorstore";
import { useLocation } from "react-router-dom";

const EditDepartment = ({ onClose }) => {
  // const [isSwitched, setIsSwitched] = useState(false);
  // const [submit, setSubmit] = useState(false);
  // const [showAnswer, setShowAnswer] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  // const handleShowAnswer = () => {
  //   setShowAnswer(!showAnswer);
  // };
  // const closeShowAnswer = () => {
  //   setShowAnswer(false);
  // };

  // const handleSubmit = () => {
  //   setSubmit(!submit);
  // };

  // const closeSubmit = () => {
  //   setSubmit(false);
  // };

  // const handleClick = () => {
  //   setIsSwitched(!isSwitched);
  // };

  const handleUpdate = () => {
    setShowUpdate(!showUpdate);
  };

    let init = {},
    items = useLocation()?.state,
			[state, setState] = useState({}),
			[loading, setLoading] = useState(false),
			textChange = ({ target: { value, name } }) => {
				setState({ ...state, [name]: value });
			},
			{ updateDepartment } = useDepartmentStore(),
			{ currentSelected } = useCompanyStore(),
			{ returnErrors } = useErrorStore();

		let handleSubmit = async e => {
			e?.preventDefault();
			if (Object.values(state)?.length === 0) return toast.info("Update Data required");
			let newState = state;

			setLoading(true);
			let { response, errArr, errMsg } = await apiCall({
				url: `/api/v1/department/${currentSelected}/${items?._id}`,
				type: "put",
				data: newState,
			});
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				updateDepartment(response);
				setState(init);
				onClose();
				handleUpdate()
				return;
			}
			setLoading(false);
		};

  return (
		<div className=" overflow-y-scroll fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
			<div className=" bg-white w-[682px] p-5 h-auto rounded-md ">
				<div className=" flex justify-between items-center">
					<p className=" font-Kumbh text-xl font-bold text-[#000]">
						Edit Department
					</p>
					<p
						onClick={onClose}
						className=" cursor-pointer text-[24px] font-bold">
						&times;
					</p>
				</div>
				<div className=" space-y-5 mt-5 pr-5">
					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Department Name
						</label>
						<input
							placeholder="Title"
							className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={state?.name || items?.name}
							onChange={textChange}
							name={"name"}
						/>
					</div>
					{/* <div className=" space-y-2">
            <label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
              Leave Type Duration
            </label>
            <input
              placeholder="Title"
              className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
              type="text"
            />
          </div> */}

					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Description
						</label>
						<input
							placeholder="Description"
							className=" border border-[#D0D0D0] px-2 w-full h-28 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={state?.description || items?.description}
							onChange={textChange}
							name={"description"}
						/>
					</div>
				</div>

				<div className=" space-y-5 mt-16">
					<CancelUpdates onClose={onClose} onUpdate={handleSubmit} loading={loading} />
					{showUpdate && <ActionCompleted onClose={onClose} />}
				</div>
			</div>
		</div>
	);
};

export default EditDepartment;
