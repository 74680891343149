import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import celebrateemoji from "../../assets/celebrateemoji.png";
import celebimg from "../../assets/celebimg.png";
import Confetti from "../../assets/Confetti.png";
import calen from "../../assets/calen.png";
import useCompanyStore from "../../data/Stores/CompanyStore";
import { apiCall } from "../../data/useGenFetcher";
import moment from "moment";
import { Link } from "react-router-dom";
import { ModalContainer, PageLoader } from "../../components/buttons/Send";

const BirthdayCelebrants = () => (
	<CelebrantsComponent event="birthMonth" picker="dateOfBirth" />
);

export default BirthdayCelebrants;

export const CelebrantsComponent = ({
	event = "birthMonth",
	picker = "dateOfBirth",
	title,
}) => {
	let { currentSelected } = useCompanyStore(),
		getData = async () => {
			apiCall({
				type: "get",
				url: `/api/v1/user/users/${
					currentSelected || ""
				}?_populate=position&_populate=department&_populate=level
				&${event}=${moment(month ? `${month}-01` : moment())
					.format("MM")
					?.toLowerCase()}&_limit=0`,
				getter: d => setData(d?.data || d),
				noToast: true,
			});
		};

	let [month, setMonth] = useState(""),
		[isOpen, setIsOpen] = useState(null),
		[data, setData] = useState(null);

	useEffect(() => {
		if (currentSelected) getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSelected, month]);
	// console.log({ data });

	return (
		<div className=" bg-[#EFF6FC]">
			<Navbar />
			<PagesList />
			<div className=" bg-white mt-5 mx-12 px-4">
				{!data ? (
					<PageLoader />
				) : (
					<>
						<div className="flex justify-between items-center pb-6 pt-2">
							<h2 className="text-2xl font-semibold flex items-center">
								<img
									src={celebrateemoji} // replace with your image source
									alt="Birthday Celebrants"
									className=" w-6 h-6"
								/>
								{title || "Birthday"} Celebrants -{" "}
								<p className="text-xl font-bold">
									{moment(month ? `${month}-01` : moment()).format("MMMM")}
								</p>
							</h2>
							<button
								onClick={() => setIsOpen(true)}
								className="p-2 border rounded-xl flex items-center">
								<img className=" w-4 h-4" src={calen} alt="Calendar icon" />
								<span className="ml-2 text-[#1b1b1b] font-Kumbh text-[13px]">
									Choose month
								</span>
							</button>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
							{data?.docs?.map((each, index) => (
								<Link
									to="/manage-employees/employee's-details"
									state={each}
									className=" bg-white shadow-lg rounded-lg w-56 py-5"
									key={index}>
									<div className=" grid justify-center items-center">
										<div className="w-80 relative grid justify-center">
											{each?.profile?.avatar || each?.avatar ? (
												<img
													width="150px"
													height="150px"
													src={
														each?.profile?.avatar || each?.avatar || celebimg
													}
													className="rounded-full relative z-40 w-24 h-24"
													alt="Birthday"
												/>
											) : (
												<AvatarImg
													user={each}
													style={{ height: "6rem", width: "6rem" }}
													css={"z-40 w-24 h-24"}
												/>
											)}
											<img
												className=" absolute top-3 right-20 w-16 h-16"
												src={Confetti}
												alt=""
											/>
										</div>
									</div>
									<div className=" w-full grid justify-center mt-2">
										<p className=" text-sm text-center font-bold font-Kumbh text-[#000] ">
											{each?.lastName || each?.profile?.lastName}{" "}
											{each?.firstName || each?.profile?.firstName}
										</p>
										<p className=" mt-1 text-sm text-center text-[#000] font-Kumbh">
											{each?.department?.name ||
												each?.profile?.department?.name}{" "}
										</p>

										<p className=" text-sm text-center mt-10 text-[#5608d4] font-Kumbh">
											{each?.[picker] || each?.profile?.[picker]
												? moment(
														each?.[picker] || each?.profile?.[picker]
												  ).format("Do MMMM")
												: null}
										</p>
									</div>
								</Link>
							))}
						</div>
					</>
				)}
				<ModalContainer
					title={"Select Month"}
					width={"max-w-sm"}
					show={isOpen ? true : false}
					close={() => setIsOpen(null)}>
					<div className="mx-20">
						<form className="space-y-4">
							<div className="my-auto w-100">
								<div className=" space-y-2">
									<label
										className=" text-[#121212] font-Nunito text-sm"
										htmlFor="">
										Department Name
									</label>
									<input
										placeholder="Title"
										className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
										type="month"
										value={month}
										onChange={e => setMonth(e.target.value)}
										name={"month"}
									/>
								</div>
								{/* <div className="pt-4 flex">
								<Button
									buttonType={"primary"}
									title={"Yes"}
									type="button"
									width={"w-fit me-2"}
									// loading={loading}
									// onClick={handleSubmit}
								/>
								<Button
									buttonType={"secondary"}
									title={"No"}
									type="button"
									width={"w-fit ms-2"}
									onClick={() => setIsOpen(null)}
								/>
							</div> */}
							</div>
						</form>
					</div>
				</ModalContainer>
			</div>
		</div>
	);
};

export const AvatarImg = ({ user, style, css }) => {
	return (
		<div
			class={`relative inline-flex items-center justify-center w-10 h-10 overflow-hidden border-red-600 border bg-gray-100 rounded-full dark:bg-gray-600 ${
				css || ""
			}`}
			style={style || null}>
			<span
				class={`font-medium text-gray-600 dark:text-gray-300 ${
					style ? "text-5xl font-bold" : ""
				} uppercase`}>
				{user?.firstName?.slice(0, 1) || "H"}
				{""}
				{user?.lastName?.slice(0, 1) || "R"}
			</span>
		</div>
	);
};
