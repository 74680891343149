import React from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { Link } from "react-router-dom";

const CompanySettings = () => {
	const card = [
		{
			id: 1,
			img: require("../../assets/img21.png"),
			text: "Leave Types",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "leave-types",
			width: "34.46px",
			height: "50.14px",
		},
		{
			id: 2,
			img: require("../../assets/img22.png"),
			text: "Approval Flows",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "approval-flows",
			width: "40px",
			height: "60.05px",
		},
		{
			id: 3,
			img: require("../../assets/img23.png"),
			text: "Admins",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "admins",
			width: "49px",
			height: "50px",
		},
		{
			id: 4,
			img: require("../../assets/img24.png"),
			text: "Permissions",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "permissions",
			width: "34.46px",
			height: "50.14px",
		},
		{
			id: 5,
			img: require("../../assets/img25.png"),
			text: "Departments",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "departments",
			width: "34.46px",
			height: "50.14px",
		},
		{
			id: 6,
			img: require("../../assets/img26.png"),
			text: "Levels",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "levels",
			width: "34.46px",
			height: "50.14px",
		},
		{
			id: 7,
			img: require("../../assets/img27.png"),
			text: "Positions",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "positions",
			width: "34.46px",
			height: "50.14px",
		},
		{
			id: 8,
			img: require("../../assets/img28.png"),
			text: "Grades",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "grades",
			width: "50px",
			height: "50px",
		},
		{
			id: 9,
			img: require("../../assets/img29.png"),
			text: "Media",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "media",
			width: "50px",
			height: "43.86px",
		},
		{
			id: 10,
			img: require("../../assets/img30.png"),
			text: "Company",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "company",
			width: "60px",
			height: "45.04px",
		},
	];

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">
					Company Settings
				</p>
			</div>

			<div className=" bg-white mx-12 p-5 mt-5">
				<div className=" grid lg:grid-cols-5 md:grid-cols-4 grid-cols-2 mt-5 gap-5">
					{card.map(i => (
						<Link
							to={i.to}
							className=" bg-white justify-center hover:bg-slate-200 items-center grid gap-2 py-4 rounded-lg lg:w-52 md:w-56 w-full shadow-lg">
							<div className=" grid justify-center items-center">
								<img
									style={{ width: i.width, height: i.height }}
									className=" "
									src={i.img}
									alt=""
								/>
							</div>
							<p className=" text-center font-Kumbh text-sm text-[#000] font-bold">
								{i.text}
							</p>
							<p className=" text-center font-Kumbh text-[13px] leading-[14px] font-medium text-gray-400 ">
								{i.desc}
							</p>
						</Link>
					))}
				</div>
			</div>
		</div>
	);
};

export default CompanySettings;
