import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import SideBar from "../../components/cards/SideBar";
import AssignMembers from "../../components/cards/tast/AssignMembers";
import AssignMember from "../../components/cards/tast/AssignMember";
import NewProject from "../../components/cards/tast/NewProject";
import Delete from "../../components/cards/Delete";
import { Link } from "react-router-dom";
import AddTask from "../../components/cards/tast/AddTask";

const ProgressLoader = ({ percentage }) => {
  return (
    <div className=" flex items-center gap-2">
      <div className="bg-[#F1F2F7] w-[447px] h-2 rounded-full relative flex items-center gap-2">
        <div
          className="bg-[#1BE3A7] h-2 rounded-full "
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <div className=" text-xs text-gray-600">{percentage}% Complete</div>
    </div>
  );
};

const ProjectDetails = () => {
  const [isAssign, setIsAssign] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [showTask, setShowTask] = useState(false);

  const handleTask = () => {
    setShowTask(!showTask);
  };
  const closeTask = () => {
    setShowTask(false);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };
  const closeEdit = () => {
    setIsEdit(false);
  };
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const closeDelete = () => {
    setIsDelete(false);
  };

  const handleAssign = () => {
    setIsAssign(!isAssign);
  };
  const closeAssign = () => {
    setIsAssign(false);
  };

  const task = [
    {
      id: 1,
      text: "Today’s Tasks",
      num: "3",
      img: require("../../assets/projAddIcon.png"),
    },
    {
      id: 2,
      text: "Upcoming Tasks",
      num: "2",
      img: require("../../assets/projAddIcon.png"),
    },
    {
      id: 3,
      text: "Completed Tasks",
      num: "5",
    },
  ];

  const bgColors = ["bg-[#FF26A8]", "bg-[#29B6F6]", "bg-[#66BB6A]"];
  return (
    <div className=" bg-[#EFF6FC] pb-10">
      <Navbar />
      <PagesList />

      <div className=" flex justify-between items-center px-12 pt-4">
        <p className=" font-Kumbh text-[#000] text-base font-bold">
          Tasks management
        </p>
      </div>

      <div className=" bg-white mx-12 p-5 mt-5">
        <div className=" flex gap-5">
          <div>
            <SideBar />
          </div>
          <div className=" w-full">
            <div className=" w-full">
              <p className=" text-xl text-[#000] font-medium Satoshi ">
                Project Details
              </p>
            </div>

            <div className=" flex items-center justify-between w-full mt-5">
              <div className=" flex items-center gap-2">
                <img
                  className=" w-12 "
                  src={require("../../assets/Group73.png")}
                  alt=""
                />
                <div className="">
                  <p className=" text-[#131313] text-base Satoshi font-bold">
                    Project Title{" "}
                  </p>
                  <div>
                    <ProgressLoader percentage={75} />
                  </div>
                </div>
              </div>
              <div className=" flex items-center gap-2">
                <img
                  className=" w-20 h-8"
                  src={require("../../assets/projAvatars.png")}
                  alt=""
                />
                {/*  */}
                <img
                  onClick={handleAssign}
                  className=" w-8 h-8"
                  src={require("../../assets/projAddButton.png")}
                  alt=""
                />
                {isAssign && <AssignMember onclose={closeAssign} />}
              </div>
            </div>
            {/*  */}
            <div className=" mt-5 flex items-center justify-between">
              <div>
                <div className=" flex items-center gap-2">
                  <div className=" bg-[#00B884] w-4 h-4 rounded-md"></div>
                  <p className=" Satoshi text-[#000] text-base font-bold">
                    Project Title
                  </p>
                </div>
                <div className=" flex items-center gap-2 ml-2">
                  <img
                    className=" w-[18.41px] h-4"
                    src={require("../../assets/Vector2.png")}
                    alt=""
                  />
                  <div className=" flex items-center gap-1 relative top-1">
                    <div className=" flex items-center gap-1">
                      <img
                        className=" w-[13.33px] h-[13.33px]"
                        src={require("../../assets/ProjectsFolder.png")}
                        alt=""
                      />
                      <p className="text-[#82808F] text-sm Satoshi">Projects</p>
                    </div>
                    <img
                      className=" w-[14.5px]"
                      src={require("../../assets/Vector3.png")}
                      alt=""
                    />
                    <div className=" flex items-center gap-1">
                      <img
                        className=" w--[14px] h-[14px]"
                        src={require("../../assets/TeamprojectsRectangle8.png")}
                        alt=""
                      />
                      <p className=" text-[#82808F] text-sm Satoshi ">
                        Team projects
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" flex items-center gap-2">
                {/*  */}
                <img
                  onClick={handleEdit}
                  className=" w-6 h-6"
                  src={require("../../assets/projbasil_edit-outline.png")}
                  alt=""
                />
                {isEdit && <NewProject onclose={closeEdit} />}
                <img
                  onClick={handleDelete}
                  className=" w-6 h-6 cursor-pointer"
                  src={require("../../assets/projfluent_delete-20-regular.png")}
                  alt=""
                />
                {isDelete && <Delete onClose={closeDelete} />}
              </div>
            </div>
            {/*  */}
            <div className=" bg-gradient-to-r from-[#060000] to-[#520776] p-5 rounded-2xl mt-5 space-y-16">
              <div className=" flex items-center gap-2">
                <img
                  className=" w-12 "
                  src={require("../../assets/Group482174.png")}
                  alt=""
                />
                <div>
                  <p className=" Satoshi text-white text-base">
                    Wireframe Design
                  </p>
                  <p className="Satoshi text-white text-sm">
                    Lorem ipsum dolor sit amet
                  </p>
                </div>
              </div>

              <div className=" flex items-center gap-3">
                <div className=" w-36 grid justify-start">
                  <p className="  text-sm text-white font-light">Created</p>
                  <p className="Satoshi text-sm text-white">Mar 23, 10:34 PM</p>
                </div>
                <div className=" w-36 grid justify-start">
                  <p className=" text-sm text-white font-light">Deadline</p>
                  <p className="Satoshi text-sm text-white">Jun 02, 04:01 PM</p>
                </div>
                <div className=" w-36 grid justify-start">
                  <p className="text-sm text-white font-light">Tracked Time</p>
                  <p className="Satoshi text-sm text-white">10h 32m</p>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="mt-5">
              <div className=" flex gap-5">
                <div className=" space-y-5">
                  {/*  */}
                  <div className=" flex items-center gap-10">
                    <div className=" w-28 flex items-center gap-2 justify-start">
                      <img
                        className=" w-4 h-4"
                        src={require("../../assets/projSchedule.png")}
                        alt=""
                      />
                      <p className=" text-[#82808F] text-sm Satoshi">
                        Created At
                      </p>
                    </div>
                    <div className=" w-48 flex items-center gap-2 justify-start">
                      <p className=" text-[#BBBBBE] textsm Satoshi">
                        April, 16 2024 13:14 PM
                      </p>
                    </div>
                  </div>
                  {/*  */}
                  <div className=" flex items-center gap-10">
                    <div className=" w-28 flex items-center gap-2 justify-start">
                      <img
                        className=" w-4 h-4"
                        src={require("../../assets/projTag.png")}
                        alt=""
                      />
                      <p className=" text-[#82808F] text-sm Satoshi">Tags</p>
                    </div>
                    <div className=" w-56 flex items-center gap-2 justify-start">
                      <p className=" bg-[#f1c9db] px-2 py-1 rounded-full text-[#FD71AF] textsm Satoshi">
                        Profitable
                      </p>
                      <p className=" bg-[#c2f2e5] px-4 py-1 rounded-full text-[#00B884] textsm Satoshi">
                        Ai
                      </p>
                      <p className=" bg-[#bec6e9] px-2 py-1 rounded-full text-[#5577FF] textsm Satoshi">
                        1 Person
                      </p>
                    </div>
                  </div>
                  {/*  */}
                  <div className=" flex items-center gap-10">
                    <div className=" w-28 flex items-center gap-2 justify-start">
                      <img
                        className=" w-4 h-4"
                        src={require("../../assets/projAssign.png")}
                        alt=""
                      />
                      <p className=" text-[#82808F] text-sm Satoshi">Assign</p>
                    </div>
                    <div className=" w-56 flex items-center gap-2 justify-start">
                      <p className=" bg-[#E9E8EC] px-2 py-1 rounded-lg text-[#9D9BA1] textsm Satoshi">
                        @ Adeola
                      </p>
                    </div>
                  </div>
                  {/*  */}
                  <div className=" flex items-center gap-10">
                    <div className=" w-28 flex items-center gap-2 justify-start">
                      <img
                        className=" w-4 h-4"
                        src={require("../../assets/projPrioritize.png")}
                        alt=""
                      />
                      <p className=" text-[#82808F] text-sm Satoshi">
                        Priority
                      </p>
                    </div>
                    <div className=" w-56 flex items-center gap-2 justify-start">
                      <p className=" bg-[#E9E8EC] px-2 py-1 rounded-lg text-[#9D9BA1] textsm Satoshi">
                        Small
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" pr-28 space-y-3">
                  <div className=" flex items-center justify-between">
                    <p className="text-[#4F4F4F] Satoshi text-sm font-semibold">
                      Description
                    </p>
                    <img
                      className=" w-4 h-4"
                      src={require("../../assets/projCopy-outline.png")}
                      alt=""
                    />
                  </div>
                  <p className=" text-sm text-[#999999] Satoshi ">
                    Lorem ipsum dolor sit amet consectetur. Platea tortor nibh
                    diam mi. Eu libero pharetra commodo malesuada. Id convallis
                    sit nisi auctor cras at tincidunt vel diam. In vehicula
                    turpis lacus fusce tortor sodales ac dignissim sapien.
                    Potenti feugiat varius nunc morbi hendrerit magna. Neque
                    sagittis augue nulla augue magna mattis.
                  </p>
                </div>
              </div>
            </div>

            {/*  */}
            <div className=" mt-10">
              <div className=" flex items-center justify-between border-b border-b-[#E6E4F0]">
                <p className=" ml-2 text-xl Satoshi text-[#000] font-semibold">
                  All Tasks
                </p>
                <Link
                  to="/my-tasks"
                  className=" mr-2 text-sm text-[#5608D4] cursor-pointer "
                >
                  View more
                </Link>
              </div>

              <div className=" grid lg:grid-cols-3 md:grid-cols-3 mt-3 ">
                {task.map((i, index) => (
                  <div
                    key={i.id}
                    className={`w-72 h-auto rounded-t-xl ${bgColors[index]} pt-1`}
                  >
                    <div className=" bg-[#F9F9F9] rounded-t-xl w-full h-full flex justify-between items-center p-3 ">
                      <div className=" flex items-center gap-2">
                        <p>{i.text}</p>
                        <p className="  bg-[#dcd8d8] text-[#131313] font-Nunito text-sm w-8 h-8 justify-center grid items-center rounded-full ">
                          {i.num}
                        </p>
                      </div>
                      {i.img && (
                        <img
                          onClick={handleTask}
                          className="w-8 h-8 cursor-pointer"
                          src={i.img}
                          alt=""
                        />
                      )}
                    </div>
                    {showTask && <AddTask onClose={closeTask} />}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
