import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Delete from "./Delete";
import ActionCompleted from "./ActionCompleted";
import { useFileStore } from "../../data/Stores/LoggerStore";
import useErrorStore from "../../data/Stores/errorstore";
import { apiCall } from "../../data/useGenFetcher";
import { toast } from "react-toastify";
import moment from "moment";

const FilesDetails = ({ closeDetails, item }) => {
	const [isDeleteDetails, setIsDeleteDetails] = useState(false);
	const [isEditDetails, setIsEditDetails] = useState(false);

	const handleDeleteDetails = () => {
		setIsDeleteDetails(!isDeleteDetails);
	};
	const closeDeleteDetails = () => {
		setIsDeleteDetails(false);
	};

	const handleEditDetails = () => {
		setIsEditDetails(!isDeleteDetails);
	};

	const closeEditDetails = () => {
		setIsEditDetails(false);
	};

	let { deleteLogger } = useFileStore(),
		[loading, setLoading] = useState(false),
		{ returnErrors } = useErrorStore(),
		items = useLocation()?.state;

	let handleSubmit = async e => {
		// e?.preventDefault();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/file/${item?._id}?ref=${item?.ref}`,
			type: "delete",
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response || response === "") {
			deleteLogger(item);
			closeDetails();
			return;
		}
		setLoading(false);
	};

	return (
		<>
			<div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-65 grid justify-center items-center">
				<div className=" bg-white w-[500px] h-auto rounded-md p-5">
					<div className=" flex items-center justify-between ">
						<p className=" font-Kumbh text-xl text-[#000] font-bold">
							File’s Details
						</p>
						<span
							onClick={closeDetails}
							className="font-Kumbh text-3xl text-[#000] font-bold cursor-pointer">
							&times;
						</span>
					</div>
					<div className=" flex gap-5 mt-10">
						<div className=" ">
							<div className=" pb-10">
								<img
									className=" w-12 h-16"
									src={require("../../assets/Contents.png")}
									alt=""
								/>
							</div>
							<p className=" text-[#0811D4] font-Nunito text-xs uppercase">
								{item?.format}
							</p>
						</div>
						<div className=" space-y-5">
							<p className=" Satoshi text-[#101828] font-bold text-sm">
								{item?.name}
							</p>
							<div className=" flex items-center gap-5">
								{items?.levels?.map((it, x) => (
									<p
										key={x}
										className={` rounded-3xl text-[10px] grid justify-center items-center w-24 h-5 ${
											x % 2 === 0
												? "bg-[#FCF3FF] text-[#7A12B9]"
												: "bg-[#F4FFF3] text-[#12B919]"
										}`}>
										{it?.name}
									</p>
								))}
							</div>
							<div>
								<img className=" w-[321px]" src={item?.url} alt="" />
							</div>

							<div className=" pt-10 flex justify-between items-center">
								<p className=" text-[#667085] text-[10px] font-Nunito">
									{moment(item?.createdAt).format("DD/MM/YYYY")}
								</p>
								<div
									className=" flex items-center gap-2 cursor-pointer"
									onClick={() => {
										window.open(item?.url, "_blank");
									}}>
									<img
										className=" w-3 h-3"
										src={require("../../assets/iconamoon.png")}
										alt=""
									/>
									<p className=" font-Nunito text-[#0811D4] text-xs">
										Download
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className=" flex items-center gap-5 mt-10">
						<button
							onClick={handleDeleteDetails}
							className=" border-[0.5px] border-[#D4082D] text-[#D4082D] text-sm font-Nunito rounded-xl w-44 h-8">
							Delete
						</button>
						{isDeleteDetails && (
							<Delete
								onClose={closeDeleteDetails}
								deleteFunc={() => handleSubmit()}
								title={item?.name}
								loading={loading}
							/>
						)}
						<button
							onClick={handleEditDetails}
							className=" bg-gradient-to-b from-[#0811D4] to-[#384295] text-white text-sm font-Nunito rounded-xl w-44 h-8 hidden">
							Edit file
						</button>
						{isEditDetails && <ActionCompleted onClose={closeEditDetails} />}
					</div>
				</div>
			</div>
		</>
	);
};

export default FilesDetails;
