import React from "react";

const JobCard = ({ image, title, description, buttonText, onClick }) => {
  return (
    <div className=" relative z-40 ">
      <div className=" w-[300px] rounded-lg bg-white px-5 pt-10 pb-4 ">
        <div className=" justify-center items-center grid">
          <img className=" w-24 h-16" src={image} alt="" />
        </div>
        <div className=" mt-4">
          <p className=" text-center font-Nunito font-bold text-[#000]">
            {title}
          </p>
          <p className=" textsm font-Nunito text-[#000] py-2 text-center">
            {description}
          </p>
        </div>
        <div className=" flex justify-center items-center mt-5">
          <button
            onClick={onClick}
            className=" bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito w-36 h-9 rounded-lg"
          >
            {buttonText}
          </button>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default JobCard;
