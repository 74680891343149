import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import SearchInput from "../../../components/cards/SearchInput";
import { Link } from "react-router-dom";
import useDepartmentStore from "../../../data/Stores/DepartmentStore";
import { apiCall } from "../../../data/useGenFetcher";
import { MainPaginate } from "../../../components/buttons/Send";

const Permissions = () => {
	const tableHeadAdmins = [
		"S/N",
		"Department Name",
		"Department Description",
		"Assigned",
	];

	const tableDataAdmins = [
		{
			SN: "1",
			RoleName: "Finance & Accounts",
			PermissionDescriotiion:
				"Lorem ipsum dolor sit amet ipsum dolor sit amet.",
			Assigned: "Dashboard, Finance, Inventory, Leave Requests",
		},
		{
			SN: "2",
			RoleName: "Finance & Accounts",
			PermissionDescriotiion:
				"Lorem ipsum dolor sit amet ipsum dolor sit amet.",
			Assigned: "Dashboard, Finance, Inventory, Leave Requests",
		},
		{
			SN: "3",
			RoleName: "Finance & Accounts",
			PermissionDescriotiion:
				"Lorem ipsum dolor sit amet ipsum dolor sit amet.",
			Assigned: "Dashboard, Finance, Inventory, Leave Requests",
		},
		{
			SN: "4",
			RoleName: "Finance & Accounts",
			PermissionDescriotiion:
				"Lorem ipsum dolor sit amet ipsum dolor sit amet.",
			Assigned: "Dashboard, Finance, Inventory, Leave Requests",
		},
		{
			SN: "5",
			RoleName: "Finance & Accounts",
			PermissionDescriotiion:
				"Lorem ipsum dolor sit amet ipsum dolor sit amet.",
			Assigned: "Dashboard, Finance, Inventory, Leave Requests",
		},
		{
			SN: "6",
			RoleName: "Finance & Accounts",
			PermissionDescriotiion:
				"Lorem ipsum dolor sit amet ipsum dolor sit amet.",
			Assigned: "Dashboard, Finance, Inventory, Leave Requests",
		},
		{
			SN: "7",
			RoleName: "Finance & Accounts",
			PermissionDescriotiion:
				"Lorem ipsum dolor sit amet ipsum dolor sit amet.",
			Assigned: "Dashboard, Finance, Inventory, Leave Requests",
		},
	];

	let [datum, setDatum] = useState({ docs: tableDataAdmins }),
		{ getDynamicDept, mainSearch, isFound, all, data } = useDepartmentStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(all || data);
	}, [all, isFound, mainSearch, data]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: "/api/v1/department?_limit=0",
			getter: d => getDynamicDept(d, "all"),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Company Settings
					</p>
				</div>

				<div className=" bg-white mx-12 mt-5 p-5">
					<div className=" flex items-center justify-between">
						<div className=" flex items-center gap-20">
							<div className=" flex gap-2 items-center">
								<p className=" text-[#121212] text-xs font-Nunito">Search </p>
								<SearchInput />
							</div>
						</div>
						<div className=" cursor-pointer">
							<Link
								to="information"
								className=" text-sm Satoshi text-white bg-black rounded-md px-4 py-2 animate-pulse ">
								Create new
							</Link>
							{/* {showId && <ApprovalFlow onClose={closeId} />} */}
						</div>
					</div>
					<div>
						<div className="card">
							<table className="min-w-full mt-5">
								<thead className="bg-gray-50 border-b-[0.5px] border-b-gray-200">
									<tr>
										{tableHeadAdmins?.map((head, index) => (
											<th
												key={index}
												className="px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
												{head}
											</th>
										))}
									</tr>
								</thead>
								<tbody className="bg-white">
									{currentItems?.map((rowData, rowIndex) => (
										<tr key={rowIndex}>
											<td className="px-4 py-4 text-xs text-gray-800">
												{rowIndex + 1}
											</td>
											<td className="px-4 py-4 text-xs text-gray-800">
												{rowData?.name}
											</td>
											<td className="px-4 py-4 text-xs text-gray-800">
												{rowData?.description}
											</td>
											<td className="px-4 py-4 text-xs text-gray-800">
												{rowData?.modules?.map((it, x) => (
													<span key={x}>
														{it}
														{x === rowData?.modules?.length - 1 ? "" : ", "}
													</span>
												))}
											</td>
										</tr>
									))}
								</tbody>
							</table>
							{pageCount > 1 && (
								<MainPaginate
									pageCount={pageCount}
									handlePageClick={handlePageClick}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Permissions;
