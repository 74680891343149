import React, { useState } from "react";
import ActionCompleted from "../ActionCompleted";
import GradientBtn from "../../buttons/gradientbtn";

const AssignMember = ({ onclose }) => {
  const [activeView, setActiveView] = useState("board");
  const [isAdd, setIsAdd] = useState(false);
  const [addAssign, setAddAssign] = useState(false);

  const handleAssign = () => {
    setAddAssign(!addAssign);
  };

  const closeAssign = () => {
    setAddAssign(false);
  };

  const closeAdd = () => {
    setIsAdd(false);
  };

  return (
    <div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center z-10">
      <div className=" bg-white w-72 h-auto p-5">
        <div className=" flex items-center justify-between">
          <p className=" font-Kumbh text-xl text-[#000] font-bold">
            Assign Members
          </p>
          <p
            onClick={onclose}
            className="font-Kumbh text-xl text-[#000] font-bold cursor-pointer"
          >
            X
          </p>
        </div>
        <div className=" space-y-3 mt-5">
          <p className=" text-sm font-Nunito text-[#121212]">Assign Members</p>
          <div className=" flex items-center gap-2">
            <input type="radio" />
            <p className=" font-Nunito text-sm text-[#5D5A5A]">All Staff</p>
          </div>
          <div className=" flex items-center gap-2">
            <input type="radio" />
            <p className=" font-Nunito text-sm text-[#5D5A5A]">Position</p>
          </div>
          <div className=" flex items-center gap-2">
            <input type="radio" />
            <p className=" font-Nunito text-sm text-[#5D5A5A]">Department</p>
          </div>
          <div className=" flex items-center gap-2">
            <input type="radio" />
            <p className=" font-Nunito text-sm text-[#5D5A5A]">Level</p>
          </div>
        </div>

        <div className=" mt-10">
          <div onClick={handleAssign} className=" w-44">
            <GradientBtn btnText=" Assign" />
          </div>
          {addAssign && <ActionCompleted onClose={closeAssign} />}
        </div>
      </div>
    </div>
  );
};

export default AssignMember;
