// import React from "react";
// import { Doughnut } from "react-chartjs-2";

// const ProfileStatusChart = ({ percentage }) => {
//   const data = {
//     labels: ["Completed", "Remaining"],
//     datasets: [
//       {
//         data: [percentage, 100 - percentage],
//         backgroundColor: ["#4F46E5", "#E0E7FF"],
//         hoverBackgroundColor: ["#4338CA", "#C7D2FE"],
//         borderWidth: 0,
//       },
//     ],
//   };

//   const options = {
//     cutout: "70%",
//     plugins: {
//       legend: {
//         display: false,
//       },
//       tooltip: {
//         enabled: false,
//       },
//     },
//     maintainAspectRatio: false,
//   };

//   return (
//     <div className="relative w-40 h-40">
//       {" "}
//       <Doughnut data={data} options={options} />
//       <div className="absolute inset-0 flex items-center justify-center">
//         <div className="text-center">
//           <p className="text-lg font-semibold">{percentage}%</p>
//           <p className="text-gray-500">completed</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProfileStatusChart;
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register required elements for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const ProfileStatusChart = ({ percentage }) => {
  const data = {
    labels: ["Completed", "Remaining"],
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: ["#4F46E5", "#E0E7FF"],
        hoverBackgroundColor: ["#4338CA", "#C7D2FE"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="relative w-40 h-40">
      <Doughnut data={data} options={options} />
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center">
          <p className="text-lg font-semibold">{percentage}%</p>
          <p className="text-gray-500">completed</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileStatusChart;
