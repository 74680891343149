import React from "react";

const CalendarViewCard = () => {
  return (
    <div>
      <p>CalendarViewCard</p>
    </div>
  );
};

export default CalendarViewCard;
