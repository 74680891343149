import React, { useState } from "react";
import Tools from "../buttons/Tools";
import ShortAnswer from "../buttons/ShortAnswer";
import CopyDelete from "../buttons/CopyDelete";
import ActionCompleted from "./ActionCompleted";
import Option from "./Option";
import { useRequestStore } from "../../data/Stores/LoggerStore";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/useGenFetcher";
import { Button } from "../buttons/Send";
import ReactQuill from "react-quill";

const AddRequests = ({ onClose, items }) => {
	const [isSwitched, setIsSwitched] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [showAnswer, setShowAnswer] = useState(false);

	const handleShowAnswer = () => {
		setShowAnswer(!showAnswer);
	};
	const closeShowAnswer = () => {
		setShowAnswer(false);
	};

	const handleSubmit2 = () => {
		setSubmit(!submit);
	};

	const closeSubmit = () => {
		setSubmit(false);
	};

	const handleClick = () => {
		setIsSwitched(!isSwitched);
	};

	let init = {},
		[state, setState] = useState({}),
		[loading, setLoading] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ updateLogger, addLogger } = useRequestStore(),
		{ returnErrors } = useErrorStore();

	let handleSubmit = async e => {
		e?.preventDefault();
		if (Object.values(state)?.length === 0)
			return toast.info("Update Data required");
		let newState = state;

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/request/${items ? items?._id : ""}`,
			type: items ? "put" : "post",
			data: newState,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			items ? addLogger(response) : updateLogger(response);
			setState(init);
			onClose();
			handleSubmit2();
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" overflow-y-scroll fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
			<div className=" bg-white w-[682px] p-5 h-auto rounded-md ">
				<div className=" flex justify-between items-center">
					<p className=" font-Kumbh text-xl font-bold text-[#000]">
						{items ? "Update" : "New"} Request
					</p>
					<p
						onClick={onClose}
						className=" cursor-pointer text-[24px] font-bold">
						&times;
					</p>
				</div>
				<div className=" space-y-5 mt-5 pr-5">
					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Request name
						</label>
						<input
							placeholder="ID card "
							className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={state?.name || items?.name}
							onChange={textChange}
							name={"name"}
						/>
					</div>
					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Request details
						</label>
						{/* <input
							placeholder="Description"
							className=" border border-[#D0D0D0] px-2 w-full h-28 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
						/> */}
						<ReactQuill
							placeholder="Enter Request description"
							theme="snow"
							value={state?.description || items?.description}
							onChange={e => setState({ ...state, description: e })}
							className=" smallText max-w-[750px] w-full placeholder:text-xs placeholder-[#A3A3A3] outline-none px-2"
						/>
					</div>
				</div>

				<div className=" space-y-5">
					{/*  */}
					<div className="hidden bg-white shadow-lg gap-16 p-5">
						<div className=" flex">
							<div className=" w-3/5">
								<input
									placeholder="Name on Card"
									type="text"
									className=" placeholder:text-sm placeholder:Satoshi placeholder-[#1B1B1B] bg-[#F6F6F7] w-full h-12 pl-2"
								/>
								<div className=" mt-3">
									<Tools />
								</div>
							</div>
							<div className="  w-2/5 justify-center grid">
								<div onClick={handleShowAnswer}>
									{" "}
									<ShortAnswer text="Short answer text" />
								</div>
								{showAnswer && <Option onClose={closeShowAnswer} />}
							</div>
						</div>
						<div className=" flex justify-center">
							<CopyDelete />
						</div>
					</div>
					{/*  */}
					<div className="hidden bg-white shadow-lg gap-16 p-5">
						<div className=" flex">
							<div className=" w-3/5">
								<input
									placeholder="Phone number"
									type="text"
									className=" placeholder:text-sm placeholder:Satoshi placeholder-[#1B1B1B] bg-[#F6F6F7] w-full h-12 pl-2"
								/>
								<div className=" mt-3">
									<Tools />
								</div>
							</div>
							<div className="  w-2/5 justify-center grid">
								<div onClick={handleShowAnswer}>
									<ShortAnswer text="Short answer text" />
								</div>
								{showAnswer && <Option onClose={closeShowAnswer} />}
							</div>
						</div>
						<div className=" flex justify-center">
							<CopyDelete />
						</div>
					</div>
					{/*  */}
					<div className="hidden bg-white shadow-lg  p-5">
						<div className=" flex">
							<div className=" w-3/5">
								<input
									placeholder="Upload Picture"
									type="text"
									className=" placeholder:text-sm placeholder:Satoshi placeholder-[#1B1B1B] bg-[#F6F6F7] w-full h-12 pl-2"
								/>
								<div className=" mt-3">
									<Tools />
								</div>
							</div>
							<div className="  w-2/5 justify-center grid">
								<div onClick={handleShowAnswer}>
									{" "}
									<ShortAnswer text="File upload" />
								</div>
								{showAnswer && <Option onClose={closeShowAnswer} />}
							</div>
						</div>
						<div className=" w-52 h-auto py-5">
							<div className=" flex items-center justify-between">
								<p className=" text-[#1b1b1b] text-sm ">
									Allow only specific file type
								</p>
								<div className="bg-[#D1EFFF] h-[6px] w-5 rounded-full flex items-center">
									<div
										className={`w-2 h-2 bg-[#5597C9] rounded-full transition-transform duration-500 ${
											isSwitched ? "transform translate-x-full" : ""
										}`}
										onClick={handleClick}></div>
								</div>
							</div>
							<div className=" space-y-3 pt-2">
								<div className=" flex justify-between items-center">
									<div className=" justify-start flex gap-3">
										<input type="checkbox" className=" w-4 h-4" />
										<p className=" font-Nunito text-[10px] text-[#1B1B1B]">
											Document
										</p>
									</div>
									<div className=" w-16 flex justify-start gap-3">
										<input type="checkbox" className=" w-4 h-4" />
										<p className=" font-Nunito text-[10px] text-[#1B1B1B]">
											Video
										</p>
									</div>
								</div>
								<div className=" flex justify-between items-center">
									<div className=" justify-start flex gap-3">
										<input type="checkbox" className=" w-4 h-4" />
										<p className=" font-Nunito text-[10px] text-[#1B1B1B]">
											Image
										</p>
									</div>
									<div className=" w-16 flex justify-start gap-3">
										<input type="checkbox" className=" w-4 h-4" />
										<p className=" font-Nunito text-[10px] text-[#1B1B1B]">
											PDF
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className=" flex justify-center">
							<CopyDelete />
						</div>
					</div>
					<div>
						{/* <button
							onClick={handleSubmit}
							className=" w-44 h-8 rounded-lg bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito">
							Submit
						</button> */}
						<Button
							css={
								" w-44 h-8 rounded-lg bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito"
							}
							children={"Submit"}
							onClick={handleSubmit}
							loading={loading}
						/>
						{submit && <ActionCompleted onClose={closeSubmit} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddRequests;
