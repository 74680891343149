import React from "react";
import DOMPurify from "dompurify";

export const createMarkup = html => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

const ReviewSubmit = ({ state, handleStepClick }) => {
	return (
		<div>
			<div className=" flex gap-3 pr-20">
				<img
					className=" w-3 h-3 mt-5"
					src={require("../../assets/mdi_required.png")}
					alt=""
				/>
				<div>
					<p className=" text-[#1E2126] font-Lato text-lg font-bold">Review</p>
					<div className=" flex items-center justify-between gap-96 mt-5">
						<p className="font-bold">Basic Information</p>

						<img
							className=" w-4 h-4 cursor-pointer"
							src={require("../../assets/pencil-line.png")}
							alt=""
							onClick={() => handleStepClick(1)}
						/>
					</div>
					{/*  */}
					<div className=" space-y-2 mt-5 ">
						<p className=" text-[#1E2126] font-Lato text-sm ">Job Name</p>
						<p className=" font-light font-Lato text-sm text-[#7E8597]">
							{state?.name}
						</p>
					</div>
					{/*  */}
					<div className=" space-y-2 mt-5 ">
						<p className=" text-[#1E2126] font-Lato text-sm ">
							Job Description
						</p>
						<p
							className=" font-light font-Lato text-sm text-[#7E8597]"
							dangerouslySetInnerHTML={createMarkup(state?.description || "")}
						/>
					</div>
					{/*  */}
					<div className=" space-y-2 mt-5 ">
						<p className=" text-[#1E2126] font-Lato text-sm ">Job Type</p>
						<p className=" font-light font-Lato text-sm text-[#7E8597]">
							{state?.type}
						</p>
					</div>
					{/*  */}
					<div className=" space-y-2 mt-5 ">
						<p className=" text-[#1E2126] font-Lato text-sm ">Job Location</p>
						<p className=" font-light font-Lato text-sm text-[#7E8597]">
							{state?.location}
						</p>
					</div>
					{/*  */}
					<div className=" space-y-2 mt-5 ">
						<p className=" text-[#1E2126] font-Lato text-sm ">
							Recruitment Limit
						</p>
						<p className=" font-light font-Lato text-sm text-[#7E8597]">
							{state?.limit}
						</p>
					</div>
					<div className=" flex items-center justify-between gap-96 mt-5">
						<p className="font-bold">Hiring Details</p>

						<img
							className=" w-4 h-4 cursor-pointer"
							src={require("../../assets/pencil-line.png")}
							alt=""
							onClick={() => handleStepClick(2)}
						/>
					</div>
					<div className=" space-y-2 mt-5 ">
						<p className=" text-[#1E2126] font-Lato text-sm ">Job Level</p>
						<p className=" font-light font-Lato text-sm text-[#7E8597]">
							{state?.level}
						</p>
					</div>
					<div className=" space-y-2 mt-5 ">
						<p className=" text-[#1E2126] font-Lato text-sm ">Hiring Manager</p>
						<p className=" font-light font-Lato text-sm text-[#7E8597]">
							{state?.hiringManager}
						</p>
					</div>
					<div className=" space-y-2 mt-5 ">
						<p className=" text-[#1E2126] font-Lato text-sm ">Recruiters</p>
						<p className=" font-light font-Lato text-sm text-[#7E8597]">
							{state?.recruiters}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewSubmit;
