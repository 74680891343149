import React, { useState } from "react";

const DepartmentChats = () => {
  const initialPinned = [
    {
      id: 1,
      userimg: require("../../assets/userimgg.png"),
      name: "Debbi",
      occ: " - HR Lead",
      text: "Hey Jane, I sent you a document on discord...",
      img: require("../../assets/pined.png"),
      time: "12:30",
      userOnline: "online",
    },
    {
      id: 2,
      userimg: require("../../assets/userimgg.png"),
      name: "Group Zoe",
      occ: " - HR Lead",
      text: "Hey Jane, I sent you a document on discord...",
      img: require("../../assets/pined.png"),
      time: "12:30",
      groupAvat: require("../../assets/chatAvatars.png"),
      groupAdd: require("../../assets/ChatAddButton.png"),
      members: "50 members",
      membersOnline: "12 online",
    },
    {
      id: 3,
      userimg: require("../../assets/userimgg.png"),
      name: "Debbie Zoe",
      occ: " - HR Lead",
      text: "Hey Jane, I sent you a document on discord...",
      img: require("../../assets/pined.png"),
      time: "12:30",
      userOnline: "online",
    },
  ];

  const initialAllMessages = [
    {
      id: 4,
      userimg: require("../../assets/userimgg.png"),
      name: "Group Dev K",
      occ: " - CEO",
      text: "Hey Jane, I sent you a document on discord...",
      img: require("../../assets/pined.png"),
      time: "12:30",
      groupAvat: require("../../assets/chatAvatars.png"),
      groupAdd: require("../../assets/ChatAddButton.png"),
      members: "50 members",
      membersOnline: "12 online",
    },
    {
      id: 5,
      userimg: require("../../assets/userimgg.png"),
      name: "Jhon",
      occ: " - CEO",
      text: "Hey Jane, I sent you a document on discord...",
      img: require("../../assets/pined.png"),
      time: "12:30",
      userOnline: "online",
    },
    {
      id: 6,
      userimg: require("../../assets/userimgg.png"),
      name: "Eze",
      occ: " - CEO",
      text: "Hey Jane, I sent you a document on discord...",
      img: require("../../assets/pined.png"),
      time: "12:30",
      userOnline: "online",
    },
    {
      id: 7,
      userimg: require("../../assets/userimgg.png"),
      name: "Debbie Group",
      occ: " - CEO",
      text: "Hey Jane, I sent you a document on discord...",
      img: require("../../assets/pined.png"),
      time: "12:30",
      groupAvat: require("../../assets/chatAvatars.png"),
      groupAdd: require("../../assets/ChatAddButton.png"),
      members: "50 members",
      membersOnline: "12 online",
    },
  ];

  const [pinned, setPinned] = useState(initialPinned);
  const [allMessages, setAllMessages] = useState(initialAllMessages);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    user: null,
    type: "",
  });

  const [selectedUser, setSelectedUser] = useState(null);

  const handlePin = (user) => {
    setPinned([...pinned, user]);
    setAllMessages(allMessages.filter((message) => message.id !== user.id));
    setContextMenu({ visible: false, x: 0, y: 0, user: null, type: "" });
  };

  const handleUnpin = (user) => {
    setAllMessages([...allMessages, user]);
    setPinned(pinned.filter((message) => message.id !== user.id));
    setContextMenu({ visible: false, x: 0, y: 0, user: null, type: "" });
  };

  const showContextMenu = (e, user, type) => {
    e.preventDefault();
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      user,
      type,
    });
  };

  const handleClickOutside = () => {
    setContextMenu({ visible: false, x: 0, y: 0, user: null, type: "" });
  };

  const handleCardClick = (user) => {
    setSelectedUser(user);
  };

  return (
    <div className="flex">
      <div className=" h-[470px] w-1/3 shadow-right relative z-10 pr-4 py-2 bg-white overflow-y-scroll">
        <div>
          <p className="pb-2 text-[#000] text-base font-Kumbh font-semibold">
            Pinned
          </p>
          <div className="space-y-1">
            {pinned.map((i) => (
              <div
                key={i.id}
                className="shadow-lg gap-3 cursor-pointer bg-white flex justify-between items-center px-5 py-2"
                onClick={() => handleCardClick(i)}
              >
                <img className="w-12 h-12" src={i.userimg} alt="" />
                <div className="flex items-center justify-between">
                  <div className="space-y-1">
                    <div className="flex items-center gap-1">
                      <p className="Satoshi text-sm text-[#000] font-semibold">
                        {i.name}
                      </p>
                      <p className="text-xs Satoshi text-[#B0ADB4]">{i.occ}</p>
                    </div>
                    <p className="text-[10px] font-Nunito text-[#000]">
                      {i.text}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <div className="grid justify-center">
                      <img
                        className="w-4 h-4 cursor-pointer"
                        src={i.img}
                        alt=""
                        onClick={() => handleUnpin(i)}
                        // onClick={(e) => showContextMenu(e, i, "unpin")}
                      />
                    </div>
                    <div className="grid justify-center">
                      <p className="text-[#9C99A1] font-Nunito text-xs">
                        {i.time}
                      </p>
                    </div>
                    <div className="grid justify-center">
                      <p className="rounded-full bg-[#BA99FF] w-2 h-2"></p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-10 relative">
          <p className="mb-2 text-[#000] text-base font-Kumbh font-semibold">
            All Messages
          </p>
          <div className="space-y-1 relative">
            {allMessages.map((i) => (
              <div
                key={i.id}
                className="cursor-pointer shadow-lg gap-3 bg-white flex justify-between items-center px-5 py-2"
                onContextMenu={(e) => showContextMenu(e, i, "pin")}
                onClick={() => handleCardClick(i)}
              >
                <img className="w-12 h-12" src={i.userimg} alt="" />
                <div className="flex items-center justify-between">
                  <div className="space-y-1">
                    <div className="flex items-center gap-1">
                      <p className="Satoshi text-sm text-[#000] font-semibold">
                        {i.name}
                      </p>
                      <p className="text-xs Satoshi text-[#B0ADB4]">{i.occ}</p>
                    </div>
                    <p className="text-[10px] font-Nunito text-[#000]">
                      {i.text}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <div className="grid justify-center">
                      <p className="text-[#9C99A1] font-Nunito text-xs">
                        {i.time}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className=" fixed left-1/3 z-20 top-[56%] ">
              {contextMenu.type === "pin" && (
                <div className="bg-[#EFF6FC] p-5">
                  <button
                    className="px-4 py-2 text-sm bg-blue-500 text-white rounded"
                    onClick={() => handlePin(contextMenu.user)}
                  >
                    Pin Account
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" h-[470px] w-9/12 bg-white grid items-center ">
        <div className="h-full items-stretch grid ">
          {selectedUser && (
            <div>
              <div className="flex items-center justify-between bg-white shadow-lg px-4 py-2">
                <div className="flex items-center gap-10">
                  <div className="flex items-center gap-5">
                    <div>
                      <img
                        className="w-12 h-1/2"
                        src={selectedUser.userimg}
                        alt=""
                      />
                    </div>
                    <div className="space-y-1">
                      <p className="text-base font-Kumbh text-[#000] font-semibold">
                        {selectedUser.name}
                      </p>
                      <div className="flex items-center gap-4">
                        <p className="text-xs font-Kumbh">
                          {selectedUser.members}
                        </p>
                        <div className="flex items-center gap-1">
                          {selectedUser.membersOnline && (
                            <p className="w-2 h-2 bg-[#46E10F] rounded-full"></p>
                          )}
                          {selectedUser.userOnline && (
                            <p className="w-2 h-2 bg-[#46E10F] rounded-full"></p>
                          )}
                          <p
                            className={`text-${
                              selectedUser.membersOnline ||
                              selectedUser.userOnline
                                ? "#46E10F"
                                : "#B0ADB4"
                            } font-Kumbh text-xs`}
                          >
                            {selectedUser.membersOnline
                              ? selectedUser.membersOnline
                              : selectedUser.userOnline}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    {selectedUser.groupAvat && (
                      <img
                        className="w-36 h-10"
                        src={selectedUser.groupAvat}
                        alt="Group Avatar"
                      />
                    )}
                    {selectedUser.groupAdd && (
                      <img
                        className="w-10 h-10 cursor-pointer"
                        src={selectedUser.groupAdd}
                        alt="Add Button"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <img
                    className="w-5 cursor-pointer"
                    src={require("../../assets/Chaticon.png")}
                    alt=""
                  />
                </div>
              </div>

              <div className=" px-10 h-[377px] pt-60 flex overflow-y-scroll hide-scrollbar items-center gap-3">
                <div className="w-full flex justify-between">
                  <div className=" w-1/2">
                    <div>
                      <div className="grid items-center gap-3 justify-between">
                        <div className="flex items-center gap-5">
                          <img
                            className="w-7 h-7"
                            src={require("../../assets/Ellipse976.png")}
                            alt=""
                          />
                          <div className="flex items-center gap-2">
                            <p className="font-Nunito text-sm text-black font-semibold">
                              James Smith
                            </p>
                            <div className="flex items-center gap-2">
                              <p className="rounded-full bg-[#D9D9D9] w-[6px] h-[6px]"></p>
                              <p className="text-[#595454] font-Nunito text-[13px]">
                                UI/UX Designer
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 mt-3">
                          <div className="bg-[#F8F4FF] rounded-lg border border-[#E2CFFF] p-3">
                            <p className="font-Nunito text-xs text-[#3D3C3D]">
                              Lorem ipsum dolor sit amet consectetur. Quam
                              pulvinar tristique in lobortis aliquet justo duis
                              cursus. Aenean ultrices sed purus duis massa enim.
                              Tortor rhoncus.
                            </p>
                          </div>
                          <img
                            className="w-5 h-5"
                            src={require("../../assets/emojii.png")}
                            alt=""
                          />
                        </div>
                        <div className="mt-5">
                          <p className="font-Nunito text-[13px] text-[#7D7B7B]">
                            11:00
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="flex justify-end pr-20">
                      <p className="text-sm font-Nunito text-[#000]">
                        Yesterday
                      </p>
                    </div>

                    <div className="mt-5">
                      <div>
                        <div className="flex items-center gap-3 justify-between">
                          <div className="flex items-center gap-5">
                            <p className="font-Nunito text-sm text-black font-semibold">
                              You
                            </p>
                            <div className="flex items-center gap-2">
                              <p className="rounded-full bg-[#D9D9D9] w-[6px] h-[6px]"></p>
                              <p className="text-[#595454] font-Nunito text-[13px]">
                                UI/UX Designer
                              </p>
                            </div>
                          </div>

                          <img
                            className="w-7 h-7"
                            src={require("../../assets/Ellipse976.png")}
                            alt=""
                          />
                        </div>
                        <div className="flex items-center gap-2 mt-3">
                          <div className="bg-[#F8F4FF] rounded-lg border border-[#E2CFFF] p-3">
                            <p className="font-Nunito text-xs text-[#3D3C3D]">
                              Lorem ipsum dolor sit amet consectetur. Quam
                              pulvinar tristique in lobortis aliquet justo duis
                              cursus. Aenean ultrices sed purus duis massa enim.
                              Tortor rhoncus.
                            </p>
                          </div>
                          <img
                            className="w-5 h-5"
                            src={require("../../assets/emojii.png")}
                            alt=""
                          />
                        </div>
                        <div className="mt-5">
                          <p className="font-Nunito text-[13px] text-[#7D7B7B]">
                            11:00
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div>
                        <div className="flex items-center gap-3 justify-between">
                          <div className="flex items-center gap-5">
                            <p className="font-Nunito text-sm text-black font-semibold">
                              You
                            </p>
                            <div className="flex items-center gap-2">
                              <p className="rounded-full bg-[#D9D9D9] w-[6px] h-[6px]"></p>
                              <p className="text-[#595454] font-Nunito text-[13px]">
                                UI/UX Designer
                              </p>
                            </div>
                          </div>

                          <img
                            className="w-7 h-7"
                            src={require("../../assets/Ellipse976.png")}
                            alt=""
                          />
                        </div>
                        <div className="flex items-center gap-2 mt-3">
                          <div className="bg-[#F8F4FF] rounded-lg border border-[#E2CFFF] p-3">
                            <p className="font-Nunito text-xs text-[#3D3C3D]">
                              Lorem ipsum dolor sit amet consectetur. Quam
                              pulvinar tristique in lobortis aliquet justo duis
                              cursus. Aenean ultrices sed purus duis massa enim.
                              Tortor rhoncus.
                            </p>
                          </div>
                          <img
                            className="w-5 h-5"
                            src={require("../../assets/emojii.png")}
                            alt=""
                          />
                        </div>
                        <div className="mt-5">
                          <p className="font-Nunito text-[13px] text-[#7D7B7B]">
                            11:00
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="flex items-center justify-center mt-10 gap-3 overflow-y-scroll">
                <div className="w-1/2">
                  <div className="flex justify-start pl-20">
                    <p className="text-sm font-Nunito text-[#000]">Yesterday</p>
                  </div>
                  <div className="mt-5">
                    <div>
                      <div className="flex items-center gap-3 justify-between">
                        <div className="flex items-center gap-5">
                          <img
                            className="w-7 h-7"
                            src={require("../../assets/Ellipse976.png")}
                            alt=""
                          />
                          <div className="flex items-center gap-2">
                            <p className="font-Nunito text-sm text-black font-semibold">
                              You
                            </p>
                            <div className="flex items-center gap-2">
                              <p className="rounded-full bg-[#D9D9D9] w-[6px] h-[6px]"></p>
                              <p className="text-[#595454] font-Nunito text-[13px]">
                                UI/UX Designer
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 mt-3">
                          <div className="bg-[#F8F4FF] rounded-lg border border-[#E2CFFF] p-3">
                            <p className="font-Nunito text-xs text-[#3D3C3D]">
                              {selectedUser.text}
                            </p>
                          </div>
                          <img
                            className="w-5 h-5"
                            src={require("../../assets/emojii.png")}
                            alt=""
                          />
                        </div>
                        <div className="mt-5">
                          <p className="font-Nunito text-[13px] text-[#7D7B7B]">
                            {selectedUser.time}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="flex justify-end pr-20">
                      <p className="text-sm font-Nunito text-[#000]">
                        Yesterday
                      </p>
                    </div>

                    <div className="mt-5">
                      <div>
                        <div className="flex items-center gap-3 justify-between">
                          <div className="flex items-center gap-5">
                            <p className="font-Nunito text-sm text-black font-semibold">
                              You
                            </p>
                            <div className="flex items-center gap-2">
                              <p className="rounded-full bg-[#D9D9D9] w-[6px] h-[6px]"></p>
                              <p className="text-[#595454] font-Nunito text-[13px]">
                                UI/UX Designer
                              </p>
                            </div>
                          </div>

                          <img
                            className="w-7 h-7"
                            src={require("../../assets/Ellipse976.png")}
                            alt=""
                          />
                        </div>
                        <div className="flex items-center gap-2 mt-3">
                          <div className="bg-[#F8F4FF] rounded-lg border border-[#E2CFFF] p-3">
                            <p className="font-Nunito text-xs text-[#3D3C3D]">
                              {selectedUser.text}
                            </p>
                          </div>
                          <img
                            className="w-5 h-5"
                            src={require("../../assets/emojii.png")}
                            alt=""
                          />
                        </div>
                        <div className="mt-5">
                          <p className="font-Nunito text-[13px] text-[#7D7B7B]">
                            {selectedUser.time}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="grid items-end">
                <div className="bg-[#EEE5FE] py-3 px-4 gap-4 flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <img
                      className="w-4 h-4 cursor-pointer"
                      src={require("../../assets/chatgrommet-icons_emoji.png")}
                      alt=""
                    />
                    <img
                      className="w-4 h-4 cursor-pointer"
                      src={require("../../assets/chatmaterial-symbols-.png")}
                      alt=""
                    />
                  </div>
                  <input
                    placeholder="Type a message"
                    className="bg-[#EEE5FE] w-full outline-none placeholder:text-sm placeholder-[#000] font-Nunito placeholder:font-Nunito text-[#000]"
                    type="text"
                  />
                  <div className="flex items-center gap-6">
                    <img
                      className="w-4 h-4 cursor-pointer"
                      src={require("../../assets/Chatstreamline_voice-mail.png")}
                      alt=""
                    />
                    <img
                      className="w-6 h-6 cursor-pointer"
                      src={require("../../assets/Chatmdi_send-circle.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div>
            <div className="grid items-start">
              <div className="flex items-center justify-between bg-white shadow-lg px-4 py-2">
                <div className="flex items-center gap-10">
                  <div className="flex items-center gap-5">
                    <div>
                      <img
                        className="w-12 h-1/2"
                        src={require("../../assets/userimgg.png")}
                        alt=""
                      />
                    </div>
                    <div className="space-y-1">
                      <p className="text-base font-Kumbh text-[#000] font-semibold">
                        Human Resources Dept
                      </p>
                      <div className="flex items-center gap-4">
                        <p className="text-xs font-Kumbh">50 members</p>
                        <div className="flex items-center gap-1">
                          <p className="w-2 h-2 bg-[#46E10F] rounded-full"></p>
                          <p className="text-[#46E10F] font-Kumbh text-xs">
                            12 online
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <img
                      className="w-36 h-10"
                      src={require("../../assets/chatAvatars.png")}
                      alt=""
                    />
                    <img
                      className="w-10 h-10 cursor-pointer"
                      src={require("../../assets/ChatAddButton.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div>
                  <img
                    className="w-5 cursor-pointer"
                    src={require("../../assets/Chaticon.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>










            <div className="flex items-center justify-center mt-10 gap-3">
              <div className="w-1/2">
                <div className="flex justify-start pl-20">
                  <p className="text-sm font-Nunito text-[#000]">Yesterday</p>
                </div>
                <div className="mt-5">
                  <div>
                    <div className="flex items-center gap-3 justify-between">
                      <div className="flex items-center gap-5">
                        <img
                          className="w-7 h-7"
                          src={require("../../assets/Ellipse976.png")}
                          alt=""
                        />
                        <div className="flex items-center gap-2">
                          <p className="font-Nunito text-sm text-black font-semibold">
                            You
                          </p>
                          <div className="flex items-center gap-2">
                            <p className="rounded-full bg-[#D9D9D9] w-[6px] h-[6px]"></p>
                            <p className="text-[#595454] font-Nunito text-[13px]">
                              UI/UX Designer
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center gap-2 mt-3">
                        <div className="bg-[#F8F4FF] rounded-lg border border-[#E2CFFF] p-3">
                          <p className="font-Nunito text-xs text-[#3D3C3D]">
                            Lorem ipsum dolor sit amet consectetur. Quam
                            pulvinar tristique in lobortis aliquet justo duis
                            cursus. Aenean ultrices sed purus duis massa enim.
                            Tortor rhoncus.
                          </p>
                        </div>
                        <img
                          className="w-5 h-5"
                          src={require("../../assets/emojii.png")}
                          alt=""
                        />
                      </div>
                      <div className="mt-5">
                        <p className="font-Nunito text-[13px] text-[#7D7B7B]">
                          11:00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="flex justify-end pr-20">
                    <p className="text-sm font-Nunito text-[#000]">Yesterday</p>
                  </div>

                  <div className="mt-5">
                    <div>
                      <div className="flex items-center gap-3 justify-between">
                        <div className="flex items-center gap-5">
                          <p className="font-Nunito text-sm text-black font-semibold">
                            You
                          </p>
                          <div className="flex items-center gap-2">
                            <p className="rounded-full bg-[#D9D9D9] w-[6px] h-[6px]"></p>
                            <p className="text-[#595454] font-Nunito text-[13px]">
                              UI/UX Designer
                            </p>
                          </div>
                        </div>

                        <img
                          className="w-7 h-7"
                          src={require("../../assets/Ellipse976.png")}
                          alt=""
                        />
                      </div>
                      <div className="flex items-center gap-2 mt-3">
                        <div className="bg-[#F8F4FF] rounded-lg border border-[#E2CFFF] p-3">
                          <p className="font-Nunito text-xs text-[#3D3C3D]">
                            Lorem ipsum dolor sit amet consectetur. Quam
                            pulvinar tristique in lobortis aliquet justo duis
                            cursus. Aenean ultrices sed purus duis massa enim.
                            Tortor rhoncus.
                          </p>
                        </div>
                        <img
                          className="w-5 h-5"
                          src={require("../../assets/emojii.png")}
                          alt=""
                        />
                      </div>
                      <div className="mt-5">
                        <p className="font-Nunito text-[13px] text-[#7D7B7B]">
                          11:00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid items-end">
              <div className="bg-[#EEE5FE] py-3 px-4 gap-4 flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <img
                    className="w-4 h-4 cursor-pointer"
                    src={require("../../assets/chatgrommet-icons_emoji.png")}
                    alt=""
                  />
                  <img
                    className="w-4 h-4 cursor-pointer"
                    src={require("../../assets/chatmaterial-symbols-.png")}
                    alt=""
                  />
                </div>
                <input
                  placeholder="Type a message"
                  className="bg-[#EEE5FE] w-full outline-none placeholder:text-sm placeholder-[#000] font-Nunito placeholder:font-Nunito text-[#000]"
                  type="text"
                />
                <div className="flex items-center gap-6">
                  <img
                    className="w-4 h-4 cursor-pointer"
                    src={require("../../assets/Chatstreamline_voice-mail.png")}
                    alt=""
                  />
                  <img
                    className="w-6 h-6 cursor-pointer"
                    src={require("../../assets/Chatmdi_send-circle.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className=" fixed  left-0 z-20">
        {contextMenu.type === "pin" && (
          <button
            className="px-4 py-2 text-sm bg-blue-500 text-white rounded"
            onClick={() => handlePin(contextMenu.user)}
          >
            Pin Account
          </button>
        )}
      </div> */}
    </div>
  );
};

export default DepartmentChats;
