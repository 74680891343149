import React, { useState, useEffect, useContext } from "react";
import empform from "../../assets/emp-form.png";
import logo from "../../assets/logo.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { GlobalState } from "../../data/Context";
import useDepartmentStore from "../../data/Stores/DepartmentStore";
import usePositionStore from "../../data/Stores/PositionStore";
import useLevelStore from "../../data/Stores/LevelStore";
import useGradeStore from "../../data/Stores/GradeStore";
import { apiCall } from "../../data/useGenFetcher";
import { toast } from "react-toastify";
import useErrorStore from "../../data/Stores/errorstore";
import { Button } from "../../components/buttons/Send";

export const MainEmployeeForm = () => {
	// const [states] = useState([
	// 	{ ID: "0", Name: "Choose State" },
	// 	{ ID: "Abuja", Name: "Abuja" },
	// 	{ ID: "Abia", Name: "Abia" },
	// 	{ ID: "Adamawa", Name: "Adamawa" },
	// 	{ ID: "Anambra", Name: "Anambra" },
	// 	{ ID: "Akwa Ibom", Name: "Akwa Ibom" },
	// 	{ ID: "Bauchi", Name: "Bauchi" },
	// 	{ ID: "Bayelsa", Name: "Bayelsa" },
	// 	{ ID: "Benue", Name: "Benue" },
	// 	{ ID: "Borno", Name: "Borno" },
	// 	{ ID: "Cross River", Name: "Cross River" },
	// 	{ ID: "Delta", Name: "Delta" },
	// 	{ ID: "Ebonyi", Name: "Ebonyi" },
	// 	{ ID: "Edo", Name: "Edo" },
	// 	{ ID: "Ekiti", Name: "Ekiti" },
	// 	{ ID: "Enugu", Name: "Enugu" },
	// 	{ ID: "Gombe", Name: "Gombe" },
	// 	{ ID: "Imo", Name: "Imo" },
	// 	{ ID: "Jigawa", Name: "Jigawa" },
	// 	{ ID: "Kaduna", Name: "Kaduna" },
	// 	{ ID: "Kano", Name: "Kano" },
	// 	{ ID: "Katsina", Name: "Katsina" },
	// 	{ ID: "kebbi", Name: "Kebbi" },
	// 	{ ID: "Kogi", Name: "Kogi" },
	// 	{ ID: "Kwara", Name: "Kwara" },
	// 	{ ID: "Lagos", Name: "Lagos" },
	// 	{ ID: "Nassarawa", Name: "Nassarawa" },
	// 	{ ID: "Niger", Name: "Niger" },
	// 	{ ID: "Ogun", Name: "Ogun" },
	// 	{ ID: "Ondo", Name: "Ondo" },
	// 	{ ID: "Osun", Name: "Osun" },
	// 	{ ID: "Oyo", Name: "Oyo" },
	// 	{ ID: "Plateau", Name: "Plateau" },
	// 	{ ID: "Rivers", Name: "Rivers" },
	// 	{ ID: "Sokoto", Name: "Sokoto" },
	// 	{ ID: "Taraba", Name: "Taraba" },
	// 	{ ID: "Yobe", Name: "Yobe" },
	// 	{ ID: "Zamfara", Name: "Zamfara" },
	// ]);

	const [lgaData] = useState({
		"Abia": [
			"Aba North",
			"Aba South",
			"Arochukwu",
			"Bende",
			"Ikwuano",
			"Isiala-Ngwa North",
			"Isiala-Ngwa South",
			"Isuikwato",
			"Obi Nwa",
			"Ohafia",
			"Osisioma",
			"Ngwa",
			"Ugwunagbo",
			"Ukwa East",
			"Ukwa West",
			"Umuahia North",
			"Umuahia South",
			"Umu-Neochi",
		],
		"Adamawa": [
			"Demsa",
			"Fufore",
			"Ganaye",
			"Gireri",
			"Gombi",
			"Guyuk",
			"Hong",
			"Jada",
			"Lamurde",
			"Madagali",
			"Maiha",
			"Mayo-Belwa",
			"Michika",
			"Mubi North",
			"Mubi South",
			"Numan",
			"Shelleng",
			"Song",
			"Toungo",
			"Yola North",
			"Yola South",
		],
		"Anambra": [
			"Aguata",
			"Anambra East",
			"Anambra West",
			"Anaocha",
			"Awka North",
			"Awka South",
			"Ayamelum",
			"Dunukofia",
			"Ekwusigo",
			"Idemili North",
			"Idemili south",
			"Ihiala",
			"Njikoka",
			"Nnewi North",
			"Nnewi South",
			"Ogbaru",
			"Onitsha North",
			"Onitsha South",
			"Orumba North",
			"Orumba South",
			"Oyi",
		],
		"Akwa Ibom": [
			"Abak",
			"Eastern Obolo",
			"Eket",
			"Esit Eket",
			"Essien Udim",
			"Etim Ekpo",
			"Etinan",
			"Ibeno",
			"Ibesikpo Asutan",
			"Ibiono Ibom",
			"Ika",
			"Ikono",
			"Ikot Abasi",
			"Ikot Ekpene",
			"Ini",
			"Itu",
			"Mbo",
			"Mkpat Enin",
			"Nsit Atai",
			"Nsit Ibom",
			"Nsit Ubium",
			"Obot Akara",
			"Okobo",
			"Onna",
			"Oron",
			"Oruk Anam",
			"Udung Uko",
			"Ukanafun",
			"Uruan",
			"Urue-Offong/Oruko ",
			"Uyo",
		],
		"Bauchi": [
			"Alkaleri",
			"Bauchi",
			"Bogoro",
			"Damban",
			"Darazo",
			"Dass",
			"Ganjuwa",
			"Giade",
			"Itas/Gadau",
			"Jama'are",
			"Katagum",
			"Kirfi",
			"Misau",
			"Ningi",
			"Shira",
			"Tafawa-Balewa",
			"Toro",
			"Warji",
			"Zaki",
		],
		"Bayelsa": [
			"Brass",
			"Ekeremor",
			"Kolokuma/Opokuma",
			"Nembe",
			"Ogbia",
			"Sagbama",
			"Southern Jaw",
			"Yenegoa",
		],
		"Benue": [
			"Ado",
			"Agatu",
			"Apa",
			"Buruku",
			"Gboko",
			"Guma",
			"Gwer East",
			"Gwer West",
			"Katsina-Ala",
			"Konshisha",
			"Kwande",
			"Logo",
			"Makurdi",
			"Obi",
			"Ogbadibo",
			"Oju",
			"Okpokwu",
			"Ohimini",
			"Oturkpo",
			"Tarka",
			"Ukum",
			"Ushongo",
			"Vandeikya",
		],
		"Borno": [
			"Abadam",
			"Askira/Uba",
			"Bama",
			"Bayo",
			"Biu",
			"Chibok",
			"Damboa",
			"Dikwa",
			"Gubio",
			"Guzamala",
			"Gwoza",
			"Hawul",
			"Jere",
			"Kaga",
			"Kala/Balge",
			"Konduga",
			"Kukawa",
			"Kwaya Kusar",
			"Mafa",
			"Magumeri",
			"Maiduguri",
			"Marte",
			"Mobbar",
			"Monguno",
			"Ngala",
			"Nganzai",
			"Shani",
		],
		"Cross River": [
			"Akpabuyo",
			"Odukpani",
			"Akamkpa",
			"Biase",
			"Abi",
			"Ikom",
			"Yarkur",
			"Odubra",
			"Boki",
			"Ogoja",
			"Yala",
			"Obanliku",
			"Obudu",
			"Calabar South",
			"Etung",
			"Bekwara",
			"Bakassi",
			"Calabar Municipality",
		],
		"Delta": [
			"Oshimili",
			"Aniocha",
			"Aniocha South",
			"Ika South",
			"Ika North-East",
			"Ndokwa West",
			"Ndokwa East",
			"Isoko south",
			"Isoko North",
			"Bomadi",
			"Burutu",
			"Ughelli South",
			"Ughelli North",
			"Ethiope West",
			"Ethiope East",
			"Sapele",
			"Okpe",
			"Warri North",
			"Warri South",
			"Uvwie",
			"Udu",
			"Warri Central",
			"Ukwani",
			"Oshimili North",
			"Patani",
		],
		"Ebonyi": [
			"Afikpo South",
			"Afikpo North",
			"Onicha",
			"Ohaozara",
			"Abakaliki",
			"Ishielu",
			"lkwo",
			"Ezza",
			"Ezza South",
			"Ohaukwu",
			"Ebonyi",
			"Ivo",
		],
		"Enugu": [
			"Enugu South,",
			"Igbo-Eze South",
			"Enugu North",
			"Nkanu",
			"Udi Agwu",
			"Oji-River",
			"Ezeagu",
			"IgboEze North",
			"Isi-Uzo",
			"Nsukka",
			"Igbo-Ekiti",
			"Uzo-Uwani",
			"Enugu Eas",
			"Aninri",
			"Nkanu East",
			"Udenu.",
		],
		"Edo": [
			"Esan North-East",
			"Esan Central",
			"Esan West",
			"Egor",
			"Ukpoba",
			"Central",
			"Etsako Central",
			"Igueben",
			"Oredo",
			"Ovia SouthWest",
			"Ovia South-East",
			"Orhionwon",
			"Uhunmwonde",
			"Etsako East",
			"Esan South-East",
		],
		"Ekiti": [
			"Ado",
			"Ekiti-East",
			"Ekiti-West",
			"Emure/Ise/Orun",
			"Ekiti South-West",
			"Ikare",
			"Irepodun",
			"Ijero",
			"Ido/Osi",
			"Oye",
			"Ikole",
			"Moba",
			"Gbonyin",
			"Efon",
			"Ise/Orun",
			"Ilejemeje",
		],
		"FCT": ["Abaji", "AMAC", "Bwari", "Gwagwalada", "Kuje", "Kwali"],
		"Gombe": [
			"Akko",
			"Balanga",
			"Billiri",
			"Dukku",
			"Kaltungo",
			"Kwami",
			"Shomgom",
			"Funakaye",
			"Gombe",
			"Nafada/Bajoga",
			"Yamaltu/Delta.",
		],
		"Imo": [
			"Aboh-Mbaise",
			"Ahiazu-Mbaise",
			"Ehime-Mbano",
			"Ezinihitte",
			"Ideato North",
			"Ideato South",
			"Ihitte/Uboma",
			"Ikeduru",
			"Isiala Mbano",
			"Isu",
			"Mbaitoli",
			"Mbaitoli",
			"Ngor-Okpala",
			"Njaba",
			"Nwangele",
			"Nkwerre",
			"Obowo",
			"Oguta",
			"Ohaji/Egbema",
			"Okigwe",
			"Orlu",
			"Orsu",
			"Oru East",
			"Oru West",
			"Owerri-Municipal",
			"Owerri North",
			"Owerri West",
		],
		"Jigawa": [
			"Auyo",
			"Babura",
			"Birni Kudu",
			"Biriniwa",
			"Buji",
			"Dutse",
			"Gagarawa",
			"Garki",
			"Gumel",
			"Guri",
			"Gwaram",
			"Gwiwa",
			"Hadejia",
			"Jahun",
			"Kafin Hausa",
			"Kaugama Kazaure",
			"Kiri Kasamma",
			"Kiyawa",
			"Maigatari",
			"Malam Madori",
			"Miga",
			"Ringim",
			"Roni",
			"Sule-Tankarkar",
			"Taura",
			"Yankwashi",
		],
		"Kaduna": [
			"Birni-Gwari",
			"Chikun",
			"Giwa",
			"Igabi",
			"Ikara",
			"jaba",
			"Jema'a",
			"Kachia",
			"Kaduna North",
			"Kaduna South",
			"Kagarko",
			"Kajuru",
			"Kaura",
			"Kauru",
			"Kubau",
			"Kudan",
			"Lere",
			"Makarfi",
			"Sabon-Gari",
			"Sanga",
			"Soba",
			"Zango-Kataf",
			"Zaria",
		],
		"Kano": [
			"Ajingi",
			"Albasu",
			"Bagwai",
			"Bebeji",
			"Bichi",
			"Bunkure",
			"Dala",
			"Dambatta",
			"Dawakin Kudu",
			"Dawakin Tofa",
			"Doguwa",
			"Fagge",
			"Gabasawa",
			"Garko",
			"Garum",
			"Mallam",
			"Gaya",
			"Gezawa",
			"Gwale",
			"Gwarzo",
			"Kabo",
			"Kano Municipal",
			"Karaye",
			"Kibiya",
			"Kiru",
			"kumbotso",
			"Kunchi",
			"Kura",
			"Madobi",
			"Makoda",
			"Minjibir",
			"Nasarawa",
			"Rano",
			"Rimin Gado",
			"Rogo",
			"Shanono",
			"Sumaila",
			"Takali",
			"Tarauni",
			"Tofa",
			"Tsanyawa",
			"Tudun Wada",
			"Ungogo",
			"Warawa",
			"Wudil",
		],
		"Katsina": [
			"Bakori",
			"Batagarawa",
			"Batsari",
			"Baure",
			"Bindawa",
			"Charanchi",
			"Dandume",
			"Danja",
			"Dan Musa",
			"Daura",
			"Dutsi",
			"Dutsin-Ma",
			"Faskari",
			"Funtua",
			"Ingawa",
			"Jibia",
			"Kafur",
			"Kaita",
			"Kankara",
			"Kankia",
			"Katsina",
			"Kurfi",
			"Kusada",
			"Mai'Adua",
			"Malumfashi",
			"Mani",
			"Mashi",
			"Matazuu",
			"Musawa",
			"Rimi",
			"Sabuwa",
			"Safana",
			"Sandamu",
			"Zango",
		],
		"Kebbi": [
			"Aleiro",
			"Arewa-Dandi",
			"Argungu",
			"Augie",
			"Bagudo",
			"Birnin Kebbi",
			"Bunza",
			"Dandi",
			"Fakai",
			"Gwandu",
			"Jega",
			"Kalgo",
			"Koko/Besse",
			"Maiyama",
			"Ngaski",
			"Sakaba",
			"Shanga",
			"Suru",
			"Wasagu/Danko",
			"Yauri",
			"Zuru",
		],
		"Kogi": [
			"Adavi",
			"Ajaokuta",
			"Ankpa",
			"Bassa",
			"Dekina",
			"Ibaji",
			"Idah",
			"Igalamela-Odolu",
			"Ijumu",
			"Kabba/Bunu",
			"Kogi",
			"Lokoja",
			"Mopa-Muro",
			"Ofu",
			"Ogori/Mangongo",
			"Okehi",
			"Okene",
			"Olamabolo",
			"Omala",
			"Yagba East",
			"Yagba West",
		],
		"Kwara": [
			"Asa",
			"Baruten",
			"Edu",
			"Ekiti",
			"Ifelodun",
			"Ilorin East",
			"Ilorin West",
			"Irepodun",
			"Isin",
			"Kaiama",
			"Moro",
			"Offa",
			"Oke-Ero",
			"Oyun",
			"Pategi",
		],
		"Lagos": [
			"Agege",
			"Ajeromi-Ifelodun",
			"Alimosho",
			"Amuwo-Odofin",
			"Apapa",
			"Badagry",
			"Epe",
			"Eti-Osa",
			"Ibeju/Lekki",
			"Ifako-Ijaye",
			"Ikeja",
			"Ikorodu",
			"Kosofe",
			"Lagos Island",
			"Lagos Mainland",
			"Mushin",
			"Ojo",
			"Oshodi-Isolo",
			"Shomolu",
			"Surulere",
		],
		"Nasarawa": [
			"Akwanga",
			"Awe",
			"Doma",
			"Karu",
			"Keana",
			"Keffi",
			"Kokona",
			"Lafia",
			"Nasarawa",
			"Nasarawa-Eggon",
			"Obi",
			"Toto",
			"Wamba",
		],
		"Niger": [
			"Agaie",
			"Agwara",
			"Bida",
			"Borgu",
			"Bosso",
			"Chanchaga",
			"Edati",
			"Gbako",
			"Gurara",
			"Katcha",
			"Kontagora",
			"Lapai",
			"Lavun",
			"Magama",
			"Mariga",
			"Mashegu",
			"Mokwa",
			"Muya",
			"Pailoro",
			"Rafi",
			"Rijau",
			"Shiroro",
			"Suleja",
			"Tafa",
			"Wushishi",
		],
		"Ogun": [
			"Abeokuta North",
			"Abeokuta South",
			"Ado-Odo/Ota",
			"Egbado North",
			"Egbado South",
			"Ewekoro",
			"Ifo",
			"Ijebu East",
			"Ijebu North",
			"Ijebu North East",
			"Ijebu Ode",
			"Ikenne",
			"Imeko-Afon",
			"Ipokia",
			"Obafemi-Owode",
			"Ogun Waterside",
			"Odeda",
			"Odogbolu",
			"Remo North",
			"Shagamu",
		],
		"Ondo": [
			"Akoko North East",
			"Akoko North West",
			"Akoko South Akure East",
			"Akoko South West",
			"Akure North",
			"Akure South",
			"Ese-Odo",
			"Idanre",
			"Ifedore",
			"Ilaje",
			"Ile-Oluji",
			"Okeigbo",
			"Irele",
			"Odigbo",
			"Okitipupa",
			"Ondo East",
			"Ondo West",
			"Ose",
			"Owo",
		],
		"Osun": [
			"Aiyedade",
			"Aiyedire",
			"Atakumosa East",
			"Atakumosa West",
			"Boluwaduro",
			"Boripe",
			"Ede North",
			"Ede South",
			"Egbedore",
			"Ejigbo",
			"Ife Central",
			"Ife East",
			"Ife North",
			"Ife South",
			"Ifedayo",
			"Ifelodun",
			"Ila",
			"Ilesha East",
			"Ilesha West",
			"Irepodun",
			"Irewole",
			"Isokan",
			"Iwo",
			"Obokun",
			"Odo-Otin",
			"Ola-Oluwa",
			"Olorunda",
			"Oriade",
			"Orolu",
			"Osogbo",
		],
		"Oyo": [
			"Afijio",
			"Akinyele",
			"Atiba",
			"Atigbo",
			"Egbeda",
			"Ibadan Central",
			"Ibadan North",
			"Ibadan North West",
			"Ibadan South East",
			"Ibadan South West",
			"Ibarapa Central",
			"Ibarapa East",
			"Ibarapa North",
			"Ido",
			"Irepo",
			"Iseyin",
			"Itesiwaju",
			"Iwajowa",
			"Kajola",
			"Lagelu Ogbomosho North",
			"Ogbmosho South",
			"Ogo Oluwa",
			"Olorunsogo",
			"Oluyole",
			"Ona-Ara",
			"Orelope",
			"Ori Ire",
			"Oyo East",
			"Oyo West",
			"Saki East",
			"Saki West",
			"Surulere",
		],
		"Plateau": [
			"Barikin Ladi",
			"Bassa",
			"Bokkos",
			"Jos East",
			"Jos North",
			"Jos South",
			"Kanam",
			"Kanke",
			"Langtang North",
			"Langtang South",
			"Mangu",
			"Mikang",
			"Pankshin",
			"Qua'an Pan",
			"Riyom",
			"Shendam",
			"Wase",
		],
		"Rivers": [
			"Abua/Odual",
			"Ahoada East",
			"Ahoada West",
			"Akuku Toru",
			"Andoni",
			"Asari-Toru",
			"Bonny",
			"Degema",
			"Emohua",
			"Eleme",
			"Etche",
			"Gokana",
			"Ikwerre",
			"Khana",
			"Obia/Akpor",
			"Ogba/Egbema/Ndoni",
			"Ogu/Bolo",
			"Okrika",
			"Omumma",
			"Opobo/Nkoro",
			"Oyigbo",
			"Port-Harcourt",
			"Tai",
		],
		"Sokoto": [
			"Binji",
			"Bodinga",
			"Dange-shnsi",
			"Gada",
			"Goronyo",
			"Gudu",
			"Gawabawa",
			"Illela",
			"Isa",
			"Kware",
			"kebbe",
			"Rabah",
			"Sabon birni",
			"Shagari",
			"Silame",
			"Sokoto North",
			"Sokoto South",
			"Tambuwal",
			"Tqngaza",
			"Tureta",
			"Wamako",
			"Wurno",
			"Yabo",
		],
		"Taraba": [
			"Ardo-kola",
			"Bali",
			"Donga",
			"Gashaka",
			"Cassol",
			"Ibi",
			"Jalingo",
			"Karin-Lamido",
			"Kurmi",
			"Lau",
			"Sardauna",
			"Takum",
			"Ussa",
			"Wukari",
			"Yorro",
			"Zing",
		],
		"Yobe": [
			"Bade",
			"Bursari",
			"Damaturu",
			"Fika",
			"Fune",
			"Geidam",
			"Gujba",
			"Gulani",
			"Jakusko",
			"Karasuwa",
			"Karawa",
			"Machina",
			"Nangere",
			"Nguru Potiskum",
			"Tarmua",
			"Yunusari",
			"Yusufari",
		],
		"Zamfara": [
			"Anka",
			"Bakura",
			"Birnin Magaji",
			"Bukkuyum",
			"Bungudu",
			"Gummi",
			"Gusau",
			"Kaura",
			"Namoda",
			"Maradun",
			"Maru",
			"Shinkafi",
			"Talata Mafara",
			"Tsafe",
			"Zurmi",
		],
	});
	let formData = {},
		department = useDepartmentStore(),
		position = usePositionStore(),
		level = useLevelStore(),
		grade = useGradeStore(),
		[data, setData] = useState(formData),
		navigate = useNavigate(),
		[getSearch] = useSearchParams(),
		textChange = e => {
			e?.stopPropagation();
			let { name, value } = e.target;
			setData(prev => {
				let prevData = { ...prev };
				prevData[name] = value;
				return prevData;
			});
		},
		{ countryState } = useContext(GlobalState),
		[lgas, setLgas] = useState([]);

	useEffect(() => {
		if (data?.stateOfOrigin) {
			setLgas(lgaData[data?.stateOfOrigin] || []);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.stateOfOrigin]);

	useEffect(() => {
		if (!getSearch?.get("company")) return;
		apiCall({
			type: "get",
			url: "/api/v1/department?_limit=0",
			getter: d => department?.getDepartment(d),
		});
		apiCall({
			type: "get",
			url: "/api/v1/position?_populate=department&_limit=0",
			getter: d => position?.getPosition(d),
		});
		apiCall({
			type: "get",
			url: `/api/v1/level/${getSearch?.get("company")}?_limit=0`,
			getter: d => level?.getLevel(d),
		});
		apiCall({
			type: "get",
			url: `/api/v1/grade?_limit=0`,
			getter: d => grade?.getGrade(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSearch?.get("company")]);

	useEffect(() => {
		if (!getSearch?.get("company")) navigate("/");
	}, [getSearch, navigate]);

	// State to track the current step
	const [step, setStep] = useState(1);

	// Function to go to the next step
	const nextStep = () => {
		if (step === 1) {
			if (
				!data?.firstName ||
				!data?.lastName ||
				!data?.email ||
				!data?.phone ||
				!data?.personalEmail
			)
				return toast.info("Please fill all fields");
		}
		if (step === 2) {
			if (
				!data?.employeeId ||
				!data?.maritalStatus ||
				!data?.lgaOfOrigin ||
				!data?.stateOfOrigin ||
				!data?.stateOfResidence ||
				(data?.maritalStatus === "married" && !data?.weddingAnniversary)
			)
				return toast.info("Please fill all fields");
		}
		if (step === 3) {
			if (
				!data?.gender ||
				// !data?.department ||
				// !data?.position ||
				// !data?.grade ||
				// !data?.level ||
				!data?.dateOfBirth
			)
				return toast.info("Please fill all fields");
		}
		setStep(step + 1);
	};

	// Function to go back to the previous step
	const prevStep = () => {
		setStep(step - 1);
	};

	const [show, setShow] = useState(false),
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		handleSubmit = async e => {
			e?.preventDefault();
			let newState = { ...data };

			if (Object.values(data)?.length === 0)
				return toast.info("Please fill all fields");
			let err = "";
			for (var prop in data) {
				if (data?.hasOwnProperty(prop)) {
					if (!data?.[prop]) err = "error";
				} else {
				}
			}
			if (err) return toast.info("Please fill all fields");

			if (data?.dateOfBirth) {
				newState = {
					...newState,
					birthMonth: moment(data?.dateOfBirth)?.format("MM"),
					birthDay: moment(data?.dateOfBirth)?.format("DD"),
				};
			}
			if (data?.weddingAnniversary && data?.maritalStatus === "married") {
				newState = {
					...newState,
					weddingAnniversaryMonth: moment(data?.weddingAnniversary)?.format(
						"MM"
					),
					weddingAnniversaryDay: moment(data?.weddingAnniversary)?.format("DD"),
				};
			}

			setLoading(true);
			let { response, errArr, errMsg } = await apiCall({
				url: `/api/v1/company/${getSearch?.get("company") || ""}/add-staff`,
				type: "post",
				data: newState,
			});
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				navigate("/");
			}
			setLoading(false);
		};

	// console.log({ data });

	// Render the appropriate step
	return (
		<div className="flex justify-center items-center min-h-screen bg-gray-100">
			<div className=" w-1/2 bg-gradient-to-b from-[#7d37ef] to-[#2d046e] p-10">
				<div>
					<p className=" Satoshi text-4xl text-[#fff]">
						All You Need In One Tool
					</p>
					<p className=" text-xl Satoshi text-[#fff] mt-3">
						Lorem ipsum dolor sit amet consectetur. Massa viverra bibendum
						cursus odio porttitor auctor cursus nibh sit.
					</p>
				</div>
				<div className=" grid justify-center items-center mt-14">
					<img className=" w-[291.48px] h-[354.48px] " src={empform} alt="" />
				</div>
			</div>

			<div className=" w-1/2 grid justify-center items-center">
				<div className=" w-[350px]">
					<div className=" grid justify-center">
						<img className=" w-24 h-24" src={logo} alt="" />
					</div>
					<div>
						{/* <p className=" text-2xl text-[#000] Satoshi">GET STARTED</p> */}
					</div>
					{step === 1 && (
						<Step1 data={data} textChange={textChange} nextStep={nextStep} />
					)}
					{step === 2 && (
						<Step2
							data={data}
							textChange={textChange}
							nextStep={nextStep}
							prevStep={prevStep}
							countryState={countryState}
							lgas={lgas}
						/>
					)}
					{step === 3 && (
						<Step3
							data={data}
							textChange={textChange}
							nextStep={nextStep}
							prevStep={prevStep}
							department={department}
							grade={grade}
							level={level}
							position={position}
						/>
					)}
					{step === 4 && (
						<Step4
							data={data}
							textChange={textChange}
							prevStep={prevStep}
							handleSubmit={handleSubmit}
							loading={loading}
							show={show}
							setShow={setShow}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const EmployeeForm = () => <MainEmployeeForm />;

export default EmployeeForm;

// Form 1 (Personal Information)
const Step1 = ({ data, textChange, nextStep }) => (
	<div>
		<h2 className="text-2xl font-bold Satoshi mb-4">Get Started</h2>
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">First Name</label>
			<input
				className="w-full outline-none px-3 py-2 border rounded-md placeholder:text-sm"
				placeholder="Chulks"
				type="text"
				value={data?.firstName}
				onChange={textChange}
				name="firstName"
			/>
		</div>
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">Last Name</label>
			<input
				className="w-full outline-none px-3 py-2 border rounded-md placeholder:text-sm"
				placeholder=" George"
				type="text"
				value={data?.lastName}
				onChange={textChange}
				name="lastName"
			/>
		</div>
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">
				Work Email address
			</label>
			<input
				className="w-full  outline-none px-3 py-2 border rounded-md placeholder:text-sm"
				placeholder=" best@codestech.com"
				type="email"
				value={data?.email}
				onChange={textChange}
				name="email"
			/>
		</div>
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">
				Personal Email address
			</label>
			<input
				className="w-full  outline-none px-3 py-2 border rounded-md placeholder:text-sm"
				placeholder=" best@codestech.com"
				type="email"
				value={data?.personalEmail}
				onChange={textChange}
				name="personalEmail"
			/>
		</div>
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">
				Phone number
			</label>
			<input
				className="w-full outline-none px-3 py-2 border rounded-md placeholder:text-sm"
				placeholder="+234304567890"
				type="tel"
				value={data?.phone}
				onChange={textChange}
				name="phone"
				maxLength={11}
			/>
		</div>

		<button
			onClick={nextStep}
			className=" bg-pink-500 text-white px-4 py-2 rounded-md">
			Next
		</button>
	</div>
);

// Form 2 (Additional Information)
const Step2 = ({
	data,
	textChange,
	nextStep,
	countryState,
	lgas,
	prevStep,
}) => (
	<div>
		<h2 className="text-2xl font-bold mb-4">More Information</h2>

		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">Employee Id</label>
			<input
				className="w-full outline-none px-3 py-2 border rounded-md placeholder:text-sm"
				type="text"
				value={data?.employeeId}
				onChange={textChange}
				name="employeeId"
			/>
		</div>
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">
				Marital Status
			</label>
			<select
				className="w-full outline-none px-3 py-2 border rounded-md"
				value={data?.maritalStatus}
				onChange={textChange}
				name="maritalStatus"
				placeholder="Single">
				<option>Select Marital Status</option>
				<option value="single">Single</option>
				<option value="married">Married</option>
			</select>
		</div>
		{data?.maritalStatus?.toLowerCase() === "married" && (
			<div className="mb-4">
				<p className="block text-base font-medium Satoshi">
					Wedding Anniversary
				</p>
				<input
					type="date"
					value={data?.weddingAnniversary}
					onChange={textChange}
					name="weddingAnniversary"
					placeholder=""
					className="w-full outline-none px-3 py-2 border rounded-md"
					max={moment().format("YYYY-MM-DD")}
				/>
			</div>
		)}
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">
				State of Origin
			</label>
			<select
				className="w-full outline-none px-3 py-2 border rounded-md Satoshi  text-[#000]"
				value={data?.stateOfOrigin}
				onChange={textChange}
				name="stateOfOrigin">
				<option value="">Select State of Origin</option>
				{countryState?.map((state, i) => (
					<option
						className="Satoshi text-sm text-[#000]"
						key={i}
						value={state.name}>
						{state.name}
					</option>
				))}
			</select>

			{lgas?.length > 0 && data?.stateOfOrigin && (
				<div className="mt-4">
					<label className="block text-base font-medium Satoshi">
						LGA of Origin
					</label>
					<select
						className="w-full outline-none px-3 py-2 border rounded-md"
						value={data?.lgaOfOrigin}
						onChange={textChange}
						name="lgaOfOrigin">
						<option className=" Satoshi text-sm text-[#000]">
							Local Government
						</option>
						{lgas.map((lga, index) => (
							<option key={index} value={lga}>
								{lga}
							</option>
						))}
					</select>
				</div>
			)}
		</div>
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">
				State of Residence
			</label>
			<select
				className="w-full outline-none px-3 py-2 border rounded-md Satoshi  text-[#000]"
				value={data?.stateOfResidence}
				onChange={textChange}
				name="stateOfResidence"
				placeholder="Lagos State">
				<option value="">Select State of Residence</option>
				{countryState?.map((state, i) => (
					<option
						className="Satoshi text-sm text-[#000]"
						key={i}
						value={state.name}>
						{state.name}
					</option>
				))}
			</select>
		</div>

		<div className="flex justify-between">
			<button
				onClick={prevStep}
				className=" bg-gray-300 text-gray-800 px-4 py-2 rounded-md">
				Back
			</button>
			<button
				onClick={nextStep}
				className=" bg-pink-500 text-white px-4 py-2 rounded-md">
				Next
			</button>
		</div>
	</div>
);

// More Information
const Step3 = ({
	data,
	textChange,
	nextStep,
	prevStep,
	grade,
	level,
	position,
	department,
}) => (
	<div>
		<h2 className="text-3xl font-medium Satoshi mb-4">More Information</h2>
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">Gender</label>
			<select
				value={data?.gender}
				onChange={textChange}
				name="gender"
				placeholder="Male"
				className="w-full outline-none px-3 py-2 border rounded-md">
				<option>Select Gender</option>
				<option value="male">Male</option>
				<option value="female">Female</option>
			</select>
		</div>
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">Department</label>
			<select
				className="w-full outline-none px-3 py-2 border rounded-md"
				value={data?.department}
				onChange={textChange}
				name="department">
				<option>Select Department</option>
				{department?.data?.docs
					?.filter(it => it?.name)
					?.map((it, i) => (
						<option value={it?._id}>{it?.name}</option>
					))}
			</select>
		</div>
		{data?.department && (
			<div className="mb-4">
				<label className="block text-base font-medium Satoshi">Position</label>
				<select
					className="w-full outline-none px-3 py-2 border rounded-md"
					value={data?.position}
					onChange={textChange}
					name="position">
					<option>Select Position</option>
					{position?.data?.docs
						?.filter(it => it?.name && it?.department?._id === data?.department)
						?.map((it, i) => (
							<option value={it?._id}>{it?.name}</option>
						))}
				</select>
			</div>
		)}
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">Level</label>
			<select
				className="w-full outline-none px-3 py-2 border rounded-md"
				value={data?.level}
				onChange={textChange}
				name="level">
				<option>Select Level</option>
				{level?.data?.docs
					?.filter(it => it?.name)
					?.map((it, i) => (
						<option value={it?._id}>{it?.name}</option>
					))}
			</select>
		</div>
		<div className="mb-4">
			<label className="block text-base font-medium Satoshi">Grade</label>
			<select
				className="w-full outline-none px-3 py-2 border rounded-md"
				value={data?.grade}
				onChange={textChange}
				name="grade">
				<option>Select Grade</option>
				{grade?.data?.docs
					?.filter(it => it?.name)
					?.map((it, i) => (
						<option value={it?._id}>{it?.name}</option>
					))}
			</select>
		</div>

		<div className="mb-4">
			<label className="block text-sm font-medium">Date of Birth</label>
			<input
				className="w-full outline-none px-3 py-2 border rounded-md"
				type="date"
				value={data?.dateOfBirth}
				onChange={textChange}
				name="dateOfBirth"
				max={moment().format("YYYY-MM-DD")}
			/>
		</div>
		<div className="flex justify-between">
			<button
				onClick={prevStep}
				className=" bg-gray-300 text-gray-800 px-4 py-2 rounded-md">
				Back
			</button>
			<button
				onClick={nextStep}
				className=" bg-pink-500 text-white px-4 py-2 rounded-md">
				Next
			</button>
		</div>
	</div>
);

// Form 4 (Final Information)
const Step4 = ({
	data,
	textChange,
	show,
	setShow,
	loading,
	handleSubmit,
	prevStep,
}) => (
	<div>
		<h2 className="text-3xl font-medium Satoshi mb-4">Complete</h2>

		{/* <div className="mb-4">
        <label className="block text-base font-medium Satoshi">
          Department
        </label>
        <select className="w-full outline-none px-3 py-2 border rounded-md">
          <option>Select Department</option>
          <option>HR</option>
          <option>HR</option>
        </select>
      </div> */}

		<div className="grid space-y-2 mb-4">
			<label className="block text-sm font-medium" htmlFor="">
				Password
			</label>
			<div className=" items-center flex  relative">
				<input
					placeholder="*****"
					className="w-full outline-none px-3 py-2 border rounded-md"
					type={show ? "text" : "password"}
					value={data?.password}
					onChange={textChange}
					name="password"
				/>
				<img
					onClick={() => setShow(!show)}
					className=" w-6 h-6 absolute right-4 cursor-pointer"
					src={require("../../assets/hide.png")}
					alt=""
				/>
			</div>
		</div>
		<div className="flex justify-between">
			<button
				onClick={prevStep}
				className="mt-4 bg-gray-300 text-gray-800 px-4 py-2 rounded-md">
				Back
			</button>
			{/* <button className="mt-4 bg-pink-500 text-white px-4 py-2 rounded-md">
					Submit
				</button> */}
			<Button
				css={" mt-4 bg-pink-500 text-white px-4 py-2 rounded-md"}
				children={"Submit"}
				onClick={handleSubmit}
				loading={loading}
			/>
		</div>
	</div>
);
