import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import SideBar from "../../components/cards/SideBar";
import BoardViewCard from "../../components/cards/tast/BoardViewCard";
import ListViewCard from "../../components/cards/tast/ListViewCard";
import TimelineViewCard from "../../components/cards/tast/TimelineViewCard";
import CalendarViewCard from "../../components/cards/tast/CalendarViewCard";
import AssignMember from "../../components/cards/tast/AssignMember";

const MyTasks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeView, setActiveView] = useState("board");
  const [isAdd, setIsAdd] = useState(false);

  const handleAdd = () => {
    setIsAdd(!isAdd);
  };
  const closeAdd = () => {
    setIsAdd(false);
  };

  const handleSwitchView = (view) => {
    setActiveView(view);
  };

  return (
    <div className=" bg-[#EFF6FC] pb-10">
      <Navbar />
      <PagesList />

      <div className=" flex justify-between items-center px-12 pt-4">
        <p className=" font-Kumbh text-[#000] text-base font-bold">
          Tasks management
        </p>
      </div>
      <div className=" bg-white mx-12 p-5 mt-5">
        <div className=" flex gap-5">
          <div>
            <SideBar />
          </div>
          <div className=" w-full">
            <div>
              <p className=" text-xl Satoshi text-[#000]">All Tasks</p>
            </div>
            <div className=" flex items-center justify-between">
              <div>
                <div className=" flex items-center gap-1">
                  <p className=" text-base Satoshi font-bold text-[#1F2633]">
                    Project Name
                  </p>
                  <img
                    className=" w-6 h-6"
                    src={require("../../assets/gridicons_dropdown.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className=" flex items-center gap-5">
                <div
                  className={`  cursor-pointer ${
                    activeView === "timeline" ? "bg-gray-200" : ""
                  }`}
                  onClick={() => handleSwitchView("timeline")}
                >
                  <p className="text-xs font-Nunito text-[#606C80]">Timeline</p>
                </div>
                <div
                  className={`cursor-pointer ${
                    activeView === "calendar" ? "bg-gray-200" : ""
                  }`}
                  onClick={() => handleSwitchView("calendar")}
                >
                  <p className="text-xs font-Nunito text-[#606C80]">Calendar</p>
                </div>
              </div>
              <div>
                <div className=" flex items-center">
                  <img
                    className=" w-36 h-10"
                    src={require("../../assets/Avatars.png")}
                    alt=""
                  />
                  {/*  */}
                  {/*  */}
                  <img
                    onClick={handleAdd}
                    className="w-10 h-10"
                    src={require("../../assets/AddButton.png")}
                    alt=""
                  />
                  {isAdd && <AssignMember onclose={closeAdd} />}
                </div>
              </div>
            </div>
            {/*  */}
            <div className=" flex items-center justify-between">
              <div>
                <div className=" flex items-center gap-5">
                  <div className="flex items-center">
                    <div
                      className={`w-32 h-10 flex gap-2 bg-[#FAFBFC] border border-[#EBEEF2] rounded-l-lg pl-3 items-center cursor-pointer ${
                        activeView === "board" ? "bg-gray-200" : ""
                      }`}
                      onClick={() => handleSwitchView("board")}
                    >
                      <img
                        className="w-6 h-6"
                        src={require("../../assets/taskIcon1.png")}
                        alt=""
                      />
                      <p className="text-xs font-Nunito text-[#606C80]">
                        Board View
                      </p>
                    </div>
                    <div
                      className={`w-32 h-10 flex gap-2 bg-[#FAFBFC] border border-[#EBEEF2] rounded-r-lg pl-3 items-center cursor-pointer ${
                        activeView === "list" ? "bg-gray-200" : ""
                      }`}
                      onClick={() => handleSwitchView("list")}
                    >
                      <img
                        className="w-6 h-6"
                        src={require("../../assets/taskIcon2.png")}
                        alt=""
                      />
                      <p className="text-xs font-Nunito text-[#606C80]">
                        List View
                      </p>
                    </div>
                  </div>
                  <div className=" w-1 h-10 bg-[#EBEEF2]"></div>

                  <div className=" flex items-center gap-2">
                    <p className=" text-[11px] Inter text-[#98A2B2]">Owners</p>
                    <img
                      className=" w-6 h-6"
                      src={require("../../assets/twitter-3.png")}
                      alt=""
                    />
                    <p className=" font-Nunito text-[11px] text-[#606C80]">
                      CeHR Team
                    </p>
                  </div>
                </div>
              </div>
              <div className=" mt-2">
                <div className=" flex items-center relative">
                  <img
                    className=" w-6 h-6 absolute left-2"
                    src={require("../../assets/taskIcon3.png")}
                    alt=""
                  />
                  <input
                    placeholder="Search Tasks"
                    className=" border border-[#EBEEF2] bg-[#fcfcfc] placeholder:text-xs pr-2 placeholder-[#98A2B2] pl-8 h-10 w-44 rounded-l-xl outline-none"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className=" flex justify-between mt-5">
              {/* todo */}

              {activeView === "board" && <BoardViewCard />}
            </div>
            <div className=" flex justify-between mt-5">
              {/* todo */}

              {activeView === "list" && <ListViewCard />}
            </div>
            <div className=" flex justify-between mt-5">
              {/* todo */}

              {activeView === "timeline" && <TimelineViewCard />}
            </div>
            <div className=" flex justify-between mt-5">
              {/* todo */}

              {activeView === "calendar" && <CalendarViewCard />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTasks;
