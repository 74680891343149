import { useEffect, useState } from "react";
import { MainLeaveFlow, tableData } from "../leave-flow";
import { useFlowTypesStore } from "../../../../data/Stores/LoggerStore";
import useCompanyStore from "../../../../data/Stores/CompanyStore";
import { apiCall } from "../../../../data/useGenFetcher";
import { useLocation } from "react-router-dom";

const ApprovalFlow = () => {
	let [datum, setDatum] = useState({ docs: tableData }),
		{ getLogger, mainSearch, isFound, data } = useFlowTypesStore(),
		{ currentSelected } = useCompanyStore(),
		[loading, setLoading] = useState(false),
		items = useLocation()?.state;

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		getLogger({ docs: [] });
		apiCall({
			type: "get",
			url: `/api/v1/approvalFlow/${currentSelected}?flow=${items?._id}&_populate=level&_populate=approvers`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/approvalFlow/${currentSelected}?flow=${
				items?._id
			}&_populate=level&_populate=approvers&_limit=${
				data?.limit * data?.nextPage
			}`,
			getter: d => getLogger(d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<MainLeaveFlow
			currentItems={currentItems}
			pageCount={pageCount}
			handlePageClick={handlePageClick}
			handleLoadMore={handleLoadMore}
			loading={loading}
			data={datum}
		/>
	);
};

export default ApprovalFlow;
