import React from "react";

const Account = () => {
  return (
    <div>
      <p>Account Setup contents</p>
    </div>
  );
};

export default Account;
