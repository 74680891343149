import React from "react";
import { Button } from "./Send";

const CancelUpdates = ({ onClose, onUpdate, loading }) => {
	return (
		<div>
			<div className="flex items-center gap-5 mt-10">
				<button
					onClick={onClose}
					className=" w-44 h-8 rounded-md font-Nunito text-sm border border-[#5608D4] text-[#5608D4]">
					Cancel
				</button>

				{/* <button
					onClick={onUpdate}
					className="w-44 h-8 rounded-md bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito">
					Update
				</button> */}
				<Button
					css={
						" w-44 h-8 rounded-md bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito"
					}
					children={"Update"}
					onClick={onUpdate}
					loading={loading}
				/>
			</div>
		</div>
	);
};

export default CancelUpdates;

// import React, { useState } from "react";
// import Delete from "../cards/Delete";
// import ActionCompleted from "../cards/ActionCompleted";

// const CancelUpdates = () => {
//   return (
//     <div>
//       <div className=" flex items-center gap-5 mt-10">
//         <button className=" w-44 h-12 rounded-md font-Nunito text-sm border border-[#D4082D] text-[#D4082D]">
//           Cancel
//         </button>

//         <button className=" w-44 h-12 rounded-md bg-[#5608D4] text-white text-sm font-Nunito">
//           Update
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CancelUpdates;
