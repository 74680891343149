import React from "react";

const UploadFile = () => {
  return (
    <div>
      <div className=" mt-5 space-y-1">
        <p className=" font-medium font-Nunito text-sm text-[#000]">
          Upload File
        </p>
        <div className=" flex mt-2 gap-2 items-center">
          <div className=" flex justify-center cursor-pointer border border-[#D0D0D0] items-center w-44 h-8 rounded-lg">
            <div className=" flex items-center gap-3">
              <img
                className=" w-5 h-5"
                src={require("../../../assets/taskSubmit.png")}
                alt=""
              />
              <p className=" text-sm font-Nunito text-[#000]">FileName.pdf</p>
            </div>
          </div>
          <img
            className=" w-7 h-8 cursor-pointer"
            src={require("../../../assets/taskGroup4.png")}
            alt=""
          />
        </div>
        <img
          className=" w-5 h-5"
          src={require("../../../assets/taskIcon4.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default UploadFile;
