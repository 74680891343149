import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { Link } from "react-router-dom";
import { useJobStore } from "../../data/Stores/LoggerStore";
import useCompanyStore from "../../data/Stores/CompanyStore";
import { apiCall } from "../../data/useGenFetcher";
import { MainPaginate } from "../../components/buttons/Send";

const Recuitment = () => {
	const [selectedReport, setSelectedReport] = useState("basic");

	const card = [
		{
			id: 1,
			num: "1",
			text: "UI/UX Designer",
			img: require("../../assets/req1.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "small",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #BB5BF6, #E214B5)",
				textColor: "#ffffff",
			},
		},
		{
			id: 2,
			num: "1",
			text: "Digital Marketer",
			img: require("../../assets/req2.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "medium",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #3FA9F6, #0F15A6)",
				textColor: "#ffffff",
			},
		},
		{
			id: 3,
			num: 1,
			text: "Product Manager",
			img: require("../../assets/req3.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "large",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #B34BF2, #6B11DE)",
				textColor: "#ffffff",
			},
		},
		{
			id: 4,
			num: "1",
			text: "Data Analyst",
			img: require("../../assets/req4.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "extra-large",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #2BFC8B, #05845E)",
				textColor: "#ffffff",
			},
		},
	];

	const Inactivecard = [
		{
			id: 1,
			Inum: "1",
			Itext: "UI/UX Designer",
			Iimg: require("../../assets/girl-dynamic-color.png"),
			Idesc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "small",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #D5DFD8, #4D534F)",
				textColor: "#ffffff",
			},
		},
		{
			id: 2,
			Inum: "1",
			Itext: "Digital Marketer",
			Iimg: require("../../assets/megaphone-iso-color.png"),
			Idesc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "medium",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #D5DFD8, #4D534F)",
				textColor: "#ffffff",
			},
		},
		{
			id: 3,
			Inum: 1,
			Itext: "Product Manager",
			Iimg: require("../../assets/render-light.png"),
			Idesc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "large",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #D5DFD8, #4D534F)",
				textColor: "#ffffff",
			},
		},
		{
			id: 4,
			Inum: "1",
			Itext: "Data Analyst",
			Iimg: require("../../assets/Flat-lay.png"),
			Idesc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "medium",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #D5DFD8, #4D534F)",
				textColor: "#ffffff",
			},
		},
		{
			id: 5,
			Inum: "1",
			Itext: "Pending",
			Iimg: require("../../assets/girl-dynamic-color.png"),
			Idesc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "small",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #D5DFD8, #4D534F)",
				textColor: "#ffffff",
			},
		},
		{
			id: 6,
			Inum: "1",
			Itext: "Health Checked",
			Iimg: require("../../assets/megaphone-iso-color.png"),
			Idesc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "medium",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #D5DFD8, #4D534F)",
				textColor: "#ffffff",
			},
		},
		{
			id: 7,
			Inum: "1",
			Itext: "Rejected",
			Iimg: require("../../assets/render-light.png"),
			Idesc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "large",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #D5DFD8, #4D534F)",
				textColor: "#ffffff",
			},
		},
		{
			id: 8,
			Inum: "1",
			Itext: "Rejected",
			Iimg: require("../../assets/circle-iso-color.png"),
			Idesc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "medium",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #D5DFD8, #4D534F)",
				textColor: "#ffffff",
			},
		},
	];

	const tableHead = [
		"Job ",
		"Positions Left",
		"Applications",
		"Rejected",
		"Accepted",

		"Actions",
	];
	const tableData = [
		{
			Job: "Seniior UI/UX Designer",
			PositionsLeft: "12",
			Applications: "20",
			Rejected: "03",
			Accepted: "05",

			Actions: "...",
		},
		{
			Job: "Seniior UI/UX Designer",
			PositionsLeft: "12",
			Applications: "20",
			Rejected: "03",
			Accepted: "05",

			Actions: "...",
		},
		{
			Job: "Seniior UI/UX Designer",
			PositionsLeft: "12",
			Applications: "20",
			Rejected: "03",
			Accepted: "05",

			Actions: "...",
		},
		{
			Job: "Seniior UI/UX Designer",
			PositionsLeft: "12",
			Applications: "20",
			Rejected: "03",
			Accepted: "05",

			Actions: "...",
		},
		{
			Job: "Seniior UI/UX Designer",
			PositionsLeft: "12",
			Applications: "20",
			Rejected: "03",
			Accepted: "05",

			Actions: "...",
		},
		{
			Job: "Seniior UI/UX Designer",
			PositionsLeft: "12",
			Applications: "20",
			Rejected: "03",
			Accepted: "05",

			Actions: "...",
		},
		{
			Job: "Seniior UI/UX Designer",
			PositionsLeft: "12",
			Applications: "20",
			Rejected: "03",
			Accepted: "05",

			Actions: "...",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData }),
		{ getLogger, mainSearch, isFound, data } = useJobStore(),
		{ currentSelected } = useCompanyStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/job/${currentSelected}?_populate=form`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">All Jobs</p>
			</div>

			<div className=" mx-12 bg-white p-5 mt-5">
				<div className=" flex items-center gap-10">
					<button
						className={` text-gray-600 font-Nunito text-base cursor-pointer${
							selectedReport === "basic"
								? " border-b-[5px] border-b-[#F7AC25]"
								: "bg-white text-[#1C1C1C]  "
						}`}
						onClick={() => setSelectedReport("basic")}>
						Active Jobs
					</button>
					<button
						className={` text-gray-600 font-Nunito text-base cursor-pointer ${
							selectedReport === "org"
								? "border-b-[5px] border-b-[#F7AC25]"
								: "bg-white text-#071C50] "
						}`}
						onClick={() => setSelectedReport("org")}>
						Inactive Jobs
					</button>
				</div>

				<div className=" mt-5">
					{selectedReport === "basic" && (
						<div>
							{" "}
							<div className=" grid grid-cols-4 items-center justify-between px-6 pt-8">
								{/* one */}

								{currentItems?.map((i, ind) => (
									<div className=" w-60 h-auto " key={ind}>
										<div
											style={{
												borderWidth:
													card?.[ind % card?.length]?.numStyle.borderWidth,
												borderColor:
													card?.[ind % card?.length]?.numStyle.borderColor,
												width: card?.[ind % card?.length]?.numStyle.width,
												height: card?.[ind % card?.length]?.numStyle.height,
												background:
													card?.[ind % card?.length]?.numStyle.background,
												//   backgroundColor: card?.[ind % card?.length]?.numStyle.backgroundColor,
											}}
											className=" absolute  rounded-md grid justify-center items-center bg-white">
											<p className=" Satoshi text-[#fff] text-xs font-bold">
												{ind + 1}
											</p>
										</div>
										<Link
											to="applications"
											state={i}
											className=" bg-white mt-5 hover:bg-slate-200  rounded-md shadow-lg grid items-end w-full h-auto ml-5 pb-8 px-4">
											<div className=" flex items-center justify-between mt-5 ">
												<p className="  text-base text-[#808080] font-bold  font-Kumbh">
													{i?.name}
												</p>
												<img
													className={`w-16 h-16 ${
														card?.[ind % card?.length]?.size === "small"
															? "sm:w-9 sm:h-9"
															: card?.[ind % card?.length]?.size === "medium"
															? "md:w-10 md:h-10"
															: card?.[ind % card?.length]?.size === "large"
															? "lg:w-10 lg:h-9"
															: card?.[ind % card?.length]?.size ===
															  "extra-large"
															? "xl:w-16 xl:h-12"
															: card?.[ind % card?.length]?.size ===
															  "mega-large"
															? "w-10 h-10"
															: card?.[ind % card?.length]?.size ===
															  "ultra-large"
															? "w-10 h-10"
															: card?.[ind % card?.length]?.size ===
															  "jumbo-large"
															? "w-10 h-10"
															: "" // Default size
													}`}
													src={card?.[ind % card?.length]?.img}
													alt=""
												/>
											</div>
											<div>
												<p className=" text-[#A0A0A0] font-Kumbh text-xs">
													{i?.desc}
												</p>
											</div>
										</Link>
									</div>
								))}
							</div>
							<div>
								<div className=" flex items-center justify-between py-5 px-10">
									<p className=" font-Kumbh text-base font-bold text-[#071C50]">
										Jobs
									</p>
									<Link
										to="new-job"
										className=" grid justify-center items-center Satoshi text-sm text-white bg-black rounded-md w-28 h-10 animate-pulse">
										Create new
									</Link>
								</div>
								<table className="min-w-full divide-y divide-gray-200">
									<thead className="bg-gray-50">
										<tr>
											{tableHead.map((head, index) => (
												<th
													key={index}
													className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
													{head}
												</th>
											))}
										</tr>
									</thead>
									<tbody className="bg-white divide-y divide-gray-200">
										{currentItems?.map((rowData, rowIndex) => (
											<tr
												key={rowIndex}
												className="border-t-[0.5px] border-t-gray-200">
												<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
													{rowData?.name}
												</td>
												<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
													{rowData?.PositionsLeft}
												</td>
												<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
													{rowData?.Applications}
												</td>
												<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
													{rowData?.Rejected}
												</td>
												<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
													{rowData?.Accepted}
												</td>

												<td className="px-4 py-4 text-xs border-b-[0.5] text-[#0811D4] boray-200 ">
													<Link to="applications" state={rowData}>
														...
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
								{pageCount > 1 && (
									<MainPaginate
										pageCount={pageCount}
										handlePageClick={handlePageClick}
									/>
								)}
							</div>
						</div>
					)}

					{selectedReport === "org" && (
						<div>
							<div className=" grid grid-cols-4 items-center gap-y-5 justify-between px-6 pt-8">
								{/* one */}

								{currentItems?.map((i, ind) => (
									<div className=" w-60 h-auto " key={ind}>
										<div
											style={{
												borderWidth:
													Inactivecard?.[ind % card?.length]?.numStyle
														.borderWidth,
												borderColor:
													Inactivecard?.[ind % card?.length]?.numStyle
														.borderColor,
												width:
													Inactivecard?.[ind % card?.length]?.numStyle.width,
												height:
													Inactivecard?.[ind % card?.length]?.numStyle.height,
												background:
													Inactivecard?.[ind % card?.length]?.numStyle
														.background,
												//   backgroundColor: i.numStyle.backgroundColor,
											}}
											className=" absolute  rounded-md grid justify-center items-center bg-white">
											<p className=" Satoshi text-[#fff] text-xs font-bold">
												{ind + 1}
											</p>
										</div>
										<Link className=" bg-white mt-5  rounded-md shadow-lg grid items-end w-full h-auto ml-5 pb-8 px-4">
											<div className=" flex items-center justify-between mt-5 ">
												<p className="  text-base text-[#808080] font-bold  font-Kumbh">
													{i?.name}
												</p>
												<img
													className={`w-16 h-16 ${
														Inactivecard?.[ind % card?.length]?.size === "small"
															? "sm:w-9 sm:h-9"
															: Inactivecard?.[ind % card?.length]?.size ===
															  "medium"
															? "md:w-10 md:h-10"
															: Inactivecard?.[ind % card?.length]?.size ===
															  "large"
															? "lg:w-10 lg:h-10"
															: Inactivecard?.[ind % card?.length]?.size ===
															  "extra-large"
															? "xl:w-16 xl:h-12"
															: Inactivecard?.[ind % card?.length]?.size ===
															  "mega-large"
															? "w-10 h-10"
															: Inactivecard?.[ind % card?.length]?.size ===
															  "ultra-large"
															? "w-10 h-10"
															: Inactivecard?.[ind % card?.length]?.size ===
															  "jumbo-large"
															? "w-10 h-10"
															: "" // Default size
													}`}
													src={Inactivecard?.[ind % card?.length]?.Iimg}
													alt=""
												/>
											</div>
											<div>
												<p className=" text-[#A0A0A0] font-Kumbh text-xs">
													{i?.Idesc}
												</p>
											</div>
										</Link>
									</div>
								))}
							</div>
							{pageCount > 1 && (
								<MainPaginate
									pageCount={pageCount}
									handlePageClick={handlePageClick}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Recuitment;
