import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import { Link } from "react-router-dom";

import CreateFlow from "../../../components/cards/CreateFlow";
import { useFlowStore } from "../../../data/Stores/LoggerStore";
import useCompanyStore from "../../../data/Stores/CompanyStore";
import { apiCall } from "../../../data/useGenFetcher";
import moment from "moment";
import useErrorStore from "../../../data/Stores/errorstore";
import { toast } from "react-toastify";
import Delete from "../../../components/cards/Delete";

const ApprovalFlows = () => {
	const [showId, setShowId] = useState(false);

	const handleId = () => {
		setShowId(!showId);
	};
	const closeId = () => {
		setShowId(false);
	};
	const card = [
		{
			id: 1,
			img: require("../../../assets/img31.png"),
			text: "Leave Types",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "leave-flow",
			with: 70,
			height: 53,
		},
	];

	const card2 = [
		{
			id: 1,
			img: require("../../../assets/img31.png"),
			text: "Leave Types",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			to: "leave-flow",
			with: 70,
			height: 53,
			delete: require("../../../assets/Bin.png"),
		},
		{
			id: 2,
			img: require("../../../assets/img32.png"),
			text: "Cash Flow",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			with: 28,
			height: 42,
			delete: require("../../../assets/Bin.png"),
		},
		{
			id: 3,
			img: require("../../../assets/img33.png"),
			text: "Leave Flow",
			desc: "Lorem ipsum dolor sit amet consectetur. At a phasellus.",
			with: 70,
			height: 53,
			delete: require("../../../assets/Bin.png"),
		},
	];

	let [datum, setDatum] = useState({ docs: card }),
		{ getLogger, mainSearch, isFound, data, deleteLogger } = useFlowStore(),
		{ currentSelected } = useCompanyStore(),
		[items, setItems] = useState(null),
		[loading, setLoading] = useState(false),
		[isDelete, setIsDelete] = useState(null),
		{ returnErrors } = useErrorStore();

	let handleSubmit = async e => {
		// e?.preventDefault();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/flow/${currentSelected}/${items?._id}`,
			type: "delete",
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response || response === "") {
			deleteLogger(items);
			setItems(null);
			setIsDelete(null);
			return;
		}
		setLoading(false);
	};

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/flow/${currentSelected}?_limit=0`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs;

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">
					Approval Flows
				</p>
			</div>

			<div className=" bg-white mx-12 mt-5 p-5">
				<div className=" flex justify-between items-center">
					<p>All Flows</p>
					<div className=" cursor-pointer">
						<button
							onClick={handleId}
							// onClick={handleAction}
							className=" text-sm Satoshi text-white bg-black rounded-md w-28 h-9 animate-pulse ">
							Create new
						</button>
						{showId && <CreateFlow onClose={closeId} />}

						{/* {addAction && <ActionCompleted onClose={closeAction} />}
                </div>
                {addFile && <AddAsset closeFile={closeFile} />} */}
					</div>
				</div>

				<div>
					<div className=" flex items-center gap-10  mt-5">
						{card.map(i => (
							<Link
								to={i.to}
								className=" bg-white p-5 justify-center hover:bg-slate-200 items-center grid gap-4 py-5 rounded-lg lg:w-64 md:w-56 w-full shadow-lg">
								<div className=" grid justify-center items-center">
									<img
										width={i.with}
										height={i.height}
										className=""
										src={i.img}
										alt=""
									/>
								</div>
								<p className=" text-center font-Kumbh text-sm text-[#000] font-bold">
									{i.text}
								</p>
								<p className=" text-center font-Kumbh text-sm text-[#000] ">
									{i.desc}
								</p>
							</Link>
						))}
						{currentItems?.map((i, ind) => (
							<div
								className=" bg-white p-5 justify-center hover:bg-slate-200 items-center grid gap-4 py-5 rounded-lg lg:w-64 md:w-56 w-full shadow-lg"
								key={ind}>
								<Link to={"approval-flow-details"} state={i} className="">
									<div className=" grid justify-center items-center">
										<img
											width={card2?.[ind % card2?.length]?.with}
											height={card2?.[ind % card2?.length]?.height}
											className=""
											src={card2?.[ind % card2?.length]?.img}
											alt=""
										/>
									</div>
									<p className=" text-center font-Kumbh text-sm text-[#000] font-bold">
										{i?.name}
									</p>
									<p className=" text-center font-Kumbh text-sm text-[#000] ">
										{i?.description || card2?.[ind % card2?.length]?.desc}
									</p>
								</Link>
								<div className=" flex justify-between items-center">
									<p className=" font-Nunito text-[10px] text-[#667085] font-light">
										{moment(i?.createdAt).format("DD/MM/YYYY")}
									</p>
									<div className=" flex items-center gap-4">
										<img
											onClick={() => {
												setItems(i);
												setIsDelete(true);
											}}
											className=" w-3 h-3 cursor-pointer"
											src={card2?.[ind % card2?.length]?.delete}
											alt=""
										/>
									</div>
								</div>
							</div>
						))}
					</div>
					{isDelete && (
						<Delete
							onClose={() => {
								setIsDelete(null);
								setItems(null);
							}}
							deleteFunc={() => handleSubmit()}
							title={items?.name}
							loading={loading}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default ApprovalFlows;
