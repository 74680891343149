import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import InvoiceTable from "../../components/tables/InvoiceTable";
import RecentDocumentsTable from "../../components/tables/RecentDocumentsTable";
import LeaveRequestsTable from "../../components/tables/LeaveRequestsTable";
import useDepartmentStore from "../../data/Stores/DepartmentStore";
import useLevelStore from "../../data/Stores/LevelStore";
import usePositionStore from "../../data/Stores/PositionStore";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useMediaStore from "../../data/Stores/MediaStore";
import useAnnoucementStore from "../../data/Stores/AnnoucementStore";
import useAuthStore from "../../data/Stores/Authstore";

const Dashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const department = useDepartmentStore(),
    level = useLevelStore(),
    position = usePositionStore(),
    navigate = useNavigate(),
    media = useMediaStore(),
    announcement = useAnnoucementStore(),
    { user } = useAuthStore();

  const card = [
    {
      id: 1,
      num: department?.data?.totalDocs || 0,
      text: "Department",
      img: require("../../assets/department.png"),
      size: "small",
      numStyle: {
        borderColor: "#770873",
        borderWidth: "0.5px",
        width: "40px",
        height: "40px",
        backgroundColor: "white",
        textColor: "#071C50",
      },
      onCLick: () => navigate("/company-settings/departments"),
    },
    {
      id: 2,
      num: level?.data?.totalDocs || 0,
      text: "Level",
      img: require("../../assets/Growth.png"),
      size: "medium",
      numStyle: {
        borderColor: "#08771A",
        borderWidth: "0.5px",
        width: "40px",
        height: "40px",
        backgroundColor: "white",
        textColor: "#071C50",
      },
      onCLick: () => navigate("/company-settings/levels"),
    },
    {
      id: 3,
      num: position?.data?.totalDocs || 0,
      text: "Positions",
      img: require("../../assets/Blueusericon.png"),
      size: "large",
      numStyle: {
        borderColor: "#F53232",
        borderWidth: "0.5px",
        width: "40px",
        height: "40px",
        backgroundColor: "white",
        textColor: "#071C50",
      },
      onCLick: () => navigate("/company-settings/positions"),
    },
    {
      id: 4,
      num: announcement?.data?.totalDocs || 0,
      text: "Announcements",
      img: require("../../assets/render.png"),
      size: "extra-large",
      numStyle: {
        borderColor: "#0B0877",
        borderWidth: "0.5px",
        width: "40px",
        height: "40px",
        backgroundColor: "white",
        textColor: "#071C50",
      },
      onCLick: () => navigate("/announcements"),
    },
    {
      id: 5,
      num: 3,
      text: "Branches",
      img: require("../../assets/renders.png"),
      size: "mega-large",
      numStyle: {
        borderColor: "#FAC403",
        borderWidth: "0.5px",
        width: "40px",
        height: "40px",
        backgroundColor: "white",
        textColor: "#071C50",
      },
    },
  ];

  useEffect(() => {
    if (!user?.isAdmin) navigate("/employee-dashboard");
  }, [user, navigate]);

  return (
    <div className="min-h-screen bg-[#F4F6F8]">
      <Navbar />
      <PagesList />
      <div className="mx-12 my-2">
        <div className="pt-5 flex items-center gap-2">
          <img
            className=" w-6 h-6"
            src={require("../../assets/emoji-one.png")}
            alt=""
          />
          <p className="text-[#000] font-Kumbh font-bold">
            Welcome to your Dashboard!
          </p>
          <img
            className=" w-10 h-8"
            src={require("../../assets/emoji-two.png")}
            alt=""
          />
        </div>
      </div>

      <div className=" mx-12 p-5 -mt-3">
        <div className="grid grid-cols-5 gap-5 items-center justify-between relative right-3">
          {card.map((i, ind) => (
            <div
              key={ind}
              className={`lg:w-52 xl:w-[210px] md:w-52 h-auto ${
                i?.onCLick ? "cursor-pointer" : ""
              }`}
              onClick={i?.onCLick}
            >
              <div
                style={{
                  borderWidth: i.numStyle.borderWidth,
                  borderColor: i.numStyle.borderColor,
                  width: i.numStyle.width,
                  height: i.numStyle.height,
                  backgroundColor: i.numStyle.backgroundColor,
                }}
                className=" absolute  rounded-2xl grid justify-center items-center bg-white"
              >
                <p className=" Satoshi text-[#071C50] text-xl font-bold">
                  {i.num}
                </p>
              </div>
              <div className=" bg-white mt-5 rounded-md grid items-center w-[234px] h-[124px] ml-5 px-4 ">
                <div className=" flex mt-7 items-center justify-between relative bottom-3">
                  <p className=" font-thin text-sm text-[#000] Satoshi">
                    {i.text}
                  </p>
                  <img
                    className={`w-16 h-16 ${
                      i.size === "small"
                        ? "sm:w-12 sm:h-12"
                        : i.size === "medium"
                        ? "md:w-16 md:h-12"
                        : i.size === "large"
                        ? "lg:w-12 lg:h-12"
                        : i.size === "extra-large"
                        ? "xl:w-[70px] xl:h-[50px]"
                        : i.size === "mega-large"
                        ? " w-[50px] h-[50px]"
                        : "" // Default size
                    }`}
                    src={i.img}
                    alt=""
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className=" flex justify-center items-center pt-10">
          <div className=" w-[45%] h-80">
            <ImageChange item={media?.data?.docs} />
          </div>
          <div className=" w-[55%] rounded-md p-4">
            <InvoiceTable />
          </div>
        </div>

        <div className=" flex justify-center gap-5   mt-5  ">
          <div className=" w-[55%] h-auto bg-white shadow-lg rounded-md p-4">
            <RecentDocumentsTable />
          </div>
          <div className=" w-[45%] h-auto bg-white shadow-lg rounded-md p-4">
            <LeaveRequestsTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

export let ImageChange = ({ item }) => {
  let [count, setCount] = useState(0);
  useEffect(() => {
    if (item && item?.length > 0) {
      const next = (count + 1) % item?.length;
      const id = setTimeout(() => setCount(next), 10000);
      return () => clearTimeout(id);
    }
  }, [item, count]);
  return (
    <img
      src={
        item?.length > 0
          ? item?.[count]?.url
          : require("../../assets/hero3.png")
      }
      alt="Home Banner"
      className="h-full w-full max-h-96 ease-in-out"
    />
  );
};
