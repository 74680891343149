// import React from 'react'

// const ProfileDone = () => {
//   return (
//     <div>

//     </div>
//   )
// }

// export default ProfileDone
import React from "react";

const ProfileDone = ({ onClose }) => {
  return (
    <div
      onClick={onClose}
      className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center"
    >
      <div className=" bg-white w-[380px] h-auto rounded-md pt-5 pb-3">
        <div className=" grid justify-center items-center py-5">
          <img
            className=" w-20 h-20"
            src={require("../../assets/like-hand-gesture.png")}
            alt=""
          />
        </div>
        <p className=" font-Nunito text-[#000] text-base font-bold text-center">
          Success
        </p>
        <p className=" text-[#000] font-Nunito text-sm text-center pt-3 pb-10">
          Great Job, your profile has been updated successfully. Click Done to
          continue
        </p>
        <div className=" flex justify-center items-center">
          <button className=" bg-gradient-to-b from-[#5608D4] to-[#384295] w-56 h-14 text-sm font-Nunito text-white">
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileDone;
