import React, { useEffect, useState } from "react";
import Navbar from "../../../../../components/navbar/Navbar";
import PagesList from "../../../../../components/pages-list/PagesList";
import DeleteEditDetails from "../../../../../components/buttons/DeleteEditDetails";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	useFlowTypesStore,
	useLeaveFlowStore,
} from "../../../../../data/Stores/LoggerStore";
import useCompanyStore from "../../../../../data/Stores/CompanyStore";
import useErrorStore from "../../../../../data/Stores/errorstore";
import { apiCall } from "../../../../../data/useGenFetcher";
import { toast } from "react-toastify";
import moment from "moment";
import ApprovalFlow from "../../../../../components/cards/ApprovalFlow";

export const MainFlowDetails = () => {
	let items = useLocation()?.state;
	let [state, setState] = useState(items),
		flowType = useFlowTypesStore(),
		leaveFlow = useLeaveFlowStore(),
		navigate = useNavigate(),
		{ step } = useParams();

	useEffect(() => {
		flowType?.data?.docs?.map(it => it?._id === items?._id && setState(it));
		leaveFlow?.data?.docs?.map(it => it?._id === items?._id && setState(it));
	}, [flowType?.data, items, leaveFlow?.data]);

	let [loading, setLoading] = useState(false),
		{ currentSelected } = useCompanyStore(),
		{ returnErrors } = useErrorStore();

	let handleSubmit = async e => {
		// e?.preventDefault();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/${step === "leave-flow" ? "leaveFlow" : "approvalFlow"}/${
				step !== "leave-flow" ? `/${currentSelected}` : ""
			}/${items?._id}`,
			type: "delete",
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response || response === "") {
			if (step === "leave-flow") leaveFlow?.deleteLogger(items);
			else flowType?.deleteLogger(items);
			if (e) e();
			navigate(-1);
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">
					Flow Details
				</p>
			</div>

			<div className=" bg-white mx-12 p-5 mt-5">
				<div className=" space-y-5 pr-80">
					<p className=" text-xl text-[#5608D4] font-Nunito font-bold">
						LEAVE FLOW
					</p>
					<div className=" Satoshi text-sm text-[#000]">
						{state?.description}
					</div>
					<div className=" space-y-5">
						<div className=" flex items-center justify-between w-[500px]">
							<div className=" w-[200px] flex justify-start">
								<p className=" text-[#071C50] text-sm font-bold Satoshi">
									Date created -{" "}
								</p>
							</div>
							<div className=" w-[200px] flex justify-start">
								<p className="text-[#071C50] text-sm font-light Satoshi">
									{" "}
									{moment(state?.createdAt).format("Do MMMM, YYYY")}
								</p>
							</div>
						</div>
						<div className=" flex items-center justify-between w-[500px]">
							<div className=" w-[200px] flex justify-start">
								<p className=" text-[#071C50] text-sm font-bold Satoshi">
									Level -{" "}
								</p>
							</div>
							<div className=" w-[200px] flex justify-start">
								<p className="text-[#071C50] text-sm font-light Satoshi">
									{" "}
									{state?.level?.name}
								</p>
							</div>
						</div>
					</div>

					<div className=" space-y-5">
						{" "}
						<p className=" text-xl text-[#5608D4] font-Nunito font-bold">
							APPROVERS
						</p>
						<div className=" Satoshi text-sm text-[#000]">
							{state?.approvers?.map((it, x) => (
								<span key={x}>
									{it?.name}
									{x === state?.approvers?.length - 1 ? "" : " => "}
								</span>
							))}
						</div>
					</div>

					<div className=" pt-10">
						<img src={require("../../../../../assets/Group37.png")} alt="" />
					</div>
				</div>

				<div className="">
					<DeleteEditDetails
						editDetailsCard={<ApprovalFlow items={state} />}
						deleteFunc={handleSubmit}
						loading={loading}
						title={items?.name}
					/>
				</div>
			</div>
		</div>
	);
};

const FlowDetails = () => {
	return <MainFlowDetails />;
};

export default FlowDetails;
