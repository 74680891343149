import React from "react";
import { ActionFolder } from "../resources/Actions";
import { HiOutlineDotsVertical } from "react-icons/hi";

export default function FolderCard({
  folderImg,
  labelBgColor,
  label,
  date,
  labelColor,
  fileCount,
  isOpen,
  toggleModal,
}) {
  return (
    <div className="relative w-full md:w-5/6 lg:w-[204px] h-[169px] bg-white shadow-md px-3 py-2 rounded">
      <div className="flex justify-between items-center">
        <p className="text-[12px] text-[#101828] font-Nunito font-[400]">
          Folder name
        </p>
        <HiOutlineDotsVertical
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        />
      </div>
      <div className="flex items-center mt-2 relative">
        <img src={folderImg} alt="Folder" />
        <p className="font-[600] text-[20px] font-Inter absolute bottom-10 left-5 text-white">
          {fileCount}
        </p>
      </div>
      <div className="flex items-center justify-between -mt-1">
        <div
          className={`text-[10px] font-semibold mt-2 font-Inter px-3 py-[4px] rounded-full w-max-[47px]`}
          style={{ color: labelColor, backgroundColor: labelBgColor }}
        >
          {label}
        </div>
        <div className="text-[10px] text-[#667085] mt-1 font-Nunito">
          {date}
        </div>
      </div>

      {/* Modal */}
      {isOpen && (
        <div className="absolute top-0 right-0">
          <ActionFolder onClose={toggleModal} />
        </div>
      )}
    </div>
  );
}
