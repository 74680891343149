import React, { useState } from "react";
import JobNavbar from "../../components/job/JobNavbar";
import JobSecNav from "../../components/job/JobSecNav";
import down from "../../assets/down.png";
import AllJobsCard from "../../components/job/AllJobsCard";
import SavedCard from "../../components/job/SavedCard";
import AppliedCard from "../../components/job/AppliedCard";
import ClosedCard from "../../components/job/ClosedCard";

// Example card component
const Card = ({ content }) => (
  <div className="bg-[#fff] ">
    <p>{content}</p>
  </div>
);

const AllJobs = () => {
  const [activeTab, setActiveTab] = useState("All Jobs");

  const tabs = ["All Jobs", "Active", "Saved", "Applied", "Closed"];
  const cardContent = {
    "All Jobs": <AllJobsCard />,
    Active: "",
    Saved: <SavedCard />,
    Applied: <AppliedCard />,
    Closed: <ClosedCard />,
  };

  return (
    <div className="bg-[#F2EFFC]">
      <JobNavbar />
      <JobSecNav />
      <div className="mx-12 bg-white p-5 mt-2">
        <div className="flex items-center gap-10">
          {tabs.map((tab) => (
            <div
              key={tab}
              className="grid justify-center items-center cursor-pointer"
              onClick={() => setActiveTab(tab)}
            >
              <div className="grid justify-center items-center">
                {activeTab === tab && (
                  <div className="bg-[#5608d4] h-1 w-1 rounded-full"></div>
                )}
              </div>

              <p
                className={`${
                  activeTab === tab ? "text-[#5608d4]" : "text-[#636E72]"
                }`}
              >
                {tab}
              </p>
            </div>
          ))}

          <div className="flex items-center gap-2">
            <label htmlFor="">Filter jobs</label>
            <div className="flex items-center relative">
              <input
                className="bg-[#F5F7F8] px-2 placeholder:text-sm h-9 outline-none text-sm"
                type="text"
                placeholder="experience"
              />
              <img className="absolute right-2 w-4 h-4" src={down} alt="" />
            </div>
          </div>
        </div>

        {/* Display the card below the active tab */}
        {cardContent[activeTab] && <Card content={cardContent[activeTab]} />}
      </div>
    </div>
  );
};

export default AllJobs;
