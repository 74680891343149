import React from "react";
import Tools from "../buttons/Tools";
import ReactQuill from "react-quill";

const BasicInformation = ({
	textChange,
	state,
	setState,
	handleTextChange,
}) => {
	const jobtype = [
		{
			id: 1,
			text: "Part-Time",
		},
		{
			id: 2,
			text: "Full-Time",
		},
		{
			id: 3,
			text: "Internship",
		},
		{
			id: 4,
			text: "Contract",
		},
		{
			id: 5,
			text: "Temporary",
		},
	];

	const RecruitmentLimit = [
		{
			id: 1,
			limit: "0 - 10 Candidates",
		},
		{
			id: 2,
			limit: "10 - 50 Candidates",
		},
		{
			id: 3,
			limit: "> 50 Candidates",
		},
	];

	return (
		<div>
			<div className=" flex items-center gap-1">
				<img
					className=" w-3 h-3"
					src={require("../../assets/mdi_required.png")}
					alt=""
				/>
				<p className=" text-xs text-[#3C4657] font-Lato">
					Indicates a required field
				</p>
			</div>
			{/*  */}
			<div
				className=" flex mt-5 gap-5
      ">
				<div className=" grid  w-1/3">
					<div className=" flex">
						<p className=" font-bold font-Lato text-[#1B1B1B] text-sm">
							Job Name{" "}
						</p>
						<img
							className=" w-3 h-3"
							src={require("../../assets/mdi_required.png")}
							alt=""
						/>
					</div>
					<p className=" font-Lato font-light text-xs text-[#7E8597]">
						Enter your name
					</p>
				</div>
				<div className=" w-3/4">
					<input
						type="text"
						className=" w-full h-11 border border-[#D8DFEC] rounded-md outline-none px-2"
						value={state?.name}
						onChange={textChange}
						name={"name"}
					/>
				</div>
			</div>
			{/*  */}

			<div
				className=" flex mt-5 gap-5
      ">
				<div className=" grid  w-1/3">
					<div className=" flex">
						<p className=" font-bold font-Lato text-[#1B1B1B] text-sm">
							Job Description{" "}
						</p>
						<img
							className=" w-3 h-3"
							src={require("../../assets/mdi_required.png")}
							alt=""
						/>
					</div>
				</div>
				<div className=" w-3/4">
					<div className="">
						{/* <input
							type="text"
							className=" w-full placeholder:text-xs placeholder-[#7E8597] h-11 border border-[#D8DFEC] rounded-t-md outline-none px-2"
							value={state?.description}
							onChange={textChange}
							name={"description"}
							placeholder="Enter Job description"
						/> */}
						<ReactQuill
							placeholder="Enter Job description"
							theme="snow"
							value={state?.description}
							onChange={e => handleTextChange(e, "description")}
							className=" smallText max-w-[750px] w-full placeholder:text-xs placeholder-[#7E8597] outline-none px-2"
						/>
						{/* <div className=" w-full border border-[#D8DFEC] rounded-b-md flex items-center gap-3 px-5  h-8">
							<Tools />
						</div> */}
					</div>
				</div>
			</div>
			{/*  */}

			<div
				className=" hidden mt-5 gap-5
      ">
				<div className=" grid  w-1/3">
					<div className=" flex">
						<p className=" font-bold font-Lato text-[#1B1B1B] text-sm">
							Job Requirement{" "}
						</p>
						<img
							className=" w-3 h-3"
							src={require("../../assets/mdi_required.png")}
							alt=""
						/>
					</div>
				</div>
				<div className=" w-3/4">
					<div className="">
						<input
							placeholder="Enter Job requirement"
							type="text"
							className=" w-full placeholder:text-xs placeholder-[#7E8597]  h-11 outline-none px-2 border  border-l border-r border-t border-b border-l-[#D8DFEC] border-r-[#D8DFEC] border-b-[#fff] border-t-[#D8DFEC] rounded-t-md"
						/>
						<div className=" w-full border-l border-r border-t border-b border-l-[#D8DFEC] border-r-[#D8DFEC] border-b-[#D8DFEC] border-t-[#fff] rounded-b-md flex items-center gap-3 px-5  h-8">
							<Tools />
							{/* <img
                className=" w-4 h-4"
                src={require("../../assets/boldIcon.png")}
                alt=""
              />
              <img
                className=" w-4 h-4"
                src={require("../../assets/italicIcon.png")}
                alt=""
              />
              <img
                className=" w-4 h-4"
                src={require("../../assets/underlineIcon.png")}
                alt=""
              />
              <img
                className=" w-6 h-4"
                src={require("../../assets/Frame441.png")}
                alt=""
              />
              <img
                className=" w-6 h-4"
                src={require("../../assets/Frame442.png")}
                alt=""
              />
              <img
                className=" w-6 h-4"
                src={require("../../assets/Frame443.png")}
                alt=""
              />
              <img
                className=" w-6 h-4"
                src={require("../../assets/attachment444.png")}
                alt=""
              /> */}
						</div>
					</div>
				</div>
			</div>
			{/*  */}
			<div
				className=" flex mt-5 gap-5
      ">
				<div className=" grid  w-1/3">
					<div className=" flex">
						<p className=" font-bold font-Lato text-[#1B1B1B] text-sm">
							Job Type{" "}
						</p>
						<img
							className=" w-3 h-3"
							src={require("../../assets/mdi_required.png")}
							alt=""
						/>
					</div>
					<p className=" font-Lato font-light text-xs text-[#7E8597]">
						Choose multiple options if applicable
					</p>
				</div>
				<div className=" w-3/4">
					{/* <div className=" flex items-center gap-3">
						{jobtype.map(i => (
							<div className=" flex gap-2">
								<input type="checkbox" />
								<p className=" font-Lato text-xs text-[#3C4657] font-medium">
									{i.text}
								</p>
							</div>
						))}
					</div> */}
					<div className="space-y-2  flex">
						<RadioGroup
							options={jobtype?.map(it => it?.text)}
							selected={state?.type}
							onChange={handleTextChange}
							type="type"
						/>
					</div>
				</div>
			</div>

			{/*  */}
			<div
				className=" flex mt-5 gap-5
      ">
				<div className=" grid  w-1/3">
					<div className=" flex">
						<p className=" font-bold font-Lato text-[#1B1B1B] text-sm">
							Job Location{" "}
						</p>
						<img
							className=" w-3 h-3"
							src={require("../../assets/mdi_required.png")}
							alt=""
						/>
					</div>
					<p className=" font-Lato font-light text-xs text-[#7E8597]">
						Choose multiple options if applicable
					</p>
				</div>
				<div className=" w-3/4">
					<input
						placeholder="Choose location"
						type="text"
						className=" w-full h-11 placeholder:text-xs placeholder-[#7E8597] border border-[#D8DFEC] rounded-md outline-none px-2"
						onChange={textChange}
						name="location"
						value={state?.location}
					/>
				</div>
			</div>
			{/*  */}
			<div
				className=" flex mt-5 gap-5
      ">
				<div className=" grid  w-1/3">
					<div className=" flex">
						<p className=" font-bold font-Lato text-[#1B1B1B] text-sm">
							Recruitment Limit{" "}
						</p>
						<img
							className=" w-3 h-3"
							src={require("../../assets/mdi_required.png")}
							alt=""
						/>
					</div>
					<p className=" font-Lato font-light text-xs text-[#7E8597]">
						Mention number of open positions or number of candidates going to be
						hired
					</p>
				</div>
				<div className=" w-3/4">
					{/* <div className=" flex items-center gap-3">
						{RecruitmentLimit.map(i => (
							<div className=" flex gap-2">
								<input type="checkbox" />
								<p className=" font-Lato text-xs text-[#3C4657] font-medium">
									{i.limit}
								</p>
							</div>
						))}
					</div> */}
					<div className="space-y-2  flex">
						<RadioGroup
							options={RecruitmentLimit?.map(it => it?.limit)}
							selected={state?.limit}
							onChange={handleTextChange}
							type="limit"
						/>
					</div>
				</div>
			</div>
			{/* <div className=" flex justify-end items-center">
        <button className=" text-white font-Nunito text-sm bg-gradient-to-r from-[#5608D4] to-[#04096E] rounded-md w-44 h-12 animate-pulse">
          Save and continue
        </button>
      </div> */}
		</div>
	);
};

export default BasicInformation;

const RadioButton = ({ value, selectedValue, onChange, type }) => {
	return (
		<label className="flex items-center space-x-2 cursor-pointer">
			<input
				type="radio"
				value={value}
				checked={selectedValue === value}
				onChange={() => onChange(value, type)}
				className="form-radio h-4 w-4 text-indigo-600"
			/>
			<span className=" font-Lato text-xs text-[#3C4657] font-medium">
				{value}
			</span>
		</label>
	);
};

export const RadioGroup = ({ options, selected, onChange, type }) => {
	return (
		<div className="flex gap-4">
			{options.map(option => (
				<RadioButton
					key={option}
					value={option}
					selectedValue={selected}
					onChange={onChange}
					type={type}
				/>
			))}
		</div>
	);
};