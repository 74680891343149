// import React, { useState } from "react";
// import JobNavbar from "../../components/job/JobNavbar";
// import JobSecNav from "../../components/job/JobSecNav";
// import PersonalInfoForm from "../../components/job/PersonalInfoForm";
// import EducationForm from "../../components/job/EducationForm";
// import ExperienceForm from "../../components/job/ExperienceForm";
// import Groupchart from "../../assets/Groupchart.png";
// import jobuser from "../../assets/jobuser.png";

// const Accountsetup = () => {
//   const [activeStep, setActiveStep] = useState(1);

//   const steps = [
//     { id: 1, name: "Personal Information", component: <PersonalInfoForm /> },
//     { id: 2, name: "Education", component: <EducationForm /> },
//     { id: 3, name: "Experience", component: <ExperienceForm /> },
//     { id: 4, name: "Projects", component: <div>Projects Form Content</div> },
//     { id: 5, name: "Salary", component: <div>Salary Form Content</div> },
//     {
//       id: 6,
//       name: "Document Upload",
//       component: <div>Document Upload Form Content</div>,
//     },
//   ];

//   return (
//     <div className="bg-[#F2EFFC]">
//       <JobNavbar />
//       <JobSecNav />
//       <div className="bg-white mx-10 mt-2">
//         <div className="flex items-center justify-between bg-[#FCF7FF] p-5">
//           <div className="items-center flex gap-10">
//             <img className="w-28 h-28" src={Groupchart} alt="Chart" />
//             <div>
//               <p className="font-Nunito text-[#000] text-2xl font-medium">
//                 You are{" "}
//                 <span className="text-2xl font-bold text-[#5608d4] font-Nunito">
//                   40%{" "}
//                 </span>{" "}
//                 Set
//               </p>
//               <p className="font-Nunito text-base text-[#000]">
//                 Complete your profile and start applying for jobs. Grow with
//                 CareerHR
//               </p>
//               <button className="w-44 h-9 rounded-lg bg-black text-white mt-3">
//                 Save
//               </button>
//             </div>
//           </div>
//           <div>
//             <img className="w-28 h-28" src={jobuser} alt="" />
//           </div>
//         </div>

//         <div className="bg-white p-5">
//           <div className="flex items-center gap-10">
//             {steps.map((step) => (
//               <div
//                 key={step.id}
//                 className="flex items-center gap-2 cursor-pointer"
//                 onClick={() => setActiveStep(step.id)}
//               >
//                 <div
//                   className={`w-6 h-6 rounded-full grid justify-center items-center ${
//                     activeStep === step.id
//                       ? "bg-[#5608d4] text-white"
//                       : "bg-[#f5eeff] text-[#000]"
//                   }`}
//                 >
//                   <p>{step.id}</p>
//                 </div>
//                 <p className="font-Nunito text-[15px] text-[#000] font-medium">
//                   {step.name}
//                 </p>
//               </div>
//             ))}
//           </div>

//           {/* Render the form content based on the selected step */}
//           <div className="mt-5">
//             {steps.find((step) => step.id === activeStep)?.component}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Accountsetup;

// import React, { useState } from "react";
// import JobNavbar from "../../components/job/JobNavbar";
// import JobSecNav from "../../components/job/JobSecNav";
// import PersonalInfoForm from "../../components/job/PersonalInfoForm";
// import EducationForm from "../../components/job/EducationForm";
// import ExperienceForm from "../../components/job/ExperienceForm";
// import ProgressChart from "../../components/job/ProgressChart";
// import jobuser from "../../assets/jobuser.png";

// const Accountsetup = () => {
//   const [activeStep, setActiveStep] = useState(1);
//   const [progress, setProgress] = useState(0); // Start with 0% progress

//   const steps = [
//     { id: 1, name: "Personal Information", component: <PersonalInfoForm onSave={handleSaveAndContinue} /> },
//     { id: 2, name: "Education", component: <EducationForm /> },
//     { id: 3, name: "Experience", component: <ExperienceForm /> },
//     { id: 4, name: "Projects", component: <div>Projects Form Content</div> },
//     { id: 5, name: "Salary", component: <div>Salary Form Content</div> },
//     { id: 6, name: "Document Upload", component: <div>Document Upload Form Content</div> },
//   ];

//   const handleSaveAndContinue = () => {
//     // Logic to move to the next step and update progress
//     if (activeStep < steps.length) {
//       setActiveStep((prev) => prev + 1);
//       setProgress(((activeStep + 1) / steps.length) * 100); // Update progress dynamically
//     }
//   };

//   return (
//     <div className="bg-[#F2EFFC]">
//       <JobNavbar />
//       <JobSecNav />
//       <div className="bg-white mx-10 mt-2">
//         <div className="flex items-center justify-between bg-[#FCF7FF] p-5">
//           <div className="items-center flex gap-10">
//             <ProgressChart progress={progress} />
//             <div>
//               <p className="font-Nunito text-[#000] text-2xl font-medium">
//                 You are{" "}
//                 <span className="text-2xl font-bold text-[#5608d4] font-Nunito">
//                   {progress}%{" "}
//                 </span>{" "}
//                 Set
//               </p>
//               <p className="font-Nunito text-base text-[#000]">
//                 Complete your profile and start applying for jobs. Grow with
//                 CareerHR
//               </p>
//               <button
//                 className="w-44 h-9 rounded-lg bg-black text-white mt-3"
//                 onClick={handleSaveAndContinue}
//               >
//                 Save and continue
//               </button>
//             </div>
//           </div>
//           <div>
//             <img className="w-28 h-28" src={jobuser} alt="" />
//           </div>
//         </div>

//         <div className="bg-white p-5">
//           <div className="flex items-center gap-10">
//             {steps.map((step) => (
//               <div
//                 key={step.id}
//                 className="flex items-center gap-2 cursor-pointer"
//                 onClick={() => setActiveStep(step.id)}
//               >
//                 <div
//                   className={`w-6 h-6 rounded-full grid justify-center items-center ${
//                     activeStep === step.id
//                       ? "bg-[#5608d4] text-white"
//                       : "bg-[#f5eeff] text-[#000]"
//                   }`}
//                 >
//                   <p>{step.id}</p>
//                 </div>
//                 <p className="font-Nunito text-[15px] text-[#000] font-medium">
//                   {step.name}
//                 </p>
//               </div>
//             ))}
//           </div>

//           {/* Render the form content based on the selected step */}
//           <div className="mt-5">
//             {steps.find((step) => step.id === activeStep)?.component}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Accountsetup;

import React, { useState } from "react";
import JobNavbar from "../../components/job/JobNavbar";
import JobSecNav from "../../components/job/JobSecNav";
import PersonalInfoForm from "../../components/job/PersonalInfoForm";
import Education from "../../components/job/Education";
import ExperienceForm from "../../components/job/ExperienceForm";
import ProgressChart from "../../components/job/ProgressChart";
import jobuser from "../../assets/jobuser.png";
import ProjectsForm from "../../components/job/ProjectsForm";
import SalaryForm from "../../components/job/SalaryForm";
import DocumentUpload from "../../components/job/DocumentUpload";

const Accountsetup = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0); // Start with 0% progress

  const handleSaveAndContinue = () => {
    // Logic to move to the next step and update progress
    if (activeStep < steps.length) {
      const newStep = activeStep + 1;
      setActiveStep(newStep);
      setProgress(Math.round(((newStep / steps.length) * 100) / 20) * 20); // Update progress in steps of 20%
    }
  };
  const goBack = () => {
    // Logic to move back to the previous step
    if (activeStep > 1) {
      const newStep = activeStep - 1;
      setActiveStep(newStep);
      setProgress(Math.round(((newStep / steps.length) * 100) / 20) * 20); // Update progress in steps of 20%
    }
  };

  const steps = [
    {
      id: 1,
      name: "Personal Information",
      component: (
        <PersonalInfoForm onSave={handleSaveAndContinue} progress={progress} />
      ),
    },
    {
      id: 2,
      name: "Education",
      component: (
        <Education
          onSave={handleSaveAndContinue}
          onBack={goBack}
          progress={progress}
        />
      ),
    },
    {
      id: 3,
      name: "Experience",
      component: (
        <ExperienceForm
          onSave={handleSaveAndContinue}
          onBack={goBack}
          progress={progress}
        />
      ),
    },
    {
      id: 4,
      name: "Projects",
      component: (
        <ProjectsForm
          onSave={handleSaveAndContinue}
          onBack={goBack}
          progress={progress}
        />
      ),
    },
    {
      id: 5,
      name: "Salary",
      component: (
        <SalaryForm
          onSave={handleSaveAndContinue}
          onBack={goBack}
          progress={progress}
        />
      ),
    },
    {
      id: 6,
      name: "Document Upload",
      component: (
        <DocumentUpload
          onSave={handleSaveAndContinue}
          onBack={goBack}
          progress={progress}
        />
      ),
    },
  ];

  return (
    <div className="bg-[#F2EFFC]">
      <JobNavbar />
      <JobSecNav />
      <div className="bg-white mx-12 mt-2">
        <div className="flex items-center justify-between bg-[#FCF7FF] p-5">
          <div className="items-center flex gap-10">
            <ProgressChart progress={progress} />
            <div>
              <p className="font-Nunito text-[#000] text-2xl font-medium">
                You are{" "}
                <span className="text-2xl font-bold text-[#5608d4] font-Nunito">
                  {progress}%{" "}
                </span>{" "}
                Set
              </p>
              <p className="font-Nunito text-base text-[#000]">
                Complete your profile and start applying for jobs. Grow with
                CareerHR
              </p>
              <button
                className="w-44 h-9 rounded-lg bg-black text-white mt-3"
                onClick={handleSaveAndContinue}
              >
                {progress === 100 ? "Save" : "Save and continue"}
              </button>
            </div>
          </div>
          <div>
            <img className="w-28 h-28" src={jobuser} alt="" />
          </div>
        </div>

        <div className="bg-white p-5">
          <div className="flex items-center gap-10">
            {steps.map((step) => (
              <div
                key={step.id}
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => setActiveStep(step.id)}
              >
                <div
                  className={`w-6 h-6 rounded-full grid justify-center items-center ${
                    activeStep === step.id
                      ? "bg-[#5608d4] text-white"
                      : "bg-[#f5eeff] text-[#000]"
                  }`}
                >
                  <p>{step.id}</p>
                </div>
                <p className="font-Nunito text-[15px] text-[#000] font-medium">
                  {step.name}
                </p>
              </div>
            ))}
          </div>

          {/* Render the form content based on the selected step */}
          <div className="mt-5">
            {steps.find((step) => step.id === activeStep)?.component}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accountsetup;
