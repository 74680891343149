import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
// import { FiChevronDown } from "react-icons/fi";
// import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
// import AddNewBtn from "../../../components/buttons/AddNewBtn";
import SearchInput from "../../../components/cards/SearchInput";
import useCompanyStore from "../../../data/Stores/CompanyStore";
import { useCandidateStore } from "../../../data/Stores/LoggerStore";
import { apiCall } from "../../../data/useGenFetcher";
import moment from "moment";
import { LoadMore, MainPaginate } from "../../../components/buttons/Send";

export const MainAllCandidates = ({
	currentItems,
	pageCount,
	handlePageClick,
	handleLoadMore,
	loading,
	data,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// const [isSearch, setIsSearch] = useState(false);

	// const handleSeach = () => {
	// 	setIsSearch(!isSearch);
	// };
	// const closeSearch = () => {
	// 	setIsSearch(false);
	// };

	const tableHead = [
		"Name",
		"Job/Department",
		"Date Applied",
		"Stages Passed",
		"Email address",

		"Actions",
	];

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">
					All Candidates
				</p>

				{/* <Link
          to="/add-new-employee"
          className=" flex items-center gap-3 justify-center bg-[#000] w-28 h-9 rounded-xl text-white"
        >
          <img
            className=" w-4 h-4"
            src={require("../../assets/add.png")}
            alt=""
          />
          <p className=" text-sm Satoshi text-white ">Add new</p>
        </Link> */}
			</div>

			<div className=" bg-white mx-12 p-5 mt-5 ">
				<div className=" flex items-center justify-between">
					<div className=" flex items-center gap-20">
						<div className=" flex items-center">
							<p className=" Satoshi font-normal text-sm text-[#000]">
								Candidates
							</p>
						</div>

						<div className=" flex items-center gap-3">
							<p className=" text-[#121212]  text-sm font-light font-Nunito">
								Search candidates
							</p>
							<SearchInput />
							{/* <div className="relative">
                <input
                  className="w-48 h-9 pr-10 pl-3 outline-none bg-[#F5F7F8] rounded-md"
                  type="text"
                />
                <div className="absolute inset-y-0 right-3 flex items-center pl-3">
                  <FaSearch className="text-gray-500" />
                </div>
              </div> */}
						</div>
					</div>
					<div>
						{/* <button className=" w-28 h-9 rounded-xl bg-black text-wrap Satoshi text-sm">
              Create New
            </button> */}
					</div>
				</div>
			</div>

			{/* Table */}
			<div className="bg-white mx-12">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50 ">
						<tr>
							{tableHead.map((head, index) => (
								<th
									key={index}
									className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
									{head}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{currentItems?.map((rowData, rowIndex) => (
							<tr key={rowIndex} className="border-t-[0.5px] border-t-gray-200">
								<td className="px-4 py-4  items-center gap-3 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.name}
								</td>
								<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.job?.name}
								</td>
								<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.createdAt
										? moment(rowData?.createdAt).format("DD/MM/YYYY")
										: null}
								</td>
								<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.stagesPassed}
								</td>
								<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.email}
								</td>

								<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
									<Link to="candidates-details" state={rowData}>
										...
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="mt-10 flex justify-center w-44 items-center mx-auto">
					<LoadMore
						next={data?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
			{/* End Table */}
		</div>
	);
};

const AllCandidates = () => {
	const tableData = [
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "3janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "3janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "3janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "3janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "3janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "No Tools Assigned",
			StagesPassed: "12",
			Emailaddress: "janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "janevic@gmail.com",
			Actions: "...",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData }),
		{ getLogger, mainSearch, isFound, data } = useCandidateStore(),
		{ currentSelected } = useCompanyStore(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		if (!currentSelected) return;
		apiCall({
			type: "get",
			url: `/api/v1/job-application/${currentSelected}?_populate=job`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSelected]);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/job-application/${currentSelected}?_populate=job&_limit=${
				data?.limit * data?.nextPage
			}`,
			getter: d => getLogger(d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<MainAllCandidates
			currentItems={currentItems}
			pageCount={pageCount}
			handlePageClick={handlePageClick}
			loading={loading}
			handleLoadMore={handleLoadMore}
			data={datum}
		/>
	);
};

export default AllCandidates;
