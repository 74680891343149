import React, { useState } from "react";
import EditTime from "../tast/EditTime";

const Timesheets = () => {
  const [activeCard, setActiveCard] = useState("Timesheets");
  const [projects, setProjects] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectHours, setProjectHours] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [isEditTime, setIsEditTime] = useState(false);
  const [showDayCard, setShowDayCard] = useState(false);
  const [showWeekCard, setShowWeekCard] = useState(false);

  const handleEditTime = () => {
    setIsEditTime(!isEditTime);
  };

  const closeEditTime = () => {
    setIsEditTime(false);
  };

  const handleHourChange = (dayIndex, value) => {
    const newProjectHours = [...projectHours];
    newProjectHours[dayIndex] = parseFloat(value);
    setProjectHours(newProjectHours);
  };

  const handleAddProject = () => {
    setProjects([{ name: projectName, hours: projectHours }, ...projects]);
    setProjectName("");
    setProjectHours([0, 0, 0, 0, 0, 0, 0]);
  };

  const handleDeleteProject = (index) => {
    const newProjects = [...projects];
    newProjects.splice(index, 1);
    setProjects(newProjects);
  };

  const getTotalHours = (project) => {
    return project.hours.reduce((total, hour) => total + hour, 0);
  };

  const getTotalDayHours = (dayIndex) => {
    return projects.reduce(
      (total, project) => total + project.hours[dayIndex],
      0
    );
  };

  const totalWeeklyHours = projects.reduce(
    (total, project) => total + getTotalHours(project),
    0
  );
  return <div></div>;
};

export default Timesheets;
