import React, { useEffect, useState } from "react";
// import Tools from "../buttons/Tools";
// import ShortAnswer from "../buttons/ShortAnswer";
// import CopyDelete from "../buttons/CopyDelete";
import ActionCompleted from "./ActionCompleted";
import useErrorStore from "../../data/Stores/errorstore";
import { apiCall } from "../../data/useGenFetcher";
import { toast } from "react-toastify";
import {
	useFlowTypesStore,
	useLeaveFlowStore,
} from "../../data/Stores/LoggerStore";
import { useLocation, useParams } from "react-router-dom";
import useCompanyStore from "../../data/Stores/CompanyStore";
import { Button } from "../buttons/Send";
import useLevelStore from "../../data/Stores/LevelStore";
import { v4 } from "uuid";
import { IconContext } from "react-icons";
import { BiSolidCheckboxMinus } from "react-icons/bi";
import { BsArrowDown } from "react-icons/bs";

// import Option from "./Option";

const ApprovalFlow = ({ onClose, items }) => {
	// const [isSwitched, setIsSwitched] = useState(false);
	const [submit, setSubmit] = useState(false);
	// const [showAnswer, setShowAnswer] = useState(false);

	// const handleShowAnswer = () => {
	//   setShowAnswer(!showAnswer);
	// };
	// const closeShowAnswer = () => {
	//   setShowAnswer(false);
	// };

	const handleSubmit2 = () => {
		setSubmit(!submit);
	};

	const closeSubmit = () => {
		setSubmit(false);
	};

	// const handleClick = () => {
	//   setIsSwitched(!isSwitched);
	// };

	let init = {},
		[state, setState] = useState({}),
		[loading, setLoading] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		flowType = useFlowTypesStore(),
		leaveFlow = useLeaveFlowStore(),
		{ returnErrors } = useErrorStore(),
		{ step } = useParams(),
		loci = useLocation()?.state,
		{ currentSelected } = useCompanyStore(),
		level = useLevelStore(),
		[approval, setApproval] = useState([]),
		[da, setDa] = useState(),
		colorArr = ["#FFF3FF", "#E8FEEE", "#E8F2FE"],
		handleDelete = e => {
			let newFil = approval?.filter(it => it?.id !== e?.id);
			console.log({ newFil, e, approval });
			setApproval(newFil);
		},
		handleAdd = () => {
			console.log({ da });
			if (!da?.level) return;
			setApproval([
				...approval,
				{
					...da,
					id: v4(),
					levelId: level?.data?.docs?.find(i => i?._id === da?.level),
				},
			]);
			setDa(init);
		};

	useEffect(() => {
		if (items)
			setApproval(
				items?.approvers?.map(it => {
					return {
						id: v4(),
						level: it?._id,
						levelId: it,
					};
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/level/${currentSelected}?_limit=0`,
			getter: d => level?.getLevel(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let handleSubmit = async e => {
		e?.preventDefault();
		if (Object.values(state)?.length === 0 && approval?.length === 0)
			return toast.info("Update Data required");
		let newState = state;

		newState.approvers = approval?.map(item => item?.level);

		if (!items) if (step !== "leave-flow") newState.flow = loci?._id;

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/${step === "leave-flow" ? "leaveFlow" : "approvalFlow"}${
				items
					? step !== "leave-flow"
						? `/${currentSelected}`
						: ""
					: `/${currentSelected}`
			}/${items ? items?._id : ""}`,
			type: items ? "put" : "post",
			data: newState,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			let newResp = response;
			if (items) {
				if (step === "leave-flow") leaveFlow?.updateLogger(newResp);
				else flowType?.updateLogger(newResp);
			} else {
				if (step === "leave-flow") leaveFlow?.addLogger(newResp);
				else flowType?.addLogger(newResp);
			}
			if (step === "leave-flow")
				apiCall({
					type: "get",
					url: `/api/v1/leaveFlow/${currentSelected}?_populate=level&_populate=approvers`,
					getter: d => leaveFlow?.getLogger(d),
				});
			else
				apiCall({
					type: "get",
					url: `/api/v1/approvalFlow/${currentSelected}?flow=${items?.flow?._id}&_populate=level&_populate=approvers`,
					getter: d => flowType?.getLogger(d),
				});
			setState(init);
			onClose();
			handleSubmit2();
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" overflow-y-scroll fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
			<div className=" bg-white w-[682px] p-5 h-auto rounded-md ">
				<div className=" flex justify-between items-center">
					<p className=" font-Kumbh text-xl font-bold text-[#000]">
						{items ? "Update" : "New"} Approval Flow
					</p>
					<p
						onClick={onClose}
						className=" cursor-pointer text-[24px] font-bold">
						&times;
					</p>
				</div>
				<div className=" space-y-5 mt-5 pr-5">
					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Level
						</label>
						{/* <input
							placeholder="Title"
							className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={state?.level || items?.level?._id || items?.level}
							onChange={textChange}
							name={"level"}
						/> */}
						<select
							className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12 "
							type="text"
							value={state?.level || items?.level?._id || items?.level}
							onChange={textChange}
							name={"level"}>
							<option value="">Select One</option>
							{level?.data?.docs?.map((it, i) => (
								<option value={it?._id} key={i}>
									{it?.name}
								</option>
							))}
						</select>
					</div>

					{(state?.level || items?.level?._id || items?.level) && (
						<>
							<div className=" space-y-2">
								<label
									className=" text-[#121212] font-Nunito text-sm"
									htmlFor="">
									Choose Approver
								</label>
								<select
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12 "
									type="text"
									value={da?.level}
									onChange={e => setDa({ level: e?.target?.value })}
									name={"level"}>
									<option value="">Select One</option>
									{level?.data?.docs
										?.filter(it => it?._id !== state?.level)
										?.map((it, i) => (
											<option value={it?._id} key={i}>
												{it?.name}
											</option>
										))}
								</select>
							</div>

							<div className=" flex justify-end">
								<img
									onClick={handleAdd}
									className=" w-6 h-6"
									src={require("../../assets/pluse.png")}
									alt=""
								/>
							</div>
						</>
					)}
					{approval?.map((it, i) => (
						<div className="flex justify-center items-center" key={i}>
							<div className=" ">
								<p className="text-center flex items-center">
									<span
										className="p-2 rounded-sm"
										style={{
											background: colorArr?.[i % colorArr?.length],
										}}>
										Leave Approver {i + 1}: {it?.levelId?.name}
									</span>
									<div className="flex justify-end">
										<IconContext.Provider
											value={{
												className:
													"global-class-name text-[#F97AB4] cursor-pointer",
											}}>
											<div
												title="Remove Approval"
												onClick={() => handleDelete(it)}>
												<BiSolidCheckboxMinus size={24} />
											</div>
										</IconContext.Provider>
									</div>
								</p>

								{i !== approval?.length - 1 && (
									<div className="m-auto flex justify-center w-full">
										<IconContext.Provider
											value={{
												className: "global-class-name text-[#2A72A8CC]",
											}}>
											<div>
												<BsArrowDown />
											</div>
										</IconContext.Provider>
									</div>
								)}
							</div>
						</div>
					))}
				</div>

				<div className=" space-y-5 mt-20">
					<div>
						{/* <button
              onClick={handleSubmit}
              className=" w-44 h-8 rounded-lg bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito"
            >
              Create
            </button> */}
						<Button
							css={
								" w-44 h-8 rounded-lg bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito"
							}
							children={"Create"}
							onClick={handleSubmit}
							loading={loading}
						/>
						{submit && <ActionCompleted onClose={closeSubmit} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ApprovalFlow;
