import React from "react";

const NewGroup = () => {
  return (
    <div>
      <p>New Group</p>
    </div>
  );
};

export default NewGroup;
