import React from "react";
import { Link } from "react-router-dom";

const SideBar = () => {
  const sidebar = [
    {
      id: 1,
      img: require("../../assets/sideone.png"),
      text: "Dashboard",
      to: "/tasks",
    },
    {
      id: 2,
      img: require("../../assets/sidetwo.png"),
      text: "My Tasks",
      to: "/my-tasks",
    },
    {
      id: 3,
      img: require("../../assets/sidethree.png"),
      text: "Projects",
      to: "/projects",
    },
    {
      id: 4,
      img: require("../../assets/sidefour.png"),
      text: "Timetracker",
      to: "/timetracker",
    },
  ];
  return (
    <div className="bg-[#F5F5F7] pb-20 pt-5 pl-5 space-y-3 rounded-lg">
      {sidebar.map((i) => (
        <div className="  w-44  rounded-lg  ">
          <Link to={i.to} className=" flex items-center gap-3">
            <img className=" w-4 h-4" src={i.img} alt="" />
            <p className=" font-bold font-Kumbh text-sm">{i.text}</p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default SideBar;
