import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import ActionCompleted from "../cards/ActionCompleted";
import ReactPaginate from "react-paginate";
import "./loader.css";

export const MainPaginate = ({ handlePageClick, pageCount }) => (
	<div className="flex justify-center items-center py-10">
		<ReactPaginate
			breakLabel="..."
			onPageChange={handlePageClick}
			pageRangeDisplayed={5}
			pageCount={pageCount}
			renderOnZeroPageCount={null}
			pageClassName="h-10 w-10 flex items-center justify-center rounded-full text-xl font-medium manrope border border-main mx-2 cursor-pointer"
			className="flex items-center justify-center"
			previousClassName="hidden"
			nextClassName="hidden"
			activeClassName="bg-gradient-to-b from-[#5608D4] to-[#384295] text-white"
		/>
	</div>
);

const Send = () => {
	const [isSend, setIsSend] = useState(false);

	const handleSend = () => {
		setIsSend(!isSend);
	};
	const closeSend = () => {
		setIsSend(false);
	};
	return (
		<div>
			<button
				onClick={handleSend}
				className=" w-44 h-8 rounded-lg bg-gradient-to-b from-[#5608D4] to-[#384295] text-white font-Nunito text-sm">
				Send
			</button>
			{isSend && <ActionCompleted onClose={closeSend} />}
		</div>
	);
};

export default Send;

export const Button = ({
	buttonType,
	bg,
	children,
	css,
	icon,
	...restProps
}) => {
	return (
		<button
			type={restProps?.type || "button"}
			disabled={restProps?.loading || restProps?.disabled}
			className={`  ${icon || restProps?.loading ? "gap-2" : ""} ${
				css ||
				"rounded-lg w-full flex justify-center items-center bg-gradient-to-b from-[#070FBE] to-[#384295] font-Nunito text-sm text-white h-12"
			}`}
			style={{
				...restProps?.style,
			}}
			{...restProps}>
			<span>{icon}</span>
			<span>{children}</span>
			{restProps?.loading && (
				<ClipLoader color={restProps?.loadCss || "white"} size={16} />
			)}
		</button>
	);
};

export const LoadMore = ({ handleLoadMore, next, loading }) => {
	return (
		<>
			{!next ? (
				""
			) : (
				<Button
					onClick={handleLoadMore}
					children={loading ? "Loading..." : "Load More"}
					loading={loading}
					type={"button"}
				/>
			)}
		</>
	);
};

export const ModalContainer = ({ show, title, close, children, width }) => {
	return (
		<div
			className={`fixed ${
				show ? "flex" : "hidden"
			} flex items-center top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
			<div className="fixed inset-0 bg-black opacity-30"></div>
			<div
				className={`relative w-full ${
					width ? width : "max-w-2xl"
				} mx-auto max-h-full`}>
				{/* <!-- Modal content --> */}
				<div className="relative bg-white rounded-lg shadow">
					{/* <!-- Modal header --> */}
					<div className="flex items-start justify-between gap-4 p-4 rounded-t">
						<h3 className="text-xl capitalize font-semibold text-gray-900">
							{title}
						</h3>
						<button
							type="button"
							className="bg-gray-400 text-white rounded-lg text-sm p-1.5 inline-flex items-center"
							onClick={close}>
							<span className="sr-only">Close modal</span>
							<svg
								aria-hidden="true"
								className="w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clip-rule="evenodd"></path>
							</svg>
						</button>
					</div>
					<div className="p-6 space-y-6">{children}</div>
				</div>
			</div>
		</div>
	);
};

export const PageLoader = () => {
	return (
		<div className="ring">
			Loading
			<span className="spanClass"></span>
		</div>
	);
};