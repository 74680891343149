// import React from "react";

// const LevelDetails = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default LevelDetails;
import React from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import DeleteEditDetails from "../../../components/buttons/DeleteEditDetails";
import EditLevel from "../../../components/cards/EditLevel";
import { toast } from "react-toastify";
import { apiCall, numberWithCommas } from "../../../data/useGenFetcher";
import useLevelStore from "../../../data/Stores/LevelStore";
import useCompanyStore from "../../../data/Stores/CompanyStore";
import useErrorStore from "../../../data/Stores/errorstore";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";

const LevelsDetails = () => {
	let items = useLocation()?.state;
	let [state, setState] = useState(items),
		{ data } = useLevelStore(),
		navigate = useNavigate();

	useEffect(() => {
		data?.docs?.map(it => it?._id === items?._id && setState(it));
	}, [data, items]);

	let [loading, setLoading] = useState(false),
		{ deleteLevel } = useLevelStore(),
		{ currentSelected } = useCompanyStore(),
		{ returnErrors } = useErrorStore();

	let handleSubmit = async e => {
		// e?.preventDefault();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/level/${currentSelected}/${items?._id}`,
			type: "delete",
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response || response === "") {
			deleteLevel(items);
			if (e) e();
			navigate(-1);
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">
					Company Settings
				</p>
			</div>

			<div className=" bg-white flex gap-40 mx-12 p-5 mt-5">
				<div className=" space-y-5 ">
					<div>
						<p className=" Satoshi text-lg text-[#000]">Level Details</p>
					</div>
					<p className=" text-xl text-[#5608D4] font-Nunito font-bold">
						{state?.name}
					</p>
					<div className=" Satoshi text-sm text-[#000]">
						{state?.description}
					</div>
					<div className=" space-y-5">
						<div className=" flex items-center justify-between w-[500px]">
							<div className=" w-[200px] flex justify-start">
								<p className=" text-[#071C50] text-sm font-bold Satoshi">
									Date created -{" "}
								</p>
							</div>
							<div className=" w-[200px] flex justify-start">
								<p className="text-[#071C50] text-sm font-light Satoshi">
									{" "}
									{moment(state?.createdAt).format("Do MMMM, YYYY")}
								</p>
							</div>
						</div>
						<div className=" flex items-center justify-between w-[500px]">
							<div className=" w-[200px] flex justify-start">
								<p className=" text-[#071C50] text-sm font-bold Satoshi">
									Leave days -{" "}
								</p>
							</div>
							<div className=" w-[200px] flex justify-start">
								<p className="text-[#071C50] text-sm font-light Satoshi"> </p>
							</div>
						</div>
						<div className=" flex items-center justify-between w-[500px]">
							<div className=" w-[200px] flex justify-start">
								<p className=" text-[#071C50] text-sm font-bold Satoshi">
									Salary Structure -{" "}
								</p>
							</div>
							<div className=" w-[200px] flex justify-start">
								<p className="text-[#071C50] text-sm font-light Satoshi">
									{" "}
									{numberWithCommas(state?.salary)}
								</p>
							</div>
						</div>
						<div className=" flex items-center justify-between w-[500px]">
							<div className=" w-[200px] flex justify-start">
								<p className=" text-[#071C50] text-sm font-bold Satoshi">
									Level Entitled to -{" "}
								</p>
							</div>
							<div className=" w-[200px] flex justify-start">
								<p className="text-[#071C50] text-sm font-light Satoshi">
									{" "}
									{/* 200, 000 */}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className=" mt-[-120px]">
					<DeleteEditDetails
						editDetailsCard={<EditLevel />}
						deleteFunc={handleSubmit}
						loading={loading}
						title={items?.name}
					/>
					{/* <DeleteEditDetails /> */}
				</div>
			</div>
		</div>
	);
};

export default LevelsDetails;
