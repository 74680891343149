import React from "react";
import { IoOpenOutline } from "react-icons/io5";
import { BsVr } from "react-icons/bs";
import { VscNewFile } from "react-icons/vsc";
import { HiOutlineShare } from "react-icons/hi2";
import Collaborator from "../../assets/carbon_collaborate.png";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";

export function ActionFolder({ onClose }) {
  return (
    <>
      <div
        className="fixed inset-0 bg-transparent"
        onClick={onClose}
        aria-hidden="true"
      ></div>

      {/* Action Menu */}
      <div
        className="absolute flex flex-col gap-6 right-2 top-8 bg-white shadow-lg w-[230px] h-[302px] rounded-lg z-50 border border-[#EBEEF2] p-2"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center gap-3 cursor-pointer  text-[12px] text-[#475467]">
          <IoOpenOutline size={18} className="text-[#1B1B1BB2]" />
          <p>Open</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]">
          <BsVr size={18} className="text-[#1B1B1BB2]" />
          <p>Rename</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]">
          <VscNewFile size={18} className="text-[#1B1B1BB2]" />
          <p>Add file</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]">
          <HiOutlineShare size={18} className="text-[#1B1B1BB2]" />
          <p>Share</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]">
          <img src={Collaborator} alt="Collaborator" className="w-4 h-4" />
          <p>Collaborate</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]">
          <AiOutlineExclamationCircle size={18} className="text-[#1B1B1BB2]" />
          <p>Details</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px] text-[#DD1F1F]">
          <RiDeleteBin6Line size={18} className="text-[#DD1F1F]" />
          <p>Delete</p>
        </div>
      </div>
    </>
  );
}


export function ActionsFile({ onClose, style }) {
  return (
    <>
      <div
        className="fixed inset-0 bg-transparent"
        onClick={onClose}
        aria-hidden="true"
      ></div>

      {/* Action Menu */}
      <div
        className="absolute flex flex-col gap-4 right-2 top-6 bg-white shadow-lg w-[200px] rounded-lg z-50 border p-2"
        style={style}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center gap-3 cursor-pointer text-[12px]">
          <IoOpenOutline size={18} />
          <p>Open</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px]">
          <BsVr size={18} />
          <p>Rename</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px]">
          <HiOutlineShare size={18} />
          <p>Share</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px]">
          <AiOutlineExclamationCircle size={18} />
          <p>Details</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px] text-[#DD1F1F]">
          <RiDeleteBin6Line size={18} />
          <p>Delete</p>
        </div>
      </div>
    </>
  );
}
