import React, { useEffect, useState } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import PagesList from "../../../../components/pages-list/PagesList";
import ActionCompleted from "../../../../components/cards/ActionCompleted";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiCall } from "../../../../data/useGenFetcher";
import useDepartmentStore from "../../../../data/Stores/DepartmentStore";
import useCompanyStore from "../../../../data/Stores/CompanyStore";
import useErrorStore from "../../../../data/Stores/errorstore";
import { Button } from "../../../../components/buttons/Send";
import { EditData } from "../../../../data/Stores/Authstore";

const Information = () => {
	const [isContinue, setIsContinue] = useState(true);
	const [isBack, setIsBack] = useState(false);
	const [isDone, setIsDone] = useState(false);

	const handleContinue = () => {
		setIsBack(false);
		setIsContinue(!isContinue);
	};

	const handleDone = () => {
		setIsDone(!isDone);
	};

	const rowOne = [
		{
			id: 1,
			text: "Dashboard",
		},
		{
			id: 2,
			text: "Employee",
		},
		{
			id: 3,
			text: "Engagement",
		},
		{
			id: 5,
			text: "Leave",
		},
		{
			id: 6,
			text: "Finance",
		},
		{
			id: 7,
			text: "Inventory",
		},
	];

	const rowTwo = [
		{
			id: 1,
			textTwo: "Recruitment",
		},
		{
			id: 2,
			textTwo: "Request",
		},
		{
			id: 3,
			textTwo: "Attendance",
		},
		{
			id: 4,
			textTwo: "Notifications",
		},
		{
			id: 5,
			textTwo: "Performance",
		},
		{
			id: 6,
			textTwo: "HR Policy",
		},
		{
			id: 7,
			textTwo: "Company Settings",
		},
		{
			id: 8,
			textTwo: "Tasks Management",
		},
	];

	let init = {},
		[state, setState] = useState({}),
		[modules, setModules] = useState([]),
		[permit, setPermit] = useState(
			[...rowOne, ...rowTwo]?.map((it, x) => {
				return {
					...it,
					_id: it?.text?.toLowerCase() || it?.textTwo?.toLowerCase(),
					name: it?.text || it?.textTwo,
					id: x + 1,
				};
			})
		),
		[loading, setLoading] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ updateDepartment, data } = useDepartmentStore(),
		{ currentSelected } = useCompanyStore(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate();

	useEffect(() => {
		if (state?.department) {
			let findIt = data?.docs?.find(it => it?._id === state?.department);
			setModules(findIt?.modules);
			setPermit(
				permit?.map(it => {
					let findOne = findIt?.modules?.find(li => it?.name === li);
					return {
						...it,
						isChecked: findOne ? true : false,
					};
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.department, data]);

	const handleBack = () => {
		if (!state?.department) return;
		setIsContinue(false);
		setIsBack(!isBack);
	};

	const closeDone = () => {
		setIsDone(false);
		navigate(-1);
	};

	let handleSubmit = async e => {
		e?.preventDefault();
		if (Object.values(state)?.length === 0)
			return toast.info("Update Data required");
		let newState = {
			modules: permit?.filter(it => it?.isChecked)?.map(it => it?.name),
		};
		// console.log({ state });

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/department/${currentSelected}/${state?.department}`,
			type: "put",
			data: newState,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			updateDepartment(response);
			setState(init);
			handleDone();
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Company Settings
					</p>
				</div>

				<div className=" bg-white mx-12 py-5 px-52">
					<div className=" flex items-center">
						<div className=" w-1/2 justify-start flex items-center gap-2">
							<p
								className={`w-6 h-6 rounded-full text-xs font-Nunito grid justify-center items-center ${
									isBack
										? " text-black bg-[#E4E4E4]"
										: "text-white bg-[#5608D4]"
								}`}>
								1
							</p>
							<p className=" text-[#000] font-Nunito text-sm">
								Basic Information
							</p>
						</div>
						<div className=" w-1/2 justify-start flex items-center gap-2">
							<p
								className={`w-6 h-6 rounded-full text-xs font-Nunito grid justify-center items-center ${
									isContinue
										? "text-black bg-[#E4E4E4]"
										: "text-white bg-[#5608D4]"
								}`}>
								2
							</p>
							<p className=" text-[#000] font-Nunito text-sm">Permissions</p>
						</div>
					</div>
					{isContinue && (
						<div>
							<p className=" font-Nunito text-sm text-[#000000] font-bold mt-3">
								Provide basic information for the role
							</p>

							<div className=" space-y-2 mt-4">
								<label
									className=" font-Nunito text-sm text-[#121212]"
									htmlFor="">
									Department name
								</label>
								<div className=" flex relative items-center">
									<select
										className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
										type="text"
										value={state?.department}
										onChange={textChange}
										name={"department"}>
										<option value="">Select One</option>
										{data?.docs?.map((it, i) => (
											<option value={it?._id} key={i}>
												{it?.name}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className=" grid justify-center items-center mt-40">
								<button
									onClick={handleBack}
									className="bg-gradient-to-b from-[#5608D4] to-[#5608D4] w-44 h-12 rounded-lg text-white font-Nunito">
									Save and continue
								</button>
							</div>
						</div>
					)}

					{isBack && (
						<div>
							<div className=" flex justify-between items-center">
								{/* <div className=" flex items-center gap-2">
                  <p className=" w-6 h-6 rounded-full text-xs text-white bg-[#5608D4] font-Nunito grid justify-center items-center ">
                    1
                  </p>
                  <p className=" text-[#000] font-Nunito text-sm">
                    Basic Information
                  </p>
                </div>
                <div className=" flex items-center gap-2">
                  <p className=" w-6 h-6 rounded-full text-xs text-black bg-[#E4E4E4] font-Nunito grid justify-center items-center ">
                    2
                  </p>
                  <p className=" text-[#000] font-Nunito text-sm">
                    Permissions
                  </p>
                </div> */}
							</div>
							<div className=" mt-3">
								<p className=" font-Nunito text-lg font-medium ">
									Select Permissions
								</p>
							</div>
							<div className=" hidden  mt-3">
								{/* first row */}
								<div className=" w-1/2 grid  gap-2 ">
									{rowOne.map(i => (
										<div
											key={i.id}
											className=" w-1/2 flex items-center gap-2 justify-start">
											<input type="checkbox" />
											<p className="font-Nunito text-sm text-[#000]">
												{i.text}
											</p>
										</div>
									))}
								</div>
								{/* sec row */}
								<div className=" w-1/2 grid  gap-2 justify-start">
									{rowTwo.map(i => (
										<div
											key={i.id}
											className=" w-1/2 flex items-center gap-2 justify-start">
											<input type="checkbox" />
											<p className="font-Nunito text-sm text-[#000] w-72">
												{i.textTwo}
											</p>
										</div>
									))}
								</div>
							</div>

							<div className="mt-3">
								<div className="pl-3 grid grid-cols-2 gap-5">
									<PermissionInfo
										permit={permit}
										setPermit={setPermit}
										modules={modules}
									/>
								</div>
							</div>

							<div className=" flex justify-center items-center mt-16 gap-5">
								<button
									onClick={handleContinue}
									className="w-44 h-12 rounded-md border border-[#5608D4] text-[#5608D4]">
									Back
								</button>

								{/* <button
									onClick={handleDone}
									className=" w-44 h-12 rounded-md bg-gradient-to-b from-[#5608D4] to-[#384295] font-Nunito text-sm text-white">
									Done
								</button> */}
								<Button
									css={
										" w-44 h-12 rounded-md bg-gradient-to-b from-[#5608D4] to-[#384295] font-Nunito text-sm text-white"
									}
									children={"Done"}
									onClick={handleSubmit}
									loading={loading}
								/>
								{isDone && <ActionCompleted onClose={closeDone} />}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Information;

export const DepartmentCheck = ({ item, permit, setPermit, i, modules }) => {
	let [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		let findUser = permit?.find(it => it?.isChecked && it?._id === item?._id);
		if (findUser) setIsChecked(true);
		else if (modules?.includes(item?._id)) {
			setIsChecked(true);
			setPermit(EditData(permit, { ...item, isChecked: true }));
		} else setIsChecked(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item, permit, modules]);

	return (
		<span className="me-3">
			<input
				type="checkbox"
				name={`level${i}`}
				id={`level${i}`}
				className="form-check-input form-check form-check-inline"
				checked={isChecked}
				onChange={e => {
					let newItem = item;
					if (e.target.checked) newItem = { ...newItem, isChecked: true };
					else
						newItem = {
							...newItem,
							isChecked: false,
						};
					setPermit(EditData(permit, newItem));
				}}
			/>
		</span>
	);
};

export const PermissionInfo = ({ permit, setPermit, modules }) => {
	return (
		<>
			{permit?.map((item, i) => (
				<div className="p-2 flex items-center" key={i}>
					<DepartmentCheck
						item={item}
						setPermit={setPermit}
						permit={permit}
						i={i}
						modules={modules}
					/>
					<label htmlFor={`level${i}`} className="text-capitalize my-0">
						{item?.name}
					</label>
				</div>
			))}
		</>
	);
};
