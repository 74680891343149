// import React, { useState } from "react";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";

// const ItemType = {
//   CARD: "CARD",
// };

// const Card = ({ card, index, column, moveCard }) => {
//   const ref = React.useRef(null);
//   const [, drop] = useDrop({
//     accept: ItemType.CARD,
//     hover(item, monitor) {
//       if (!ref.current) {
//         return;
//       }
//       const dragIndex = item.index;
//       const hoverIndex = index;
//       const sourceColumn = item.column;
//       const targetColumn = column;
//       if (dragIndex === hoverIndex && sourceColumn === targetColumn) {
//         return;
//       }
//       const hoverBoundingRect = ref.current?.getBoundingClientRect();
//       const hoverMiddleY =
//         (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
//       const clientOffset = monitor.getClientOffset();
//       const hoverClientY = clientOffset.y - hoverBoundingRect.top;
//       if (
//         dragIndex < hoverIndex &&
//         hoverClientY < hoverMiddleY &&
//         targetColumn !== "In Progress"
//       ) {
//         return;
//       }
//       if (
//         dragIndex > hoverIndex &&
//         hoverClientY > hoverMiddleY &&
//         targetColumn !== "Done"
//       ) {
//         return;
//       }
//       if (sourceColumn !== targetColumn) {
//         moveCard(dragIndex, hoverIndex, sourceColumn, targetColumn);
//         item.index = hoverIndex;
//         item.column = targetColumn;
//       } else {
//         moveCard(dragIndex, hoverIndex, sourceColumn);
//         item.index = hoverIndex;
//       }
//     },
//   });
//   const [{ isDragging }, drag] = useDrag({
//     type: ItemType.CARD,
//     item: () => {
//       return { card, index, column };
//     },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging(),
//     }),
//   });
//   const opacity = isDragging ? 0.5 : 1;
//   drag(drop(ref));

//   return (
//     <div ref={ref} style={{ opacity }} className="cursor-pointer">
//       {card && (
//         <div className="w-full p-3">
//           <div className="flex items-center justify-between">
//             <p className="font-Nunito text-[10px] font-bold text-[#1F2633]">
//               {card.title}
//             </p>
//             <div className="flex gap-2">
//               {card.imageone && (
//                 <img className="w-4 h-4" src={card.imageone} alt="" />
//               )}
//               <p className="text-[#17A5E6] text-[10px] font-Nunito">
//                 {card.fileNum}
//               </p>
//             </div>
//           </div>
//           <div className="flex items-center gap-2 py-3">
//             <p className="rounded-full py-1 px-1 text-[6px] font-Nunito border border-[#EBEEF2]">
//               {card.boxone}
//             </p>
//             <p className="rounded-full py-1 px-1 text-[6px] font-Nunito border border-[#EBEEF2]">
//               {card.boxtwo}
//             </p>
//             <p className="rounded-full py-1 px-1 text-[6px] font-Nunito border border-[#EBEEF2]">
//               {card.boxthree}
//             </p>
//             <p className="rounded-full py-1 px-1 text-[6px] font-Nunito border border-[#EBEEF2]">
//               {card.boxfour}
//             </p>
//           </div>
//           <div className="flex items-center justify-between">
//             <div className="flex items-center gap-2">
//               {card.avat && <img className="w-20 h-8" src={card.avat} alt="" />}
//               {card.add && (
//                 <img className="w-8 h-8 cursor-pointer" src={card.add} alt="" />
//               )}
//             </div>
//             <div className="flex items-center gap-2">
//               <div className="flex items-center gap-2">
//                 {card.attach && (
//                   <>
//                     <img className="w-4 h-4" src={card.attach} alt="" />
//                     <p className="font-Nunito text-[10px] text-[#9333EA]">2</p>
//                   </>
//                 )}
//               </div>
//               <div className="flex items-center gap-2">
//                 {card.chat && (
//                   <>
//                     <img className="w-4 h-4" src={card.chat} alt="" />
//                     <p className="font-Nunito text-[10px] text-[#0674D9]">3</p>
//                   </>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const Column = ({ column, cards, moveCard }) => {
//   const [, drop] = useDrop({
//     accept: ItemType.CARD,
//     drop: (item) => handleDrop(item, column),
//   });

//   const handleDrop = (item, targetColumn) => {
//     const sourceColumn = item.column;
//     if (sourceColumn !== targetColumn) {
//       moveCard(item.index, 0, sourceColumn, targetColumn);
//     }
//   };

//   return (
//     <div className="w-52 h-auto" ref={drop}>
//       <div className=" items-center flex justify-between">
//         <div className=" flex items-center gap-2">
//           <p className=" font-xs font-Nunito text-[#1F2633] font-medium">
//             {column}
//           </p>
//           <div className=" bg-[#c7efd6] w-6 h-6 grid justify-center items-center font-bold rounded-full text-[10px] text-[#16A34A] inter">
//             {cards.length}
//           </div>
//         </div>
//         <div>
//           <p className=" text-[#C7CED9] w-5 h-5">...</p>
//         </div>
//       </div>

//       <div className=" space-y-4">
//         {cards.map((card, index) => (
//           <Card
//             key={card.id}
//             card={card}
//             index={index}
//             column={column}
//             moveCard={moveCard}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// const BoardViewCard = () => {
//   const [columns, setColumns] = useState({
//     "To-do": [
//       {
//         id: 1,
//         title: " Create a Wireframe for SMS",
//         imageone: require("../../../assets/taskFile.png"),
//         fileNum: "4",
//         boxone: "CeHR001",
//         boxtwo: "Medium",
//         boxthree: "Design",
//         boxfour: "To-Do",
//         avat: require("../../../assets/taskAvatars.png"),
//         add: require("../../../assets/taskAddButton.png"),
//         attach: require("../../../assets/taskAttach.png"),
//         chat: require("../../../assets/taskChat.png"),
//       },
//       {
//         id: 2,
//         title: " Create a Wireframe for SMS",
//         imageone: require("../../../assets/taskFile.png"),
//         fileNum: "4",
//         boxone: "CeHR001",
//         boxtwo: "Medium",
//         boxthree: "Design",
//         boxfour: "To-Do",
//         avat: require("../../../assets/taskAvatars.png"),
//         add: require("../../../assets/taskAddButton.png"),
//         attach: require("../../../assets/taskAttach.png"),
//         chat: require("../../../assets/taskChat.png"),
//       },
//       {
//         id: 3,
//         title: " Create a Wireframe for SMS",
//         imageone: require("../../../assets/taskFile.png"),
//         fileNum: "4",
//         boxone: "CeHR001",
//         boxtwo: "Medium",
//         boxthree: "Design",
//         boxfour: "To-Do",
//         avat: require("../../../assets/taskAvatars.png"),
//         add: require("../../../assets/taskAddButton.png"),
//         attach: require("../../../assets/taskAttach.png"),
//         chat: require("../../../assets/taskChat.png"),
//       },
//     ],
//     "Yet to start": [],
//     "In Progress": [],
//     Done: [],
//   });

//   const moveCard = (dragIndex, hoverIndex, sourceColumn, targetColumn) => {
//     const newColumns = { ...columns };

//     // Remove the card from the source column
//     const sourceCards = [...newColumns[sourceColumn]];
//     sourceCards.splice(dragIndex, 1);
//     newColumns[sourceColumn] = sourceCards;

//     // Add the card to the target column
//     const targetCards = [...newColumns[targetColumn]];
//     targetCards.splice(hoverIndex, 0, columns[sourceColumn][dragIndex]);
//     newColumns[targetColumn] = targetCards;

//     setColumns(newColumns);
//   };

//   //   const moveCard = (dragIndex, hoverIndex, sourceColumn, targetColumn) => {
//   //     const dragCard = columns[sourceColumn][dragIndex];
//   //     const newColumns = { ...columns };

//   //     // Remove the card from the source column
//   //     newColumns[sourceColumn].splice(dragIndex, 1);

//   //     // Add the card to the target column
//   //     newColumns[targetColumn].splice(hoverIndex, 0, dragCard);

//   //     setColumns(newColumns);
//   //   };

//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div className="grid lg:grid-cols-4 justify-between gap-5">
//         {Object.keys(columns).map((column) => (
//           <Column
//             key={column}
//             column={column}
//             cards={columns[column]}
//             moveCard={(dragIndex, hoverIndex, sourceColumn, targetColumn) =>
//               moveCard(dragIndex, hoverIndex, sourceColumn, targetColumn)
//             }
//           />
//         ))}
//       </div>
//     </DndProvider>
//   );
// };

// export default BoardViewCard;

// /////////
// /////////
// /////////
// /////////

import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AssignMember from "./AssignMember";

const ItemType = {
  CARD: "CARD",
};

const Card = ({ card, index, column, moveCard }) => {
  const [isCard, setIscard] = useState(false);

  const handleCard = () => {
    setIscard(!isCard);
  };
  const closeCard = () => {
    setIscard(false);
  };
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: ItemType.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      const sourceColumn = item.column;
      const targetColumn = column;
      if (dragIndex === hoverIndex && sourceColumn === targetColumn) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        targetColumn !== "In Progress"
      ) {
        return;
      }
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        targetColumn !== "Done"
      ) {
        return;
      }
      if (sourceColumn !== targetColumn) {
        moveCard(dragIndex, hoverIndex, sourceColumn, targetColumn);
        item.index = hoverIndex;
        item.column = targetColumn;
      } else {
        moveCard(dragIndex, hoverIndex, sourceColumn);
        item.index = hoverIndex;
      }
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.CARD,
    item: () => {
      return { card, index, column };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }} className="cursor-pointer">
      {card && (
        <div className="w-full p-3">
          <div className="flex items-center justify-between">
            <p className="font-Nunito text-[10px] font-bold text-[#1F2633]">
              {card.title}
            </p>
            <div className="flex gap-2">
              {card.imageone && (
                <img className="w-4 h-4" src={card.imageone} alt="" />
              )}
              <p className="text-[#17A5E6] text-[10px] font-Nunito">
                {card.fileNum}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2 py-3">
            <p className="rounded-full py-1 px-1 text-[6px] font-Nunito border border-[#EBEEF2]">
              {card.boxone}
            </p>
            <p className="rounded-full py-1 px-1 text-[6px] font-Nunito border border-[#EBEEF2]">
              {card.boxtwo}
            </p>
            <p className="rounded-full py-1 px-1 text-[6px] font-Nunito border border-[#EBEEF2]">
              {card.boxthree}
            </p>
            <p className="rounded-full py-1 px-1 text-[6px] font-Nunito border border-[#EBEEF2]">
              {card.boxfour}
            </p>
          </div>
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              {card.avat && <img className="w-16 h-6" src={card.avat} alt="" />}
              {card.add && (
                <img
                  onClick={handleCard}
                  className="w-6 h-6 cursor-pointer"
                  src={card.add}
                  alt=""
                />
              )}
            </div>
            {isCard && <AssignMember onclose={closeCard} />}
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2">
                {card.attach && (
                  <>
                    <img className="w-4 h-4" src={card.attach} alt="" />
                    <p className="font-Nunito text-[10px] text-[#9333EA]">2</p>
                  </>
                )}
              </div>
              <div className="flex items-center gap-2">
                {card.chat && (
                  <>
                    <img className="w-4 h-4" src={card.chat} alt="" />
                    <p className="font-Nunito text-[10px] text-[#0674D9]">3</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Column = ({ column, cards, moveCard }) => {
  const [, drop] = useDrop({
    accept: ItemType.CARD,
    drop: (item) => handleDrop(item, column),
  });

  const handleDrop = (item, targetColumn) => {
    const sourceColumn = item.column;
    if (sourceColumn !== targetColumn) {
      moveCard(item.index, 0, sourceColumn, targetColumn);
    }
  };

  return (
    <div className="w-52 h-auto" ref={drop}>
      <div className=" items-center flex justify-between">
        <div className=" flex items-center gap-2">
          <p className=" font-xs font-Nunito text-[#1F2633] font-medium">
            {column}
          </p>
          <div className=" bg-[#c7efd6] w-6 h-6 grid justify-center items-center font-bold rounded-full text-[10px] text-[#16A34A] inter">
            {cards.length}
          </div>
        </div>
        <div>
          <p className=" text-[#C7CED9] w-5 h-5">...</p>
        </div>
      </div>

      <div className=" space-y-4">
        {cards.map((card, index) => (
          <Card
            key={card.id}
            card={card}
            index={index}
            column={column}
            moveCard={moveCard}
          />
        ))}
      </div>
      <div>
        {/*  */}
        {/*  */}
        {/*  */}
        <div className=" border border-[#F2F4F7] grid justify-center items-center h-8 rounded-lg">
          <img
            className=" w-5 h-5"
            src={require("../../../assets/taskadd.png")}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const BoardViewCard = () => {
  const [columns, setColumns] = useState({
    "To-do": [
      {
        id: 1,
        title: " Create a Wireframe for SMS",
        imageone: require("../../../assets/taskFile.png"),
        fileNum: "4",
        boxone: "CeHR001",
        boxtwo: "Medium",
        boxthree: "Design",
        boxfour: "To-Do",
        avat: require("../../../assets/taskAvatars.png"),
        add: require("../../../assets/taskAddButton.png"),
        attach: require("../../../assets/taskAttach.png"),
        chat: require("../../../assets/taskChat.png"),
      },
      {
        id: 2,
        title: "Lorem ipsum dolor sit amet consectetur. Eget.",
        imageone: require("../../../assets/taskFile2.png"),
        fileNum: "4",
        boxone: "CeHR001",
        boxtwo: "Medium",
        boxthree: "Design",
        boxfour: "To-Do",
        avat: require("../../../assets/taskAvatars2.png"),
        add: require("../../../assets/taskAddButton.png"),
        attach: require("../../../assets/file_present2.png"),
        chat: require("../../../assets/taskChat2.png"),
      },
      {
        id: 3,
        title: "Lorem ipsum dolor sit amet consectetur. Eget.",
        imageone: require("../../../assets/taskFile2.png"),
        fileNum: "4",
        boxone: "CeHR001",
        boxtwo: "Medium",
        boxthree: "Design",
        boxfour: "To-Do",
        avat: require("../../../assets/taskAvatars2.png"),
        add: require("../../../assets/taskAddButton.png"),
        attach: require("../../../assets/file_present2.png"),
        chat: require("../../../assets/taskChat2.png"),
      },
      {
        id: 4,
        title: "Lorem ipsum dolor sit amet consectetur. Eget.",
        imageone: require("../../../assets/taskFile2.png"),
        fileNum: "4",
        boxone: "CeHR001",
        boxtwo: "Medium",
        boxthree: "Design",
        boxfour: "To-Do",
        avat: require("../../../assets/taskAvatars2.png"),
        add: require("../../../assets/taskAddButton.png"),
        attach: require("../../../assets/file_present2.png"),
        chat: require("../../../assets/taskChat2.png"),
      },
      {
        id: 5,
        title: "Lorem ipsum dolor sit amet consectetur. Eget.",
        imageone: require("../../../assets/taskFile2.png"),
        fileNum: "4",
        boxone: "CeHR001",
        boxtwo: "Medium",
        boxthree: "Design",
        boxfour: "To-Do",
        avat: require("../../../assets/taskAvatars2.png"),
        add: require("../../../assets/taskAddButton.png"),
        attach: require("../../../assets/file_present2.png"),
        chat: require("../../../assets/taskChat2.png"),
      },
      {
        id: 6,
        title: "Lorem ipsum dolor sit amet consectetur. Eget.",
        imageone: require("../../../assets/taskFile2.png"),
        fileNum: "4",
        boxone: "CeHR001",
        boxtwo: "Medium",
        boxthree: "Design",
        boxfour: "To-Do",
        avat: require("../../../assets/taskAvatars2.png"),
        add: require("../../../assets/taskAddButton.png"),
        attach: require("../../../assets/file_present2.png"),
        chat: require("../../../assets/taskChat2.png"),
      },
      // Add more tasks here...
    ],
    "Yet to start": [],
    "In Progress": [],
    Done: [],
  });

  const moveCard = (dragIndex, hoverIndex, sourceColumn, targetColumn) => {
    const dragCard = columns[sourceColumn][dragIndex];
    const newColumns = { ...columns };

    // Remove the card from the source column
    newColumns[sourceColumn].splice(dragIndex, 1);

    // Add the card to the target column
    newColumns[targetColumn].splice(hoverIndex, 0, dragCard);

    setColumns(newColumns);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="grid lg:grid-cols-4 justify-between gap-5">
        {Object.keys(columns).map((column) => (
          <Column
            key={column}
            column={column}
            cards={columns[column]}
            moveCard={(dragIndex, hoverIndex, sourceColumn, targetColumn) =>
              moveCard(dragIndex, hoverIndex, sourceColumn, targetColumn)
            }
          />
        ))}
      </div>
    </DndProvider>
  );
};

export default BoardViewCard;

// ///////////////////////////////////////////////////
// ///////////////////////////////////////////////////
// ///////////////////////////////////////////////////
// ///////////////////////////////////////////////////

// import React from "react";

// const BoardViewCard = () => {
//   const todo = [
//     {
//       id: 1,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",
//       boxthree: "Design",
//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/taskAttach.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//     {
//       id: 2,
//       title: "Lorem ipsum dolor sit amet consectetur. Eget.",
//       imageone: require("../../../assets/taskFile2.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",
//       boxthree: "Design",
//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars2.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/file_present2.png"),
//       chat: require("../../../assets/taskChat2.png"),
//     },
//     {
//       id: 3,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile3.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",

//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/file_present2.png"),
//       chat: require("../../../assets/taskChat2.png"),
//     },
//     {
//       id: 4,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile3.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",

//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/file_present2.png"),
//       chat: require("../../../assets/taskChat2.png"),
//     },
//   ];

//   const YetToStart = [
//     {
//       id: 1,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",
//       boxthree: "Design",
//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/file_present2.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//     {
//       id: 2,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",
//       boxthree: "Design",
//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/file_present2.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//     {
//       id: 3,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",

//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/file_present2.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//     {
//       id: 4,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",

//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/file_present2.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//   ];

//   const InProgress = [
//     {
//       id: 1,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile3.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",
//       boxthree: "Design",
//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/taskAttach.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//     {
//       id: 2,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",
//       boxthree: "Design",
//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/taskAttach.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//   ];

//   const Done = [
//     {
//       id: 1,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",
//       boxthree: "Design",
//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/taskAttach.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//     {
//       id: 2,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",
//       boxthree: "Design",
//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/taskAttach.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//     {
//       id: 3,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",
//       boxthree: "Design",
//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/taskAttach.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//     {
//       id: 4,
//       title: " Create a Wireframe for SMS",
//       imageone: require("../../../assets/taskFile.png"),
//       fileNum: "4",
//       boxone: "CeHR001",
//       boxtwo: "Medium",
//       boxthree: "Design",
//       boxfour: "To-Do",
//       avat: require("../../../assets/taskAvatars.png"),
//       add: require("../../../assets/taskAddButton.png"),
//       attach: require("../../../assets/taskAttach.png"),
//       chat: require("../../../assets/taskChat.png"),
//     },
//   ];
//   return (
//     <div className=" grid lg:grid-cols-4 justify-between gap-5">
//       <div className=" w-52 h-auto">
//         <div className=" items-center flex justify-between">
//           <div className=" flex items-center gap-2">
//             <p className=" font-xs font-Nunito text-[#1F2633] font-medium">
//               To-do
//             </p>
//             <div className=" bg-[#EAB308] w-6 h-6 grid justify-center items-center font-bold rounded-full text-[10px] text-[#CA8A04] inter">
//               5
//             </div>
//           </div>
//           <div>
//             <p className=" text-[#C7CED9] w-5 h-5">...</p>
//           </div>
//         </div>

//         <div className=" space-y-4">
//           {todo.map((i) => (
//             <div key={i.id}>
//               {/*  */}
//               <div className=" w-full p-3 ">
//                 <div className=" flex items-center justify-between">
//                   <p className=" font-Nunito text-[10px] font-bold text-[#1F2633]">
//                     {i.title}
//                   </p>
//                   <div className=" flex gap-2">
//                     <img className=" w-4 h-4" src={i.imageone} alt="" />
//                     <p className=" text-[#17A5E6] text-[10px] font-Nunito">
//                       {i.fileNum}
//                     </p>
//                   </div>
//                 </div>
//                 <div className=" flex items-center gap-2 py-3">
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxone}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxtwo}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxthree}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxfour}
//                   </p>
//                 </div>
//                 <div className=" flex items-center justify-between">
//                   <div className=" flex items-center gap-2">
//                     <img className=" w-14 h-6" src={i.avat} alt="" />
//                     <img
//                       className=" w-6 h-6 cursor-pointer"
//                       src={i.add}
//                       alt=""
//                     />
//                   </div>
//                   <div className=" flex items-center gap-2">
//                     <div className=" flex items-center gap-2">
//                       <img className=" w-4 h-4" src={i.attach} alt="" />
//                       <p className=" font-Nunito text-[10px] text-[#9333EA]">
//                         2
//                       </p>
//                     </div>
//                     {/*  */}
//                     <div className=" flex items-center gap-2">
//                       <img className=" w-4 h-4" src={i.chat} alt="" />
//                       <p className=" font-Nunito text-[10px] text-[#0674D9]">
//                         3
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Yet to start */}
//       <div className=" w-52 h-auto">
//         <div className=" items-center flex justify-between">
//           <div className=" flex items-center gap-2">
//             <p className=" font-xs font-Nunito text-[#1F2633] font-medium">
//               Yet to start
//             </p>
//             <div className=" bg-[#e9a2c6] w-6 h-6 grid justify-center items-center font-bold rounded-full text-[10px] text-[#DB2777] inter">
//               5
//             </div>
//           </div>
//           <div>
//             <p className=" text-[#C7CED9] w-5 h-5">...</p>
//           </div>
//         </div>

//         <div className=" space-y-4">
//           {YetToStart.map((i) => (
//             <div key={i.id}>
//               {/*  */}
//               <div className=" w-full p-3 ">
//                 <div className=" flex items-center justify-between">
//                   <p className=" font-Nunito text-[10px] font-bold text-[#1F2633]">
//                     {i.title}
//                   </p>
//                   <div className=" flex gap-2">
//                     <img className=" w-4 h-4" src={i.imageone} alt="" />
//                     <p className=" text-[#17A5E6] text-[10px] font-Nunito">
//                       {i.fileNum}
//                     </p>
//                   </div>
//                 </div>
//                 <div className=" flex items-center gap-2 py-3">
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxone}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxtwo}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxthree}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxfour}
//                   </p>
//                 </div>
//                 <div className=" flex items-center justify-between">
//                   <div className=" flex items-center gap-2">
//                     <img className=" w-20 h-8" src={i.avat} alt="" />
//                     <img
//                       className=" w-8 h-8 cursor-pointer"
//                       src={i.add}
//                       alt=""
//                     />
//                   </div>
//                   <div className=" flex items-center gap-2">
//                     <div className=" flex items-center gap-2">
//                       <img className=" w-4 h-4" src={i.attach} alt="" />
//                       <p className=" font-Nunito text-[10px] text-[#9333EA]">
//                         2
//                       </p>
//                     </div>
//                     {/*  */}
//                     <div className=" flex items-center gap-2">
//                       <img className=" w-4 h-4" src={i.chat} alt="" />
//                       <p className=" font-Nunito text-[10px] text-[#0674D9]">
//                         3
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//       {/* In Progress */}
//       <div className=" w-52 h-auto">
//         <div className=" items-center flex justify-between">
//           <div className=" flex items-center gap-2">
//             <p className=" font-xs font-Nunito text-[#1F2633] font-medium">
//               In Progress
//             </p>
//             <div className=" bg-[#dbc7ee] w-6 h-6 grid justify-center items-center font-bold rounded-full text-[10px] text-[#9333EA] inter">
//               5
//             </div>
//           </div>
//           <div>
//             <p className=" text-[#C7CED9] w-5 h-5">...</p>
//           </div>
//         </div>

//         <div className=" space-y-4">
//           {InProgress.map((i) => (
//             <div key={i.id}>
//               {/*  */}
//               <div className=" w-full p-3 ">
//                 <div className=" flex items-center justify-between">
//                   <p className=" font-Nunito text-[10px] font-bold text-[#1F2633]">
//                     {i.title}
//                   </p>
//                   <div className=" flex gap-2">
//                     <img className=" w-4 h-4" src={i.imageone} alt="" />
//                     <p className=" text-[#17A5E6] text-[10px] font-Nunito">
//                       {i.fileNum}
//                     </p>
//                   </div>
//                 </div>
//                 <div className=" flex items-center gap-2 py-3">
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxone}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxtwo}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxthree}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxfour}
//                   </p>
//                 </div>
//                 <div className=" flex items-center justify-between">
//                   <div className=" flex items-center gap-2">
//                     <img className=" w-20 h-8" src={i.avat} alt="" />
//                     <img
//                       className=" w-8 h-8 cursor-pointer"
//                       src={i.add}
//                       alt=""
//                     />
//                   </div>
//                   <div className=" flex items-center gap-2">
//                     <div className=" flex items-center gap-2">
//                       <img className=" w-4 h-4" src={i.attach} alt="" />
//                       <p className=" font-Nunito text-[10px] text-[#9333EA]">
//                         2
//                       </p>
//                     </div>
//                     {/*  */}
//                     <div className=" flex items-center gap-2">
//                       <img className=" w-4 h-4" src={i.chat} alt="" />
//                       <p className=" font-Nunito text-[10px] text-[#0674D9]">
//                         3
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Done */}
//       <div className=" w-52 h-auto">
//         <div className=" items-center flex justify-between">
//           <div className=" flex items-center gap-2">
//             <p className=" font-xs font-Nunito text-[#1F2633] font-medium ">
//               Done
//             </p>
//             <div className=" bg-[#c7efd6] w-6 h-6 grid justify-center items-center font-bold rounded-full text-[10px] text-[#16A34A] inter">
//               5
//             </div>
//           </div>
//           <div>
//             <p className=" text-[#C7CED9] w-5 h-5">...</p>
//           </div>
//         </div>

//         <div className=" space-y-4">
//           {Done.map((i) => (
//             <div key={i.id}>
//               {/*  */}
//               <div className=" w-full p-3 ">
//                 <div className=" flex items-center justify-between">
//                   <p className=" font-Nunito text-[10px] font-bold text-[#1F2633]">
//                     {i.title}
//                   </p>
//                   <div className=" flex gap-2">
//                     <img className=" w-4 h-4" src={i.imageone} alt="" />
//                     <p className=" text-[#17A5E6] text-[10px] font-Nunito">
//                       {i.fileNum}
//                     </p>
//                   </div>
//                 </div>
//                 <div className=" flex items-center gap-2 py-3">
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxone}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxtwo}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxthree}
//                   </p>
//                   <p className=" rounded-full py-1 px-1 text-[6px] font-Nunito    border border-[#EBEEF2]">
//                     {i.boxfour}
//                   </p>
//                 </div>
//                 <div className=" flex items-center justify-between">
//                   <div className=" flex items-center gap-2">
//                     <img className=" w-20 h-8" src={i.avat} alt="" />
//                     <img
//                       className=" w-8 h-8 cursor-pointer"
//                       src={i.add}
//                       alt=""
//                     />
//                   </div>
//                   <div className=" flex items-center gap-2">
//                     <div className=" flex items-center gap-2">
//                       <img className=" w-4 h-4" src={i.attach} alt="" />
//                       <p className=" font-Nunito text-[10px] text-[#9333EA]">
//                         2
//                       </p>
//                     </div>
//                     {/*  */}
//                     <div className=" flex items-center gap-2">
//                       <img className=" w-4 h-4" src={i.chat} alt="" />
//                       <p className=" font-Nunito text-[10px] text-[#0674D9]">
//                         3
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BoardViewCard;
