import React, { useState } from "react";
import SideBar from "../../components/cards/SideBar";
import PagesList from "../../components/pages-list/PagesList";
import Navbar from "../../components/navbar/Navbar";
import EditTime from "../../components/cards/tast/EditTime";
import Reports from "../../components/cards/timetracker/Reports";
import Report from "../../components/cards/timetracker/Report";
import Daily from "../../components/cards/timetracker/Daily";
import Weekly from "../../components/cards/timetracker/Weekly";
import Monthly from "../../components/cards/timetracker/Monthly";

const Timetracker = () => {
  // const [activeCard, setActiveCard] = useState("Timesheets");
  const [projects, setProjects] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectHours, setProjectHours] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [isEditTime, setIsEditTime] = useState(false);
  const [showDayCard, setShowDayCard] = useState(false);
  const [showWeekCard, setShowWeekCard] = useState(false);
  const [isTimesheets, setIsTimesheets] = useState(true);
  const [isReport, setIsReport] = useState(false);
  const [isDay, setIsDay] = useState(true);
  const [isWeek, setIsWeek] = useState(false);
  const [isMonth, setIsMonth] = useState(false);

  const handleDays = () => {
    setIsWeek(false);
    setIsMonth(false);
    setIsDay(!isDay);
  };
  const handleWeeks = () => {
    setIsDay(false);
    setIsMonth(false);
    setIsWeek(!isWeek);
  };
  const handleMonths = () => {
    setIsDay(false);
    setIsWeek(false);
    setIsMonth(!isMonth);
  };

  const handleTimesheets = () => {
    setIsReport(false);
    setShowDayCard(false);
    setShowWeekCard(false);
    setIsTimesheets(!isTimesheets);
  };

  const handleReports = () => {
    setIsTimesheets(false);
    setShowDayCard(false);
    setShowWeekCard(false);
    setIsReport(!isReport);
  };

  const handleDay = () => {
    setShowWeekCard(false);
    setIsReport(false);
    setIsTimesheets(false);
    setShowDayCard(true);
  };

  const handleWeek = () => {
    setShowDayCard(false);
    setIsReport(false);
    setIsTimesheets(false);
    setShowWeekCard(true);
  };

  const handleEditTime = () => {
    setIsEditTime(!isEditTime);
  };

  const closeEditTime = () => {
    setIsEditTime(false);
  };

  const handleHourChange = (dayIndex, value) => {
    const newProjectHours = [...projectHours];
    newProjectHours[dayIndex] = parseFloat(value);
    setProjectHours(newProjectHours);
  };

  const handleAddProject = () => {
    setProjects([{ name: projectName, hours: projectHours }, ...projects]);
    setProjectName("");
    setProjectHours([0, 0, 0, 0, 0, 0, 0]);
  };

  const handleDeleteProject = (index) => {
    const newProjects = [...projects];
    newProjects.splice(index, 1);
    setProjects(newProjects);
  };

  const getTotalHours = (project) => {
    return project.hours.reduce((total, hour) => total + hour, 0);
  };

  const getTotalDayHours = (dayIndex) => {
    return projects.reduce(
      (total, project) => total + project.hours[dayIndex],
      0
    );
  };

  const totalWeeklyHours = projects.reduce(
    (total, project) => total + getTotalHours(project),
    0
  );
  // const handleCardClick = (card) => {
  //   setActiveCard(card);
  // };

  // const handleCardClick = (card) => {
  //   if (card === "Day") {
  //     setShowDayCard(!showDayCard);
  //     setShowWeekCard(false);
  //   } else if (card === "Week") {
  //     setShowWeekCard(!showWeekCard);
  //     setShowDayCard(false);
  //   } else {
  //     setActiveCard(activeCard === card ? null : card);
  //     setShowDayCard(false);
  //     setShowWeekCard(false);
  //   }
  // };
  return (
    <div className=" bg-[#EFF6FC] pb-10">
      <Navbar />
      <PagesList />

      <div className=" flex justify-between items-center px-12 pt-4">
        <p className=" font-Kumbh text-[#000] text-base font-bold">
          Tasks management
        </p>
      </div>

      <div className=" bg-white mx-12 p-5 mt-5">
        <div className=" flex gap-5">
          <div>
            <SideBar />
          </div>
          <div className=" w-full">
            {/*  */}

            <div className=" ">
              {isTimesheets && (
                <div className="flex items-center justify-between gap-10 ">
                  <p className="text-xl font-semibold Satoshi text-[#000]">
                    Timesheets
                  </p>
                  <div className="bg-[#5318FA] w-36 h-10 rounded-lg flex items-center justify-center gap-2">
                    <img
                      className="w-4 h-4"
                      src={require("../../assets/arcticons_simple-time-tracker.png")}
                      alt=""
                    />
                    <p className="Satoshi text-[#fff] text-sm">Start Tracker</p>
                  </div>
                </div>
              )}
              {isReport && (
                <div className="flex items-center gap-10 mt-4">
                  <p className="text-xl font-semibold Satoshi text-[#000]">
                    Time Tracker
                  </p>
                  <div className="flex items-center gap-10">
                    <div className="flex items-center gap-10">
                      <p
                        onClick={handleTimesheets}
                        className={`text-sm Satoshi cursor-pointer ${
                          isTimesheets === true
                            ? "text-[#5608D4]"
                            : "text-[#636E72]"
                        }`}
                      >
                        Timesheets
                      </p>
                      <p
                        onClick={handleReports}
                        className={`text-sm Satoshi cursor-pointer ${
                          isReport === true
                            ? "text-[#5608D4]"
                            : "text-[#636E72]"
                        }`}
                      >
                        Reports
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/*  */}
            <div className=" ">
              <div className=" flex items-center gap-3">
                {isTimesheets && (
                  <div className="flex items-center justify-between mt-5 w-full ">
                    <div className="flex items-center gap-10">
                      <p
                        onClick={handleTimesheets}
                        className={`text-sm Satoshi cursor-pointer ${
                          isTimesheets === true
                            ? "text-[#5608D4]"
                            : "text-[#636E72]"
                        }`}
                      >
                        Timesheets
                      </p>
                      <p
                        onClick={handleReports}
                        className={`text-sm Satoshi cursor-pointer ${
                          isReport === true
                            ? "text-[#5608D4]"
                            : "text-[#636E72]"
                        }`}
                      >
                        Reports
                      </p>
                    </div>

                    {/*  */}
                    <div className=" flex items-center justify-center gap-4">
                      <img
                        className=" w-4 h-4"
                        src={require("../../assets/lets-icons_date-today-duotone.png")}
                        alt=""
                      />
                      <p
                        className="bg-[#c4cdd1] text-[#636E72] text-sm  w-12 h-6 rounded-lg flex items-center justify-center gap-2 cursor-pointer"
                        onClick={handleDay}
                      >
                        Day
                      </p>
                      <p
                        className="bg-[#bcaed4] text-[#5608D4] text-sm  w-12 h-6 rounded-lg flex items-center justify-center gap-2 cursor-pointer"
                        onClick={handleWeek}
                      >
                        Week
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              {isTimesheets && (
                <div>
                  <div className="bg-[#D9D9D9] space-y-1">
                    {projects.map((project, projectIndex) => (
                      <div
                        key={projectIndex}
                        className="flex justify-between items-center gap-3 bg-[#fff] py-3 px-5 relative"
                      >
                        <div className="w-[35%] flex items-center gap-3">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#F72147] rounded-full w-[10px] h-[10px]"></div>
                            <p className="text-[#000] font-Nunito text-[12px]">
                              {project.name}
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-[#C1C3C6] rounded-full w-[6px] h-[6px]"></div>
                            <p className="text-[#000] font-Nunito text-[10px]">
                              CeHR
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center w-[60%] justify-between font-Nunito text-xs text-[#000] gap-1">
                          {project.hours.map((hour, dayIndex) => (
                            <div
                              key={dayIndex}
                              className="relative flex items-center"
                            >
                              <input
                                className="border border-[#CCCCCC] rounded-lg text-center w-14 h-6 flex justify-center items-center"
                                type="text"
                                value={hour}
                                onChange={(e) =>
                                  handleHourChange(dayIndex, e.target.value)
                                }
                              />
                              {hour !== 0 && (
                                <img
                                  onClick={handleEditTime}
                                  className=" w-[1.75px] h-[10.5px] absolute right-[-4px] cursor-pointer"
                                  src={require("../../assets/dotVector.png")}
                                  alt=""
                                />
                              )}

                              {isEditTime && (
                                <EditTime onClose={closeEditTime} />
                              )}
                            </div>
                          ))}
                          <div className="flex items-center font-Nunito text-xs text-[#000] gap-1">
                            <p className="border border-[#CCCCCC] rounded-lg w-14 h-6 flex justify-center items-center">
                              {getTotalHours(project)}
                            </p>
                            <p
                              className="text-xs font-Nunito text-[#C1B6B6] cursor-pointer"
                              onClick={() => handleDeleteProject(projectIndex)}
                            >
                              x
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="bg-white py-3 px-5 flex justify-between items-center gap-3">
                      <div className="w-[35%] flex items-center gap-3">
                        <div className="flex items-center gap-2 cursor-pointer">
                          <img
                            className="w-6 h-6"
                            src={require("../../assets/IconAdd.png")}
                            alt=""
                            onClick={handleAddProject}
                          />
                          <input
                            type="text"
                            className="text-xs font-Nunito text-[#5608D4] outline-none bg-transparent border-none"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            placeholder="Select project"
                          />
                        </div>
                      </div>

                      <div className="flex items-center w-[60%] justify-between font-Nunito text-xs text-[#000] gap-1">
                        {projectHours.map((hour, dayIndex) => (
                          <div
                            key={dayIndex}
                            className="relative flex items-center"
                          >
                            <input
                              className="border border-[#CCCCCC] outline-none text-center rounded-lg w-14 h-6 flex justify-center items-center"
                              type="text"
                              value={hour}
                              onChange={(e) =>
                                handleHourChange(dayIndex, e.target.value)
                              }
                            />

                            {hour !== 0 && (
                              <img
                                className=" w-[1.75px] h-[10.5px] absolute right-[-4px] to-[-4px] cursor-pointer"
                                src={require("../../assets/dotVector.png")}
                                alt=""
                              />
                            )}
                          </div>
                        ))}
                        <div className="flex items-center font-Nunito text-xs text-[#000] gap-1">
                          <p className="border border-[#CCCCCC] rounded-lg w-14 h-6 flex justify-center items-center">
                            {projectHours.reduce(
                              (total, hour) => total + parseFloat(hour),
                              0
                            )}
                          </p>
                          <p className="text-xs font-Nunito text-[#C1B6B6] cursor-pointer">
                            x
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-center gap-3 bg-white py-3 px-5">
                      <div className="w-[35%] flex items-center gap-3">
                        <div className="flex items-center gap-2 cursor-pointer">
                          <p className="font-Nunito font-semibold text-[13px] text-[#000]">
                            Total
                          </p>
                        </div>
                      </div>

                      <div className="flex items-center w-[60%] justify-between font-Nunito text-xs text-[#000] gap-1">
                        {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => (
                          <input
                            key={dayIndex}
                            className="border border-[#CCCCCC] rounded-lg w-14 h-6 flex text-center outline-none justify-center items-center"
                            type="text"
                            value={getTotalDayHours(dayIndex)}
                            readOnly
                          />
                        ))}
                        <div className="flex items-center font-Nunito text-xs text-[#000] gap-1">
                          <input
                            className="border border-[#CCCCCC] text-center outline-none rounded-lg w-14 h-6 flex justify-center items-center"
                            type="text"
                            value={totalWeeklyHours}
                            readOnly
                          />
                          <p className="text-xs font-Nunito text-[#C1B6B6]">
                            x
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isReport && (
                <div>
                  <div>
                    <div className=" flex items-center gap-10">
                      <p
                        onClick={handleDays}
                        className={`text-sm Satoshi cursor-pointer ${
                          isDay === true ? "text-[#5608D4]" : "text-[#636E72]"
                        }`}
                      >
                        Today
                      </p>
                      <p
                        onClick={handleWeeks}
                        className={`text-sm Satoshi cursor-pointer ${
                          isWeek === true ? "text-[#5608D4]" : "text-[#636E72]"
                        }`}
                      >
                        Weekly
                      </p>
                      <p
                        onClick={handleMonths}
                        className={`text-sm Satoshi cursor-pointer ${
                          isMonth === true ? "text-[#5608D4]" : "text-[#636E72]"
                        }`}
                      >
                        Monthly
                      </p>
                    </div>

                    {isDay && <Daily />}
                    {isWeek && <Weekly />}
                    {isMonth && <Monthly />}
                  </div>
                </div>
              )}
              {/*  */}
              {/*  */}

              <div>
                {showDayCard && (
                  <div>
                    <div className="flex items-center justify-between gap-10 ">
                      <p className="text-xl font-semibold Satoshi text-[#000]">
                        Timesheets
                      </p>
                      <div className="bg-[#5318FA] w-36 h-10 rounded-lg flex items-center justify-center gap-2">
                        <img
                          className="w-4 h-4"
                          src={require("../../assets/arcticons_simple-time-tracker.png")}
                          alt=""
                        />
                        <p className="Satoshi text-[#fff] text-sm">
                          Start Tracker
                        </p>
                      </div>
                    </div>
                    {/*  */}
                    <div className=" ">
                      <div className=" flex items-center gap-3">
                        <div className="flex items-center justify-between mt-5 w-full ">
                          <div className="flex items-center gap-10">
                            <p
                              onClick={handleTimesheets}
                              className={`text-sm Satoshi cursor-pointer ${
                                isTimesheets === true
                                  ? "text-[#5608D4]"
                                  : "text-[#636E72]"
                              }`}
                            >
                              Timesheets
                            </p>
                            <p
                              onClick={handleReports}
                              className={`text-sm Satoshi cursor-pointer ${
                                isReport === true
                                  ? "text-[#5608D4]"
                                  : "text-[#636E72]"
                              }`}
                            >
                              Reports
                            </p>
                          </div>

                          {/*  */}
                          <div className=" flex items-center justify-center gap-4">
                            <img
                              className=" w-4 h-4"
                              src={require("../../assets/lets-icons_date-today-duotone.png")}
                              alt=""
                            />
                            <p
                              className="bg-[#c4cdd1] text-[#636E72] text-sm  w-12 h-6 rounded-lg flex items-center justify-center gap-2 cursor-pointer"
                              onClick={handleDay}
                            >
                              Day
                            </p>
                            <p
                              className="bg-[#bcaed4] text-[#5608D4] text-sm  w-12 h-6 rounded-lg flex items-center justify-center gap-2 cursor-pointer"
                              onClick={handleWeek}
                            >
                              Week
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#D9D9D9] space-y-1">
                      {projects.map((project, projectIndex) => (
                        <div
                          key={projectIndex}
                          className="flex justify-between items-center gap-3 bg-[#fff] py-3 px-5 relative"
                        >
                          <div className="w-[35%] flex items-center gap-3">
                            <div className="flex items-center gap-2">
                              <div className="bg-[#F72147] rounded-full w-[10px] h-[10px]"></div>
                              <p className="text-[#000] font-Nunito text-[12px]">
                                {project.name}
                              </p>
                            </div>
                            <div className="flex items-center gap-2">
                              <div className="bg-[#C1C3C6] rounded-full w-[6px] h-[6px]"></div>
                              <p className="text-[#000] font-Nunito text-[10px]">
                                CeHR
                              </p>
                            </div>
                          </div>

                          <div className="flex items-center w-[60%] justify-between font-Nunito text-xs text-[#000] gap-1">
                            {project.hours.map((hour, dayIndex) => (
                              <div
                                key={dayIndex}
                                className="relative flex items-center"
                              >
                                <input
                                  className="border border-[#CCCCCC] rounded-lg text-center w-14 h-6 flex justify-center items-center"
                                  type="text"
                                  value={hour}
                                  onChange={(e) =>
                                    handleHourChange(dayIndex, e.target.value)
                                  }
                                />
                                {hour !== 0 && (
                                  <img
                                    onClick={handleEditTime}
                                    className=" w-[1.75px] h-[10.5px] absolute right-[-4px] cursor-pointer"
                                    src={require("../../assets/dotVector.png")}
                                    alt=""
                                  />
                                )}

                                {isEditTime && (
                                  <EditTime onClose={closeEditTime} />
                                )}
                              </div>
                            ))}
                            <div className="flex items-center font-Nunito text-xs text-[#000] gap-1">
                              <p className="border border-[#CCCCCC] rounded-lg w-14 h-6 flex justify-center items-center">
                                {getTotalHours(project)}
                              </p>
                              <p
                                className="text-xs font-Nunito text-[#C1B6B6] cursor-pointer"
                                onClick={() =>
                                  handleDeleteProject(projectIndex)
                                }
                              >
                                x
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="bg-white py-3 px-5 flex justify-between items-center gap-3">
                        <div className="w-[35%] flex items-center gap-3">
                          <div className="flex items-center gap-2 cursor-pointer">
                            <img
                              className="w-6 h-6"
                              src={require("../../assets/IconAdd.png")}
                              alt=""
                              onClick={handleAddProject}
                            />
                            <input
                              type="text"
                              className="text-xs font-Nunito text-[#5608D4] outline-none bg-transparent border-none"
                              value={projectName}
                              onChange={(e) => setProjectName(e.target.value)}
                              placeholder="Select project"
                            />
                          </div>
                        </div>

                        <div className="flex items-center w-[60%] justify-between font-Nunito text-xs text-[#000] gap-1">
                          {projectHours.map((hour, dayIndex) => (
                            <div
                              key={dayIndex}
                              className="relative flex items-center"
                            >
                              <input
                                className="border border-[#CCCCCC] outline-none text-center rounded-lg w-14 h-6 flex justify-center items-center"
                                type="text"
                                value={hour}
                                onChange={(e) =>
                                  handleHourChange(dayIndex, e.target.value)
                                }
                              />

                              {hour !== 0 && (
                                <img
                                  className=" w-[1.75px] h-[10.5px] absolute right-[-4px] to-[-4px] cursor-pointer"
                                  src={require("../../assets/dotVector.png")}
                                  alt=""
                                />
                              )}
                            </div>
                          ))}
                          <div className="flex items-center font-Nunito text-xs text-[#000] gap-1">
                            <p className="border border-[#CCCCCC] rounded-lg w-14 h-6 flex justify-center items-center">
                              {projectHours.reduce(
                                (total, hour) => total + parseFloat(hour),
                                0
                              )}
                            </p>
                            <p className="text-xs font-Nunito text-[#C1B6B6] cursor-pointer">
                              x
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between items-center gap-3 bg-white py-3 px-5">
                        <div className="w-[35%] flex items-center gap-3">
                          <div className="flex items-center gap-2 cursor-pointer">
                            <p className="font-Nunito font-semibold text-[13px] text-[#000]">
                              Total
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center w-[60%] justify-between font-Nunito text-xs text-[#000] gap-1">
                          {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => (
                            <input
                              key={dayIndex}
                              className="border border-[#CCCCCC] rounded-lg w-14 h-6 flex text-center outline-none justify-center items-center"
                              type="text"
                              value={getTotalDayHours(dayIndex)}
                              readOnly
                            />
                          ))}
                          <div className="flex items-center font-Nunito text-xs text-[#000] gap-1">
                            <input
                              className="border border-[#CCCCCC] text-center outline-none rounded-lg w-14 h-6 flex justify-center items-center"
                              type="text"
                              value={totalWeeklyHours}
                              readOnly
                            />
                            <p className="text-xs font-Nunito text-[#C1B6B6]">
                              x
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/*  */}
                {/*  */}
                {showWeekCard && (
                  <div>
                    <div className="flex items-center justify-between gap-10 ">
                      <p className="text-xl font-semibold Satoshi text-[#000]">
                        Timesheets
                      </p>
                      <div className="bg-[#5318FA] w-36 h-10 rounded-lg flex items-center justify-center gap-2">
                        <img
                          className="w-4 h-4"
                          src={require("../../assets/arcticons_simple-time-tracker.png")}
                          alt=""
                        />
                        <p className="Satoshi text-[#fff] text-sm ">
                          Start Tracker
                        </p>
                      </div>
                    </div>
                    {/*  */}
                    <div className=" ">
                      <div className=" flex items-center gap-3">
                        <div className="flex items-center justify-between mt-5 w-full ">
                          <div className="flex items-center gap-10">
                            <p
                              onClick={handleTimesheets}
                              className={`text-sm Satoshi cursor-pointer ${
                                isTimesheets === true
                                  ? "text-[#5608D4]"
                                  : "text-[#636E72]"
                              }`}
                            >
                              Timesheets
                            </p>
                            <p
                              onClick={handleReports}
                              className={`text-sm Satoshi cursor-pointer ${
                                isReport === true
                                  ? "text-[#5608D4]"
                                  : "text-[#636E72]"
                              }`}
                            >
                              Reports
                            </p>
                          </div>

                          {/*  */}
                          <div className=" flex items-center justify-center gap-4">
                            <img
                              className=" w-4 h-4"
                              src={require("../../assets/lets-icons_date-today-duotone.png")}
                              alt=""
                            />
                            <p
                              className="bg-[#c4cdd1] text-[#636E72] text-sm  w-12 h-6 rounded-lg flex items-center justify-center gap-2 cursor-pointer"
                              onClick={handleDay}
                            >
                              Day
                            </p>
                            <p
                              className="bg-[#bcaed4] text-[#5608D4] text-sm  w-12 h-6 rounded-lg flex items-center justify-center gap-2 cursor-pointer"
                              onClick={handleWeek}
                            >
                              Week
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#D9D9D9] space-y-1">
                      {projects.map((project, projectIndex) => (
                        <div
                          key={projectIndex}
                          className="flex justify-between items-center gap-3 bg-[#fff] py-3 px-5 relative"
                        >
                          <div className="w-[35%] flex items-center gap-3">
                            <div className="flex items-center gap-2">
                              <div className="bg-[#F72147] rounded-full w-[10px] h-[10px]"></div>
                              <p className="text-[#000] font-Nunito text-[12px]">
                                {project.name}
                              </p>
                            </div>
                            <div className="flex items-center gap-2">
                              <div className="bg-[#C1C3C6] rounded-full w-[6px] h-[6px]"></div>
                              <p className="text-[#000] font-Nunito text-[10px]">
                                CeHR
                              </p>
                            </div>
                          </div>

                          <div className="flex items-center w-[60%] justify-between font-Nunito text-xs text-[#000] gap-1">
                            {project.hours.map((hour, dayIndex) => (
                              <div
                                key={dayIndex}
                                className="relative flex items-center"
                              >
                                <input
                                  className="border border-[#CCCCCC] rounded-lg text-center w-14 h-6 flex justify-center items-center"
                                  type="text"
                                  value={hour}
                                  onChange={(e) =>
                                    handleHourChange(dayIndex, e.target.value)
                                  }
                                />
                                {hour !== 0 && (
                                  <img
                                    onClick={handleEditTime}
                                    className=" w-[1.75px] h-[10.5px] absolute right-[-4px] cursor-pointer"
                                    src={require("../../assets/dotVector.png")}
                                    alt=""
                                  />
                                )}

                                {isEditTime && (
                                  <EditTime onClose={closeEditTime} />
                                )}
                              </div>
                            ))}
                            <div className="flex items-center font-Nunito text-xs text-[#000] gap-1">
                              <p className="border border-[#CCCCCC] rounded-lg w-14 h-6 flex justify-center items-center">
                                {getTotalHours(project)}
                              </p>
                              <p
                                className="text-xs font-Nunito text-[#C1B6B6] cursor-pointer"
                                onClick={() =>
                                  handleDeleteProject(projectIndex)
                                }
                              >
                                x
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="bg-white py-3 px-5 flex justify-between items-center gap-3">
                        <div className="w-[35%] flex items-center gap-3">
                          <div className="flex items-center gap-2 cursor-pointer">
                            <img
                              className="w-6 h-6"
                              src={require("../../assets/IconAdd.png")}
                              alt=""
                              onClick={handleAddProject}
                            />
                            <input
                              type="text"
                              className="text-xs font-Nunito text-[#5608D4] outline-none bg-transparent border-none"
                              value={projectName}
                              onChange={(e) => setProjectName(e.target.value)}
                              placeholder="Select project"
                            />
                          </div>
                        </div>

                        <div className="flex items-center w-[60%] justify-between font-Nunito text-xs text-[#000] gap-1">
                          {projectHours.map((hour, dayIndex) => (
                            <div
                              key={dayIndex}
                              className="relative flex items-center"
                            >
                              <input
                                className="border border-[#CCCCCC] outline-none text-center rounded-lg w-14 h-6 flex justify-center items-center"
                                type="text"
                                value={hour}
                                onChange={(e) =>
                                  handleHourChange(dayIndex, e.target.value)
                                }
                              />

                              {hour !== 0 && (
                                <img
                                  className=" w-[1.75px] h-[10.5px] absolute right-[-4px] to-[-4px] cursor-pointer"
                                  src={require("../../assets/dotVector.png")}
                                  alt=""
                                />
                              )}
                            </div>
                          ))}
                          <div className="flex items-center font-Nunito text-xs text-[#000] gap-1">
                            <p className="border border-[#CCCCCC] rounded-lg w-14 h-6 flex justify-center items-center">
                              {projectHours.reduce(
                                (total, hour) => total + parseFloat(hour),
                                0
                              )}
                            </p>
                            <p className="text-xs font-Nunito text-[#C1B6B6] cursor-pointer">
                              x
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between items-center gap-3 bg-white py-3 px-5">
                        <div className="w-[35%] flex items-center gap-3">
                          <div className="flex items-center gap-2 cursor-pointer">
                            <p className="font-Nunito font-semibold text-[13px] text-[#000]">
                              Total
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center w-[60%] justify-between font-Nunito text-xs text-[#000] gap-1">
                          {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => (
                            <input
                              key={dayIndex}
                              className="border border-[#CCCCCC] rounded-lg w-14 h-6 flex text-center outline-none justify-center items-center"
                              type="text"
                              value={getTotalDayHours(dayIndex)}
                              readOnly
                            />
                          ))}
                          <div className="flex items-center font-Nunito text-xs text-[#000] gap-1">
                            <input
                              className="border border-[#CCCCCC] text-center outline-none rounded-lg w-14 h-6 flex justify-center items-center"
                              type="text"
                              value={totalWeeklyHours}
                              readOnly
                            />
                            <p className="text-xs font-Nunito text-[#C1B6B6]">
                              x
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timetracker;
