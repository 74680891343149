import axios, { isAxiosError } from "axios";
import { toast } from "react-toastify";
import useAuthStore, { TOKEN } from "./Stores/Authstore";
import { SetAuthCompanyID, SetAuthToken } from "./Config";
import useErrorStore from "./Stores/errorstore";
import useDepartmentStore from "./Stores/DepartmentStore";
import usePositionStore from "./Stores/PositionStore";
import useLevelStore from "./Stores/LevelStore";
import useCompanyStore from "./Stores/CompanyStore";
import useMediaStore from "./Stores/MediaStore";
import useAnnoucementStore from "./Stores/AnnoucementStore";

export const apiCall = async ({
	type,
	url,
	data,
	getter,
	headers,
	noToast,
}) => {
	try {
		let res;
		if (type === "get")
			res = await axios.get(url, {
				headers: {
					...headers,
				},
			});
		if (type === "post")
			res = await axios.post(
				url,
				{ ...data },
				{
					headers: {
						...headers,
					},
				}
			);
		if (type === "put")
			res = await axios.put(
				url,
				{ ...data },
				{
					headers: {
						...headers,
					},
				}
			);
		if (type === "patch")
			res = await axios.patch(
				url,
				{ ...data },
				{
					headers: {
						...headers,
					},
				}
			);
		if (type === "delete")
			res = await axios.delete(url, {
				headers: {
					...headers,
				},
			});
		if (type === "file") {
			res = await axios.post(
				`/api/v1/file`,
				{ ...data },
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
		}

		let response = res?.data;
		if (!["file", "get", "patch"]?.includes(type))
			if (!noToast) toast.success(res?.data?.message);
		if (getter) getter(response);
		return { response };
	} catch (error) {
		let message = "Unknown Error";
		if (error instanceof Error) message = error.message;
		if (isAxiosError(error)) {
			if (error) console.log({ error: error?.response?.data, err: error });
			if (error?.response?.status === 429) toast.error(error?.response?.data);
			if (error?.response?.data) {
				if (type && type !== "get") {
					let { error: errors } = error?.response?.data;
					if (errors && errors?.length > 1) {
						return { errArr: errors };
					} else {
						let errMsg =
							error?.response?.data?.message ||
							error?.response?.data?.error?.[0]?.message ||
							error?.response?.data?.error?.[0]?.msg ||
							error?.message;
						return { errMsg };
					}
				}
				return { errMsg: "" };
			}
			return { errMsg: "" };
		} else {
			return { errMsg: message };
		}
	}
};

const useGenFetcher = () => {
	let { getErrorText, clearErrors } = useErrorStore(),
		{ getUser, getUserFail, getUserLoading } = useAuthStore(),
		{ getDepartment } = useDepartmentStore(),
		{ getPosition } = usePositionStore(),
		{ getCompany, setCurrentCompany } = useCompanyStore(),
		{ getLevel } = useLevelStore(),
		{ getMedia } = useMediaStore(),
		{ getAnnoucement } = useAnnoucementStore(),
		loadUser = async () => {
			let token = localStorage.getItem(TOKEN);
			if (token) SetAuthToken(token);

			getUserLoading();
			clearErrors();
			try {
				let res = await axios.get(
					`/api/v1/user?_populate=position&_populate=department&_populate=level&_populate=grade`
				);
				if (res?.data) {
					getUser(res.data);
					apiCall({
						type: "get",
						url: "/api/v1/company",
						getter: d => {
							getCompany(d);
							let company = d?.data?.docs?.[0]?._id;
							setCurrentCompany(company);
							SetAuthCompanyID(company);
							apiCall({
								type: "get",
								url: `/api/v1/level/${company}`,
								getter: d => getLevel(d),
							});
						},
					});
					apiCall({
						type: "get",
						url: "/api/v1/department",
						getter: d => getDepartment(d),
					});
					apiCall({
						type: "get",
						url: "/api/v1/position?_populate=department",
						getter: d => getPosition(d),
					});
					apiCall({
						type: "get",
						url: `/api/v1/medias`,
						getter: d => getMedia(d),
					});
					apiCall({
						type: "get",
						url: `/api/v1/announcement?_populate=files&_populate=createdBy`,
						getter: d => getAnnoucement(d),
					});
				} else {
					getUserFail();
					getErrorText("Access denied");
				}
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				getUserFail();
				getErrorText(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						""
				);
			}
		};
	return { loadUser };
};

export let numberWithCommas = x => {
	return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
};

export default useGenFetcher;
