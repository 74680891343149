import React, { useState } from "react";

const CopyDelete = () => {
  const [isSwitched, setIsSwitched] = useState(false);

  const handleClick = () => {
    setIsSwitched(!isSwitched);
  };
  return (
    <div>
      <div className=" flex gap-3">
        <div className="  flex items-center gap-3">
          <img
            className=" w-4 h-4"
            src={require("../../assets/iconamoon_copy-thin.png")}
            alt=""
          />
          <img
            className=" w-4 h-4"
            src={require("../../assets/fluent_delete-28-regular.png")}
            alt=""
          />
        </div>
        <img
          className=" h-4"
          src={require("../../assets/Line-256.png")}
          alt=""
        />
        <div className="  flex items-center gap-1">
          <p className=" text-[11px] text-[#1B1B1B] font-Nunito">Required</p>
          <div className="bg-[#D1EFFF] h-[6px] w-5 rounded-full flex items-center">
            <div
              className={`w-2 h-2 bg-[#5597C9] rounded-full transition-transform duration-500 ${
                isSwitched ? "transform translate-x-full" : ""
              }`}
              onClick={handleClick}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyDelete;
