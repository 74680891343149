import React from "react";

const NewChat = () => {
  return (
    <div>
      <p>New Chat</p>
    </div>
  );
};

export default NewChat;
