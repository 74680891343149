import React from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import DeleteEditDetails from "../../../components/buttons/DeleteEditDetails";
import EditCompany from "../../../components/cards/EditCompany";
import useCompanyStore from "../../../data/Stores/CompanyStore";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";

const CompanyDetails = () => {
	let items = useLocation()?.state;
	let [state, setState] = useState(items),
		{ data } = useCompanyStore();

	useEffect(() => {
		data?.docs?.map(it => it?._id === items?._id && setState(it));
	}, [data, items]);

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">
					Company Settings
				</p>
			</div>

			<div className=" bg-white flex gap-40 mx-12 p-5 mt-5">
				<div className=" space-y-5 ">
					<div>
						<p className=" Satoshi text-lg text-[#000]">Company Details</p>
					</div>
					<p className=" text-xl text-[#5608D4] font-Nunito font-bold">
						{state?.name}
					</p>
					<div className=" Satoshi text-sm text-[#000]">
						{state?.description}
					</div>
					<div className=" space-y-5">
						<div className=" flex items-center justify-between w-[500px]">
							<div className=" w-[200px] flex justify-start">
								<p className=" text-[#071C50] text-sm font-bold Satoshi">
									Date created -{" "}
								</p>
							</div>
							<div className=" w-[200px] flex justify-start">
								<p className="text-[#071C50] text-sm font-light Satoshi">
									{" "}
									{moment(state?.createdAt).format("Do MMMM, YYYY")}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className=" mt-[-120px]">
					<DeleteEditDetails editDetailsCard={<EditCompany />} />
					{/* <DeleteEditDetails /> */}
				</div>
			</div>
		</div>
	);
};

export default CompanyDetails;
