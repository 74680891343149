import React from "react";
// import down from "../../assets/down.png";
import Addjob from "../../assets/Addjob.png";

const ExperienceForm = ({ onSave, onBack, progress }) => {
  return (
    <div>
      <div className=" flex  gap-20">
        <div className=" grid grid-cols-2 w-[60%] gap-5">
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Name of Company
            </label>
            <input
              className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
              placeholder="Company"
              type="text"
            />
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Position
            </label>
            <input
              className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
              placeholder=" Position"
              type="text"
            />
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Start Year
            </label>
            <input
              className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
              type="date"
            />
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              End Year
            </label>
            <input
              className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
              type="date"
            />
          </div>
        </div>
      </div>
      <div className=" grid justify-center mt-5 items-center w-9 h-9 border border-[#1b1b1b] rounded-lg">
        <img className=" w-4 h-4" src={Addjob} alt="" />
      </div>

      <div className=" flex items-center gap-10">
        <div className=" py-10">
          <button
            className="bg-white text-black border border-[#000] text-sm Satoshi w-44 h-9 rounded-lg"
            onClick={onBack} // Use the onBack function for the Previous button
          >
            Previous
          </button>
        </div>
        <div className=" py-10">
          <button
            className="bg-black text-white text-sm Satoshi w-44 h-9 rounded-lg"
            onClick={onSave} // Call the onSave function when clicked
          >
            {progress === 100 ? "Save" : "Save and continue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExperienceForm;
