import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { FiChevronDown } from "react-icons/fi";
import { FaLink, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import AddNewBtn from "../../components/buttons/AddNewBtn";
import useStaffStore from "../../data/Stores/StaffReducer";
import { apiCall } from "../../data/useGenFetcher";
import useCompanyStore from "../../data/Stores/CompanyStore";
import { LoadMore, MainPaginate } from "../../components/buttons/Send";
import { toast } from "react-toastify";

const ManageEmployees = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [isSearch, setIsSearch] = useState(false);

	const handleSeach = () => {
		setIsSearch(!isSearch);
	};
	const closeSearch = () => {
		setIsSearch(false);
	};

	const tableHead = [
		"S/N",
		"Name",
		"Employee ID",
		"Department",
		"Position",
		"Role",
		"Email Address",
		"Phone number",
		"Actions",
	];
	const tableData = [
		{
			SN: "1",
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",
			Department: "IT",
			Position: "UI/UX Designer",
			Role: "Staff",
			EmailAddress: "example@gmail.com",
			Phonenumber: "+234 901 222 3333",
			Actions: "Manage Employee",
		},
		{
			SN: "2",
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",
			Department: "IT",
			Position: "UI/UX Designer",
			Role: "Staff",
			EmailAddress: "example@gmail.com",
			Phonenumber: "+234 901 222 3333",
			Actions: "Manage Employee",
		},
		{
			SN: "3",
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",
			Department: "IT",
			Position: "UI/UX Designer",
			Role: "Staff",
			EmailAddress: "example@gmail.com",
			Phonenumber: "+234 901 222 3333",
			Actions: "Manage Employee",
		},
		{
			SN: "3",
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",
			Department: "IT",
			Position: "UI/UX Designer",
			Role: "Staff",
			EmailAddress: "example@gmail.com",
			Phonenumber: "+234 901 222 3333",
			Actions: "Manage Employee",
		},
		{
			SN: "4",
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",
			Department: "IT",
			Position: "UI/UX Designer",
			Role: "Staff",
			EmailAddress: "example@gmail.com",
			Phonenumber: "+234 901 222 3333",
			Actions: "Manage Employee",
		},
		{
			SN: "5",
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",
			Department: "IT",
			Position: "UI/UX Designer",
			Role: "Staff",
			EmailAddress: "example@gmail.com",
			Phonenumber: "+234 901 222 3333",
			Actions: "Manage Employee",
		},
		{
			SN: "6",
			Name: "Catherine James",
			EmployeeID: "HRMS.00A/22",
			Department: "IT",
			Position: "UI/UX Designer",
			Role: "Staff",
			EmailAddress: "example@gmail.com",
			Phonenumber: "+234 901 222 3333",
			Actions: "Manage Employee",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData }),
		{ getStaff, mainSearch, isFound, data } = useStaffStore(),
		{ currentSelected } = useCompanyStore(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		if (!currentSelected) return;
		apiCall({
			type: "get",
			getter: d => getStaff(d),
			url: `/api/v1/user/users/${currentSelected}?_populate=position&_populate=department&_populate=level&_populate=grade`,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSelected]);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/user/users/${currentSelected}?_populate=position&_populate=department&_populate=level&_populate=grade&_limit=${
				data?.limit * data?.nextPage
			}`,
			getter: d => getStaff(d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className=" bg-[#fff] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">
					Manage Employees
				</p>
				<div className=" flex items-center gap-10">
					<div
						// onClick={() => navigate("/employee-form")}
						onClick={() =>
							navigator.clipboard
								.writeText(
									`${window.origin}/employee-form?company=${currentSelected}`
								)
								.then(
									() => {
										toast.info("Copied", { autoClose: 2000 });
									},
									err => {
										toast.warn(`Could not copy: ${err}`, {
											autoClose: 2000,
										});
									}
								)
						}
						className=" flex items-center gap-1 cursor-pointer ">
						<p className="font-Kumbh text-[#000] text-base font-bold ">
							Copy link{" "}
						</p>
						<div>
							<FaLink />
						</div>
					</div>

					<Link to="add-new-employee" className="">
						<AddNewBtn text="Add New" />
					</Link>
				</div>

				{/* <Link
          to="/add-new-employee"
          className=" flex items-center gap-3 justify-center bg-[#000] w-28 h-9 rounded-xl text-white"
        >
          <img
            className=" w-4 h-4"
            src={require("../../assets/add.png")}
            alt=""
          />
          <p className=" text-sm Satoshi text-white ">Add new</p>
        </Link> */}
			</div>

			<div className=" bg-white mx-12 p-5 mt-5 ">
				<div className=" flex items-center justify-between">
					<div className=" flex items-center gap-5">
						<div className=" flex items-center">
							<p className=" Satoshi font-normal text-sm text-[#000]">
								All Employees
							</p>
						</div>
						<div className=" flex items-center gap-3">
							<p className=" text-[#121212] font-normal  text-xs  font-Nunito">
								Filter staff
							</p>
							<div className="relative">
								<input
									className="w-48 h-9 pl-3 pr-9 bg-[#F5F7F8] rounded-md outline-none"
									type="text"
								/>
								<div className="absolute inset-y-0 right-0 flex items-center px-2">
									<FiChevronDown
										onClick={handleSeach}
										className="text-gray-500 cursor-pointer"
									/>
								</div>
								{isSearch && (
									<div
										onClick={closeSearch}
										className=" fixed inset-0  w-full h-full bg-black bg-opacity-45">
										<div className=" absolute top-72 left-60  grid gap-3 bg-white w-56 p-5 h-auto">
											<Link className=" cursor-pointer">
												<p className=" font-normal font-Nunito text-sm text-[#272525]">
													All staff
												</p>
											</Link>
											<Link className="  cursor-pointer">
												<p className=" font-normal font-Nunito text-sm text-[#272525]">
													Admin staff
												</p>
											</Link>
											<Link className="  cursor-pointer">
												<p className=" font-normal font-Nunito text-sm text-[#272525]">
													I.T staff
												</p>
											</Link>
											<Link className="  cursor-pointer">
												<p className=" font-normal font-Nunito text-sm text-[#272525]">
													Human Resources staff
												</p>
											</Link>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className=" flex items-center gap-3">
							<p className=" text-[#121212] font-normal  text-xs  font-Nunito">
								Search staff
							</p>
							<div className="relative">
								<input
									className="w-48 h-9 pr-10 pl-3 outline-none border border-[#acc3e7] rounded-md"
									type="text"
								/>
								<div className="absolute inset-y-0 right-3 flex items-center pl-3">
									<FaSearch className="text-gray-500" />
								</div>
							</div>
						</div>
					</div>
					<div className=" flex items-center gap-5">
						<p className=" text-[#000] text-xs font-Nunito font-medium">
							Showing
						</p>
						<p className=" border-[#0811D4] font-medium grid justify-center items-center border-[0.7px] h-6 w-6 font-Nunito text-[#0811D4] text-xs">
							{range}
						</p>
						<p className=" text-[#000] text-xs font-Nunito">per page</p>
					</div>
				</div>
			</div>

			{/* Table */}
			<div className="bg-white mx-12">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							{tableHead.map((head, index) => (
								<th
									key={index}
									className=" px-4 py-4 font-Nunito text-left Satoshi text-[11px] font-medium text-gray-500 tracking-wider">
									{head}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{currentItems?.map((rowData, rowIndex) => (
							<tr key={rowIndex} className="border-t-[0.5px] border-t-gray-200">
								<td className="px-4 py-4 text-[11px] Satoshi border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowIndex + 1}
								</td>
								<td className="px-4 py-4 text-[11px] Satoshi border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.firstName} {rowData?.lastName}
								</td>
								<td className="px-4 py-4 text-[11px] Satoshi border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.employeeId}
								</td>
								<td className="px-4 py-4 text-[11px] Satoshi border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.department?.name}
								</td>
								<td className="px-4 py-4 text-[11px] Satoshi border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.position?.name}
								</td>
								<td className="px-4 py-4 text-[11px] Satoshi border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.level?.name}
								</td>
								<td className="px-4 py-4 text-[11px] Satoshi border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.email}
								</td>
								<td className="px-4 py-4 text-[11px] Satoshi border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowData?.phone}
								</td>
								<td className="px-4 py-4 text-[11px] Satoshi border-b-[0.5] text-[#0811D4] boray-200 ">
									<Link to="employee's-details" state={rowData}>
										...
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="mt-10 flex justify-center w-44 items-center mx-auto">
					<LoadMore
						next={data?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
			{/* End Table */}
		</div>
	);
};

export default ManageEmployees;
