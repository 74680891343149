import React, { useState } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import { AiOutlineClose } from "react-icons/ai";

const FileUploadPreview = () => {
  const [file, setFile] = useState(null);

  // Handle file selection
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  // Handle removing the file
  const removeFile = () => {
    setFile(null);
  };

  // Get file extension for the icon
  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };

  return (
    <div className="w-full">
      {/* File Input */}
      <input
        type="file"
        id="fileUpload"
        onChange={handleFileChange}
        className="hidden"
      />
      <label
        htmlFor="fileUpload"
        className={`block w-full p-3 rounded-lg cursor-pointer border-dashed border-[1.5px] 
          ${file ? "border-[#E0E0E0]" : "border-[#5608D4]"}
          text-center hover:bg-[#F7F7F7] transition`}
      >
        {file ? "Replace file" : "+ Upload File"}
      </label>

      {/* File Preview */}
      {file && (
        <div className="mt-4 flex items-center justify-between rounded-lg p-2">
          {/* File Icon and Name */}
          <div className="flex items-center gap-2">
            {/* File Icon */}
            <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center">
              <FileIcon
                extension={getFileExtension(file.name)}
                {...defaultStyles[getFileExtension(file.name)]}
              />
            </div>

            {/* File Details */}
            <div className="flex flex-col justify-center">
              <p className="text-sm font-medium text-[#121212]">{file.name}</p>
              <p className="text-xs text-[#606C80]">
                {(file.size / 1024).toFixed(2)} KB
              </p>
            </div>
          </div>

          {/* Remove File Button */}
          <button
            onClick={removeFile}
            className="text-[#C4C4C4] hover:text-[#FF4B4B] transition"
          >
            <AiOutlineClose size={18} />
          </button>
        </div>
      )}
    </div>
  );
};

export default FileUploadPreview;
