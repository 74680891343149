import React, { useState } from "react";
import DeletedSuccessfully from "./deletedSuccessfully";
import { Button } from "../buttons/Send";

const Delete = ({ onClose, title, loading, deleteFunc }) => {
	const [isDeleted, setIsDeleted] = useState(false);

	const handleDeleted = () => {
		setIsDeleted(!isDeleted);
	};
	const closeDeleted = () => {
		setIsDeleted(false);
	};

	return (
		<div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
			<div className=" bg-white w-72 h-auto rounded-md">
				<div className=" grid justify-center items-center py-10">
					<img
						className=" w-36 h-16"
						src={require("../../assets/Delete.png")}
						alt=""
					/>
				</div>
				<p className=" font-Nunito text-[#000] text-base font-bold text-center">
					Continue to delete ‘{title || "Item Type"}’
				</p>
				<p className=" text-[#000] font-Nunito text-sm text-center py-2 ">
					Action cannot be undone
				</p>
				<div className=" justify-center items-center flex gap-5 pt-10 pb-5">
					<button
						onClick={onClose}
						className=" border-[0.5px] text-[#0811D4] font-Nunito text-sm border-[#0811D4] rounded-md w-16 h-7">
						No
					</button>
					{/* <button
						onClick={handleDeleted}
						className="rounded-md w-16 h-7 text-white font-Nunito text-sm bg-gradient-to-b from-[#1E27AB] to-[#384295]">
						Yes
					</button> */}
					<Button
						css={
							"rounded-md w-16 h-7 text-white font-Nunito text-sm bg-gradient-to-b from-[#1E27AB] to-[#384295]"
						}
						children={"Yes"}
						onClick={
							deleteFunc ? () => deleteFunc(handleDeleted) : handleDeleted
						}
						loading={loading}
					/>
					{isDeleted && <DeletedSuccessfully onClose={closeDeleted} />}
				</div>
			</div>
		</div>
	);
};

export default Delete;
