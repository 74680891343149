import React, { useState, useEffect } from "react";
import messageone from "../../assets/messageone.png";
import messagetwo from "../../assets/messagetwo.png";
import left from "../../assets/left.png";
import right from "../../assets/right.png";
import arrowright from "../../assets/arrowright.png";
import cal from "../../assets/cal.png";
import RecentNotifications from "./RecentNotifications";
import notif from "../../assets/notif.png";
import postimg from "../../assets/postimg.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import useCompanyStore from "../../data/Stores/CompanyStore";
import { apiCall } from "../../data/useGenFetcher";

const EmployeeMessage = () => {
  const navigate = useNavigate();
  const notification = [
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
    {
      img: notif,
      title: "Your payment invoice request has been approved by Admin",
      min: "3min ago",
    },
  ];

  const announcements = [
    {
      postedBy: "Posted By: Admin",
      image: "",
      title: "Title Of Post",
      text: " Porro fugit consequatur ipsa voluptas neque omnis. Modi exinventore corrupti.",
      date: "2/7/2023",
      public: "Public",
    },
    {
      postedBy: "Posted By: Admin",
      image: "",
      title: "Title Of Post",
      text: " Porro fugit consequatur ipsa voluptas neque omnis. Modi exinventore corrupti.",
      date: "2/7/2023",
      public: "Public",
    },
  ];

  return (
    <div className=" flex justify-between gap-3 ">
      <div className=" w-1/2 space-y-5">
        <Celebrants />
        <div className=" bg-white rounded-lg px-4 py-2 h-[104px]">
          <div className=" justify-between items-center flex">
            <img className=" w-3 h-3" src={left} alt="" />
            <p className="text-[#191d23] text-[13px] font-bold Satoshi">
              Upcoming Holiday{" "}
            </p>
            <img className=" w-3 h-3" src={right} alt="" />
          </div>
          <div className=" flex justify-between items-center bg-[#fceff6] p-2 mt-3">
            <div className=" flex items-center gap-2">
              <img className=" w-5 h-5" src={cal} alt="" />
              <div className="">
                <p className=" Satoshi text-[10px] text-[#000000] font-bold">
                  1st October
                </p>
                <p className=" Satoshi text-[#000] text-[10px]">
                  Independent Day
                </p>
              </div>
            </div>
            <div>
              <div className=" bg-[#cccccc] h-8 w-[0.6px]"></div>
            </div>
            <div className=" flex items-center gap-2">
              <img className=" w-5 h-5" src={cal} alt="" />
              <div className="">
                <p className=" Satoshi text-[10px] text-[#000000] font-bold">
                  1st October
                </p>
                <p className=" Satoshi text-[#000] text-[10px]">
                  Independent Day
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className=" bg-white rounded-lg p-5">
          <div className=" justify-between items-center flex">
            <p className=" text-[13px] Satoshi text-[#191d23] font-bold">
              Recent Announcements
            </p>
            <p className=" Shatohi text-[10px] text-[#5608d4] cursor-pointer">
              View all
            </p>
          </div>

          <div>
            {announcements.map((i) => (
              <div className=" mt-5">
                <div className=" flex justify-between items-center">
                  <p>{i.postedBy}</p>
                  <p className=" font-Nunito bg-[#dbf6ff] px-2 py-1 text-[8px] rounded-full text-[#191d23]">
                    {i.public}
                  </p>
                </div>
                <div className=" flex items-center gap-2 mt-3">
                  <div className=" w-[30%]">
                    <img
                      className=" w-20 h-16 mt-[-10px]"
                      src={postimg}
                      alt=""
                    />
                  </div>
                  <div className=" w-[70%]">
                    <p className=" text-[12px] font-bold font-Kumbh text-[#000]">
                      {i.title}
                    </p>
                    <p className=" font-Nunito text-xs text-[#000]">{i.text}</p>
                    <div className=" float-right relative flex">
                      <p className=" text-[#686868] text-[8px]">{i.date}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className=" w-1/2 h-[670px] overflow-y-scroll hide-scrollbar">
        <RecentNotifications notification={notification} />
      </div>
    </div>
  );
};

export default EmployeeMessage;

export const Celebrants = () => {
  const navigate = useNavigate();
  const messages = [
    {
      img: messageone,
      Text: `${moment().format("MMMM")} birthday celebrants`,
      more: "Click to see more  ",
      event: "birthMonth",
    },
    {
      img: messagetwo,
      Text: "Work anniversaries",
      more: "Click to see more  ",
      link: "/anniversaries",
      event: "weddingAnniversaryMonth",
    },
  ];

  let { currentSelected } = useCompanyStore(),
    [birthday, setBirthDay] = useState(null),
    [anniversary, setAnniversary] = useState(null),
    getData = async (event) => {
      apiCall({
        type: "get",
        url: `/api/v1/user/users/${
          currentSelected || ""
        }?_populate=position&_populate=department&_populate=level
				&${event}=${moment().format("MM")?.toLowerCase()}&_limit=0`,
        getter: (d) =>
          event === "weddingAnniversaryMonth"
            ? setAnniversary(d?.data || d)
            : setBirthDay(d?.data || d),
        noToast: true,
      });
    };

  useEffect(() => {
    if (currentSelected) {
      getData("birthMonth");
      getData("weddingAnniversaryMonth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelected]);

  return (
    <>
      {messages.map((i) => (
        <div className=" bg-white rounded-lg flex items-center h-[91px] px-4 pt-2 pb-4 gap-2">
          <div className="flex -space-x-4">
            {(i?.event === "birthMonth" ? birthday : anniversary)?.docs
              ?.filter((it) => it?.avatar)
              ?.slice(0, 2)
              ?.map((ac, i) => (
                <img
                  key={i}
                  className="w-8 h-8 border-2 border-[#080FAE] rounded-full"
                  src={ac?.avatar}
                  alt={ac?.lastName}
                />
              ))}
            <span className="flex items-center justify-center w-8 h-8 text-xs font-medium text-white bg-[#080FAE] border-2 border-[#080FAE] rounded-full">
              +
              {(i?.event === "birthMonth" ? birthday : anniversary)
                ?.totalDocs || 0}
            </span>
          </div>
          {/* <img className=" w-12 h-6 " src={i.img} alt="" /> */}
          <div>
            <p className="Satoshi text-[13px] font-bold  text-[#1f2937]">
              {i.Text}
            </p>
            <div className=" flex items-center mt-2 gap-2">
              {" "}
              <Link
                to={i?.link || "/birthday-celebrants"}
                className="Inter text-[#4b5563] text-xs cursor-pointer"
              >
                {i.more}
              </Link>
              <img
                onClick={() => navigate("/birthday-celebrants")}
                className=" mt-1 w-[13.39px] h-[13.39px] cursor-pointer"
                src={arrowright}
                alt=""
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
