import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import { FaSearch } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import IdCardRequest from "../../../components/cards/IdCardRequest";
import { useRequestFormStore } from "../../../data/Stores/LoggerStore";
import { apiCall } from "../../../data/useGenFetcher";
import useAuthStore from "../../../data/Stores/Authstore";
import { LoadMore, MainPaginate } from "../../../components/buttons/Send";

const IdRequests = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// const [addFile, setAddFile] = useState(false);
	// const [addAction, setAddAction] = useState(false);
	const { user } = useAuthStore(),
		[showRequestsCard, setShowRequestsCard] = useState(user?.isAdmin);
	const [showMyRequestsCard, setShowMyRequestsCard] = useState(!user?.isAdmin);
	const [topText] = useState("Requests");
	const [showId, setShowId] = useState(false),
		items = useLocation()?.state;

	const handleId = () => {
		setShowId(!showId);
	};
	const closeId = () => {
		setShowId(false);
	};

	const handleRequestsClick = () => {
		setShowRequestsCard(true);
		setShowMyRequestsCard(false);
	};

	const handleMyRequestsClick = () => {
		setShowRequestsCard(false);
		setShowMyRequestsCard(true);
	};

	// const handleFile = () => {
	//   setAddFile(!addFile);
	// };
	// const handleAction = () => {
	//   setAddAction(!addAction);
	// };
	// const closeAction = () => {
	//   setAddAction(false);
	// };
	// const closeFile = () => {
	//   setAddFile(false);
	// };

	const tableHead = [
		"S/N",
		"Requestee",
		"Title",
		"Description",
		"Date",
		"Actions",
	];
	const tableData = [
		{
			SN: "1",
			Requestee: "Catherine James",
			Title: "Request Title",
			Description: "Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			Date: "4th April, 2024",
			Actions: "...",
		},
		{
			SN: "2",
			Requestee: "Catherine James",
			Title: "Request Title",
			Description: "Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			Date: "4th April, 2024",
			Actions: "...",
		},
		{
			SN: "3",
			Requestee: "Catherine James",
			Title: "Request Title",
			Description: "Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			Date: "4th April, 2024",
			Actions: "...",
		},
		{
			SN: "4",
			Requestee: "Catherine James",
			Title: "Request Title",
			Description: "Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			Date: "4th April, 2024",
			Actions: "...",
		},
		{
			SN: "5",
			Requestee: "Catherine James",
			Title: "Request Title",
			Description: "Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			Date: "4th April, 2024",
			Actions: "...",
		},
		{
			SN: "6",
			Requestee: "Catherine James",
			Title: "Request Title",
			Description: "Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			Date: "4th April, 2024",
			Actions: "...",
		},
		{
			SN: "7",
			Requestee: "Catherine James",
			Title: "Request Title",
			Description: "Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			Date: "4th April, 2024",
			Actions: "...",
		},
	];
	const tablesHead = [
		"S/N",
		"Title",
		"Description",
		"Date",
		"Status",
		"Actions",
	];
	const tablesData = [
		{
			tableSN: "1",

			tableTitle: "Request Title",
			tableDescription:
				"Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			tableDate: "4th April, 2024",
			tableStatus: "Pending",
			tableActions: "...",
		},
		{
			tableSN: "2",

			tableTitle: "Request Title",
			tableDescription:
				"Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			tableDate: "4th April, 2024",
			tableStatus: "Approved",
			tableActions: "...",
		},
		{
			tableSN: "3",

			tableTitle: "Request Title",
			tableDescription:
				"Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			tableDate: "4th April, 2024",
			tableStatus: "Pending",
			tableActions: "...",
		},
		{
			tableSN: "4",

			tableTitle: "Request Title",
			tableDescription:
				"Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			tableDate: "4th April, 2024",
			tableStatus: "Approved",
			tableActions: "...",
		},
		{
			tableSN: "5",

			tableTitle: "Request Title",
			tableDescription:
				"Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			tableDate: "4th April, 2024",
			tableStatus: "Pending",
			tableActions: "...",
		},
		{
			tableSN: "6",

			tableTitle: "Request Title",
			tableDescription:
				"Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			tableDate: "4th April, 2024",
			tableStatus: "Declined",
			tableActions: "...",
		},
		{
			tableSN: "7",

			tableTitle: "Request Title",
			tableDescription:
				"Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet",
			tableDate: "4th April, 2024",
			tableStatus: "Pending",
			tableActions: "...",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData || tablesData }),
		{ getLogger, mainSearch, isFound, data } = useRequestFormStore();

	let [loading, setLoading] = useState(false);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/requestForm?request=${
				items?._id
			}&_populate=createdBy&_populate=createdBy.profile${
				user?.isAdmin && showRequestsCard
					? ``
					: user?.isAdmin && showMyRequestsCard
					? `&createdBy=${user?._id}`
					: `&createdBy=${user?._id}`
			}`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, showMyRequestsCard, showRequestsCard]);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/requestForm?request=${
				items?._id
			}&_populate=createdBy&_populate=createdBy.profile${
				user?.isAdmin && showRequestsCard
					? ``
					: user?.isAdmin && showMyRequestsCard
					? `&createdBy=${user?._id}`
					: `&createdBy=${user?._id}`
			}&_limit=${data?.limit * data?.nextPage}`,
			getter: d => getLogger(d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						{items?.name} {topText}
					</p>
				</div>

				<div className=" bg-white mx-12 mt-5 p-5">
					<div>
						<div className=" bg-white  ">
							<div className=" flex items-center justify-between">
								<div className=" flex items-center gap-5">
									{user?.isAdmin && (
										<div className=" flex items-center gap-5">
											<p
												onClick={handleRequestsClick}
												className={`Satoshi font-normal cursor-pointer text-sm text-[#000] ${
													showRequestsCard === true
														? " text-[#5608D4]"
														: "bg-white text-[#1C1C1C]  "
												}`}>
												Requests
											</p>
											<p
												onClick={handleMyRequestsClick}
												className={`Satoshi font-normal cursor-pointer text-sm text-[#000] ${
													showMyRequestsCard === true
														? " text-[#5608D4]"
														: "bg-white text-[#1C1C1C]  "
												}`}>
												My Requests
											</p>
										</div>
									)}

									<div className=" flex items-center gap-3">
										<p className=" text-[#121212]  text-sm font-light font-Nunito">
											Search requests
										</p>
										<div className="relative">
											<input
												placeholder="Search"
												className="w-48 h-9 pr-10 pl-3 placeholder:text-xs outline-none bg-[#F5F7F8] rounded-md"
												type="text"
											/>
											<div className="absolute inset-y-0 right-3 flex items-center pl-3">
												<FaSearch className="text-gray-500" />
											</div>
										</div>
									</div>
								</div>
								<div className=" cursor-pointer">
									<button
										onClick={handleId}
										// onClick={handleAction}
										className=" text-sm Satoshi text-white bg-black rounded-md w-28 h-9 animate-pulse ">
										Create new
									</button>
									{showId && <IdCardRequest onClose={closeId} />}
									{/* {addAction && <ActionCompleted onClose={closeAction} />}
                </div>
                {addFile && <AddAsset closeFile={closeFile} />} */}
								</div>
							</div>

							{/* Cards */}
							{showRequestsCard && (
								<div className="card">
									<table className="min-w-full  mt-5">
										<thead className="bg-gray-50 border-b-[0.5px] border-b-gray-200">
											<tr>
												{tableHead.map((head, index) => (
													<th
														key={index}
														className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
														{head}
													</th>
												))}
											</tr>
										</thead>
										<tbody className="bg-white ">
											{currentItems?.map((rowData, rowIndex) => (
												<tr key={rowIndex} className="">
													<td className="px-4 py-4  text-xs  text-gray-800">
														{rowIndex + 1}
													</td>
													<td className="px-4 py-4  text-xs  text-gray-800">
														{rowData?.Requestee}
													</td>
													<td className="px-4 py-4 text-xs  text-gray-800">
														{rowData?.Title}
													</td>
													<td className="px-4 py-4 text-xs  text-gray-800">
														{rowData?.Description}
													</td>
													<td className="px-4 py-4 text-xs  text-gray-800">
														{rowData?.Date}
													</td>
													<Link
														state={rowData}
														to="request-details"
														className="px-4 py-4 text-xs  text-gray-800 cursor-pointer">
														...
													</Link>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}

							{showMyRequestsCard && (
								<div className="card">
									<table className="min-w-full  mt-5">
										<thead className="bg-gray-50 border-b-[0.5px] border-b-gray-200">
											<tr>
												{tablesHead.map((head, index) => (
													<th
														key={index}
														className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
														{head}
													</th>
												))}
											</tr>
										</thead>
										<tbody className="bg-white">
											{currentItems?.map((rowData, rowIndex) => (
												<tr key={rowIndex} className="">
													<td className="px-4 py-4  text-xs  text-gray-800">
														{rowIndex + 1}
													</td>

													<td className="px-4 py-4 text-xs  text-gray-800">
														{rowData?.tableTitle}
													</td>
													<td className="px-4 py-4 text-xs  text-gray-800">
														{rowData?.tableDescription}
													</td>
													<td className="px-4 py-4 text-xs  text-gray-800">
														{rowData?.tableDate}
													</td>
													<td className="px-4 py-4 text-xs  text-gray-800">
														{rowData?.tableStatus}
													</td>
													<Link
														to="request-details"
														state={rowData}
														className="px-4 py-4 text-xs  text-gray-800 cursor-pointer">
														...
													</Link>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}
							<div className="mt-10 flex justify-center w-44 items-center mx-auto">
								<LoadMore
									next={data?.hasNextPage}
									loading={loading === "loadmore"}
									handleLoadMore={handleLoadMore}
								/>
							</div>
							{pageCount > 1 && (
								<MainPaginate
									pageCount={pageCount}
									handlePageClick={handlePageClick}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IdRequests;
