import React from "react";

const AssignMembers = () => {
  return (
    <div>
      <div className=" mt-5 space-y-3">
        <p className=" text-sm text-[#121212] font-Nunito">Assign Members</p>
        <div className=" flex items-center gap-2 ">
          <input type="radio" />
          <p className=" font-Nunito text-xs text-[#5D5A5A]">All Staff</p>
        </div>
        <div className=" flex items-center gap-2 ">
          <input type="radio" />
          <p className=" font-Nunito text-xs text-[#5D5A5A]">Position</p>
        </div>
        <div className=" flex items-center gap-2 ">
          <input type="radio" />
          <p className=" font-Nunito text-xs text-[#5D5A5A]">Department</p>
        </div>
        <div className=" flex items-center gap-2 ">
          <input type="radio" />
          <p className=" font-Nunito text-xs text-[#5D5A5A]">Level</p>
        </div>
      </div>
    </div>
  );
};

export default AssignMembers;
