import React from "react";
import serchs from "../../assets/serchs.png";

const DashTable = () => {
  const tableHead = ["S/N", "Name", "Leave Type", "From - To", "Request"],
    tableData = [
      {
        SN: "1",
        Name: "Catherine Mark",
        LeaveType: "Unpaid - Sick Leave",
        FromTo: "25/03 - 12/04",
        Request: "10",
      },
      {
        SN: "2",
        Name: "Catherine Mark",
        LeaveType: "Unpaid - Sick Leave",
        FromTo: "25/03 - 12/04",
        Request: "10",
      },
      {
        SN: "3",
        Name: "Catherine Mark",
        LeaveType: "Unpaid - Sick Leave",
        FromTo: "25/03 - 12/04",
        Request: "10",
      },
    ];
  return (
    <div className=" grid ">
      <div className=" flex items-center pb-4 pt-10 gap-40 ">
        <p className=" text-sm font-bold text-[#000] Satoshi">All Jobs</p>
        <div className=" flex items-center relative">
          <input
            className=" border border-[#ACC3E7] w-48 h-9 rounded-lg outline-none px-2"
            type="text"
          />
          <img
            className=" w-4 h-4 absolute right-4 cursor-pointer"
            src={serchs}
            alt=""
          />
        </div>
      </div>
      <div className=" w-full overflow-x-scroll ">
        <table className="min-w-full table divide-y-2 divide-yellow-[#7E3AF2] h-full">
          <thead className="h-16 w-full ">
            <tr className="text-black">
              {tableHead?.map((it, index) => (
                <th className=" font-Nunito text-xs font-light" key={index}>
                  {it}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="divide-y">
            {tableData?.map((it, i) => (
              <tr key={i} className=" bg-white py-4 h-16    ">
                <td className=" px-4  font-Nunito text-xs Satoshi font-light text-center ">
                  {it?.SN}
                </td>
                <td className="  font-Nunito text-xs Satoshi font-light text-center">
                  {it?.Name}
                </td>
                <td className="  font-Nunito text-xs Satoshi font-light text-center">
                  {it?.LeaveType}
                </td>
                <td className="  font-Nunito text-xs Satoshi font-light text-center">
                  {it?.FromTo}
                </td>
                <td className="  font-Nunito text-xs Satoshi font-light text-center">
                  {it?.Request}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DashTable;
