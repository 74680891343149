// import React from "react";
// import JobNavbar from "../../components/job/JobNavbar";
// import JobSecNav from "../../components/job/JobSecNav";

// const JobDashboard = () => {
//   return (
//     <div>
//       <JobNavbar />
//       <JobSecNav />
//       <div>
//         <h1 className="text-2xl font-bold">Dashboard Page</h1>
//         <p>
//           Welcome to the dashboard. Here you can see your job overview and
//           statistics.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default JobDashboard;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import rafiki from "../../assets/rafiki.png";
import Groupone from "../../assets/Groupone.png";
import Grouptwo from "../../assets/Grouptwo.png";
import Groupthree from "../../assets/Groupthree.png";
import Groupfour from "../../assets/Groupfour.png";
// import DashTable from "../../components/DashTable";
// import JobCard from "../../components/JobCard";
import setup from "../../assets/setup.png";
// import Account from "../../components/AccountSetup";
import JobNavbar from "../../components/job/JobNavbar";
import JobSecNav from "../../components/job/JobSecNav";
import Accountsetup from "../account-setup";
import DashTable from "../../components/job/DashTable";
import JobCard from "../../components/job/JobCard";
// import Accountsetup from "../../pages/account-setup";

const JobDashboard = () => {
  const navigate = useNavigate();
  const [card, setCard] = useState(false);
  const [showAccount, setShowAccount] = useState(false);

  const cards = [
    {
      text: "Jobs Applied For",
      num: "20",
      img: Groupone,
    },
    {
      text: "Accepted",
      num: "05",
      img: Grouptwo,
    },
    {
      text: "Interviews",
      num: "05",
      img: Groupthree,
    },
    {
      text: "Rejected",
      num: "06",
      img: Groupfour,
    },
  ];
  return (
    <div className=" bg-[#F2EFFC]">
      <JobNavbar />
      <JobSecNav />

      <div className=" mx-10 mt-2 ">
        <div className="pl-5 pr-28 py-4 bg-[#fcf7ff] shadow-lg relative z-30 flex items-center justify-between ">
          <div className="  ">
            <p className="text-[#071C50] font-Nunito text-xl font-semibold">
              Senior Data Analyst
            </p>
            <p className="font-Nunito text-[13px] text-[#000] w-[700px]">
              Lorem ipsum dolor sit amet consectetur. Bibendum senectus
              consequat nibh purus. Fermentum urna lorem aliquam non quam
              faucibus id vitae. Nullam fermentum et massa arcu non id tincidunt
              interdum. Arcu proin ullamcorper viverra malesuada amet. Nulla
              neque sem arcu eget proin faucibus risus lorem. Duis et interdum
              viverra viverra duis aliquam sem. Turpis consectetur tortor sit
              sit morbi.
            </p>
            <button
              onClick={() => setCard(!card)}
              className="w-[190px] h-[38px] rounded-lg mt-5 text-sm bg-[#000] text-[#fff] Satoshi"
            >
              Continue to apply
            </button>
            {card && (
              <div
                onClick={() => setCard(false)}
                className="z-20 h-screen top-0 w-full left-0 justify-center grid items-center fixed"
              >
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>
                <JobCard
                  image={setup}
                  title="Account Setup"
                  description="To apply for this job, you have to setup your account."
                  buttonText="Update account"
                  onClick={() => navigate("/account-setup")} // Pass function to show Account component
                />
              </div>
            )}
          </div>

          <div>
            <img className=" w-[200px] h-[178.48px]" src={rafiki} alt="" />
          </div>
        </div>

        <div className=" bg-white p-5 mt-5">
          <div>
            <p className=" font-Kumbh text-[#000] text-base font-semibold">
              Welcome, Jane!
            </p>

            <div className="  grid items-center">
              <div className=" grid grid-cols-4">
                {cards.map((i) => (
                  <div className=" bg-white w-[275px] p-5 gap-4 justify-between h-36 rounded-lg shadow-md flex items-center">
                    <div className=" space-y-4">
                      <p className="Satoshi text-xl text-[#000]">{i.text}</p>
                      <p className=" text-3xl font-bold text-[#000]">{i.num}</p>
                    </div>
                    <div className="">
                      <img className=" w-16 h-16" src={i.img} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div>
            <DashTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDashboard;
