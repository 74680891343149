import React, { useState } from "react";
import JobNavbar from "../../components/job/JobNavbar";
import JobSecNav from "../../components/job/JobSecNav";
import JobTitleComponent from "../../components/job/JobTitleComponent";
import JobDescription from "../../components/job/JobDescription";
import PostedBy from "../../components/job/PostedBy";
import JobCard from "../../components/job/JobCard";
import { useNavigate } from "react-router-dom";
import setup from "../../assets/setup.png";

const JobDetails = () => {
  const navigate = useNavigate();
  const [appliedSucc, setAppliedSucc] = useState(false);
  return (
    <div className=" bg-[#F2EFFC]">
      <JobNavbar />
      <JobSecNav />

      <div className=" mx-10 mt-2 ">
        <div>
          <JobTitleComponent />
        </div>
        <div className=" flex items-center gap-5 ">
          <div className=" w-[70%]">
            <JobDescription />

            <div className=" mt-10 pb-5">
              <button
                onClick={() => setAppliedSucc(!appliedSucc)}
                className=" bg-[#000] text-white Satoshi text-sm w-44 h-9 rounded-md "
              >
                Apply now
              </button>
            </div>
            {appliedSucc && (
              <div>
                <div
                  onClick={() => setAppliedSucc(false)}
                  className="z-20 h-screen top-0 w-full left-0 justify-center grid items-center fixed"
                >
                  <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>
                  <JobCard
                    image={setup}
                    title="Applied Successfully!"
                    description="You have successfully applied to job ‘Job Title”!"
                    buttonText="Close"
                    onClick={() => navigate(false)} // Pass function to show Account component
                  />
                </div>
              </div>
            )}
          </div>
          <div className=" w-[30%]">
            <PostedBy />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
