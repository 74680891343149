import React, { useEffect, useState } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import PagesList from "../../../../components/pages-list/PagesList";
import DeleteEditDetails from "../../../../components/buttons/DeleteEditDetails";
import EditLeaveType from "../../../../components/cards/EditLeaveType";
import { useLocation, useNavigate } from "react-router-dom";
import { useLeaveTypeStore } from "../../../../data/Stores/LoggerStore";
import useCompanyStore from "../../../../data/Stores/CompanyStore";
import useErrorStore from "../../../../data/Stores/errorstore";
import { apiCall, numberWithCommas } from "../../../../data/useGenFetcher";
import { toast } from "react-toastify";
import moment from "moment";

const AnnualLeave = () => {
	let items = useLocation()?.state;
	let [state, setState] = useState(items),
		{ data, deleteLogger } = useLeaveTypeStore(),
		navigate = useNavigate();

	useEffect(() => {
		data?.docs?.map(it => it?._id === items?._id && setState(it));
	}, [data, items]);

	let [loading, setLoading] = useState(false),
		{ currentSelected } = useCompanyStore(),
		{ returnErrors } = useErrorStore();

	let handleSubmit = async e => {
		// e?.preventDefault();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/leaveType/${currentSelected}/${items?._id}`,
			type: "delete",
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response || response === "") {
			deleteLogger(items);
			if (e) e();
			navigate(-1);
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">
					Leave Details
				</p>
			</div>

			<div className=" bg-white mx-12 p-5 mt-5">
				<div className=" space-y-5 pr-80">
					<p className=" text-xl text-[#5608D4] font-Nunito font-bold">
						{state?.name}
					</p>
					<div className=" Satoshi text-sm text-[#000]">
						{state?.description}
					</div>
					<div className=" space-y-5">
						<div className=" flex items-center justify-between w-[500px]">
							<div className=" w-[200px] flex justify-start">
								<p className=" text-[#071C50] text-sm font-bold Satoshi">
									Date created -{" "}
								</p>
							</div>
							<div className=" w-[200px] flex justify-start">
								<p className="text-[#071C50] text-sm font-light Satoshi">
									{" "}
									{moment(state?.createdAt).format("Do MMMM, YYYY")}
								</p>
							</div>
						</div>
						<div className=" flex items-center justify-between w-[500px]">
							<div className=" w-[200px] flex justify-start">
								<p className=" text-[#071C50] text-sm font-bold Satoshi">
									Leave days -{" "}
								</p>
							</div>
							<div className=" w-[200px] flex justify-start">
								<p className="text-[#071C50] text-sm font-light Satoshi">
									{" "}
									{numberWithCommas(state?.days)} days
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="">
					<DeleteEditDetails
						editDetailsCard={<EditLeaveType />}
						deleteFunc={handleSubmit}
						loading={loading}
						title={items?.name}
					/>
				</div>
			</div>
		</div>
	);
};

export default AnnualLeave;
