import React from "react";

const Tools = () => {
  return (
    <div>
      <div className=" flex gap-3 items-center">
        <img
          className=" w-4 h-4"
          src={require("../../assets/boldIcon.png")}
          alt=""
        />
        <img
          className=" w-4 h-4"
          src={require("../../assets/italicIcon.png")}
          alt=""
        />
        <img
          className=" w-4 h-4"
          src={require("../../assets/underlineIcon.png")}
          alt=""
        />
        <img
          className=" w-6 h-4"
          src={require("../../assets/Frame441.png")}
          alt=""
        />
        <img
          className=" w-6 h-4"
          src={require("../../assets/Frame442.png")}
          alt=""
        />
        <img
          className=" w-6 h-4"
          src={require("../../assets/Frame443.png")}
          alt=""
        />
        <img
          className=" w-6 h-4"
          src={require("../../assets/attachment444.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default Tools;
