import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuthStore from "../data/Stores/Authstore";
import useGenFetcher from "../data/useGenFetcher";
import useErrorStore from "../data/Stores/errorstore";
import { Button } from "../components/buttons/Send";

const Home = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [state, setState] = useState({}),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		};
	const [show, setShow] = useState(false),
		{ isAuth, login, setUserFail } = useAuthStore(),
		{ loadUser } = useGenFetcher(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isAuth) {
			navigate("/");
		}
	});

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.username || !state?.password)
			return toast.info("Please fill all required fields");
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/auth/login`, { ...state });
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);

			login(res?.data?.data);
			loadUser();
			navigate("/");
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				returnErrors(error);
			} else {
				toast.error(err?.response?.data?.message);
			}
			setUserFail();
		}
		setLoading(false);
	};

	return (
		<div>
			<div className=" flex">
				<div className=" w-1/2 px-40 pt-16">
					<div className=" grid justify-center items-center">
						<img
							className=" w-24 h-24"
							src={require("../assets/CeHRlogo.png")}
							alt=""
						/>
					</div>
					<div className=" mt-8">
						<p className=" font-Nunito text-[#383838] text-sm">
							Welcome back!!
						</p>
						<p className=" font-bold text-[#272525] font-Nunito text-2xl">
							Please Sign In
						</p>
					</div>
					<div className=" space-y-5 mt-5">
						<div className="grid space-y-2">
							<label className=" font-Nunito text-sm text-[#121212]" htmlFor="">
								Email address
							</label>
							<input
								className=" border border-[#D0D0D0] rounded-lg h-12 px-2 outline-none"
								type="email"
								value={state?.username}
								onChange={textChange}
								name="username"
							/>
						</div>
						<div className="grid space-y-2">
							<label className=" font-Nunito text-sm text-[#121212]" htmlFor="">
								Password
							</label>
							<div className=" items-center flex  relative">
								<input
									placeholder="*****"
									className=" border w-full border-[#D0D0D0] placeholder-[#D0D0D0] rounded-lg h-12 px-2 outline-none"
									type={show ? "text" : "password"}
									value={state?.password}
									onChange={textChange}
									name="password"
								/>
								<img
									onClick={() => setShow(!show)}
									className=" w-6 h-6 absolute right-4 cursor-pointer"
									src={require("../assets/hide.png")}
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className=" flex items-center justify-between mt-3">
						<div className=" flex items-center gap-2">
							<input type="checkbox" />
							<p className=" text-[#515151] text-sm font-Nunito">Remember me</p>
						</div>
						<p className=" text-[#5608D4] text-sm font-Nunito cursor-pointer">
							I forgot my password
						</p>
					</div>

					<div className=" mt-20">
						<div className=" grid justify-center mb-3">
							<p className=" font-Nunito text-sm text-[#515151]">
								Don’t have an account?{" "}
								<span className=" text-[#FFB800] cursor-pointer">
									Register here
								</span>
							</p>
						</div>
						{/* <Link
							to="/dashboard"
							className=" rounded-lg w-full grid justify-center items-center bg-gradient-to-b from-[#070FBE] to-[#384295] font-Nunito text-sm text-white h-12">
							Sign In
						</Link> */}
						<Button
							children={"Sign In"}
							loading={loading}
							onClick={handleSubmit}
						/>
					</div>
				</div>
				<div className=" w-1/2 bg-gradient-to-b relative from-[#7D37EF] via-[#7D37EF] to-[#2D046E] py-16 px-10">
					<div className=" flex justify-end absolute right-0 top-0 ">
						<img
							className="w-[490px] h-52"
							src={require("../assets/Ellipse959.png")}
							alt=""
						/>
					</div>
					<div className="">
						<p className=" text-4xl Satoshi text-white font-bold">
							All You Need In One Tool
						</p>
						<p className=" text-white Satoshi text-base mt-5">
							Build Strength at the Center of Your Workforce. Experience the
							power of comprehensive HR management in one unified platform.
						</p>
					</div>

					<div className=" grid justify-center">
						<div className=" p-24">
							<img src={require("../assets/Illustration.png")} alt="" />
						</div>
					</div>
					<div className=" grid justify-center">
						<div>
							<img src={require("../assets/logGroup.png")} alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
