import React from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { FiChevronDown } from "react-icons/fi";

const CircularLoading = ({ progress }) => {
  return (
    <div className=" w-64 h-4 bg-[#EEEEF0] rounded-full relative">
      {/* Loading line */}
      <div
        className="h-full rounded-full bg-green-500"
        style={{ width: `${progress}%` }}
      ></div>
      {/* Progress percentage */}
      {/* <div className="absolute top-0 left-full -ml-2 text-gray-700 text-xs font-semibold">
        {`${progress}%`}
      </div> */}
    </div>
  );
};

const UpdateEmployee = () => {
  return (
    <div className=" bg-[#EFF6FC] pb-10">
      <Navbar />
      <PagesList />
      <div className="bg-[#F5F7F8] w-full  ">
        <div className=" mx-12 pt-4">
          <p className=" font-Kumbh text-base font-bold text-[#000]">
            Update Employee
          </p>
        </div>
        <div className=" mx-12 mt-5">
          <div className=" flex items-center justify-between">
            <div className=" flex items-center gap-5">
              {/*  */}
              <div className=" flex gap-2 items-center">
                <div className=" manrope text-xs text-white w-6 h-6 grid justify-center items-center rounded-full bg-slate-300">
                  1
                </div>
                <p className=" font-Nunito text-sm text-[#000]">
                  Personal Information
                </p>
              </div>
              {/*  */}
              <div className=" flex gap-2 items-center">
                <div className=" manrope text-xs text-white w-6 h-6 grid justify-center items-center rounded-full bg-slate-300">
                  2
                </div>
                <p className=" font-Nunito text-sm text-[#000]">Organization</p>
              </div>
              {/*  */}
              <div className=" flex gap-2 items-center">
                <div className=" manrope text-xs text-white w-6 h-6 grid justify-center items-center rounded-full bg-slate-300">
                  3
                </div>
                <p className=" font-Nunito text-sm text-[#000]">Education</p>
              </div>
              {/*  */}
              <div className=" flex gap-2 items-center">
                <div className=" manrope text-xs text-white w-6 h-6 grid justify-center items-center rounded-full bg-slate-300">
                  4
                </div>
                <p className=" font-Nunito text-sm text-[#000]">Experience</p>
              </div>
            </div>
            <div>
              <CircularLoading progress={70} />
            </div>
          </div>
        </div>

        <div className=" grid bg-white mx-12 mt-5 gap-10 p-5">
          <div className=" flex  ">
            <div className=" w-[55%] grid grid-cols-2 gap-5">
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  First name
                </label>
                <input
                  placeholder="Enter first name"
                  className=" border-[0.5px] placeholder-[#A3A3A3] pl-2 border-[#D0D0D0] rounded-md outline-none w-full h-12"
                  type="text"
                />
              </div>
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  Last name
                </label>
                <input
                  placeholder="Enter last name"
                  className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
                  type="text"
                />
              </div>
              {/*  */}
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  Personal email address
                </label>
                <input
                  placeholder="Enter email address"
                  className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
                  type="text"
                />
              </div>
              {/*  */}
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  Official Email
                </label>
                <input
                  placeholder="Official email"
                  className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
                  type="text"
                />
              </div>
              {/*  */}
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    placeholder="******"
                    className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
                    type="password"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center px-2">
                    <img
                      className=" w-3 h-2 cursor-pointer"
                      src={require("../../assets/passwordVector.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              {/*  */}
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  Phone number
                </label>
                <input
                  placeholder="Enter phone number"
                  className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
                  type="text"
                />
              </div>

              {/*  */}
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  Marital Status
                </label>
                <div className="relative">
                  <input
                    placeholder="Select status"
                    className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
                    type="text"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center px-2">
                    <FiChevronDown className="text-gray-500 cursor-pointer" />
                  </div>
                </div>
              </div>
              {/*  */}
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  Date of birth
                </label>
                <div className="relative">
                  <input
                    placeholder="dd/mm/yyyy"
                    className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
                    type="text"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center px-2">
                    <img
                      className=" w-4 h-4 cursor-pointer"
                      src={require("../../assets/calender.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {/*  */}
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  Employee ID
                </label>
                <input
                  placeholder="Staff ID"
                  className=" border-[0.5px] bg-[#D0D0D0] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
                  type="text"
                />
              </div>
              {/*  */}
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  Local Government Area
                </label>
                <input
                  placeholder="LGA"
                  className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
                  type="text"
                />
              </div>
              {/*  */}
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  State of Origin
                </label>
                <div className="relative">
                  <input
                    placeholder="State of origin"
                    className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
                    type="text"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center px-2">
                    <FiChevronDown className="text-gray-500 cursor-pointer" />
                  </div>
                </div>
              </div>
              {/*  */}
              <div className=" space-y-2">
                <label
                  htmlFor=""
                  className=" text-[#121212] text-sm font-Nunito"
                >
                  State of Residence
                </label>
                <div className="relative">
                  <input
                    placeholder="State of residence"
                    className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
                    type="text"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center px-2">
                    <FiChevronDown className="text-gray-500 cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-[45%] h-auto grid justify-center ">
              <div className=" border-[#E8E8E8] border-[0.5px] rounded-md bg-white w-80 h-3/5">
                <div className=" grid justify-center items-center py-10">
                  <img
                    className=" w-40 h-40"
                    src={require("../../assets/AddPhoto2.png")}
                    alt=""
                  />
                </div>
                <div className=" space-y-4">
                  <div>
                    <p className=" text-[#777777] text-sm font-Nunito font-light text-center">
                      Allowed format
                    </p>
                    <p className=" text-[#272525] text-sm font-Nunito font-bold text-center">
                      JPG, JPEG, and PNG
                    </p>
                  </div>
                  <div>
                    <p className="text-[#777777] text-sm font-Nunito font-light text-center">
                      Max file size
                    </p>
                    <p className=" text-[#272525] text-sm font-Nunito font-bold text-center">
                      2MB
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" grid justify-center items-center">
            <button className=" bg-gradient-to-b from-[#0811D4] to-[#384295] w-40 h-11 rounded-md text-white">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEmployee;
