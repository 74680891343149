import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons/Send";
import useAuthStore from "../../data/Stores/Authstore";
import moment from "moment";

const Profile = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const { user, logout } = useAuthStore(),
		navigate = useNavigate(),
		card = [
			{
				id: 1,
				text: "First Name",
				desc: user?.firstName,
			},
			{
				id: 2,
				text: "Gender",
				desc: user?.gender,
			},
			{
				id: 3,
				text: "Address",
				desc: user?.address,
			},
			{
				id: 4,
				text: "Last Name",
				desc: user?.lastName,
			},
			{
				id: 5,
				text: "Marrital Status",
				desc: user?.maritalStatus,
			},
			{
				id: 6,
				text: "Phone Number",
				desc: user?.phone,
			},
			{
				id: 7,
				text: "Birthday",
				desc: user?.dateOfBirth
					? moment(user?.dateOfBirth).format("Do MMMM, YYYY")
					: "",
			},
			{
				id: 7,
				text: "Email",
				desc: user?.email,
			},
		];
	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" mx-32 justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">Profile</p>

				<div className=" w-full relative">
					<div className=" bg-white relative z-20 p-5 rounded-3xl  ">
						<div className=" relative">
							<img
								className=" w-full h-56"
								src={require("../../assets/cover.png")}
								alt=""
							/>

							<div className=" absolute top-40 h-auto left-10 flex gap-4 items-end">
								<img
									className=" w-32 h-32"
									src={require("../../assets/userimg.png")}
									alt=""
								/>
								<div className=" pt-3">
									<p className=" font-Kumbh text-4xl font-bold text-[#1C1C1C]">
										{user?.firstName} {user?.lastName}
									</p>
									<p className=" text-[#515151] text-lg font-Kumbh">
										{user?.type}
									</p>
								</div>
							</div>
						</div>

						<div className=" p-5 grid lg:grid-cols-3 md:grid-cols-3  gap-10 mt-20">
							{card.map(i => (
								<div
									key={i.id}
									className=" w-64 h-auto bg-white shadow-lg rounded-3xl  grid items-center p-5">
									<div>
										<p className=" font-Nunito text-sm text-[#747474]">
											{i.text}
										</p>
										<p className=" font-Nunito text-base text-[#504F4F] font-semibold">
											{i.desc}
										</p>
									</div>
								</div>
							))}
						</div>

						<div className=" flex justify-end items-center absolute bottom-10 animate-pulse right-10 gap-3">
							<Link
								to="/edit-profile"
								className=" bg-gradient-to-b from-[#5608D4] to-[#384295] grid justify-center items-center h-14 w-40 rounded-lg text-[15px] text-[#fff] font-Nunito">
								Edit Profile
							</Link>
							<Button
								css={
									" bg-gradient-to-b from-[#5608D4] to-[#384295] flex justify-center items-center h-14 w-40 rounded-lg text-[15px] text-[#fff] font-Nunito"
								}
								children={"LogOut"}
								onClick={() => {
									logout();
									navigate("/");
								}}
							/>
						</div>
					</div>
					<div className=" flex justify-end items-end relative bottom-36 left-4  ">
						<div className=" bg-gradient-to-b from-[#5608D4] to-[#384295] w-28 h-40 rounded-br-3xl"></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;
