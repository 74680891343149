import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { Link } from "react-router-dom";
import ActionCompleted from "../../components/cards/ActionCompleted";
import ProfileDone from "../../components/cards/ProfileDone";

const Profile = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isSubmit, setISubmit] = useState(false);

  const handleSubmit = () => {
    setISubmit(!isSubmit);
  };
  const closeSubmit = () => {
    setISubmit(false);
  };

  return (
    <div className=" bg-[#EFF6FC] pb-10">
      <Navbar />
      <PagesList />

      <div className=" mx-32 justify-between items-center px-12 pt-4">
        <div className=" flex items-center gap-5">
          <Link to="/profile">
            <img
              className=" w-6 h-6"
              src={require("../../assets/material-symbols.png")}
              alt=""
            />
          </Link>
          <p className=" font-Kumbh text-[#000] text-base font-bold">Profile</p>
        </div>

        <div className=" w-full relative mt-5">
          <div className=" bg-white relative shadow-2xl z-20 p-10 rounded-3xl flex gap-x-10 gap-y-3  ">
            <div className=" w-1/2 space-y-3">
              <div className=" h-20 bg-white shadow-lg">
                <input
                  className=" w-full h-full font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder="First Name"
                  type="text"
                />
              </div>
              <div className=" h-20 bg-white shadow-lg">
                <input
                  className=" w-full h-full font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder="Last Name"
                  type="text"
                />
              </div>
              <div className=" h-20 bg-white shadow-lg">
                <input
                  className=" w-full h-full font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder="Address"
                  type="text"
                />
              </div>
              <div className=" h-20 bg-white shadow-lg">
                <input
                  className=" w-full h-full font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder="Gender"
                  type="text"
                />
              </div>
              <div className=" h-20 bg-white shadow-lg">
                <input
                  className=" w-full h-full font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder="Marrital Status"
                  type="text"
                />
              </div>
              <div className=" h-20 bg-white shadow-lg">
                <input
                  className=" w-full h-full font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder="Current Role"
                  type="text"
                />
              </div>
            </div>

            <div className=" w-1/2 space-y-3">
              <div className=" h-20 bg-white shadow-lg p-5">
                <label
                  className=" text-sm font-Nunito text-[#667085] "
                  htmlFor=""
                >
                  Phone Number
                </label>
                <input
                  className=" w-full outline-none font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder=" +234 000-000-0000"
                  type="phonenumber"
                />
              </div>
              <div className=" h-20 bg-white shadow-lg p-5">
                <label
                  className=" text-sm font-Nunito text-[#667085] "
                  htmlFor=""
                >
                  Birthday
                </label>
                <input
                  className=" w-full outline-none font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder=" DD/ MM/ YYYY"
                  type="date"
                />
              </div>

              <div className=" h-20 bg-white shadow-lg">
                <input
                  className=" w-full h-full font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder="Email"
                  type="email"
                />
              </div>

              {/* <div className=" h-20 bg-white shadow-lg">
                <label
                  className=" text-sm font-Nunito text-[#667085] "
                  htmlFor=""
                >
                  Phone Number
                </label>
                <input
                  className=" w-full h-full font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder="Current Role"
                  type="+234 000-000-0000"
                />
              </div>
              <div className=" h-20 bg-white shadow-lg">
                <input
                  className=" w-full h-full font-Nunito placeholder:text-xs placeholder-[#667085] px-2"
                  placeholder="Current Role"
                  type="Email"
                />
              </div> */}
            </div>
            <div className=" flex justify-end items-center absolute bottom-10 animate-pulse right-10">
              <button
                onClick={handleSubmit}
                className=" cursor-pointer bg-gradient-to-b from-[#5608D4] to-[#384295] grid justify-center items-center h-14 w-40 rounded-lg text-[15px] text-[#fff] font-Nunito"
              >
                Submit
              </button>
            </div>
            {isSubmit && <ProfileDone onClose={closeSubmit} />}
          </div>
          <div className=" flex justify-end items-end relative bottom-36 left-4 ">
            <div className=" bg-gradient-to-b from-[#5608D4] to-[#384295] w-28 h-40 rounded-br-3xl"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
