import React from "react";

const Option = ({ onClose }) => {
  const options = [
    {
      id: 1,
      img: require("../../assets/paragraph1.png"),
      text: "Short answer text",
    },
    {
      id: 2,
      img: require("../../assets/paragraph2.png"),
      text: "Paragraph",
    },
    {
      id: 3,
      img: require("../../assets/paragraph3.png"),
      text: "Multiple Choices",
    },
    {
      id: 4,
      img: require("../../assets/paragraph4.png"),
      text: "Check boxes",
    },
    {
      id: 5,
      img: require("../../assets/paragraph5.png"),
      text: "Dropdown",
    },
    {
      id: 6,
      img: require("../../assets/paragraph6.png"),
      text: "File Upload",
    },
    {
      id: 7,
      img: require("../../assets/paragraph7.png"),
      text: "Date",
    },
    {
      id: 8,
      img: require("../../assets/paragraph8.png"),
      text: "Time",
    },
  ];
  return (
    <div>
      <div
        onClick={onClose}
        className=" fixed top-0 left-0 w-full h-full bg-black bg-opacity-25 flex justify-end  "
      >
        <div className=" bg-white w-48 right-28 top-40 relative h-[285px] space-y-4 p-5  ">
          {options.map((i) => (
            <div className=" flex items-center gap-2 cursor-pointer">
              <img className=" w-4 h-4 animate-pulse" src={i.img} alt="" />
              <p className=" font-Nunito text-xs text-[#000]">{i.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Option;
