import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./Authstore";

const LeaveRequestStore = set => ({
	data: null,
	status: "",
	getSearchLeaveRequest: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchLeaveRequest"
		);
	},
	getSearch: payload => {
		set({ data: payload?.data || payload }, false, "getSearch");
	},
	resetLeaveRequestSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getLeaveRequest: payload => {
		set({ data: payload?.data || payload }, false, "getLeaveRequest");
	},
	setCurrentLeaveRequest: payload => {
		set(
			() => ({
				currentSelected: payload?._id || payload,
			}),
			false,
			"setCurrentLeaveRequest"
		);
	},
	addLeaveRequest: payload => {
		let data = payload?.data || payload;

		set(
			state => ({
				data: {
					...state?.data,
					docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
					totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
					docsTotal: state?.data?.docsTotal ? 1 + state?.data?.docsTotal : 1,
				},
				status: "added",
			}),
			false,
			"addLeaveRequest"
		);
	},
	deleteLeaveRequest: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: DeleteData(state?.data?.docs, data),
					totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
					docsTotal: state?.data?.docsTotal ? state?.data?.docsTotal - 1 : 0,
				},
				status: "deleted",
			}),
			false,
			"deleteLeaveRequest"
		);
	},
	updateLeaveRequest: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: EditData(state?.data?.docs, data),
				},
				status: "updated",
			}),
			false,
			"editLeaveRequest"
		);
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutLeaveRequest: () => {
		set({
			status: "",
			isFound: null,
			data: null,
			mainSearch: null,
			allLeaveRequest: null,
		});
	},
});

const useLeaveRequestStore = create(
	devtools(
		persist(LeaveRequestStore, {
			name: "leave-request",
		})
	)
);

export const useMyLeaveRequestStore = create(
	devtools(
		persist(LeaveRequestStore, {
			name: "my-leave-request",
		})
	)
);

export default useLeaveRequestStore;
