import React, { useState } from "react";
import { AllDocuments } from "./AllFiles";
import { ActionsFile } from "./Actions";
import FileTable from "./FileTable";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiFolderOn } from "react-icons/ci";

const ListView = () => {
  const [openModalId, setOpenModalId] = useState(null);

  const toggleModal = (type, id) => {
    const modalId = `${type}-${id}`;
    setOpenModalId(openModalId === modalId ? null : modalId);
  };

  const folders = [
    {
      id: 1,
      name: "Internal Surveys & Feedback Forms",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 2,
      name: "Project Reports",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 3,
      name: "Team Meeting Notes",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
  ];

  const files = [
    {
      id: 1,
      name: "Data Privacy and Security Plans.docs",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 2,
      name: "Data Privacy and Security Policies.pptx",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 3,
      name: "Data Privacy and Security Plans.docs",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
  ];

  return (
    <div>
      {/* Folder Table */}
      <FileTable>
        {folders.map((item) => (
          <tr
            key={item.id}
            className="text-sm text-[#121212] border-b border-[#EBEEF2] hover:bg-[#F5F5F5] relative"
          >
            <td className="py-4 px-4 flex items-center gap-3">
              <CiFolderOn size={20} className="text-[#5608D4]" />
              <span className="text-[11px] font-Satoshi font-[300]">
                {item.name}
              </span>
            </td>
            <td className="text-[14px] text-[#667085] font-Inter font-[400] py-4 px-4">
              {item.owner}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.createdOn}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.lastModified}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.modifiedBy}
            </td>
            <td className="text-[14px] py-4 px-4 text-left">
              <BsThreeDotsVertical
                className="text-[#5608D4] cursor-pointer"
                onClick={() => toggleModal("folder", item.id)}
              />
              {/* Action Modal for Folders */}
              {openModalId === `folder-${item.id}` && (
                <ActionsFile onClose={() => setOpenModalId(null)} />
              )}
            </td>
          </tr>
        ))}
      </FileTable>

      <AllDocuments files={files} />

      {/* File Table */}
      <FileTable>
        {files.map((item) => (
          <tr
            key={item.id}
            className="text-sm text-[#121212] border-b border-[#EBEEF2] hover:bg-[#F5F5F5] relative"
          >
            <td className="py-4 px-4 flex items-center gap-3">
              <CiFolderOn size={20} className="text-[#5608D4] text-xl" />
              <span className="text-[11px] font-Satoshi font-[300]">
                {item.name}
              </span>
            </td>
            <td className="text-[14px] text-[#667085] font-Inter font-[400] py-4 px-4">
              {item.owner}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.createdOn}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.lastModified}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.modifiedBy}
            </td>
            <td className="py-4 px-4 text-left">
              <BsThreeDotsVertical
                className="text-[#5608D4] cursor-pointer"
                onClick={() => toggleModal("file", item.id)}
              />
              {/* Action Modal for Files */}
              {openModalId === `file-${item.id}` && (
                <ActionsFile onClose={() => setOpenModalId(null)} />
                 
              )}
            </td>
          </tr>
        ))}
      </FileTable>
    </div>
  );
};

export default ListView;
