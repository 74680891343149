import { Route, Routes } from "react-router-dom";
import PageRender from "./PageRender";
import Index from "./pages/index";
import useAuthStore from "./data/Stores/Authstore";
import Home from "./screens/home";
import { ToastContainer } from "react-toastify";
import { Button, ModalContainer } from "./components/buttons/Send";
import useErrorStore from "./data/Stores/errorstore";

const Routers = () => {
	const { isAuth } = useAuthStore(),
		{ error, clearErrors } = useErrorStore();

	return (
		<>
			<ToastContainer position="bottom-left" />
			<Routes>
				<Route path="/" element={isAuth ? <Index /> : <Home />} />
				<Route path="/:page" element={<PageRender />} />
				<Route path="/:page/:id" element={<PageRender />} />
				<Route path="/:page/:id/:step" element={<PageRender />} />
				<Route path="/:page/:id/:step/:four" element={<PageRender />} />
			</Routes>
			<ModalContainer
				show={error?.length > 0}
				title="Error"
				width={"max-w-md"}
				close={() => clearErrors()}>
				<div className="downH2 flex flex-col">
					{error?.map((item, i) => (
						<p key={i} className="fw-bold Lexend w-100">
							<span className="fontInherit me-2">
								{error?.length !== 1 && <>{i + 1}.</>}
							</span>{" "}
							{item?.message
								? item?.message
								: typeof item === "string"
								? item
								: null}
						</p>
					))}
					<div className="flex justify-center items-center py-4">
						<Button children={"Close"} onClick={() => clearErrors()} />
					</div>
				</div>
			</ModalContainer>
		</>
	);
};

export default Routers;
