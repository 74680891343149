import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import SideBar from "../../components/cards/SideBar";
import StartTracker from "../../components/cards/StartTracker";

const Tasks = () => {
  const [isStart, setIsStart] = useState(false);

  const handleStart = () => {
    setIsStart(!isStart);
  };
  const closeStart = () => {
    setIsStart(false);
  };
  const card = [
    {
      id: 1,
      img: require("../../assets/dot.png"),
      work: "Weekly Activity",
      num: " 75%",
      image: require("../../assets/task1.png"),
      width: "80px",
      height: "70px",
    },
    {
      id: 2,
      img: require("../../assets/dot.png"),
      work: "Worked this week",
      num: " 30:00:05",
      image: require("../../assets/task2.png"),
      width: "73px",
      height: "70px",
    },
    {
      id: 3,
      img: require("../../assets/dot.png"),
      work: "Project Worked On",
      num: " 05",
      image: require("../../assets/task3.png"),
      width: "73px",
      height: "70px",
    },
  ];

  const todo = [
    {
      id: 1,
      text: "Design a wireframe for Mobile App",
      Donations: "Donations",
      Social: "Social",
      date: "May 20, 2024",
    },
    {
      id: 2,
      text: "Design a wireframe for Mobile App",
      Donations: "Donations",
      Social: "Social",
      date: "May 20, 2024",
    },
    {
      id: 3,
      text: "Design a wireframe for Mobile App",
      Donations: "Donations",
      Social: "Social",
      date: "May 20, 2024",
    },
    {
      id: 4,
      text: "Design a wireframe for Mobile App",
      Donations: "Donations",
      Social: "Social",
      date: "May 20, 2024",
    },
    {
      id: 5,
      text: "Design a wireframe for Mobile App",
      Donations: "Donations",
      Social: "Social",
      date: "May 20, 2024",
    },
  ];

  const project = [
    {
      id: 1,
      projectText: "Project one",
      time: "00:10:05",
    },
    {
      id: 2,
      projectText: "Project two",
      time: "00:10:05",
    },
  ];
  return (
    <div className=" bg-[#EFF6FC] pb-10">
      <Navbar />
      <PagesList />
      <div className="bg-[#F5F7F8] w-full  ">
        <div className=" mx-12 pt-4">
          <p className=" font-Kumbh text-base font-bold text-[#000]">
            Tasks management
          </p>
        </div>

        <div className=" bg-white mx-12 p-5 mt-5">
          <div className=" flex gap-5">
            <div>
              <SideBar />
            </div>
            <div className=" w-full">
              <div className=" flex items-center justify-between">
                <div>
                  <p className=" text-xl Satoshi text-[#000]">Tasks Overview</p>
                </div>
                <div
                  onClick={handleStart}
                  className=" bg-[#5318FA] cursor-pointer animate-pulse text-white w-36 h-10 rounded-lg flex items-center gap-3 justify-center"
                >
                  <img
                    className=" w-4 h-4"
                    src={require("../../assets/clock.png")}
                    alt=""
                  />
                  <p className=" text-white Satoshi text-sm">Start Tracker</p>
                </div>
                {isStart && <StartTracker onClose={closeStart} />}
              </div>

              {/*  */}
              <div className=" grid grid-cols-3 ">
                {card.map((i) => (
                  <div
                    key={i.id}
                    className=" bg-[#fff] w-72 h-auto rounded-2xl shadow-xl p-5"
                  >
                    <div className=" flex justify-end items-center">
                      <img
                        className=" w-[4.22px] h-[13.33px] cursor-pointer"
                        src={i.img}
                        alt=""
                      />
                    </div>

                    <div className=" flex items-center mt-3 justify-between">
                      <div className=" space-y-5  ">
                        <p className=" Satoshi text-base text-[#000]">
                          {i.work}
                        </p>
                        <p className=" font-bold text-3xl Satoshi text-[#000]">
                          {i.num}
                        </p>
                      </div>
                      <img
                        style={{ width: i.width, height: i.height }}
                        className="  "
                        src={i.image}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/*  */}
              <div className=" flex mt-5 justify-between ">
                <div>
                  <div>
                    <p className=" text-base Satoshi text-[#000]">Activities</p>
                  </div>
                  <div className=" flex items-center mt-5 gap-5">
                    <div className=" bg-[#141522] space-y-10 w-48 h-auto text-white rounded-lg p-5">
                      <div>
                        <p className=" Satoshi text-base">Running Tasks</p>
                        <p className=" text-xl Satoshi text-[#fff]">O5</p>
                      </div>
                      <div className=" flex items-center gap-3">
                        <img
                          className=" w-16 h-16"
                          src={require("../../assets/load.png")}
                          alt=""
                        />
                        <div className=" space-y-2">
                          <p className=" text-white Satoshi text-base">100</p>
                          <p className=" text-[#8E92BC] Satoshi text-sm ">
                            Task
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className=" bg-[#F5F5F7] w-[360px] py-6 rounded-lg pl-4 pr-2">
                        <div className=" flex items-center justify-between">
                          <p className=" Satoshi text-base text-[#141522]">
                            Activity
                          </p>
                          <div className=" flex gap-2 items-center justify-center">
                            <p className=" Satoshi text-xs text-[#141522]">
                              This Week
                            </p>
                            <img
                              className=" w-3 h-3"
                              src={require("../../assets/arrow-down.png")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className=" mt-5">
                          <img
                            className=""
                            src={require("../../assets/Activity.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Project */}
                  <div className=" mt-5">
                    <div className=" flex items-center justify-between">
                      <p className=" text-lg text-[#000] Satoshi">Project</p>
                      <img
                        className=" w-[4px] h-[14.35px]"
                        src={require("../../assets/dots.png")}
                        alt=""
                      />
                    </div>
                    <div className=" space-y-3">
                      {project.map((i) => (
                        <div
                          key={i.id}
                          className=" flex justify-between items-center bg-white shadow-lg rounded-lg p-5 "
                        >
                          <div className=" flex items-center gap-5">
                            <img
                              className=" w-9 h-9"
                              src={require("../../assets/files.png")}
                              alt=""
                            />
                            <p className=" font-bold text-[#000] Satoshi text-[15px]">
                              {i.projectText}
                            </p>
                          </div>
                          <div className=" flex items-center gap-5">
                            <button className=" bg-[#F2E8FF] text-[#000] pl-3 w-24 h-10 rounded-xl Satoshi text-[13px]">
                              {i.time}
                            </button>
                            <div className=" w-44 h-[3px] rounded-full bg-[#DFDFDF]"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className=" w-72 border h-auto p-5 mr-5">
                    <div className=" flex justify-between items-center pb-2 border-b w-full border-b-[#E6E4F0]">
                      <div className=" flex items-center gap-2">
                        <img
                          className=" w-5 h-5"
                          src={require("../../assets/Todos.png")}
                          alt=""
                        />
                        <p className=" text-[#56555C] Satoshi text-sm">Todos</p>
                      </div>
                      <div>
                        <img
                          className=" w-4 h-4"
                          src={require("../../assets/pluse.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className=" mt-5 space-y-3">
                      {todo.map((i) => (
                        <div
                          key={i.id}
                          className="  bg-white p-3 shadow-lg rounded-2xl space-y-3"
                        >
                          <div className=" flex gap-2 items-center">
                            <input type="checkbox" />
                            <p className=" text-[#000] text-xs">
                              {" "}
                              <p>{i.text}</p>
                            </p>
                          </div>
                          <div className=" flex items-center justify-between">
                            <div className=" flex items-center gap-2">
                              <button className=" bg-[#e0aec5] w-20 h-6 text-[11px] rounded-2xl text-[#FD71AF]">
                                {i.Donations}
                              </button>
                              <button className="bg-[#a8c8bf] w-12 h-6 text-[11px] rounded-2xl text-[#00B884]">
                                {i.Social}
                              </button>
                            </div>
                            <p className="text-[#BBBABE] Satoshi text-[11px]">
                              {i.date}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tasks;
