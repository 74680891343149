import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { Link } from "react-router-dom";
import CreatePost from "../../components/cards/CreatePost";
import useAnnoucementStore from "../../data/Stores/AnnoucementStore";
import { useEffect } from "react";
import { apiCall } from "../../data/useGenFetcher";
import { LoadMore, MainPaginate } from "../../components/buttons/Send";
import moment from "moment";
import { ImageChange } from "../dashboard";
import Delete from "../../components/cards/Delete";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";

const Announcement = () => {
  const [createNew, setCreateNew] = useState(),
    [isDelete, setIsDelete] = useState(null);

  const handleCreateNew = () => {
    setCreateNew(!createNew);
  };

  const closeCreateNew = () => {
    setCreateNew(false);
  };
  const news = [
    {
      id: 1,
      img: require("../../assets/eng1.png"),
      likeimg: require("../../assets/Like.png"),
      like: "60",
      heartimg: require("../../assets/Heart.png"),
      heart: "24",
      Comment: "Comment",
      Commentimg: require("../../assets/Comment.png"),
      view: "2K",
      posted: "Posted By: Admin",
      Public: " Public",
      date: " 2/7/2023",
      title: " Title Of Post",
      text: " Porro fugit consequatur ipsa voluptas neque omnis. Modiex inventore corrupti. Placeat dolorem enim impedit nonut totam. Perspiciatis fugit ratione ut. Omnisvoluptatem repellat porro modi. Saepe id sunt maioresodio quam quaerat illum sunt aut.",
      userimg: require("../../assets/Ellipse12.png"),
    },
    {
      id: 2,
      img: require("../../assets/eng2.png"),
      likeimg: require("../../assets/Like.png"),
      like: "60",
      heartimg: require("../../assets/Heart.png"),
      heart: "24",
      Comment: "Comment",
      Commentimg: require("../../assets/Comment.png"),
      view: "2K",
      posted: "Posted By: Admin",
      Public: " Public",
      date: " 2/7/2023",
      title: " Title Of Post",
      text: " Porro fugit consequatur ipsa voluptas neque omnis. Modiex inventore corrupti. Placeat dolorem enim impedit nonut totam. Perspiciatis fugit ratione ut. Omnisvoluptatem repellat porro modi. Saepe id sunt maioresodio quam quaerat illum sunt aut.",
      userimg: require("../../assets/Ellipse122.png"),
    },
    {
      id: 3,
      img: require("../../assets/eng1.png"),
      likeimg: require("../../assets/Like.png"),
      like: "60",
      heartimg: require("../../assets/Heart.png"),
      heart: "24",
      Comment: "Comment",
      Commentimg: require("../../assets/Comment.png"),
      view: "2K",
      posted: "Posted By: Admin",
      Public: " Public",
      date: " 2/7/2023",
      title: " Title Of Post",
      text: " Porro fugit consequatur ipsa voluptas neque omnis. Modiex inventore corrupti. Placeat dolorem enim impedit nonut totam. Perspiciatis fugit ratione ut. Omnisvoluptatem repellat porro modi. Saepe id sunt maioresodio quam quaerat illum sunt aut.",
      userimg: require("../../assets/Ellipse12.png"),
    },
  ];

  let [datum, setDatum] = useState({ docs: news }),
    { getAnnoucement, mainSearch, isFound, data, deleteAnnoucement } =
      useAnnoucementStore();

  useEffect(() => {
    if (isFound) setDatum(mainSearch);
    else setDatum(data);
  }, [data, isFound, mainSearch]);

  useEffect(() => {
    apiCall({
      type: "get",
      url: `/api/v1/announcement?_populate=files&_populate=createdBy`,
      getter: (d) => getAnnoucement(d),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [itemOffset, setItemOffset] = useState(0);
  let [range] = useState(10);
  const endOffset = itemOffset + range,
    [loading, setLoading] = useState(false),
    { returnErrors } = useErrorStore();

  const handleLoadMore = async () => {
    setLoading("loadmore");
    await apiCall({
      type: "get",
      url: `/api/v1/announcement?_populate-files&_populate=createdBy&_limit=${
        data?.limit * data?.nextPage
      }`,
      getter: (d) => getAnnoucement(d),
    });
    setLoading(false);
  };

  let handleSubmit = async (e) => {
    // e?.preventDefault();

    setLoading(true);
    let { response, errArr, errMsg } = await apiCall({
      url: `/api/v1/announcement/${e?._id}`,
      type: "delete",
    });
    console?.log({ response, errArr, errMsg });
    if (errArr) {
      setLoading(false);
      return returnErrors(errArr);
    }
    if (errMsg) {
      setLoading(false);
      return toast.error(errMsg);
    }
    setLoading(false);
    if (response || response === "") {
      deleteAnnoucement(e);
      setIsDelete(!isDelete);
      return;
    }
    setLoading(false);
  };

  // if (!datum && !data) return <PageLoader />;
  if (!datum) return;

  const currentItems = datum?.docs?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(datum?.docs?.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % datum?.docs?.length;
    setItemOffset(newOffset);
  };

  return (
    <div className=" bg-[#fff] pb-10">
      <Navbar />
      <PagesList />
      <div className="bg-[#F5F7F8] w-full  ">
        <div className=" mx-12 pt-4 flex gap-5">
          <Link
            to="/onboarding"
            className="  font-Kumbh cursor-pointer text-base font-medium text-[#000]"
          >
            Announcement
          </Link>
        </div>
        <div className=" bg-white mx-12 p-5 mt-5">
          <div className=" justify-between items-center flex">
            <p className=" text-sm Satoshi text-[#000] ">Recent Posts</p>
            <button
              onClick={handleCreateNew}
              className=" w-28 h-11 rounded-xl text-white bg-black animate-pulse"
            >
              Create new
            </button>
            {createNew && <CreatePost onClose={closeCreateNew} />}
          </div>
          <div className=" mt-5 gap-10 grid">
            {currentItems?.map((i, ind) => (
              <div key={ind} className=" flex justify-center gap-5">
                <div className=" w-2/5 grid">
                  {/* <img src={i?.files?.[0]?.url} alt="" /> */}
                  <ImageChange item={i?.files} />
                  <div className=" flex justify-between items-center my-3">
                    <div className=" flex items-center gap-3">
                      <div className=" flex items-center gap-1">
                        <img
                          className=" w-6 h-6"
                          src={require("../../assets/Like.png")}
                          alt=""
                        />
                        <p>{i?.like}</p>
                      </div>
                      <div className=" flex items-center gap-1">
                        <img
                          className=" w-6 h-6"
                          src={require("../../assets/Heart.png")}
                          alt=""
                        />
                        <p>{i?.heart}</p>
                      </div>
                    </div>
                    <div className=" flex items-center gap-3">
                      <div className=" flex items-center gap-1">
                        <img
                          className=" w-6 h-6"
                          src={require("../../assets/Comment.png")}
                          alt=""
                        />
                        <p>{i?.Comment} </p>
                      </div>
                      <div>
                        <p className=" font-Nunito text-base text-[#0811D4] font-medium">
                          {i?.view}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-3/5 gap-20">
                  <div className=" flex">
                    <div className=" w-full">
                      <div className=" justify-between items-center flex">
                        <div className=" flex items-center gap-5">
                          <p className="Satoshi text-black text-sm">
                            Posted by{" "}
                            {i?.createdBy?.lastName ||
                              i?.createdBy?.profile?.lastName ||
                              "Admin"}{" "}
                            {i?.createdBy?.firstName ||
                              i?.createdBy?.profile?.firstName}{" "}
                            :
                          </p>
                          <p className=" bg-[#DBFFE3] w-[86px] text-[#191D23] font-Nunito h-7 rounded-full grid justify-center items-center">
                            Public
                          </p>
                        </div>

                        <p>
                          <DateReturned item={i} />
                        </p>
                      </div>
                      <div className=" mt-5">
                        <p className=" text-[#000] text-xl font-Kumbh font-bold">
                          {i?.title}
                        </p>
                        <p className=" text-sm font-Nunito text-[#000] mt-3 lg:w-[500px]">
                          {i?.description}
                        </p>
                      </div>
                    </div>
                    <img
                      className=" w-12 h-12"
                      src={require("../../assets/Ellipse122.png")}
                      alt=""
                    />
                  </div>
                  <div className=" mt-32">
                    <button
                      onClick={() => setIsDelete(!isDelete)}
                      className=" text-[#D4082D] font-Nunito text-sm font-light border-[0.5px] border-[#D4082D] w-40 h-11 rounded-md "
                    >
                      Delete Post
                    </button>
                    {isDelete && (
                      <Delete
                        onClose={() => setIsDelete(!isDelete)}
                        deleteFunc={() => handleSubmit(i)}
                        title={`${i?.title || ""}`}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10 flex justify-center w-44 items-center mx-auto">
            <LoadMore
              next={data?.hasNextPage}
              loading={loading === "loadmore"}
              handleLoadMore={handleLoadMore}
            />
          </div>
          {pageCount > 1 && (
            <MainPaginate
              pageCount={pageCount}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Announcement;

export const DateReturned = ({ item }) => {
  return (
    <>
      {moment(item?.createdAt).diff(moment(), "years") < 0
        ? moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")
        : moment(item?.createdAt).diff(moment(), "months") < 0
        ? moment(item?.createdAt).format("DD/MM hh:mm A")
        : moment(item?.createdAt).diff(moment(), "days") < 0
        ? moment(item?.createdAt).format("DD/MM hh:mm A")
        : moment(item?.createdAt).format("hh:mm A")}
    </>
  );
};
