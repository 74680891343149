import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import ActionCompleted from "./ActionCompleted";

const AddAsset = ({ closeFile }) => {
  const [isCreate, setIsCreate] = useState(false);

  const handleCreate = () => {
    setIsCreate(!isCreate);
  };
  const closeCreate = () => {
    setIsCreate(false);
  };
  return (
		<div>
			<div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
				<div className=" bg-white w-[700px] h-auto rounded-md p-5">
					<div className=" flex items-center justify-between ">
						<p className=" font-Kumbh text-xl text-[#000] font-bold">
							Add Asset
						</p>
						<p
							onClick={closeFile}
							className="font-Kumbh animate-pulse text-3xl text-[#000] font-bold cursor-pointer">
							&times;
						</p>
					</div>
					<div className=" grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 mt-5">
						<div className=" space-y-3">
							<label
								className=" font-Nunito  text-sm font-normal text-[#121212]"
								htmlFor="">
								Asset Name
							</label>
							<input
								placeholder="Name"
								className=" w-full h-12 rounded-xl pl-4 border-[0.5px] outline-none border-[#D0D0D0] "
								type="text"
							/>
						</div>
						<div className=" space-y-3">
							<label
								className=" font-Nunito  text-sm font-normal text-[#121212]"
								htmlFor="">
								Asset ID
							</label>
							{/* <input
                            placeholder="Choose recipient"
                            className=" w-full h-12 rounded-xl pl-4"
                            type="text"
                          /> */}
							<div className="relative">
								<input
									placeholder="ID"
									className="border-[0.5px] border-[#D0D0D0] placeholder-[#A3A3A3]  pr-9  outline-none w-full h-12 rounded-xl pl-4"
									type="text"
								/>
								<div className="absolute inset-y-0 right-0 flex items-center px-2">
									<FiChevronDown className="text-gray-500 cursor-pointer" />
								</div>
							</div>
						</div>
					</div>
					<div className=" space-y-3 mt-5">
						<label
							className=" font-Nunito  text-sm font-normal text-[#121212]"
							htmlFor="">
							Details
						</label>
						<input
							placeholder="Details"
							className=" w-full h-10  pl-4 rounded-xl border-[0.5px] outline-none border-[#D0D0D0]"
							type="text"
						/>
					</div>
					<div className=" space-y-3 mt-5">
						<label
							className=" font-Nunito  text-sm font-normal text-[#121212]"
							htmlFor="">
							Description
						</label>
						<textarea
							placeholder="Description"
							className=" w-full h-20 rounded-xl pl-4 border-[0.5px] outline-none border-[#D0D0D0]"
							name=""
							id=""
							cols="30"
							rows="10"></textarea>
					</div>

					<div className=" space-y-3 pt-5">
						<p className=" font-Nunito text-sm font-bold text-[#000]">
							Upload Attachment
						</p>
						<div className=" flex items-center gap-2">
							<div className=" flex items-center gap-4 justify-center w-44 h-7 rounded-md border border-[#D0D0D0]">
								<img
									className=" w-4 h-4"
									src={require("../../assets/Submit.png")}
									alt=""
								/>
								<p className=" font-Nunito text-[#000] text-sm font-normal">
									FileName.jpg
								</p>
							</div>
							<img
								className=" w-7 h-7"
								src={require("../../assets/Group99.png")}
								alt=""
							/>
						</div>
					</div>
					<div className=" mt-5">
						<button
							onClick={handleCreate}
							className=" font-Nunito text-sm text-[#fff] font-normal bg-gradient-to-b from-[#0811D4] to-[#384295] w-44 h-8 rounded-3xl animate-pulse">
							Create
						</button>
						{isCreate && <ActionCompleted onClose={closeCreate} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddAsset;
