import React, { useState } from "react";

const AddImage = ({ onClose }) => {
  const [showCard, setShowCard] = useState(false);

  const handleCard = () => {
    setShowCard(!showCard);
  };
  const closeCard = () => {
    setShowCard(false);
  };

  const posts = [
    {
      id: 1,
      img: require("../../assets/Upload.png"),
      text: "Image",
    },
    {
      id: 1,
      img: require("../../assets/Play.png"),
      text: "Play",
    },
    {
      id: 1,
      img: require("../../assets/Link.png"),
      text: "Link",
    },
  ];
  return (
    <div
      onClick={onClose}
      className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center"
    >
      <div className=" bg-white w-[716px] h-auto p-6    ">
        <div className=" flex items-center justify-between">
          <p className=" text-xl font-Kumbh font-bold">Create Post</p>
          <p onClick={onClose} className=" cursor-pointer text-xl font-bold">
            &times;
          </p>
        </div>
        <div className=" py-5">
          <img
            className=" w-14 h-14"
            src={require("../../assets/Ellipse14.png")}
            alt=""
          />
        </div>
        <div className=" space-y-5">
          <div className=" space-y-3">
            <label className=" text-sm font-Nunito text-[#121212]" htmlFor="">
              Post Title
            </label>
            <input
              placeholder="Title"
              className=" placeholder:text-xs border border-[#D0D0D0] placeholder-[#A3A3A3] pl-2 w-full h-12 rounded-md"
              type="text"
            />
          </div>
          <div className=" space-y-3">
            <label className=" text-sm font-Nunito text-[#121212]" htmlFor="">
              Post Title
            </label>

            <textarea
              placeholder="Body"
              className=" placeholder:text-xs border border-[#D0D0D0] placeholder-[#A3A3A3] p-2 w-full h-24 rounded-md"
              name=""
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>

          <div className=" flex items-center gap-5">
            {posts.map((i) => (
              <div onClick={handleCard} className=" flex items-center gap-3">
                <img className=" w-4 h-4" src={i.img} alt="" />
                <p className=" font-Nunito text-[#000] text-sm font-medium">
                  {i.text}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <button className=" w-44 h-8 rounded-md animate-pulse text-white bg-gradient-to-r from-[#0811D4] to-[#384295] font-Nunito text-sm mt-10">
            Post
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddImage;
