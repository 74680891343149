import React, { useState } from "react";
import dashb from "../../assets/dashb.png";
import AccountSetup from "../../assets/AccountSetup.png";
import alljob from "../../assets/alljob.png";
import { useNavigate } from "react-router-dom";

const JobSecNav = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("/job-dashboard"); // Track active page

  const handleNavigation = (page) => {
    setActivePage(page); // Update active state for styling
    navigate(page); // Perform actual navigation to the route
  };

  return (
    <div>
      <div className="flex items-center gap-[250px] lg:px-12 md:px-10 px-8 pt-2">
        <p className="font-Kumbh text-base font-semibold">Dashboard</p>
        <div className="flex items-center gap-4">
          {/* Dashboard */}
          <div
            className="flex items-center gap-1 cursor-pointer"
            onClick={() => handleNavigation("/job-dashboard")}
          >
            <img className="w-4 h-4" src={dashb} alt="Dashboard" />
            <p
              className={`font-Kumbh text-base ${
                activePage === "/job-dashboard"
                  ? "text-[#5608D4] font-bold"
                  : "text-black"
              }`}
            >
              Dashboard
            </p>
          </div>

          {/* Account Setup */}
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleNavigation("/account-setup")}
          >
            <img className="w-4 h-4" src={AccountSetup} alt="Account Setup" />
            <p
              className={`font-Kumbh text-base ${
                activePage === "/account-setup"
                  ? "text-[#5608D4] font-bold"
                  : "text-black"
              }`}
            >
              Account Setup
            </p>
          </div>

          {/* All Jobs */}
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleNavigation("/all-jobs")}
          >
            <img className="w-4 h-4" src={alljob} alt="All Jobs" />
            <p
              className={`font-Kumbh text-base ${
                activePage === "/all-jobs"
                  ? "text-[#5608D4] font-bold"
                  : "text-black"
              }`}
            >
              All Jobs
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSecNav;

// import React, { useState } from "react";
// import dashb from "../../assets/dashb.png";
// import AccountSetup from "../../assets/AccountSetup.png";
// import alljob from "../../assets/alljob.png";
// import { useNavigate } from "react-router-dom";

// const JobSecNav = () => {
//   const navigate = useNavigate();
//   // const [activePage, setActivePage] = useState("/job-dashboard"); // Track active page

//   // const handleNavigation = (page) => {
//   //   setActivePage(page); // Update active state for styling
//   //   navigate(page); // Perform actual navigation to the route
//   // };

//   return (
//     <div>
//       <div className="flex items-center gap-[250px] lg:px-12 md:px-10 px-8 pt-2">
//         <p className="font-Kumbh text-base font-semibold">Dashboard</p>
//         <div className="flex items-center gap-4">
//           {/* Dashboard */}
//           <div
//             className="flex items-center gap-1 cursor-pointer"
//             onClick={() => navigate("/job-dashboard")}
//           >
//             <img className="w-4 h-4" src={dashb} alt="Dashboard" />
//             <p
//               className="font-Kumbh text-base"
//               // className={`font-Kumbh text-base ${
//               //   activePage === "/job-dashboard"
//               //     ? "text-[#5608D4] font-bold"
//               //     : "text-black"
//               // }`}
//             >
//               Dashboard
//             </p>
//           </div>

//           {/* Account Setup */}
//           <div
//             className="flex items-center gap-2 cursor-pointer"
//             onClick={() => navigate("/account-setup")}
//           >
//             <img className="w-4 h-4" src={AccountSetup} alt="Account Setup" />
//             <p
//               className="font-Kumbh text-base"
//               // className={`font-Kumbh text-base ${
//               //   activePage === "/account-setup"
//               //     ? "text-[#5608D4] font-bold"
//               //     : "text-black"
//               // }`}
//             >
//               Account Setup
//             </p>
//           </div>

//           {/* All Jobs */}
//           <div
//             className="flex items-center gap-2 cursor-pointer"
//             onClick={() => navigate("/all-jobs")}
//           >
//             <img className="w-4 h-4" src={alljob} alt="All Jobs" />
//             <p
//               className="font-Kumbh text-base "
//               // className={`font-Kumbh text-base ${
//               //   activePage === "/all-jobs"
//               //     ? "text-[#5608D4] font-bold"
//               //     : "text-black"
//               // }`}
//             >
//               All Jobs
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JobSecNav;
