import React from "react";
import left from "../../assets/left.png";
import right from "../../assets/right.png";
import cal from "../../assets/cal.png";
import RecentNotifications from "./RecentNotifications";
import notif from "../../assets/notif.png";
import { Celebrants } from "./EmployeeMessage";

const InvoiceTable = () => {
	// const tableHead = [
	//     "S/N",
	//     "Unique ID",
	//     "Contact",
	//     "Issue Date",
	//     "Status",
	//     "Total",
	//   ],
	//   tableData = [
	//     {
	//       SN: "1",
	//       UniqueID: "Business/1234A",
	//       Contact: "Fidelity Bank",
	//       IssueDate: "25th March, 2024",
	//       Status: "Closed",
	//       Total: "NGN 50, 000",
	//     },
	//     {
	//       SN: "2",
	//       UniqueID: "Business/1234A",
	//       Contact: "Fidelity Bank",
	//       IssueDate: "25th March, 2024",
	//       Status: "Open",
	//       Total: "NGN 50, 000",
	//     },
	//     {
	//       SN: "3",
	//       UniqueID: "Business/1234A",
	//       Contact: "GTBank",
	//       IssueDate: "25th March, 2024",
	//       Status: "Open",
	//       Total: "NGN 50, 000",
	//     },
	//   ];

	const notification = [
		{
			img: notif,
			title: "Your payment invoice request has been approved by Admin",
			min: "3min ago",
		},
		{
			img: notif,
			title: "Your payment invoice request has been approved by Admin",
			min: "3min ago",
		},
		{
			img: notif,
			title: "Your payment invoice request has been approved by Admin",
			min: "3min ago",
		},
		{
			img: notif,
			title: "Your payment invoice request has been approved by Admin",
			min: "3min ago",
		},
	];

	return (
		<div className=" flex justify-between gap-3 ">
			<div className=" w-1/2 grid gap-5">
				<Celebrants />
				<div className=" bg-white rounded-lg px-4 py-2">
					<div className=" justify-between items-center flex">
						<img className=" w-3 h-3" src={left} alt="" />
						<p className="text-[#191d23] text-[13px] font-bold Satoshi">
							Upcoming Holiday{" "}
						</p>
						<img className=" w-3 h-3" src={right} alt="" />
					</div>
					<div className=" flex justify-between items-center bg-[#fceff6] p-2 mt-3">
						<div className=" flex items-center gap-2">
							<img className=" w-5 h-5" src={cal} alt="" />
							<div className="">
								<p className=" Satoshi text-[10px] text-[#000000] font-bold">
									1st October
								</p>
								<p className=" Satoshi text-[#000] text-[10px]">
									Independent Day
								</p>
							</div>
						</div>
						<div>
							<div className=" bg-[#cccccc] h-8 w-[0.6px]"></div>
						</div>
						<div className=" flex items-center gap-2">
							<img className=" w-5 h-5" src={cal} alt="" />
							<div className="">
								<p className=" Satoshi text-[10px] text-[#000000] font-bold">
									1st October
								</p>
								<p className=" Satoshi text-[#000] text-[10px]">
									Independent Day
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className=" w-1/2 h-80 overflow-y-scroll hide-scrollbar">
				<RecentNotifications notification={notification} />
			</div>
			{/* <div className=" flex items-center justify-between">
        <p className=" text-sm font-bold text-[#000]">Invoice</p>
        <div className=" bg-[#F5DFFF] rounded-full px-2 flex gap-2 items-center cursor-pointer">
          <img
            className=" w-3 h-3"
            src={require("../../assets/ViewEye.png")}
            alt=""
          />
          <p className=" text-[#7B08D4]  text-sm font-thin">View All</p>
        </div>
      </div>
      <div className=" w-full overflow-x-scroll ">
        <table className="min-w-full table divide-y-2 divide-yellow-[#7E3AF2] h-full">
          <thead className="h-16 w-full ">
            <tr className="text-black">
              {tableHead?.map((it, index) => (
                <th className=" font-Nunito text-xs font-light" key={index}>
                  {it}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="divide-y">
            {tableData?.map((it, i) => (
              <tr key={i} className=" bg-white py-4 h-16    ">
                <td className=" px-4  font-Nunito text-xs font-light text-center ">
                  {it?.SN}
                </td>
                <td className="  font-Nunito text-xs font-light text-center">
                  {it?.UniqueID}
                </td>
                <td className="  font-Nunito text-xs font-light text-center">
                  {it?.Contact}
                </td>
                <td className="  font-Nunito text-xs font-light text-center">
                  {it?.IssueDate}
                </td>
                <td className="  font-Nunito text-xs font-light text-center">
                  {it?.Status}
                </td>
                <td className="  font-Nunito text-xs font-light text-center">
                  {it?.Total}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
		</div>
	);
};

export default InvoiceTable;
