import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import DownloadProfile from "../../components/buttons/DownloadProfile";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Delete from "../../components/cards/Delete";
import useStaffStore from "../../data/Stores/StaffReducer";
import moment from "moment";
import { apiCall } from "../../data/useGenFetcher";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";

const CircularProgress = ({ progress }) => {
	const radius = 50; // Radius of the circle
	const circumference = 2 * Math.PI * radius; // Circumference of the circle
	const strokeDashoffset = circumference - (progress / 100) * circumference; // Stroke dash offset for the progress

	return (
		<div className="w-40 h-24 relative overflow-hidden">
			<svg className="w-full h-full" viewBox="0 0 100 100">
				{/* Gray circle */}
				<circle
					cx="50"
					cy="50"
					r={radius}
					fill="none"
					stroke="#E5E7EB"
					strokeWidth="8"
				/>
				{/* Colored circle representing progress */}
				<circle
					cx="50"
					cy="50"
					r={radius}
					fill="none"
					stroke="#10B981"
					strokeWidth="8"
					strokeDasharray={circumference}
					strokeDashoffset={strokeDashoffset}
					strokeLinecap="round"
					transform="rotate(-90 50 50)"
				/>
				{/* Progress text */}
				<text
					x="50"
					y="50"
					textAnchor="middle"
					dy="0.3em"
					className="text-gray-700 text-xs font-semibold">
					{`${progress}% Completed`}
				</text>
			</svg>
		</div>
	);
};

const EmployeesDetails = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [selectedReport, setSelectedReport] = useState("basic");
	const [isDelete, setIsDelete] = useState(false);

	const handleDelete = () => {
		setIsDelete(!isDelete);
	};
	const closeDelete = () => {
		setIsDelete(false);
	};

	let items = useLocation()?.state;
	let [state, setState] = useState(items),
		{ data, deleteStaff } = useStaffStore();

	useEffect(() => {
		data?.docs?.map(it => it?._id === items?._id && setState(it));
	}, [data, items]);

	const basics = [
		{
			id: 1,
			img: require("../../assets/BCalendar.png"),
			text: "DOB",
			dash: "-",
			desc: state?.dateOfBirth
				? moment(state?.dateOfBirth).format("Do MMMM, YYYY")
				: "",
		},
		{
			id: 2,
			img: require("../../assets/Gender.png"),
			text: "Gender",
			dash: "-",
			desc: state?.gender,
		},
		{
			id: 3,
			img: require("../../assets/Diamondring.png"),
			text: "Marital Status ",
			dash: "-",
			desc: state?.maritalStatus,
		},
		{
			id: 4,
			img: require("../../assets/Houses.png"),
			text: "State of Origin ",
			dash: "-",
			desc: state?.stateOfOrigin,
		},
		{
			id: 5,
			img: require("../../assets/Townhall.png"),
			text: "LGA of Origin ",
			dash: "-",
			desc: state?.lgaOfOrigin,
		},
		{
			id: 5,
			img: require("../../assets/Town.png"),
			text: "State of Residence ",
			dash: "-",
			desc: state?.stateOfResidence,
		},
	];

	const org = [
		{
			id: 1,

			orgtext: "Level",
			orgdash: "-",
			orgdesc: state?.level?.name,
		},
		{
			id: 2,

			orgtext: "Grade",
			orgdash: "-",
			orgdesc: state?.grade?.name,
		},
		{
			id: 3,

			orgtext: "Position",
			orgdash: "-",
			orgdesc: state?.position?.name,
		},
		{
			id: 4,

			orgtext: "State of Origin ",
			orgdash: "-",
			orgdesc: state?.stateOfOrigin,
		},
		{
			id: 5,

			orgtext: "Department ",
			orgdash: "-",
			orgdesc: state?.department?.name,
		},
		{
			id: 5,

			orgtext: "Branch ",
			orgdash: "-",
			orgdesc: state?.branch,
		},
		{
			id: 6,

			orgtext: "Salary Structure ",
			orgdash: "-",
			orgdesc: state?.salary,
		},
	];
	const edu = [
			{
				id: 1,
				eduimg: "",
				edutext: "Master of Science",
				school: "Ladoke Akintola University of Technoloy",
				occ: "Digital Forensics and Incident Response",
				date: "Sept 2021 - Dec 2023",
			},
			{
				id: 2,
				eduimg: "",
				edutext: "Bachelor of Technology",
				school: "Ladoke Akintola University of Technoloy",
				occ: "Cybersecurity (First Class Honors).",
				date: "Sept 2015 - Dec 2020",
			},
			{
				id: 3,
				eduimg: "",
				edutext: "Bachelor of Technology",
				school: "Ladoke Akintola University of Technoloy",
				occ: "Cybersecurity (First Class Honors).",
				date: "Sept 2015 - Dec 2020",
			},
		],
		[loading, setLoading] = useState(false),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate();

	let handleSubmit = async e => {
		// e?.preventDefault();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/user/${state?._id}`,
			type: "delete",
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			deleteStaff(state);
			navigate(-1);
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Employee’s Details
					</p>
				</div>
				<div className=" flex items-center justify-between bg-[#EFF6FC] mx-12 p-5 mt-5">
					<div className=" flex items-center gap-20">
						<div className="grid gap-5">
							<div>
								<p className=" text-[#071C50] text-xl font-bold Satoshi">
									{state?.firstName} {state?.lastName}
								</p>
								<div className=" flex items-center gap-3">
									<p className=" text-[#071C50] text-sm font-Nunito">
										{state?.email}
									</p>
									<p className="text-[#071C50] text-sm font-Nunito">|</p>
									<p className="text-[#071C50] text-sm font-Nunito">
										{state?.phone}
									</p>
								</div>
								<div className=" flex items-center gap-3 mt-3">
									<img
										className=" w-4 h-4"
										src={require("../../assets/Linkedin.png")}
										alt=""
									/>
									<img
										className=" w-4 h-4"
										src={require("../../assets/Twitter.png")}
										alt=""
									/>
									<img
										className=" w-4 h-4"
										src={require("../../assets/Global.png")}
										alt=""
									/>
								</div>
							</div>
							<div>
								<Link
									to="/update-employee"
									className=" underline text-[#F7AC25] font-Nunito font-bold text-base">
									Update
								</Link>
							</div>
						</div>
						<div>
							<div className=" w-24 h-24 justify-center items-center grid rounded-full border-[0.5px] border-[#0811D4] bg-[] text-[#071C50] text-3xl font-Poppins font-bold">
								{state?.firstName?.split("")[0]}
								{state?.lastName?.split("")[0]}
							</div>
						</div>
					</div>
					<div className=" ">
						<img
							className=" h-24"
							src={require("../../assets/ELine.png")}
							alt=""
						/>
					</div>
					<div className=" flex items-center gap-20">
						<div className=" grid gap-4">
							<div className=" flex items-center justify-between gap-8">
								<p className="  text-gray-400 text-base Satoshi">Department</p>
								<p className=" bg-[#DDEAFB] text-[#071C50] text-xs grid justify-center items-center rounded-lg font-Nunito w-32 h-5">
									{state?.department?.name}
								</p>
							</div>
							<div className=" flex items-center justify-between gap-8">
								<p className="  text-gray-400 text-base Satoshi">Role</p>
								<p className=" bg-[#DDEAFB] text-[#071C50] text-xs grid justify-center items-center rounded-lg font-Nunito w-32 h-5">
									{state?.position?.name}
								</p>
							</div>
							<div className=" flex items-center justify-between gap-8">
								<p className=" text-gray-400 text-base Satoshi font-light">
									Level
								</p>
								<p className=" bg-[#DDEAFB] text-xs font-Nunito text-[#071C50] w-32 h-5 rounded-lg grid justify-center items-center ">
									{state?.level?.name}
								</p>
							</div>
							<div className=" flex items-center gap-8">
								<p className=" text-gray-400 text-base Satoshi font-light">
									Employee ID
								</p>
								<p className="  text-xs font-Nunito text-[#071C50] w-32 h-5 grid justify-center items-start ">
									{state?.employeeId}
								</p>
							</div>
						</div>
						<div>
							<CircularProgress progress={50} />
						</div>
					</div>
				</div>
				<div className=" bg-white mx-12 p-5">
					<div className=" flex items-center justify-between gap-10 border-b-[0.5px] mb-3 border-b-[#F5F7F8] py-4">
						<div className=" flex items-center gap-10">
							<button
								className={` text-gray-600 font-Nunito text-base cursor-pointer${
									selectedReport === "basic"
										? " border-b-[5px] border-b-[#F7AC25]"
										: "bg-white text-[#1C1C1C]  "
								}`}
								onClick={() => setSelectedReport("basic")}>
								Basic Info
							</button>
							<button
								className={` text-gray-600 font-Nunito text-base cursor-pointer ${
									selectedReport === "org"
										? "border-b-[5px] border-b-[#F7AC25]"
										: "bg-white text-#071C50] "
								}`}
								onClick={() => setSelectedReport("org")}>
								Organization
							</button>
							<button
								className={`text-gray-600 font-Nunito text-base cursor-pointer ${
									selectedReport === "edu"
										? "border-b-[5px] border-b-[#F7AC25]"
										: "bg-white text-[#071C50]"
								}`}
								onClick={() => setSelectedReport("edu")}>
								Education
							</button>
							<button
								className={`text-gray-600 font-Nunito text-base cursor-pointer ${
									selectedReport === "exp"
										? " border-b-[5px] border-b-[#F7AC25]"
										: "bg-white text-[#071C50] "
								}`}
								onClick={() => setSelectedReport("exp")}>
								Experience
							</button>
						</div>
						<div>
							<DownloadProfile />
						</div>
					</div>

					{selectedReport === "basic" && (
						<div className="grid gap-4 w-1/2">
							{basics.map(i => (
								<div className=" flex justify-between">
									<div className=" flex items-center gap-2">
										<img className=" w-5 h-5" src={i.img} alt="" />
										<div className=" flex gap-1">
											<p className=" Satoshi text-[#071C50] text-sm font-bold">
												{i.text}
											</p>
											<p className="Satoshi text-[#071C50] text-sm font-bold">
												{i.dash}
											</p>
										</div>
									</div>
									<div className=" w-96 text-start capitalize">{i.desc}</div>
								</div>
							))}
						</div>
					)}

					{selectedReport === "org" && (
						<div className="grid gap-4 w-1/2">
							{org.map(i => (
								<div className=" flex justify-between">
									<div className=" flex items-center ">
										<div className=" flex gap-1">
											<p className=" Satoshi text-[#071C50] text-sm font-bold">
												{i.orgtext}
											</p>
											<p className="Satoshi text-[#071C50] text-sm font-bold">
												{i.orgdash}
											</p>
										</div>
									</div>
									<div className=" w-96 text-start">{i.orgdesc}</div>
								</div>
							))}
						</div>
					)}

					{selectedReport === "edu" && (
						<div>
							<p className=" text-[#071C50] mb-4 font-bold font-Kumbh">
								Education
							</p>
							<div className="grid gap-4 w-[70%]">
								{edu.map(i => (
									<div className=" flex justify-between items-center">
										<div className=" flex items-center ">
											<div className=" flex gap-3">
												<img
													className=" w-3 h-14"
													src={require("../../assets/line45.png")}
													alt=""
												/>
												<div className=" grid ">
													<p className="text-[#071C50] text-base font-bold font-Nunito">
														{i.edutext}
													</p>
													<p className=" text-[#071C50] font-Nunito text-sm ">
														{i.school}
													</p>
													<p className=" pt-4 text-gray-500">{i.occ}</p>
												</div>
											</div>
										</div>
										<div className=" w-96 text-start text-gray-400 font-Nunito text-sm">
											{i.date}
										</div>
									</div>
								))}
							</div>
						</div>
					)}
					{selectedReport === "exp" && (
						<div className=" mt-5 space-y-5">
							<p className="font-Nunito text-base font-bold text-[#071C50]">
								Last Experience
							</p>
							<div className=" space-y-10">
								<div className=" flex justify-between">
									<div>
										<div className="flex items-center gap-2">
											<img
												className=" h-11 w-3"
												src={require("../../assets/Rectanglejob.png")}
												alt=""
											/>
											<div>
												<p className=" font-Nunito text-base font-bold text-[#071C50]">
													Basic Information
												</p>
												<p className=" text-[#071C50] font-Nunito text-sm">
													Senior Data Analyst
												</p>
											</div>
										</div>
										<div className=" pl-5 mt-3">
											<p className=" lg:w-[640px] font-Nunito text-xs text-[#000]">
												Lorem ipsum dolor sit amet consectetur. Bibendum
												senectus consequat nibh purus. Fermentum urna lorem
												aliquam non quam faucibus id vitae. Nullam fermentum et
												massa arcu non id tincidunt interdum. Arcu proin
												ullamcorper viverra malesuada amet. Nulla neque sem arcu
												eget proin faucibus risus lorem. Duis et interdum
												viverra viverra duis aliquam sem. Turpis consectetur
												tortor sit sit morbi.
											</p>
										</div>
									</div>
									{/* <img
                    className=" w-16 h-16"
                    src={require("../../assets/Info.png")}
                    alt=""
                  /> */}
								</div>
								<div className=" flex justify-between">
									<div>
										<div className="flex items-center gap-2">
											<img
												className=" h-11 w-3"
												src={require("../../assets/Rectanglejob.png")}
												alt=""
											/>
											<div>
												<p className=" font-Nunito text-base font-bold text-[#071C50]">
													Job Description
												</p>
												<p className=" text-[#071C50] font-Nunito text-sm">
													Level: Full Time Role
												</p>
											</div>
										</div>
										<div className=" pl-5 mt-3">
											<p className=" lg:w-[640px] font-Nunito text-xs text-[#1443B9]">
												Mode: Hybrid | Location: Ikeja, Lagos State
											</p>
										</div>
									</div>
									{/* <img
                    className=" w-16 h-16"
                    src={require("../../assets/text-dynamic-color.png")}
                    alt=""
                  /> */}
								</div>
								<div className=" flex justify-between">
									<div>
										<div className="flex items-center gap-2">
											<img
												className=" h-11 w-3"
												src={require("../../assets/Rectanglejob.png")}
												alt=""
											/>
											<div>
												<p className=" font-Nunito text-base font-bold text-[#071C50]">
													Hiring Details
												</p>
												<p className=" text-[#071C50] font-Nunito text-sm">
													Type: Graduate
												</p>
											</div>
										</div>
										<div className=" pl-5 mt-3">
											<p className=" lg:w-[640px] font-Nunito text-xs text-[#071C50]">
												Hiring Managers: Ann James, Bob Smith, John Elliot
											</p>
											<p className=" lg:w-[640px] font-Nunito text-xs text-[#071C50]">
												Recruiters: Cephas ICT HUB
											</p>
											<p className=" lg:w-[640px] font-Nunito text-xs text-[#071C50]">
												Recruitment Limit: 10 - 50 Candidates
											</p>
										</div>
									</div>
									{/* <img
                    className=" w-16 h-16"
                    src={require("../../assets/Information.png")}
                    alt=""
                  /> */}
								</div>
							</div>
						</div>
					)}

					<div className=" mt-32">
						<button
							onClick={handleDelete}
							className=" text-[#D4082D] font-Nunito text-sm font-light border-[0.5px] border-[#D4082D] w-40 h-11 rounded-md ">
							Delete user
						</button>
						{isDelete && (
							<Delete
								onClose={closeDelete}
								deleteFunc={() => handleSubmit()}
								title={`${state?.firstName || ""} ${state?.lastName || ""}`}
								loading={loading}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmployeesDetails;
