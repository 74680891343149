import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";

import { FaSearch } from "react-icons/fa";
import AddNewBtn from "../../../components/buttons/AddNewBtn";
import moment from "moment";
import AddFile, { AddFolders } from "../../../components/cards/AddFile";
import { Link, useLocation, useParams } from "react-router-dom";
import FilesDetails from "../../../components/cards/File’sDetails";
import { useFileStore, useFolderStore } from "../../../data/Stores/LoggerStore";
import useCompanyStore from "../../../data/Stores/CompanyStore";
import { apiCall } from "../../../data/useGenFetcher";
import { LoadMore, MainPaginate } from "../../../components/buttons/Send";
import useErrorStore from "../../../data/Stores/errorstore";
import { toast } from "react-toastify";
import Delete from "../../../components/cards/Delete";

const Onboarding = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [addFile, setAddFile] = useState(false);

	const handleFile = () => {
		setAddFile(!addFile);
	};
	const closeFile = () => {
		setAddFile(false);
	};

	const cards = [
		{
			id: 1,
			img: require("../../../assets/Content.png"),
			type: "JPG",
			text: "Getting Started with HR Core Software",
			desc: "Public",
			date: " 26/03/2024",
			delete: require("../../../assets/Bin.png"),
		},
		{
			id: 2,
			img: require("../../../assets/Content2.png"),
			type: "DOCS",
			text: "Getting Started with HR Core Software",
			desc: "Admin",
			date: " 26/03/2024",
			delete: require("../../../assets/Bin.png"),
		},
		{
			id: 3,
			img: require("../../../assets/Content3.png"),

			text: "Getting Started with HR Core Software",
			desc: "Public",
			date: " 26/03/2024",
			delete: require("../../../assets/Bin.png"),
		},
		{
			id: 4,
			img: require("../../../assets/Content4.png"),

			text: "Getting Started with HR Core Software",
			desc: "Everyone",
			date: " 26/03/2024",
			delete: require("../../../assets/Bin.png"),
		},
		{
			id: 5,
			img: require("../../../assets/Content5.png"),

			text: "Getting Started with HR Core Software",
			desc: "Public",
			delete: require("../../../assets/Bin.png"),
			date: " 26/03/2024",
		},
		{
			id: 6,
			img: require("../../../assets/Content6.png"),
			type: "JPG",
			text: "Getting Started with HR Core Software",
			desc: "Public",
			date: " 26/03/2024",
			delete: require("../../../assets/Bin.png"),
		},
	];

	const descStyles = {
		Public: { backgroundColor: "#FCF3FF", textColor: "#7A12B9" },
		Admin: { backgroundColor: "#F3FBFF", textColor: "#1269B9" },
		Everyone: { backgroundColor: "#F3FFF4", textColor: "#12B937" },
	};

	let [datum, setDatum] = useState({ docs: cards }),
		{ getLogger, mainSearch, isFound, data, deleteLogger } = useFolderStore(),
		{ currentSelected } = useCompanyStore(),
		[loading, setLoading] = useState(false),
		[isDelete, setIsDelete] = useState(null),
		[items, setItems] = useState(null),
		{ returnErrors } = useErrorStore();

	let handleSubmit = async e => {
		// e?.preventDefault();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/folder/${currentItems}/${items?._id}`,
			type: "delete",
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response || response === "") {
			deleteLogger(items);
			setItems(null);
			setIsDelete(null);
			return;
		}
		setLoading(false);
	};

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/folder/${currentSelected}?_populate=levels`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/folder/${currentSelected}?_populate=levels&_limit=${
				data?.limit * data?.nextPage
			}`,
			getter: d => getLogger(d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<ToolsAssetManagement />

				<div className=" bg-white mx-12 mt-5 p-5">
					<div className=" flex items-center justify-between">
						<p className=" text-[#000] Satoshi font-bold text-sm">
							Recent ({data?.totalDocs})
						</p>
						<div onClick={handleFile} className=" cursor-pointer">
							<AddNewBtn text="Add New" />
						</div>
						{addFile && <AddFolders closeFile={closeFile} />}
					</div>
					<div className=" grid lg:grid-cols-6 md:grid-cols-3 grid-cols-1 gap-5">
						{currentItems?.map((i, rowIndex) => (
							<div className=" w-44 bg-white shadow-lg p-5 rounded-md hover:bg-slate-300">
								<Link state={i} to="folder-file" className="grid gap-5">
									<div>
										<div className=" flex items-center justify-between">
											<img
												className=" w-6 h-6"
												src={cards?.[rowIndex % cards?.length]?.img}
												alt=""
											/>
											<p className=" text-[#0811D4] text-xs font-Nunito font-light">
												{i?.type}
											</p>
										</div>
										<p className=" text-[#101828] text-xs font-medium mt-2">
											{i?.name}
										</p>
									</div>
									<div className=" flex items-center justify-between">
										<p
											className="text-xs font-light inter grid justify-center items-center rounded-full w-14 h-5"
											style={{
												backgroundColor:
													descStyles[cards?.[rowIndex % cards?.length]?.desc]
														.backgroundColor,
												color:
													descStyles[cards?.[rowIndex % cards?.length]?.desc]
														.textColor,
											}}>
											{cards?.[rowIndex % cards?.length]?.desc}
										</p>
										<p className=" text-[#667085] text-[10px] font-Nunito font-light">
											{moment(i?.createdAt).format("DD/MM/YYYY")}
										</p>
									</div>
								</Link>
								<div className=" flex justify-between items-center pt-4">
									<p className=" font-Nunito text-[10px] text-[#667085] font-light"></p>
									<div className=" flex items-center gap-4">
										<img
											onClick={() => {
												setItems(i);
												setIsDelete(true);
											}}
											className=" w-3 h-3 cursor-pointer"
											src={cards?.[rowIndex % cards?.length]?.delete}
											alt=""
										/>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="mt-10 flex justify-center w-44 items-center mx-auto">
						<LoadMore
							next={data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
					{isDelete && (
						<Delete
							onClose={() => {
								setIsDelete(null);
								setItems(null);
							}}
							deleteFunc={() => handleSubmit()}
							title={items?.name}
							loading={loading}
						/>
					)}
					<FilesTable />
				</div>
			</div>
		</div>
	);
};

export default Onboarding;

export const FilesTable = () => {
	const [addFile, setAddFile] = useState(false);
	const [addAction, setAddAction] = useState(false);

	const handleFile = () => {
		setAddFile(!addFile);
	};
	const handleAction = it => {
		setAddAction(it || !addAction);
	};
	const closeAction = () => {
		setAddAction(false);
	};
	const closeFile = () => {
		setAddFile(false);
	};

	const tableHead = ["S/N", "Name", "Sharing", "Modified", "Actions"];
	const tableData = [
		{
			SN: "1",
			Name: "Instructions - Setting up your Working space",
			Sharing: "Everyone",
			img: require("../../../assets/Openfile.png"),
			Modified: "26th March, 2024",
			Actions: "View details",
		},
		{
			SN: "2",
			Name: "Instructions - Setting up your Working space",
			Sharing: "Public",
			img: require("../../../assets/Openfile.png"),
			Modified: "26th March, 2024",
			Actions: "View details",
		},
		{
			SN: "3",
			Name: "Instructions - Setting up your Working space",
			Sharing: "Admin",
			img: require("../../../assets/Openfile.png"),
			Modified: "26th March, 2024",
			Actions: "View details",
		},
		{
			SN: "3",
			Name: "Instructions - Setting up your Working space",
			Sharing: "Everyone",
			img: require("../../../assets/Openfile.png"),
			Modified: "26th March, 2024",
			Actions: "View details",
		},
		{
			SN: "4",
			Name: "Instructions - Setting up your Working space",
			Sharing: "Everyone",
			img: require("../../../assets/Openfile.png"),
			Modified: "26th March, 2024",
			Actions: "View details",
		},
		{
			SN: "5",
			Name: "Instructions - Setting up your Working space",
			Sharing: "Public",
			img: require("../../../assets/Openfile.png"),
			Modified: "26th March, 2024",
			Actions: "View details",
		},
		{
			SN: "6",
			Name: "Instructions - Setting up your Working space",
			Sharing: "Everyone",
			img: require("../../../assets/Openfile.png"),
			Modified: "26th March, 2024",
			Actions: "View details",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData }),
		{ getLogger, mainSearch, isFound, data } = useFileStore(),
		[loading, setLoading] = useState(false),
		items = useLocation()?.state;

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/file?_populate=refType${
				items?._id ? `&ref=${items?._id}` : ""
			}`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/file?_populate=refType&_limit=${
				data?.limit * data?.nextPage
			}${items?._id ? `&ref=${items?._id}` : ""}`,
			getter: d => getLogger(d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<>
			<div>
				<div className=" bg-white p-5 mt-5 ">
					<div className=" flex items-center justify-between">
						<div className=" flex items-center gap-5">
							<div className=" flex items-center">
								<p className=" Satoshi font-normal text-sm text-[#000]">
									Files management
								</p>
							</div>

							<div className=" flex items-center gap-3">
								<p className=" text-[#121212]  text-sm font-light font-Nunito">
									Search documents
								</p>
								<div className="">
									<input
										className="w-48 h-9 pr-10 pl-3 outline-none bg-[#F5F7F8] rounded-md"
										type="text"
									/>
									<div className="absolute inset-y-0 right-3 flex items-center pl-3">
										<FaSearch className="text-gray-500" />
									</div>
								</div>
							</div>
						</div>
						{items && (
							<div onClick={handleFile} className=" cursor-pointer">
								<AddNewBtn text="Add New" />
							</div>
						)}
						{addFile && <AddFile closeFile={closeFile} />}
					</div>
				</div>
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							{tableHead.map((head, index) => (
								<th
									key={index}
									className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
									{head}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{currentItems?.map((rowData, rowIndex) => (
							<tr key={rowIndex} className="border-t-[0.5px] border-t-gray-200">
								<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
									{rowIndex + 1}
								</td>
								<td className="px-4 py-4 flex w-80 items-center gap-3 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
									<img
										className=" w-4 h-4"
										src={
											rowData?.url ||
											tableData?.[rowIndex % tableData?.length]?.img
										}
										alt=""
									/>
									{rowData?.name}
								</td>
								<td className="px-4 py-4 w-60 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
									{items && items?.levels?.length > 0
										? items?.levels?.map((it, x) => (
												<span key={x}>
													{it?.name}
													{x === items?.levels?.length - 1 ? "" : ", "}
												</span>
										  ))
										: ""}
								</td>
								<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
									{moment(rowData?.createdAt).format("Do MMMM, YYYY")}
								</td>
								<td className="px-4 py-4 text-[#0811D4] text-xs border-b-[0.5] border-b-gray-200 ">
									<span
										onClick={() => handleAction(rowData)}
										className=" animate-pulse">
										{tableData?.[rowIndex % tableData?.length]?.Actions}
									</span>
								</td>
								{addAction && (
									<FilesDetails closeDetails={closeAction} item={addAction} />
								)}
							</tr>
						))}
					</tbody>
				</table>
				<div className="mt-10 flex justify-center w-44 items-center mx-auto">
					<LoadMore
						next={data?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</>
	);
};

export const ToolsAssetManagement = () => {
	let { id } = useParams();
	return (
		<div className=" mx-12 pt-4 flex gap-5">
			<Link
				to="/manage-employees/onboarding"
				className={`animate-pulse font-Kumbh text-base font-normal ${
					id === "onboarding" ? "text-[#0811D4]" : " text-[#000]"
				}`}>
				Files management
			</Link>
			<Link
				to="/manage-employees/tools-management"
				className={`animate-pulse font-Kumbh text-base font-normal ${
					id === "tools-management" ? "text-[#0811D4]" : "text-[#000]"
				}`}>
				Tools management
			</Link>
		</div>
	);
};