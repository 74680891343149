import React, { useState } from "react";
import ActionCompleted from "../cards/ActionCompleted";

const GradientBtn = ({ btnText }) => {
  const [isAdd, setIsAdd] = useState(false);

  const handleAdd = () => {
    setIsAdd(!isAdd);
  };
  const closeAdd = () => {
    setIsAdd(false);
  };
  return (
    <div>
      <button
        onClick={handleAdd}
        className=" bg-gradient-to-b from-[#5608D4] to-[#384295] w-44 h-8 rounded-lg text-white text-sm font-Nunito"
      >
        {btnText}
      </button>
      {isAdd && <ActionCompleted onClose={closeAdd} />}
    </div>
  );
};

export default GradientBtn;
