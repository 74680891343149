/* eslint-disable react/prop-types */
import { createContext } from "react";
import { useState } from "react";
import useAuthStore from "./Stores/Authstore";
import axios from "axios";
import { useEffect } from "react";
import useDepartmentStore from "./Stores/DepartmentStore";
import useCompanyStore from "./Stores/CompanyStore";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
	const [nav, setNav] = useState(false);
	let handleCapitalize = word => {
		let splitter = word.trim().split(" ");
		let firstCap = splitter[0].split("");
		let remaining = splitter.slice(1, splitter.length).join(" ");

		let firstCapOne = firstCap[0].toUpperCase();
		let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

		let joinFirst = `${firstCapOne}${firstCapTwo}`;

		return `${joinFirst} ${remaining}`;
	};

	let numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const toggleNav = () => {
		setNav(!nav);
	};

	let { isAuth, user } = useAuthStore(),
		department = useDepartmentStore(),
		company = useCompanyStore();

	let sidebarList = [];

	let [stateName, setStateName] = useState("");

	let nairaSign = <span className="fontInherit">&#8358;</span>;
	let nairaSignNeutral = "₦",
		[canApprove, setCanApprove] = useState(null),
		[canExecute, setCanExecute] = useState(null),
		[canManager1, setCanManager1] = useState(null),
		[canManager2, setCanManager2] = useState(null),
		[canAdmin, setCanAdmin] = useState(null),
		[canSee, setCanSee] = useState([]),
		[countryState, setCountryState] = useState(null);

	useEffect(() => {
		if (user) {
			if (department?.data) {
				let findDept = department?.data?.docs?.find(it => {
					let dept = user?.profile?.department?._id || user?.department?._id;

					return it?._id === dept;
				});
				if (findDept) setCanSee(findDept?.modules);
			}
		}
	}, [department?.data, user]);

	useEffect(() => {
		if (company?.currentSelected && user) {
			let findCompany = company?.data?.docs?.find(
				it => it?._id === company?.currentSelected
			);
			if (findCompany) {
				let findUser = findCompany?.managers?.find(it => it === user?._id);
				if (findUser) setCanApprove(true);
				else setCanApprove(false);
				// let findUser4 = findCompany?.manager1s?.find(
				//   (it) => it === user?._id
				// );
				if (user?.gradeForPosition1?.length > 0) setCanManager1(true);
				else setCanManager1(false);
				// let findUser5 = findCompany?.manager2s?.find(
				//   (it) => it === user?._id
				// );
				if (user?.gradeForPosition2?.length > 0) setCanManager2(true);
				else setCanManager2(false);
				let findUser2 = findCompany?.admins?.find(it => it === user?._id);
				if (findUser2) setCanAdmin(true);
				else setCanAdmin(false);
				let findUser3 = findCompany?.executive?.find(it => it === user?._id);
				if (findUser3) setCanExecute(true);
				else setCanExecute(false);
			}
			return () => {
				setCanApprove(false);
				setCanAdmin(false);
				setCanExecute(false);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company?.currentSelected, company?.data, user]);

	useEffect(() => {
		let getStates = async () => {
			try {
				let res = await axios.get(`https://nga-states-lga.onrender.com/fetch`, {
					headers: {
						Authorization: null,
						companyid: null,
					},
				});
				setCountryState(
					res?.data?.map(it => {
						return { name: it };
					})
				);
			} catch (error) {
				console.log({ error }, "country");
			}
		};
		getStates();
	}, []);

	const state = {
		handleCapitalize,

		numberWithCommas,

		sidebarList,
		auth: isAuth,
		nav,
		toggleNav,
		stateName,
		setStateName,
		nairaSign,
		nairaSignNeutral,
		countryState,
		canSee,
		canAdmin,
		canApprove,
		canExecute,
		canManager1,
		canManager2,
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
