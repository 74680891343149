import React from "react";
import { Dialog } from "@headlessui/react";

const ActionCompleted = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center"
    >
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-black bg-opacity-20"
        aria-hidden="true"
        onClick={onClose}
      ></div>

      {/* Modal Content */}
      <div className="bg-white w-72 h-auto rounded-md p-5">
        <div className="flex justify-center items-center py-5">
          <img
            className="w-36 h-36"
            src={require("../../assets/Check.png")}
            alt="Checkmark"
          />
        </div>
        <p className="font-Nunito text-[#000] text-base font-bold text-center">
          Action Completed
        </p>
        <p className="text-[#000] font-Nunito text-sm text-center pt-3 pb-6">
          You have successfully completed the action.
        </p>

        {/* Close Button */}
        {/* <div className="flex justify-center">
          <button
            onClick={onClose}
            className="px-5 py-2 rounded-lg bg-gradient-to-r from-[#5608D4] to-[#384295] text-white font-semibold transition ease-in-out hover:opacity-90"
          >
            Close
          </button>
        </div> */}
      </div>
    </Dialog>
  );
};

export default ActionCompleted;
