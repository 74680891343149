import React from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";

const AllNotifications = () => {
  return (
    <div className=" bg-[#EFF6FC] pb-10">
      <Navbar />
      <PagesList />
      <div className="bg-[#F5F7F8] w-full  ">
        <div className=" mx-12 pt-4 gap-5">
          <p className="  font-Kumbh cursor-pointer text-base font-medium text-[#000]">
            All Notifications
          </p>

          <div className=" mt-5 p-5 bg-[#EFF6FC] flex justify-between items-center">
            <p className=" text-xl font-bold text-[#000] font-Kumbh">
              Online Employees Training
            </p>
            <button className=" w-44 h-10 rounded-lg border border-[#FD5252] text-[#F33737] font-Nunito text-sm">
              Delete notification
            </button>
          </div>

          <div className=" bg-white p-5">
            <div className=" flex items-center gap-10">
              <div className=" flex items-center gap-2">
                <p className=" font-Nunito text-sm text-[#000]">Wednesday,</p>
                <p className=" font-Nunito text-sm text-[#000]">03/04. </p>
              </div>
              <p className="font-Nunito text-sm text-[#000]"> 10:00am</p>
            </div>

            <div className=" p-5">
              <p className=" text-sm font-Nunito text-[#272525]">
                Lorem ipsum dolor sit amet consectetur. Aliquet nisl laoreet
                nunc enim dignissim pulvinar ut enim nulla. Lorem eget ultrices
                est tellus enim proin id. nunc enim dignissim pulvinar ut enim
                nulla. Lorem eget ultrices est tellus enim proin id.Lorem ipsum
                dolor sit amet consectetur. Aliquet nisl laoreet nunc enim
                dignissim pulvinar ut enim nulla. Lorem eget ultrices est tellus
                enim proin id. nunc enim dignissim pulvinar ut enim nulla. Lorem
                eget ultrices est tellus enim proin id.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllNotifications;
