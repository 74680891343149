// import React from "react";

// const EditCompany = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default EditCompany;

import React, { useState } from "react";
import Delete from "../cards/Delete";
import ActionCompleted from "../cards/ActionCompleted";
import CancelUpdates from "../buttons/CancelUpdate"; // Import the CancelUpdates component

const EditCompany = ({ onClose }) => {
  const [isSwitched, setIsSwitched] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };
  const closeShowAnswer = () => {
    setShowAnswer(false);
  };

  const handleSubmit = () => {
    setSubmit(!submit);
  };

  const closeSubmit = () => {
    setSubmit(false);
  };

  const handleClick = () => {
    setIsSwitched(!isSwitched);
  };

  const handleUpdate = () => {
    setShowUpdate(!showUpdate);
  };

  return (
    <div className=" overflow-y-scroll fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
      <div className=" bg-white w-[682px] p-5 h-auto rounded-md ">
        <div className=" flex justify-between items-center">
          <p className=" font-Kumbh text-xl font-bold text-[#000]">
            Edit Company
          </p>
          <p
            onClick={onClose}
            className=" cursor-pointer text-[24px] font-bold"
          >
            &times;
          </p>
        </div>
        <div className=" space-y-5 mt-5 pr-5">
          <div className=" space-y-2">
            <label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
              Company Name
            </label>
            <input
              placeholder="Title"
              className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
              type="text"
            />
          </div>
          <div className=" space-y-2">
            <label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
              Company Website
            </label>
            <input
              placeholder="Title"
              className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
              type="text"
            />
          </div>

          {/* <div className=" space-y-2">
            <label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
              Description
            </label>
            <input
              placeholder="Description"
              className=" border border-[#D0D0D0] px-2 w-full h-28 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
              type="text"
            />
          </div> */}
        </div>

        <div className=" space-y-5 mt-16">
          <CancelUpdates onClose={onClose} onUpdate={handleUpdate} />
          {showUpdate && <ActionCompleted onClose={onClose} />}
        </div>
      </div>
    </div>
  );
};

export default EditCompany;
