import React from "react";

const JobDetail = () => {
  return (
    <div>
      <div clasName=" bg-[]"></div>
    </div>
  );
};

export default JobDetail;
