import React from "react";
import JobNavbar from "../../components/job/JobNavbar";
import JobSecNav from "../../components/job/JobSecNav";
import jobellipse from "../../assets/jobellipse.png";
import JobLinkedin from "../../assets/JobLinkedin.png";
import Work from "../../assets/Work.png";
import Placeholder from "../../assets/Placeholder.png";
import JobTwitter from "../../assets/JobTwitter.png";
import JobBehance from "../../assets/JobBehance.png";
import JobInstagram from "../../assets/JobInstagram.png";
import JobLink from "../../assets/JobLink.png";
import jobone from "../../assets/jobone.png";
import jobtwo from "../../assets/jobtwo.png";
import jobthree from "../../assets/jobthree.png";
import Editjob from "../../assets/Editjob.png";
import jobGroup from "../../assets/jobGroup.png";
import more from "../../assets/more.png";
import ProfileStatusChart from "../../components/job/ProfileStatusChart";

const AccountProfile = () => {
  const detail = [
    {
      img: jobone,
      title: "Professional Details",
      porf: "Product Designer",
      company: "Alpha Unix Limited",
      date: "Jan 2024 to Jan 2025",
    },
    {
      img: jobtwo,
      title: "Educational Details",
      porf: "B.Tech / Full Time",
      company: "Covenant University, Abuja",
      date: "2020 - 2024",
    },
    {
      img: jobthree,
      title: "Certification Details",
      porf: "Data Analytics  - From Zero to Hero",
      company: "Udemy",
      date: "Jan 2024 to Apr 2024",
    },
  ];
  return (
    <div className="bg-[#F2EFFC]">
      <JobNavbar />
      <JobSecNav />
      <div className="mx-12 bg-white p-5 mt-2">
        <div>
          <div className=" flex ">
            <div className=" w-[25%] bg-[#fcf7ff] py-8 px-5">
              <div className="">
                <div className=" justify-center grid items-center">
                  <img className=" w-36 h-36" src={jobellipse} alt="" />
                </div>
                <div className=" grid justify-center items-center mt-5">
                  <p className=" text-center font-Kumbh text-xl font-semibold text-[#000]">
                    Jane Smith
                  </p>
                  <small className=" text-center font-Kumbh text-base text-[#000]">
                    janesmith@gmail.com
                  </small>
                  <small className=" text-center font-Kumbh text-base text-[#000] mt-5">
                    +234 901 222 3333
                  </small>
                </div>
              </div>
              <div className=" grid gap-2 pl-8 mt-8">
                <div className=" flex gap-2 items-center">
                  <img className=" w-5 h-5" src={Work} alt="" />
                  <small className=" text-[#4D4D4D] font-Kumbh text-sm mt-1">
                    5 years 5 months
                  </small>
                </div>
                <div className=" flex gap-2 items-center">
                  <img className=" w-5 h-5" src={Placeholder} alt="" />
                  <small className=" text-[#4D4D4D] font-Kumbh text-sm">
                    Lagos State, Nigeria
                  </small>
                </div>
              </div>
              <div className=" space-y-2 mt-8">
                <p className=" text-center text-[#000] font-bold font-Kumbh">
                  Social Media
                </p>
                <div className=" flex items-center justify-center gap-4">
                  <img className=" w-6 h-6" src={JobLinkedin} alt="" />
                  <img className=" w-6 h-6" src={JobTwitter} alt="" />
                  <img className=" w-6 h-6" src={JobBehance} alt="" />
                  <img className=" w-6 h-6" src={JobInstagram} alt="" />
                </div>
              </div>
              <div className=" grid justify-center items-center mt-8 space-y-2">
                <p className=" text-[#000] text-base font-Kumbh font-bold text-center">
                  Portfolio
                </p>
                <div className=" flex items-center gap-2 justify-center ">
                  <img className=" w-3 h-3" src={JobLink} alt="" />
                  <small className=" font-Kumbh text-sm text-[#4d4d4d]">
                    www.tfol/janesmith/ae1
                  </small>
                </div>
              </div>
              <div className=" grid justify-center items-center mt-8 space-y-2">
                <p className=" text-[#000] text-base font-Kumbh font-bold text-center">
                  Profile Status
                </p>
                <div className=" flex items-center gap-2 justify-center ">
                  <ProfileStatusChart percentage={45} />
                </div>
              </div>
            </div>
            <div className=" w-[75%] p-5">
              <div className=" grid gap-10">
                {detail.map((i) => (
                  <div className=" flex justify-between">
                    <div className=" flex gap-5">
                      <img className=" w-10 h-10" src={i.img} alt="" />
                      <div className=" grid gap-1">
                        <p className=" text-[#000] font-semibold text-sm mb-2">
                          {i.title}
                        </p>
                        <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                          {i.porf}
                        </small>
                        <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                          {i.company}
                        </small>
                        <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                          {i.date}
                        </small>
                        <img
                          className=" cursor-pointer w-9 h-9 mt-5"
                          src={jobGroup}
                          alt=""
                        />
                      </div>
                    </div>
                    <img
                      className=" w-5 h-5 cursor-pointer"
                      src={Editjob}
                      alt=""
                    />
                  </div>
                ))}
              </div>

              <div className=" mt-10">
                <div className=" flex justify-between">
                  <div className=" flex gap-5">
                    <img className=" w-10 h-10" src={more} alt="" />
                    <div className=" grid gap-1">
                      <p className=" text-[#000] font-semibold text-sm mb-2">
                        More Details
                      </p>
                      <div className=" w-[400px]">
                        <div className=" flex justify-between">
                          <div className=" w-[100px]">
                            <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                              Date of Birth:
                            </small>
                          </div>
                          <div className=" w-[100px]">
                            <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                              12/12/2000
                            </small>
                          </div>
                        </div>
                        <div className=" flex justify-between">
                          <div className=" w-[100px]">
                            <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                              Gender:
                            </small>
                          </div>
                          <div className=" w-[100px]">
                            <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                              Female
                            </small>
                          </div>
                        </div>
                        <div className=" flex justify-between">
                          <div className=" w-[100px]">
                            <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                              Current Location:
                            </small>
                          </div>
                          <div className=" w-[100px]">
                            <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                              Abuja, Nigeria
                            </small>
                          </div>
                        </div>
                        <div className=" flex justify-between">
                          <div className=" w-[100px]">
                            <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                              Notice Period:
                            </small>
                          </div>
                          <div className=" w-[100px]">
                            <small className=" text-[#4d4d4d] font-Kumbh text-xs">
                              2 weeks
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className=" w-5 h-5 cursor-pointer"
                    src={Editjob}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountProfile;
