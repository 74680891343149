import React, { useState } from "react";
import GradientBtn from "../../buttons/gradientbtn";
import UploadFile from "./UploadFile";
import AssignMembers from "./AssignMembers";

const NewProject = ({ onclose }) => {
  const [isCreate, setIsCreate] = useState(false);

  const handleCreate = () => {
    setIsCreate(!isCreate);
  };
  const closeCreate = () => {
    setIsCreate(false);
  };
  return (
    <div className=" overflow-y-scroll fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
      <div className=" bg-white w-[682px] p-5 h-auto rounded-md ">
        <div className=" flex justify-between items-center">
          <p className=" font-Kumbh text-xl font-bold text-[#000]">
            New Project
          </p>
          <p
            onClick={onclose}
            className=" cursor-pointer text-[24px] font-bold"
          >
            &times;
          </p>
        </div>
        <div className=" space-y-5 mt-5 pr-5">
          <div className=" space-y-2">
            <label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
              Project Title
            </label>
            <input
              placeholder="Details"
              className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-lg outline-none placeholder:text-xs placeholder-[#A3A3A3] "
              type="text"
            />
          </div>
          <div className=" space-y-2">
            <label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
              Project Title
            </label>
            <input
              placeholder="Details"
              className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
              type="text"
            />
          </div>

          <div className=" ">
            <AssignMembers />
          </div>

          <div>
            <p className=" font-Kumbh text-xl font-bold text-[#000]">
              Add Task
            </p>
          </div>

          <div className=" space-y-2">
            <label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
              Task Title
            </label>
            <input
              placeholder="Details"
              className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
              type="text"
            />
          </div>
          <div className=" space-y-2">
            <label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
              Task ID
            </label>
            <input
              placeholder="Details"
              className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
              type="text"
            />
          </div>
          <div className=" space-y-2">
            <label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
              Task Tag
            </label>
            <input
              placeholder="Details"
              className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
              type="text"
            />
          </div>

          <div className=" space-y-2">
            <label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
              Priority
            </label>
            <div className=" flex items-center relative">
              <input
                placeholder="Details"
                className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
                type="text"
              />
              <img
                className=" w-4 h-4 absolute right-3"
                src={require("../../../assets/iconamoon_arrow-down-2-thin.png")}
                alt=""
              />
            </div>
          </div>
          <div>
            <AssignMembers />
          </div>

          <div>
            <UploadFile />
          </div>
        </div>
        <div className=" mt-10">
          <GradientBtn btnText="Add" />
        </div>
      </div>
    </div>
  );
};

export default NewProject;
