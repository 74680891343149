import React from "react";
import JobNavbar from "../../components/job/JobNavbar";
import JobSecNav from "../../components/job/JobSecNav";

const JobNotification = () => {
  return (
    <div className="bg-[#F2EFFC]">
      <JobNavbar />
      <JobSecNav />
      <div className="mx-12 bg-white p-5 mt-2 h-screen">
        <div className=" bg-[#F5EFFC] p-5  rounded-2xl">
          <p className=" text-xl font-bold font-Kumbh">DevOnline is Hiring</p>
        </div>

        <div className=" p-5">
          <p className=" font-Nunito text-sm text-#000]">
            Wednesday, 24/04.{" "}
            <span className="font-Nunito text-sm text-#000] pl-3">10:00am</span>
          </p>

          <div className=" p-5 text-[#272525] font-Nunito text-sm">
            {" "}
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique
            ipsam nostrum iure et maiores omnis in veniam neque sequi blanditiis
            totam beatae ex non delectus, vero quas corrupti officiis quaerat.
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobNotification;
