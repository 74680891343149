import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import useAuthStore from "../../data/Stores/Authstore";

const Navbar = () => {
  const [isSmall, setIsSmall] = useState(false),
    { user } = useAuthStore(),
    [salutation, setSalutation] = useState("");

  useEffect(() => {
    const date = new Date();
    const hrs = date.getHours();
    if (hrs < 12) {
      setSalutation("Good Morning");
    } else if (hrs >= 12 && hrs <= 17) {
      setSalutation("Good Afternoon");
    } else if (hrs >= 17 && hrs <= 24) {
      setSalutation("Good Evening");
    }
  }, []);

  const HandleSmall = () => {
    setIsSmall(!isSmall);
  };

  return (
    <div className="flex items-center justify-between bg-[#F4F6F8] border-b border-b-[#EFF6FC] lg:px-12 md:px-10 px-8 py-4">
      <div className="flex items-center gap-5">
        <div>
          <img
            className=" w-12 h-12"
            src={require("../../assets/CeHRlogo.png")}
            alt=""
          />
        </div>
        <div className=" flex items-center gap-3">
          <p className=" text-sm text-[#000] font-bold font-Satoshi">
            {salutation}, {user?.lastName || user?.type}!
          </p>
          <img className=" h-7" src={require("../../assets/Line.png")} alt="" />
        </div>
        {/* <div className=" lg:block md:block hidden">
          <input
            placeholder="Search"
            className=" border-[#CCCCCC] bg-[#EFF6FC] border-[0.5px] rounded-md w-96 h-10 outline-none pl-2"
            type="text"
          />
        </div> */}
      </div>

      <div className=" lg:flex md:flex hidden items-center gap-3 ">
        <Link to="/profile">
          <img
            className=" w-10 h-10"
            src={require("../../assets/user.png")}
            alt=""
          />
        </Link>

        <Link to="/chats">
          <img
            className=" w-10 h-10 cursor-pointer"
            src={require("../../assets/messages.png")}
            alt=""
          />
        </Link>

        <Link to="/notifications">
          <img
            className=" w-10 h-10"
            src={require("../../assets/notification.png")}
            alt=""
          />
        </Link>

        <div className=" flex items-center gap-2 pl-2">
          <div className=" grid">
            <p className="text-[#121212] font-Nunito text-[14px] font-normal">
              {user?.firstName} {user?.lastName}
            </p>
            <p className=" text-[12px] text-[#515151] font-light font-Nunito">
              {user?.type}
            </p>
          </div>
          <Link to="/profile">
            <img
              className=" w-[16px] h-[16px]"
              src={require("../../assets/down.png")}
              alt=""
            />
          </Link>
        </div>
      </div>
      <div className=" lg:hidden md:hidden block">
        <GiHamburgerMenu size={24} onClick={HandleSmall} />
        {isSmall && (
          <div className=" bg-[#EFF6FC] absolute z-10 top-20 p-5 right-0">
            <div className=" flex items-center gap-3 ">
              <Link to="/profile">
                <img
                  className=" w-10 h-10"
                  src={require("../../assets/user.png")}
                  alt=""
                />
              </Link>

              <Link to="/chats">
                <img
                  className=" w-10 h-10 cursor-pointer"
                  src={require("../../assets/messages.png")}
                  alt=""
                />
              </Link>

              <Link to="/notifications">
                <img
                  className=" w-10 h-10"
                  src={require("../../assets/notification.png")}
                  alt=""
                />
              </Link>

              <div className=" flex items-center gap-2 pl-2">
                <div className=" grid">
                  <p className="text-[#121212] font-Nunito text-[14px] font-normal">
                    Jane Smith
                  </p>
                  <p className=" text-[12px] text-[#515151] font-light font-Nunito">
                    Admin
                  </p>
                </div>
                <Link to="/profile">
                  <img
                    className=" w-[16px] h-[16px]"
                    src={require("../../assets/down.png")}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
