import React from "react";
import Option from "../../assets/Option.png";
import green from "../../assets/green.png";
import { useNavigate } from "react-router-dom";

const RecentNotifications = ({ img, title, min, notification }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" bg-white rounded-lg p-4">
        <p className=" text-[#191d23] text-base Satoshi font-bold">
          Recent Notifications
        </p>

        <div className=" grid gap-1">
          {notification.map((i) => (
            <div className=" bg-white border border-[#eeebeb] px-2 py-1 mt-4 ">
              <div className=" flex items-center justify-between gap-2">
                <img className=" w-8 h-8" src={i.img} alt="" />
                <div className=" gap-5">
                  <div>
                    <div className=" flex justify-between gap-2">
                      <p className=" text-xs font-Nunito font-medium text-[#272525]">
                        {i.title}
                      </p>
                      <img className=" w-2 h-2" src={green} alt="" />
                    </div>
                  </div>
                  <div className=" mt-3">
                    <div className=" flex justify-between items-center">
                      <p className=" text-[#151515] font-Nunito text-[10px]">
                        {i.min}
                      </p>
                      <img
                        onClick={() => navigate("/all-notifications")}
                        className=" w-3 h-3 cursor-pointer"
                        src={Option}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default RecentNotifications;
