import React, { useEffect, useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import ActionCompleted from "./ActionCompleted";
import { useFileStore, useFolderStore } from "../../data/Stores/LoggerStore";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/useGenFetcher";
import { Button } from "../buttons/Send";
import { EditData } from "../../data/Stores/Authstore";
import useLevelStore from "../../data/Stores/LevelStore";
import useCompanyStore from "../../data/Stores/CompanyStore";
import { useLocation } from "react-router-dom";

const AddFile = ({ closeFile, items }) => {
	const [isCreate, setIsCreate] = useState(false);

	const handleCreate = () => {
		setIsCreate(!isCreate);
	};
	const closeCreate = () => {
		setIsCreate(false);
		closeFile();
	};

	let init = {},
		[state, setState] = useState({}),
		[loading, setLoading] = useState(false),
		{ updateLogger, addLogger } = useFileStore(),
		{ returnErrors } = useErrorStore(),
		handleChangeImage = e => {
			const file = e.target.files[0];
			let err = "";

			if (!file) return (err = `File, ${file?.name} does not exist`);

			if (err) {
				return toast.error(err);
			} else {
				setState(prev => {
					let newPrev = { ...prev, image: file };
					return newPrev;
				});
			}
		},
		location = useLocation()?.state,
		ref = useRef();

	let handleSubmit = async e => {
		e?.preventDefault();
		if (Object.values(state)?.length === 0)
			return toast.info("Update Data required");
		let newState = state;

		newState.refType = "Folder";
		newState.ref = location?._id;

		setLoading(true);

		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/file?ref=${location?._id}`,
			type: "file",
			data: newState,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			items ? addLogger(response) : updateLogger(response);
			setState(init);
			handleCreate();
			return;
		}
		setLoading(false);
	};

	return (
		<div>
			<div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
				<div className=" bg-white w-[700px] h-auto rounded-md p-5">
					<div className=" flex items-center justify-between ">
						<p className=" font-Kumbh text-xl text-[#000] font-bold">
							Add File
						</p>
						<p
							onClick={closeFile}
							className="font-Kumbh text-xl text-[#000] font-bold cursor-pointer">
							X
						</p>
					</div>
					<div className=" space-y-5 mt-5 pr-5">
						<div
							className=" space-y-2 cursor-pointer"
							onClick={() => ref?.current?.click()}>
							<p className=" text-[#121212] font-Nunito text-sm">
								Choose File{" "}
								<span className=" font-thin text-xs text-[#121212]">
									(Not More Than 2MB)
								</span>
							</p>
							<div className=" w-full h-12 rounded-md bg-[#EFF6FC] grid justify-center items-center ">
								<p className=" text-xs Satoshi text-[#1B1B1B]">
									Choose from your device
								</p>
							</div>
						</div>
						<input
							title="Upload file"
							type="file"
							name="file"
							id="file"
							className="hidden"
							accept="image/*"
							onChange={handleChangeImage}
							ref={ref}
						/>
						<div>
							{state?.image && (
								<img
									className=" w-60"
									src={URL.createObjectURL(state?.image)}
									alt=""
								/>
							)}
						</div>
					</div>
					<div className=" mt-5">
						<Button
							css={
								" font-Nunito text-sm text-[#fff] font-normal bg-gradient-to-b from-[#0811D4] to-[#384295] w-44 h-8 rounded-3xl animate-pulse"
							}
							children={"Submit"}
							onClick={handleSubmit}
							loading={loading}
						/>
						{isCreate && <ActionCompleted onClose={closeCreate} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export const AddFolders = ({ closeFile, items }) => {
	const [isCreate, setIsCreate] = useState(false);

	const handleCreate = () => {
		setIsCreate(!isCreate);
	};
	const closeCreate = () => {
		setIsCreate(false);
		closeFile();
	};

	let init = {},
		[state, setState] = useState({}),
		[loading, setLoading] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ updateLogger, addLogger } = useFolderStore(),
		{ returnErrors } = useErrorStore(),
		{ currentSelected } = useCompanyStore(),
		level = useLevelStore(),
		[permit, setPermit] = useState(level?.data?.docs);

	let handleSubmit = async e => {
		e?.preventDefault();
		if (Object.values(state)?.length === 0)
			return toast.info("Update Data required");
		let newState = state;

		newState.levels = permit
			?.filter(item => item?.isChecked)
			?.map(item => item?._id);

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/folder/${currentSelected}/${items ? items?._id : ""}`,
			type: items ? "put" : "post",
			data: newState,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			items ? addLogger(response) : updateLogger(response);
			setState(init);
			handleCreate();
			return;
		}
		setLoading(false);
	};

	return (
		<div>
			<div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
				<div className=" bg-white w-[700px] h-auto rounded-md p-5">
					<div className=" flex items-center justify-between ">
						<p className=" font-Kumbh text-xl text-[#000] font-bold">
							Add Folder
						</p>
						<p
							onClick={closeFile}
							className="font-Kumbh text-xl text-[#000] font-bold cursor-pointer">
							X
						</p>
					</div>
					<div className=" space-y-3">
						<label
							className=" font-Nunito  text-sm font-normal text-[#121212]"
							htmlFor="">
							Folder Name
						</label>
						<input
							placeholder="Name"
							className=" w-full h-12 rounded-xl pl-4 border-[0.5px] outline-none border-[#D0D0D0] "
							type="text"
							value={state?.name || items?.name}
							onChange={textChange}
							name={"name"}
						/>
					</div>
					<div className=" hidden lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 mt-5">
						<div className=" space-y-3">
							<label
								className=" font-Nunito  text-sm font-normal text-[#121212]"
								htmlFor="">
								Send to
							</label>
							{/* <input
                            placeholder="Choose recipient"
                            className=" w-full h-12 rounded-xl pl-4"
                            type="text"
                          /> */}
							<div className="relative">
								<input
									placeholder="State of residence"
									className="border-[0.5px] border-[#D0D0D0] placeholder-[#A3A3A3]  pr-9  outline-none w-full h-12 rounded-xl pl-4"
									type="text"
								/>
								<div className="absolute inset-y-0 right-0 flex items-center px-2">
									<FiChevronDown className="text-gray-500 cursor-pointer" />
								</div>
							</div>
						</div>
					</div>
					<div className=" space-y-3 mt-5">
						<label
							className=" font-Nunito  text-sm font-normal text-[#121212]"
							htmlFor="">
							Folder description
						</label>
						<textarea
							className=" w-full h-20 rounded-xl pl-4 border-[0.5px] outline-none border-[#D0D0D0]"
							id=""
							cols="30"
							rows="10"
							value={state?.description || items?.description}
							onChange={textChange}
							name={"description"}></textarea>
					</div>

					<div className=" space-y-3 mt-5 hidden">
						<p className="text-[#121212] font-Nunito text-sm">Share by:</p>
						<div className=" flex items-center gap-3">
							<input className="" type="radio" />
							<p className="text-[#000] font-Nunito text-xs">Department</p>
						</div>
						<div className=" flex items-center gap-3">
							<input className="" type="radio" />
							<p className="text-[#000] font-Nunito text-xs">Level</p>
						</div>
						<div className=" flex items-center gap-3">
							<input className="" type="radio" />
							<p className="text-[#000] font-Nunito text-xs">Grade</p>
						</div>
						<div className=" flex items-center gap-3">
							<input className="" type="radio" />
							<p className="text-[#000] font-Nunito text-xs">Position</p>
						</div>
					</div>

					<div className="grid grid-cols-2 gap-8">
						{permit?.map((item, i) => (
							<div className="p-2 flex items-center" key={i}>
								<LevelCheck
									item={item}
									setPermit={setPermit}
									permit={permit}
									i={i}
									levels={state?.levels}
								/>
								<label htmlFor={`level${i}`} className="text-capitalize my-0">
									{item?.name}
								</label>
							</div>
						))}
					</div>

					<div className=" mt-5">
						{/* <button
							onClick={handleCreate}
							className=" font-Nunito text-sm text-[#fff] font-normal bg-gradient-to-b from-[#0811D4] to-[#384295] w-44 h-8 rounded-3xl animate-pulse">
							Create
						</button> */}
						<Button
							css={
								" font-Nunito text-sm text-[#fff] font-normal bg-gradient-to-b from-[#0811D4] to-[#384295] w-44 h-8 rounded-3xl animate-pulse"
							}
							children={"Submit"}
							onClick={handleSubmit}
							loading={loading}
						/>
						{isCreate && <ActionCompleted onClose={closeCreate} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddFile;

export const LevelCheck = ({ item, permit, setPermit, i, levels }) => {
	let [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		let findUser = permit?.find(it => it?.isChecked && it?._id === item?._id);
		if (findUser) setIsChecked(true);
		else if (levels?.includes(item?._id)) {
			setIsChecked(true);
			setPermit(EditData(permit, { ...item, isChecked: true }));
		} else setIsChecked(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item, permit, levels]);

	return (
		<span className="me-3">
			<input
				type="checkbox"
				name={`level${i}`}
				id={`level${i}`}
				className="form-check-input form-check form-check-inline"
				checked={isChecked}
				onChange={e => {
					let newItem = item;
					if (e.target.checked) newItem = { ...newItem, isChecked: true };
					else
						newItem = {
							...newItem,
							isChecked: false,
						};
					setPermit(EditData(permit, newItem));
				}}
			/>
		</span>
	);
};
