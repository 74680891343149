import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
// import { FiChevronDown } from "react-icons/fi";
import ActionCompleted from "../../../components/cards/ActionCompleted";
import { useNavigate } from "react-router-dom";
import useStaffStore from "../../../data/Stores/StaffReducer";
import useCompanyStore from "../../../data/Stores/CompanyStore";
import useErrorStore from "../../../data/Stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../../data/useGenFetcher";
import { Button } from "../../../components/buttons/Send";
import moment from "moment";
import useDepartmentStore from "../../../data/Stores/DepartmentStore";
import usePositionStore from "../../../data/Stores/PositionStore";
import useLevelStore from "../../../data/Stores/LevelStore";
import useGradeStore from "../../../data/Stores/GradeStore";
import { useContext } from "react";
import { GlobalState } from "../../../data/Context";

const AddNewEmployee = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [showComponent, setShowComponent] = useState(false);

	const handleComponent = () => {
		setShowComponent(!showComponent);
	};

	const handleClose = () => {
		setShowComponent(false);
		navigate(-1);
	};

	let init = {},
		department = useDepartmentStore(),
		position = usePositionStore(),
		level = useLevelStore(),
		grade = useGradeStore(),
		navigate = useNavigate(),
		[show, setShow] = useState(false),
		{ countryState } = useContext(GlobalState),
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ addStaff } = useStaffStore(),
		{ currentSelected } = useCompanyStore(),
		{ returnErrors } = useErrorStore();

	useEffect(() => {
		if (!currentSelected) return;
		apiCall({
			type: "get",
			url: "/api/v1/department?_limit=0",
			getter: d => department?.getDepartment(d),
		});
		apiCall({
			type: "get",
			url: "/api/v1/position?_populate=department&_limit=0",
			getter: d => position?.getPosition(d),
		});
		apiCall({
			type: "get",
			url: `/api/v1/level/${currentSelected}?_limit=0`,
			getter: d => level?.getLevel(d),
		});
		apiCall({
			type: "get",
			url: `/api/v1/grade?_limit=0`,
			getter: d => grade?.getGrade(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSelected]);

	let handleSubmit = async e => {
		e?.preventDefault();
		let newState = state;

		if (!state?.lastName) return;
		let err = "";
		for (var prop in state) {
			if (state?.hasOwnProperty(prop)) {
				if (!state?.[prop]) err = "error";
			} else {
			}
		}
		if (err) return toast.info("Please fill all fields");

		if (state?.dateOfBirth) {
			newState = {
				...newState,
				birthMonth: moment(state?.dateOfBirth)?.format("MM"),
				birthDay: moment(state?.dateOfBirth)?.format("DD"),
			};
		}
		if (state?.weddingAnniversary && state?.maritalStatus === "married") {
			newState = {
				...newState,
				weddingAnniversaryMonth: moment(state?.weddingAnniversary)?.format(
					"MM"
				),
				weddingAnniversaryDay: moment(state?.weddingAnniversary)?.format("DD"),
			};
		}

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/company/${currentSelected || ""}/add-staff`,
			type: "post",
			data: newState,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			addStaff(response);
			setState(init);
			handleComponent();
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Add New Employee
					</p>
				</div>

				<div className=" grid bg-white mx-12 mt-5 p-5">
					<div className=" flex  ">
						<div className=" w-[55%] grid grid-cols-2 gap-5">
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									First name
								</label>
								<input
									placeholder="Enter first name"
									className=" border-[0.5px] placeholder-[#A3A3A3] pl-2 border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
									value={state?.firstName}
									onChange={textChange}
									name={"firstName"}
								/>
							</div>
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Last name
								</label>
								<input
									placeholder="Enter last name"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
									value={state?.lastName}
									onChange={textChange}
									name={"lastName"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Personal email address
								</label>
								<input
									placeholder="Enter email address"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="email"
									value={state?.personalEmail}
									onChange={textChange}
									name={"personalEmail"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Official Email
								</label>
								<input
									placeholder="Official email"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="email"
									value={state?.email}
									onChange={textChange}
									name={"email"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Gender
								</label>
								<div className="relative">
									<select
										className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12 capitalize"
										type="text"
										value={state?.gender}
										onChange={textChange}
										name={"gender"}>
										<option value="">Select One</option>
										{[{ value: "male" }, { value: "female" }]?.map((it, i) => (
											<option value={it?.value} key={i}>
												{it?.value}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Department
								</label>
								<div className="relative">
									{/* <input
										placeholder="Select department"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
										type="text"
										value={state?.department}
										onChange={textChange}
										name={"department"}
									/> */}
									<select
										className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12 "
										type="text"
										value={state?.department}
										onChange={textChange}
										name={"department"}>
										<option value="">Select One</option>
										{department?.data?.docs?.map((it, i) => (
											<option value={it?._id} key={i}>
												{it?.name}
											</option>
										))}
									</select>
									{/* <div className="absolute inset-y-0 right-0 flex items-center px-2">
										<FiChevronDown className="text-gray-500 cursor-pointer" />
									</div> */}
								</div>
							</div>
							{state?.department && (
								<div className=" space-y-2">
									<label
										htmlFor=""
										className=" text-[#121212] text-sm font-Nunito">
										Position
									</label>
									<div className="relative">
										{/* <input
										placeholder="Select department"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
										type="text"
										value={state?.department}
										onChange={textChange}
										name={"department"}
									/> */}
										<select
											className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12 "
											type="text"
											value={state?.position}
											onChange={textChange}
											name={"position"}>
											<option value="">Select One</option>
											{position?.data?.docs
												?.filter(
													item =>
														item?.name &&
														item?.department?._id === state?.department
												)
												?.map((it, i) => (
													<option value={it?._id} key={i}>
														{it?.name}
													</option>
												))}
										</select>
										{/* <div className="absolute inset-y-0 right-0 flex items-center px-2">
										<FiChevronDown className="text-gray-500 cursor-pointer" />
									</div> */}
									</div>
								</div>
							)}
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Phone number
								</label>
								<input
									placeholder="Enter phone number"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="tel"
									value={state?.phone}
									onChange={textChange}
									name={"phone"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Level
								</label>
								<div className="relative">
									{/* <input
										placeholder="Select level"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
										type="text"
										value={state?.level}
										onChange={textChange}
										name={"level"}
									/> */}
									<select
										className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12 "
										type="text"
										value={state?.level}
										onChange={textChange}
										name={"level"}>
										<option value="">Select One</option>
										{level?.data?.docs?.map((it, i) => (
											<option value={it?._id} key={i}>
												{it?.name}
											</option>
										))}
									</select>
									{/* <div className="absolute inset-y-0 right-0 flex items-center px-2">
										<FiChevronDown className="text-gray-500 cursor-pointer" />
									</div> */}
								</div>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Grade
								</label>
								<div className="relative">
									{/* <input
										placeholder="Select grade"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
										type="text"
										value={state?.grade}
										onChange={textChange}
										name={"grade"}
									/> */}
									<select
										className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12 "
										type="text"
										value={state?.grade}
										onChange={textChange}
										name={"grade"}>
										<option value="">Select One</option>
										{grade?.data?.docs?.map((it, i) => (
											<option value={it?._id} key={i}>
												{it?.name}
											</option>
										))}
									</select>
									{/* <div className="absolute inset-y-0 right-0 flex items-center px-2">
										<FiChevronDown className="text-gray-500 cursor-pointer" />
									</div> */}
								</div>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Marital Status
								</label>
								<div className="relative">
									<select
										className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12 capitalize"
										type="text"
										value={state?.maritalStatus}
										onChange={textChange}
										name={"maritalStatus"}>
										<option value="">Select One</option>
										{[{ value: "married" }, { value: "single" }]?.map(
											(it, i) => (
												<option value={it?.value} key={i}>
													{it?.value}
												</option>
											)
										)}
									</select>
								</div>
							</div>
							{state?.maritalStatus === "married" && (
								<div className=" space-y-2">
									<label
										htmlFor=""
										className=" text-[#121212] text-sm font-Nunito">
										Wedding Anniversary
									</label>
									<div className="relative">
										<input
											placeholder="dd/mm/yyyy"
											className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
											type="date"
											value={state?.weddingAnniversary}
											onChange={textChange}
											name={"weddingAnniversary"}
											max={moment().format("YYYY-MM-DD")}
										/>
										{/* <div className="absolute inset-y-0 right-0 flex items-center px-2">
										<img
											className=" w-4 h-4 cursor-pointer"
											src={require("../../../assets/calender.png")}
											alt=""
										/>
									</div> */}
									</div>
								</div>
							)}
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Date of birth
								</label>
								<div className="relative">
									<input
										placeholder="dd/mm/yyyy"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
										type="date"
										value={state?.dateOfBirth}
										onChange={textChange}
										name={"dateOfBirth"}
										max={moment().format("YYYY-MM-DD")}
									/>
									{/* <div className="absolute inset-y-0 right-0 flex items-center px-2">
										<img
											className=" w-4 h-4 cursor-pointer"
											src={require("../../../assets/calender.png")}
											alt=""
										/>
									</div> */}
								</div>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Employee ID
								</label>
								<input
									placeholder="Staff ID"
									className=" border-[0.5px] bg-[#D0D0D0] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
									value={state?.employeeId}
									onChange={textChange}
									name={"employeeId"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Local Government Area
								</label>
								<input
									placeholder="LGA"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
									value={state?.lgaOfOrigin}
									onChange={textChange}
									name={"lgaOfOrigin"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									State of Origin
								</label>
								<div className="relative">
									{/* <input
										placeholder="State of origin"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
										type="text"
										value={state?.stateOfOrigin}
										onChange={textChange}
										name={"stateOfOrigin"}
									/> */}
									<div className="relative">
										<select
											className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12 capitalize"
											type="text"
											value={state?.stateOfOrigin}
											onChange={textChange}
											name={"stateOfOrigin"}>
											<option value="">Select One</option>
											{countryState?.map((it, i) => (
												<option value={it?.name} key={i}>
													{it?.name}
												</option>
											))}
										</select>
									</div>
									{/* <div className="absolute inset-y-0 right-0 flex items-center px-2">
										<FiChevronDown className="text-gray-500 cursor-pointer" />
									</div> */}
								</div>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									State of Residence
								</label>
								<div className="relative">
									{/* <input
										placeholder="State of residence"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
										type="text"
										value={state?.stateOfResidence}
										onChange={textChange}
										name={"stateOfResidence"}
									/> */}
									<div className="relative">
										<select
											className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] rounded-md outline-none w-full h-12 capitalize"
											type="text"
											value={state?.stateOfResidence}
											onChange={textChange}
											name={"stateOfResidence"}>
											<option value="">Select One</option>
											{countryState?.map((it, i) => (
												<option value={it?.name} key={i}>
													{it?.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Password
								</label>
								<div className="relative">
									<input
										placeholder="******"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
										type={show ? "text" : "password"}
										value={state?.password}
										onChange={textChange}
										name={"password"}
									/>
									<div className="absolute inset-y-0 right-0 flex items-center px-2">
										<img
											onClick={() => setShow(!show)}
											className=" w-3 h-2 cursor-pointer"
											src={require("../../../assets/passwordVector.png")}
											alt=""
										/>
									</div>
								</div>
							</div>
						</div>
						<div className=" w-[45%] h-auto grid justify-center ">
							<div className=" border-[#E8E8E8] border-[0.5px] rounded-md bg-white w-80 h-3/5">
								<div className=" grid justify-center items-center py-10">
									<img
										className=" w-40 h-40"
										src={require("../../../assets/AddPhoto.png")}
										alt=""
									/>
								</div>
								<div className=" space-y-4">
									<div>
										<p className=" text-[#777777] text-sm font-Nunito font-light text-center">
											Allowed format
										</p>
										<p className=" text-[#272525] text-sm font-Nunito font-bold text-center">
											JPG, JPEG, and PNG
										</p>
									</div>
									<div>
										<p className="text-[#777777] text-sm font-Nunito font-light text-center">
											Max file size
										</p>
										<p className=" text-[#272525] text-sm font-Nunito font-bold text-center">
											2MB
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className=" grid justify-center items-center">
						{/* <button
							onClick={handleComponent}
							className=" bg-gradient-to-b from-[#0811D4] to-[#384295] w-80 h-11 rounded-md text-white">
							Add Staff
						</button> */}
						<Button
							css={
								" bg-gradient-to-b from-[#0811D4] to-[#384295] w-80 h-11 rounded-md text-white mt-3"
							}
							children={"Add Staff"}
							onClick={handleSubmit}
							loading={loading}
						/>
						{showComponent && <ActionCompleted onClose={handleClose} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddNewEmployee;
