import React from "react";

const MyRequests = () => {
  const tableHead = ["S/N", "Name", "Leave Type", "From - To", "Request"],
    tableData = [
      {
        SN: "1",
        Name: "Catherine Mark",
        LeaveType: "Unpaid - Sick Leave",
        FromTo: "25/03 - 12/04",
        Request: "10",
      },
      {
        SN: "2",
        Name: "Catherine Mark",
        LeaveType: "Unpaid - Sick Leave",
        FromTo: "25/03 - 12/04",
        Request: "10",
      },
      {
        SN: "3",
        Name: "Catherine Mark",
        LeaveType: "Unpaid - Sick Leave",
        FromTo: "25/03 - 12/04",
        Request: "10",
      },
    ];
  return (
    <div className=" grid bg-white rounded-lg p-5 mt-5 ">
      <div className=" flex items-center justify-between ">
        <p className=" text-sm font-bold text-[#000] Satoshi">MyRequests</p>
        <div className=" bg-[#F5DFFF] rounded-full px-2 flex gap-2 items-center cursor-pointer">
          <img
            className=" w-3 h-3"
            src={require("../../assets/ViewEye.png")}
            alt=""
          />
          <p className=" text-[#7B08D4] text-sm font-thin Satoshi">View All</p>
        </div>
      </div>
      <div className=" w-full overflow-x-scroll ">
        <table className="min-w-full table divide-y-2 divide-yellow-[#7E3AF2] h-full">
          <thead className="h-16 w-full ">
            <tr className="text-black">
              {tableHead?.map((it, index) => (
                <th
                  className=" font-Nunito text-[11px] Satoshi font-light"
                  key={index}
                >
                  {it}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="divide-y">
            {tableData?.map((it, i) => (
              <tr key={i} className=" bg-white py-4 h-16    ">
                <td className=" px-4  font-Nunito text-[11px] Satoshi font-light text-center ">
                  {it?.SN}
                </td>
                <td className="  font-Nunito text-[11px] Satoshi font-light text-center">
                  {it?.Name}
                </td>
                <td className="  font-Nunito text-[11px] Satoshi font-light text-center">
                  {it?.LeaveType}
                </td>
                <td className="  font-Nunito text-[11px] Satoshi font-light text-center">
                  {it?.FromTo}
                </td>
                <td className="  font-Nunito text-[11px] Satoshi font-light text-center">
                  {it?.Request}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyRequests;
