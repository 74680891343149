import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import { Link, useLocation } from "react-router-dom";
import { useJobStore } from "../../../data/Stores/LoggerStore";
import { createMarkup } from "../../../components/cards/ReviewSubmit";
import { apiCall } from "../../../data/useGenFetcher";
import useCompanyStore from "../../../data/Stores/CompanyStore";

const Applications = () => {
	const [selectedReport, setSelectedReport] = useState("basic");

	let items = useLocation()?.state;
	let [state, setState] = useState(items),
		{ data } = useJobStore(),
		[datum, setDatum] = useState(null),
		{ currentSelected } = useCompanyStore();

	const card = [
		{
			id: 1,
			num:
				datum?.docs?.filter(
					item => item?.status?.toLowerCase() === "shortlisted"
				).length || 0,
			text: "Shortlisted",
			img: require("../../../assets/imggg2.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "small",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #C8BBFC, #A986E2  )",
				textColor: "#ffffff",
			},
			keyText: "shortlisted",
		},
		{
			id: 2,
			keyText: "interview",
			num:
				datum?.docs?.filter(item => item?.status?.toLowerCase() === "interview")
					.length || 0,
			text: "Interviewed",
			img: require("../../../assets/imgg1.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "medium",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #B1D9FF, #114DA7)",
				textColor: "#ffffff",
			},
		},
		{
			id: 3,
			keyText: "background check",
			num:
				datum?.docs?.filter(
					item => item?.status?.toLowerCase() === "background check"
				).length || 0,
			text: "Background Checked",
			img: require("../../../assets/Checkmark.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "large",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #F1CBFF, #D609F7)",
				textColor: "#ffffff",
			},
		},
		{
			id: 4,
			keyText: "hired",
			num:
				datum?.docs?.filter(item => item?.status?.toLowerCase() === "hired")
					.length || 0,
			text: "Accepted",
			img: require("../../../assets/tick-iso-color.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "extra-large",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #BB5BF6, #141DE2)",
				textColor: "#ffffff",
			},
		},
		{
			id: 5,
			keyText: "newly applied",
			num:
				datum?.docs?.filter(
					item => item?.status?.toLowerCase() === "newly applied"
				).length || 0,
			text: "Pending",
			img: require("../../../assets/Expired.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "mega-large",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #F9CF61, #F5BA61)",
				textColor: "#ffffff",
			},
		},
		{
			id: 6,
			keyText: "health check",
			num:
				datum?.docs?.filter(
					item => item?.status?.toLowerCase() === "health check"
				).length || 0,
			text: "Health Checked",
			img: require("../../../assets/heart-iso-color.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "ultra-large",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #F6ACCF, #F7096D)",
				textColor: "#ffffff",
			},
		},
		{
			id: 7,
			keyText: "rejected",
			num:
				datum?.docs?.filter(item => item?.status?.toLowerCase() === "rejected")
					.length || 0,
			text: "Rejected",
			img: require("../../../assets/Rejected.png"),
			desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
			size: "jumbo-large",
			numStyle: {
				width: "41px",
				height: "35px",
				background: "linear-gradient(to bottom, #FFA9A9, #FF5C5C)",
				textColor: "#ffffff",
			},
		},
	];

	useEffect(() => {
		data?.docs?.map(it => it?._id === items?._id && setState(it));
	}, [data, items]);

	useEffect(() => {
		if (!currentSelected) return;
		apiCall({
			type: "get",
			url: `/api/v1/job-application/${
				currentSelected || ""
			}?_populate=job&job=${items?._id}&_limit=0`,
			getter: d => setDatum(d?.data || d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, currentSelected]);

	// console.log({ datum });

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />

			<div className=" flex justify-between items-center px-12 pt-4">
				<p className=" font-Kumbh text-[#000] text-base font-bold">
					{state?.name}
				</p>
			</div>

			<div className=" mx-12 bg-white p-5 mt-5">
				<div className=" flex items-center gap-10">
					<button
						className={` text-gray-600 font-Nunito text-base cursor-pointer${
							selectedReport === "basic"
								? " border-b-[5px] border-b-[#F7AC25]"
								: "bg-white text-[#1C1C1C]  "
						}`}
						onClick={() => setSelectedReport("basic")}>
						Applications
					</button>
					<button
						className={` text-gray-600 font-Nunito text-base cursor-pointer ${
							selectedReport === "job"
								? "border-b-[5px] border-b-[#F7AC25]"
								: "bg-white text-#071C50] "
						}`}
						onClick={() => setSelectedReport("job")}>
						Job Details
					</button>
				</div>

				<div className=" ">
					{selectedReport === "basic" && (
						<div>
							{" "}
							<div className=" grid grid-cols-4 gap-y-5 items-center justify-between px-6 pt-8">
								{/* one */}

								{card.map(i => (
									<div className=" w-60 h-auto ">
										<div
											style={{
												borderWidth: i.numStyle.borderWidth,
												borderColor: i.numStyle.borderColor,
												width: i.numStyle.width,
												height: i.numStyle.height,
												background: i.numStyle.background,
												//   backgroundColor: i.numStyle.backgroundColor,
											}}
											className=" absolute  rounded-md grid justify-center items-center bg-white">
											<p className=" Satoshi text-[#fff] text-xs font-bold">
												{i.num}
											</p>
										</div>
										<Link
											to="all-candidates"
											state={{ keyText: i?.keyText, state }}
											className=" bg-white mt-5 h-32 hover:bg-slate-200 rounded-md shadow-lg grid items-end w-full ml-5  px-4">
											<div className=" flex items-center justify-between mt-5 relative bottom-6 ">
												<p className="  text-base text-[#808080] font-bold  font-Kumbh">
													{i.text}
												</p>
												<img
													className={`w-16 h-16 ${
														i.size === "small"
															? "sm:w-10 sm:h-16"
															: i.size === "medium"
															? "md:w-10 md:h-10"
															: i.size === "large"
															? "lg:w-10 lg:h-10"
															: i.size === "extra-large"
															? "xl:w-10 xl:h-10"
															: i.size === "mega-large"
															? "w-10 h-10"
															: i.size === "ultra-large"
															? "w-10 h-10"
															: i.size === "jumbo-large"
															? "w-10 h-10"
															: "" // Default size
													}`}
													src={i.img}
													alt=""
												/>
											</div>
										</Link>
									</div>
								))}
							</div>
						</div>
					)}

					{selectedReport === "job" && (
						<div className=" mt-10 space-y-10">
							<div className=" flex justify-between">
								<div>
									<div className="flex items-center gap-2">
										<img
											className=" h-11 w-3"
											src={require("../../../assets/Rectanglejob.png")}
											alt=""
										/>
										<div>
											<p className=" font-Nunito text-base font-bold text-[#071C50]">
												Basic Information
											</p>
											<p className=" text-[#071C50] font-Nunito text-sm">
												{state?.name}
											</p>
										</div>
									</div>
									<div className=" pl-5 mt-3">
										<p
											className=" lg:w-[640px] font-Nunito text-xs text-[#000]"
											dangerouslySetInnerHTML={createMarkup(
												state?.description || ""
											)}
										/>
									</div>
								</div>
								<img
									className=" w-16 h-16"
									src={require("../../../assets/Info.png")}
									alt=""
								/>
							</div>
							<div className=" flex justify-between">
								<div>
									<div className="flex items-center gap-2">
										<img
											className=" h-11 w-3"
											src={require("../../../assets/Rectanglejob.png")}
											alt=""
										/>
										<div>
											<p className=" font-Nunito text-base font-bold text-[#071C50]">
												Job Level
											</p>
											<p className=" text-[#071C50] font-Nunito text-sm capitalize">
												Level: {state?.level?.replace("-", " ")}
											</p>
										</div>
									</div>
									<div className=" pl-5 mt-3">
										<p className=" lg:w-[640px] font-Nunito text-xs text-[#1443B9]">
											Mode: - | Location: {state?.location}
										</p>
									</div>
								</div>
								<img
									className=" w-16 h-16"
									src={require("../../../assets/text-dynamic-color.png")}
									alt=""
								/>
							</div>
							<div className=" flex justify-between">
								<div>
									<div className="flex items-center gap-2">
										<img
											className=" h-11 w-3"
											src={require("../../../assets/Rectanglejob.png")}
											alt=""
										/>
										<div>
											<p className=" font-Nunito text-base font-bold text-[#071C50]">
												Hiring Details
											</p>
											<p className=" text-[#071C50] font-Nunito text-sm capitalize">
												Type: {state?.type}
											</p>
										</div>
									</div>
									<div className=" pl-5 mt-3">
										<p className=" lg:w-[640px] font-Nunito text-xs text-[#071C50]">
											Hiring Managers: {state?.hiringManager}
										</p>
										<p className=" lg:w-[640px] font-Nunito text-xs text-[#071C50]">
											Recruiters: {state?.recruiters}
										</p>
										<p className=" lg:w-[640px] font-Nunito text-xs text-[#071C50] capitalize">
											Recruitment Limit: {state?.limit}
										</p>
									</div>
								</div>
								<img
									className=" w-16 h-16"
									src={require("../../../assets/Information.png")}
									alt=""
								/>
							</div>
						</div>
						// <div className="grid gap-4 w-1/2">
						//   {job.map((i) => (
						//     <div className="">
						//       <p></p>
						//     </div>
						//   ))}
						// </div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Applications;
