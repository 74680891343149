import React, { useEffect, useState } from "react";
// import Tools from "../buttons/Tools";
// import ShortAnswer from "../buttons/ShortAnswer";
// import CopyDelete from "../buttons/CopyDelete";
import ActionCompleted from "./ActionCompleted";
import useStaffStore from "../../data/Stores/StaffReducer";
import useCompanyStore from "../../data/Stores/CompanyStore";
import { apiCall } from "../../data/useGenFetcher";
import { useManageAdminsStore } from "../../data/Stores/LoggerStore";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import { Button } from "../buttons/Send";
import usePositionStore from "../../data/Stores/PositionStore";
import useAuthStore from "../../data/Stores/Authstore";
// import Option from "./Option";

const NewAdmin = ({ onClose, title, type }) => {
	const [submit, setSubmit] = useState(false),
		{ getStaff, data } = useStaffStore(),
		{ currentSelected } = useCompanyStore();

	const closeSubmit = () => {
		setSubmit(false);
	};

	useEffect(() => {
		if (!currentSelected) return;
		apiCall({
			type: "get",
			getter: d => getStaff(d),
			url: `/api/v1/user/users/${currentSelected}?_populate=position&_populate=department&_populate=level&_populate=grade&_limit=0`,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSelected]);

	let init = {},
		[state, setState] = useState({}),
		[loading, setLoading] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ addDynamicLogger } = useManageAdminsStore(),
		{ returnErrors } = useErrorStore(),
		{ data: dataPosition } = usePositionStore(),
		{ user } = useAuthStore();

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.userId) return toast.info("Employee is required");
		let newState = {};

		if (["manager1", "manager2"]?.includes(type))
			newState.gradeForPosition = [state?.gradeForPosition];

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/company/add/${type}/${currentSelected}/${state?.userId}`,
			type: "put",
			data: newState,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			addDynamicLogger(
				response,
				["admin", "manager"]?.includes(type) ? `${type}s` : type
			);
			setState(init);
			onClose();
			setSubmit(true);
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" overflow-y-scroll fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
			<div className=" bg-white w-[682px] p-5 h-auto rounded-md ">
				<div className=" flex justify-between items-center">
					<p className=" font-Kumbh text-xl font-bold text-[#000] flex items-center">
						New {title}
					</p>
					<p
						onClick={onClose}
						className=" cursor-pointer text-[24px] font-bold">
						&times;
					</p>
				</div>
				<div className=" space-y-5 mt-5 pr-5">
					<div className=" ">
						<p className=" text-sm text-[#000000] font-Nunito">
							An Admin is created from an employee account. The Employee would
							use their already existing password to login to their Admin
							account
						</p>
					</div>

					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Select Employee
						</label>
						<select
							className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={state?.userId}
							onChange={textChange}
							name={"userId"}>
							<option value="">Select One</option>
							{data?.docs
								?.sort((a, b) => {
									let nameA = a?.profile?.lastName || a?.lastName,
										nameB = b?.profile?.lastName || b?.lastName;
									var textA = nameA?.toUpperCase();
									var textB = nameB?.toUpperCase();
									return textA < textB ? -1 : textA > textB ? 1 : 0;
								})
								?.filter(item => item?._id && item?._id !== user?._id)
								?.map((it, i) => (
									<option value={it?._id} key={i}>
										{it?.firstName} {it?.lastName}
									</option>
								))}
						</select>
					</div>
					{["manager1", "manager2"]?.includes(type) && (
						<div className=" space-y-2">
							<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
								Select Position
							</label>
							<select
								className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
								type="text"
								value={state?.gradeForPosition}
								onChange={textChange}
								name={"gradeForPosition"}>
								<option value="">Select One</option>
								{dataPosition?.docs
									?.sort((a, b) => {
										let nameA = a?.name,
											nameB = b?.name;
										var textA = nameA.toUpperCase();
										var textB = nameB.toUpperCase();
										return textA < textB ? -1 : textA > textB ? 1 : 0;
									})
									?.map((it, i) => (
										<option value={it?._id} key={i}>
											{it?.name}
										</option>
									))}
							</select>
						</div>
					)}

					{/* <div className=" flex justify-end">
            <img
              className=" w-6 h-6"
              src={require("../../assets/pluse.png")}
              alt=""
            />
          </div> */}

					<div>
						<p className=" text-lg font-Nunito text-[#000] font-bold">
							Highlights of Permissions
						</p>
						<p className="text-sm font-Nunito text-[#000]">
							This admin has the following Permissions
						</p>
						<ul className=" list-disc pl-7">
							<li className="text-sm font-Nunito text-[#000]">
								Would be able to approve Leave, Cash Request from his department
							</li>
							<li className="text-sm font-Nunito text-[#000]">
								Would be able to View Salary Structure of Employee
							</li>
							<li className="text-sm font-Nunito text-[#000]">
								Would be able to update an Employee Profile
							</li>
							<li className="text-sm font-Nunito text-[#000]">
								Would be able to View Salary Structure of Employee
							</li>
						</ul>
					</div>
				</div>

				<div className=" space-y-5 mt-20">
					<div>
						{/* <button
							onClick={handleSubmit}
							className=" w-44 h-8 rounded-lg bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito">
							Create
						</button> */}
						<Button
							css={
								" w-44 h-8 rounded-lg bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito"
							}
							children={"Create"}
							onClick={handleSubmit}
							loading={loading}
						/>
						{submit && <ActionCompleted onClose={closeSubmit} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewAdmin;
