import React, { useState } from "react";
// import Tools from "../buttons/Tools";
// import ShortAnswer from "../buttons/ShortAnswer";
// import CopyDelete from "../buttons/CopyDelete";
import ActionCompleted from "./ActionCompleted";
import usePositionStore from "../../data/Stores/PositionStore";
import useCompanyStore from "../../data/Stores/CompanyStore";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/useGenFetcher";
import { Button } from "../buttons/Send";
import useDepartmentStore from "../../data/Stores/DepartmentStore";
import { useEffect } from "react";
// import Option from "./Option";

const CreatePosition = ({ onClose }) => {
  // const [isSwitched, setIsSwitched] = useState(false);
  const [submit, setSubmit] = useState(false);
  // const [showAnswer, setShowAnswer] = useState(false);

  // const handleShowAnswer = () => {
  //   setShowAnswer(!showAnswer);
  // };
  // const closeShowAnswer = () => {
  //   setShowAnswer(false);
  // };

  // const handleSubmit = () => {
  //   setSubmit(!submit);
  // };

  const closeSubmit = () => {
    setSubmit(false);
  };

  // const handleClick = () => {
  //   setIsSwitched(!isSwitched);
  // };


    let init = {},
			[state, setState] = useState({}),
			[loading, setLoading] = useState(false),
			textChange = ({ target: { value, name } }) => {
				setState({ ...state, [name]: value });
			},
			{ addPosition } = usePositionStore(),
			{ currentSelected } = useCompanyStore(),
			{ returnErrors } = useErrorStore(),
      {data, getDepartment}=useDepartmentStore();

      useEffect(() => {
				apiCall({
					type: "get",
					url: "/api/v1/department?_limit=0",
					getter: d => getDepartment(d),
				});
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, []);

		let handleSubmit = async e => {
			e?.preventDefault();
			if (!state?.name) return toast.info("Position name is required");
			let newState = state;

			setLoading(true);
			let { response, errArr, errMsg } = await apiCall({
				url: `/api/v1/position/${currentSelected}`,
				type: "post",
				data: newState,
			});
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				addPosition(response);
				setState(init);
				onClose();
				setSubmit(true);
				return;
			}
			setLoading(false);
		};



  return (
		<div className=" overflow-y-scroll fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
			<div className=" bg-white w-[682px] p-5 h-auto rounded-md ">
				<div className=" flex justify-between items-center">
					<p className=" font-Kumbh text-xl font-bold text-[#000]">
						Create Position
					</p>
					<p
						onClick={onClose}
						className=" cursor-pointer text-[24px] font-bold">
						&times;
					</p>
				</div>
				<div className=" space-y-5 mt-5 pr-5">
					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Department Name
						</label>
						<select
							className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={state?.department}
							onChange={textChange}
							name={"department"}>
							<option value="">Select One</option>
							{data?.docs?.map((it, i) => (
								<option value={it?._id} key={i}>
									{it?.name}
								</option>
							))}
						</select>
					</div>
					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Position
						</label>
						<input
							placeholder="Title"
							className=" border border-[#D0D0D0] px-2 w-full h-12 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={state?.name}
							onChange={textChange}
							name={"name"}
						/>
					</div>

					<div className=" space-y-2">
						<label className=" text-[#121212] font-Nunito text-sm" htmlFor="">
							Description
						</label>
						<input
							placeholder="Description"
							className=" border border-[#D0D0D0] px-2 w-full h-28 rounded-md outline-none placeholder:text-xs placeholder-[#A3A3A3] "
							type="text"
							value={state?.description}
							onChange={textChange}
							name={"description"}
						/>
					</div>
				</div>

				<div className=" space-y-5 mt-20">
					<div>
						{/* <button
							onClick={handleSubmit}
							className=" w-44 h-8 rounded-lg bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito">
							Create
						</button> */}
						<Button
							css={
								" w-44 h-8 rounded-lg bg-gradient-to-b from-[#5608D4] to-[#384295] text-white text-sm font-Nunito"
							}
							children={"Create"}
							onClick={handleSubmit}
							loading={loading}
						/>
						{submit && <ActionCompleted onClose={closeSubmit} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreatePosition;
