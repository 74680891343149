import React from "react";
import { RadioGroup } from "./BasicInformation";

const HiringDetails = ({ state, handleTextChange, textChange }) => {
	const Joblevel = [
		{
			id: 1,
			level: "Graduate",
		},
		{
			id: 2,
			level: "Entry level",
		},
		{
			id: 3,
			level: "Mid level",
		},
		{
			id: 4,
			level: "Senior level",
		},
	];

	return (
		<div className=" pb-40">
			<div className=" flex items-center gap-1">
				<img
					className=" w-3 h-3"
					src={require("../../assets/mdi_required.png")}
					alt=""
				/>
				<p className=" text-xs text-[#3C4657] font-Lato">
					Indicates a required field
				</p>
			</div>

			{/*  */}
			<div
				className=" flex mt-5 gap-5
      ">
				<div className=" grid  w-1/3">
					<div className=" flex">
						<p className=" font-bold font-Lato text-[#1B1B1B] text-sm">
							Job level{" "}
						</p>
						<img
							className=" w-3 h-3"
							src={require("../../assets/mdi_required.png")}
							alt=""
						/>
					</div>
					<p className=" font-Lato font-light text-xs text-[#7E8597]">
						Choose multiple options if applicable
					</p>
				</div>
				<div className=" w-3/4">
					{/* <div className=" flex items-center gap-3">
						{Joblevel.map(i => (
							<div className=" flex gap-2">
								<input type="checkbox" />
								<p className=" font-Lato text-xs text-[#3C4657] font-medium">
									{i.level}
								</p>
							</div>
						))}
					</div> */}
					<div className="space-y-2  flex">
						<RadioGroup
							options={Joblevel?.map(it => it?.level)}
							selected={state?.level}
							onChange={handleTextChange}
							type="level"
						/>
					</div>
				</div>
			</div>

			{/*  */}
			<div
				className=" flex mt-5 gap-5
      ">
				<div className=" grid  w-1/3">
					<div className=" flex">
						<p className=" font-bold font-Lato text-[#1B1B1B] text-sm">
							Hiring Manager{" "}
						</p>
						<img
							className=" w-3 h-3"
							src={require("../../assets/mdi_required.png")}
							alt=""
						/>
					</div>
					<p className=" font-Lato font-light text-xs text-[#7E8597]">
						Enter hiring manager name and id
					</p>
				</div>
				<div className=" w-3/4">
					<input
						type="text"
						className=" w-full h-11 border border-[#D8DFEC] rounded-md outline-none px-2"
						onChange={textChange}
						name="hiringManager"
						value={state?.hiringManager}
					/>
				</div>
			</div>
			{/*  */}
			<div
				className=" flex mt-5 gap-5
      ">
				<div className=" grid  w-1/3">
					<div className=" flex">
						<p className=" font-bold font-Lato text-[#1B1B1B] text-sm">
							Recruiters{" "}
						</p>
						<img
							className=" w-3 h-3"
							src={require("../../assets/mdi_required.png")}
							alt=""
						/>
					</div>
					<p className=" font-Lato font-light text-xs text-[#7E8597]">
						Choose multiple options if applicable
					</p>
				</div>
				<div className=" w-3/4">
					<div className=" flex relative items-center">
						<input
							type="text"
							className=" w-full h-11 border border-[#D8DFEC] rounded-md outline-none px-2"
							onChange={textChange}
							name="recruiters"
							value={state?.recruiters}
						/>
						<img
							className=" w-6 h-6 absolute right-4 cursor-pointer "
							src={require("../../assets/users.png")}
							alt=""
						/>
					</div>
				</div>
			</div>
			{/* <div className=" flex justify-between items-center">
        <button className="rounded-md w-44 h-12 text-[#5608D4] border border-[#5608D4] font-Nunito text-sm">
          Previous
        </button>
        <button className=" text-white font-Nunito text-sm bg-gradient-to-r from-[#5608D4] to-[#04096E] rounded-md w-44 h-12 animate-pulse">
          Save and continue
        </button>
      </div> */}
		</div>
	);
};

export default HiringDetails;
