import React from "react";
import down from "../../assets/down.png";
import jobuser from "../../assets/jobuser.png";

const PersonalInfoForm = ({ onSave, progress }) => {
  return (
    <div>
      <div className=" flex  gap-20">
        <div className=" grid grid-cols-2 w-[60%] gap-5">
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              First name
            </label>
            <input
              className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
              placeholder="Enter first name"
              type="text"
            />
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Last name
            </label>
            <input
              className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
              placeholder="Enter last name"
              type="text"
            />
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Personal email address
            </label>
            <input
              className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
              placeholder="Enter email address"
              type="text"
            />
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Phone number
            </label>
            <input
              className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
              placeholder="Enter phone number"
              type="text"
            />
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Password
            </label>
            <input
              className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
              placeholder="Enter password"
              type="text"
            />
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Date of birth
            </label>
            <input
              className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
              type="date"
            />
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              State of origin
            </label>
            <div className=" relative items-center flex">
              <input
                className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
                type="State of origin"
              />

              <img className=" w-4 h-4 absolute right-4" src={down} alt="" />
            </div>
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              State of Residence
            </label>
            <div className=" relative items-center flex">
              <input
                className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
                type="State of Residence"
              />

              <img className=" w-4 h-4 absolute right-4" src={down} alt="" />
            </div>
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Gender
            </label>
            <div className=" relative items-center flex">
              <input
                className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
                type="Gender"
              />

              <img className=" w-4 h-4 absolute right-4" src={down} alt="" />
            </div>
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Language
            </label>
            <div className=" relative items-center flex">
              <input
                className=" border placeholder:text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12"
                type="Language"
              />

              <img className=" w-4 h-4 absolute right-4" src={down} alt="" />
            </div>
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Link to Portfolio
            </label>
            <div className="">
              <div className=" text-[#a3a3a3] border items-center justify-center grid cursor-pointer text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12 text-center">
                Link
              </div>
            </div>
          </div>
          <div className=" grid gap-1 ">
            <label
              className=" font-Nunito text-sm font-medium text-[#121212]"
              htmlFor=""
            >
              Social media handle
            </label>
            <div className="">
              <div className=" text-[#a3a3a3] border items-center justify-center grid cursor-pointer text-sm border-[#D0D0D0] w-full px-2  rounded-lg h-12 text-center">
                Link
              </div>
            </div>
          </div>
        </div>
        <div className=" w-[40%]">
          <div className=" w-72 ] border border-[#e8e8e8] rounded-lg">
            <div className=" justify-center grid items-center my-14">
              <img src={jobuser} className=" w-40 h-40" alt="" />
            </div>
            <div className=" space-y-8 mb-14">
              <div className=" space-y-1">
                <p className=" text-center font-Nunito text-sm text-[#777777]">
                  Allowed format
                </p>
                <p className=" text-[#272525] text-sm font-Nunito text-center font-medium">
                  JPG, JPEG, and PNG
                </p>
              </div>
              <div className=" space-y-1">
                <p className=" text-sm font-Nunito text-[#777777] text-center">
                  {" "}
                  Max file size
                </p>
                <p className=" text-sm font-Nunito text-[#272525] text-center font-medium">
                  {" "}
                  2MB
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" py-10">
        <button
          className="bg-black text-white text-sm Satoshi w-44 h-9 rounded-lg"
          onClick={onSave} // Call the onSave function when clicked
        >
          {progress === 100 ? "Save" : "Save and continue"}
        </button>
      </div>
    </div>
  );
};

export default PersonalInfoForm;
