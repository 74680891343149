import React from "react";
import jobusers from "../../assets/jobusers.png";
import Userprofile from "../../assets/Userprofile.png";
import JobDetailsImage from "./JobDetailsImage";

const AllJobsCard = () => {
  const jobcards = [
    {
      img: "",
      title: "Senior Software Engineer",
      level: "HighLevel",
      years: "2 - 5 Years",
      job: "Remote, anywhere in the world",
      date: "22nd Apr, 2024",
    },
    {
      img: "",
      title: "Senior Software Engineer",
      level: "HighLevel",
      years: "2 - 5 Years",
      job: "Remote, anywhere in the world",
      date: "22nd Apr, 2024",
    },
    {
      img: "",
      title: "Senior Software Engineer",
      level: "HighLevel",
      years: "2 - 5 Years",
      job: "Remote, anywhere in the world",
      date: "22nd Apr, 2024",
    },
    {
      img: "",
      title: "Senior Software Engineer",
      level: "HighLevel",
      years: "2 - 5 Years",
      job: "Remote, anywhere in the world",
      date: "22nd Apr, 2024",
    },
    {
      img: "",
      title: "Senior Software Engineer",
      level: "HighLevel",
      years: "2 - 5 Years",
      job: "Remote, anywhere in the world",
      date: "22nd Apr, 2024",
    },
    {
      img: "",
      title: "Senior Software Engineer",
      level: "HighLevel",
      years: "2 - 5 Years",
      job: "Remote, anywhere in the world",
      date: "22nd Apr, 2024",
    },
  ];
  return (
    <div className=" grid gap-5">
      {jobcards.map((i) => (
        <div className="flex items-center justify-between ml-5 bg-white py-2 pr-10  rounded-lg shadow-xl">
          <div className=" flex items-center gap-5 mt-5">
            <img
              className=" w-14 h-14 mt-[-10px] ml-[-20px] "
              src={jobusers}
              alt=""
            />
            <div className=" mt-[-15px]">
              <p className=" Satoshi text-lg font-bold text-[#000]">
                Senior Software Engineer
              </p>
              <div className=" flex items-center gap-4">
                <div className=" flex items-center gap-1">
                  <img className=" w-4 h-4" src={Userprofile} alt="" />
                  <small className=" text-[#4D4D4D] Satoshi text-sm">
                    HighLevel
                  </small>
                </div>
                <div className=" flex items-center gap-1">
                  <div className=" w-1 h-1 bg-[#687dec] rounded-full"></div>
                  <small className="text-[#4D4D4D] Satoshi text-sm">
                    2 - 5 Years
                  </small>
                </div>
                <div className=" flex items-center gap-1 ml-10">
                  <div className=" w-1 h-1 bg-[#Ec68d7] rounded-full"></div>
                  <small className="text-[#4D4D4D] Satoshi text-sm">
                    Remote, anywhere in the world
                  </small>
                </div>
                <div className=" flex items-center gap-1">
                  <div className=" w-1 h-1 bg-[#Ecc768] rounded-full"></div>
                  <small className="text-[#4D4D4D] Satoshi text-sm">
                    22nd Apr, 2024
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className=" gap-4 flex items-center">
            <JobDetailsImage />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllJobsCard;
