import React, { useState, useRef } from "react";
import ActionCompleted from "./ActionCompleted";
import { toast } from "react-toastify";
import useAnnoucementStore from "../../data/Stores/AnnoucementStore";
import useErrorStore from "../../data/Stores/errorstore";
import { apiCall } from "../../data/useGenFetcher";
import { Button } from "../buttons/Send";
import { BiTrashAlt } from "react-icons/bi";

const CreatePost = ({ onClose }) => {
	const [showCard, setShowCard] = useState(false);
	const [addImage, setAddImage] = useState(false);

	const handleImage = () => {
		setAddImage(!addImage);
	};

	const closeImage = () => {
		setAddImage(false);
	};

	const handleCard = () => {
		setShowCard(!showCard);
    onClose();
	};
	const closeCard = () => {
    setShowCard(false);
    onClose();
	};
	const posts = [
		{
			id: 1,
			img: require("../../assets/Upload.png"),
			text: "Image",
		},
		{
			id: 1,
			img: require("../../assets/Play.png"),
			text: "Play",
		},
		{
			id: 1,
			img: require("../../assets/Link.png"),
			text: "Link",
		},
	];

	let ref = useRef(),
		handleChangeImage = e => {
			const newfiles = [...e.target.files];
			let err = "",
				newImages = [];

			newfiles.forEach(file => {
				if (!file) return (err = `File, ${file?.name} does not exist`);
				if (!file.type.includes("image"))
					return (err = `File, ${file?.name} format not supported`);

				return newImages.push(file);
			});
			if (err) {
				return toast.error(err);
			} else {
				setState(prev => {
					let newPrev = { ...prev, image: [...newImages] };
					return newPrev;
				});
			}
		};

	let init = {},
		[state, setState] = useState({}),
		[loading, setLoading] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ addAnnoucement } = useAnnoucementStore(),
		{ returnErrors } = useErrorStore();

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title) return toast.info("Annoucement title is required");
		let newState = state;

		if (state?.image) {
			let newArr = [];
			for (let im = 0; im < state?.image.length; im++) {
				const element = state?.image[im];
				let { response, errArr, errMsg } = await apiCall({
					url: `/api/v1/file`,
					type: "file",
					data: { image: element },
				});
				console?.log({ response, errArr, errMsg });
				if (errArr) {
					setLoading(false);
					return returnErrors(errArr);
				}
				if (errMsg) {
					setLoading(false);
					return toast.error(errMsg);
				}
				setLoading(false);
				if (response) {
					newArr?.push(response?.data?.filesId?.files?.[0]);
				}
			}
			if (newArr?.length > 0) newState.files = newArr;
			delete state.image;
		}

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/announcement`,
			type: "post",
			data: newState,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			addAnnoucement(response);
			setState(init);
			handleCard();
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
			<div className=" bg-white w-[716px] h-auto p-6    ">
				<div className=" flex items-center justify-between">
					<p className=" text-xl font-Kumbh font-bold">Create Post</p>
					<p onClick={onClose} className=" cursor-pointer text-xl font-bold">
						&times;
					</p>
				</div>
				<div className=" py-5">
					<img
						className=" w-14 h-14"
						src={require("../../assets/Ellipse14.png")}
						alt=""
					/>
				</div>
				<div className=" space-y-5">
					<div className=" space-y-3">
						<label className=" text-sm font-Nunito text-[#121212]" htmlFor="">
							Post Title
						</label>
						<input
							placeholder="Title"
							className=" placeholder:text-xs border border-[#D0D0D0] placeholder-[#A3A3A3] pl-2 w-full h-12 rounded-md"
							type="text"
							value={state?.title}
							onChange={textChange}
							name={"title"}
						/>
					</div>
					<div className=" space-y-3">
						<label className=" text-sm font-Nunito text-[#121212]" htmlFor="">
							Post Body
						</label>

						<textarea
							placeholder="Body"
							className=" placeholder:text-xs border border-[#D0D0D0] placeholder-[#A3A3A3] p-2 w-full h-24 rounded-md"
							id=""
							cols="30"
							rows="10"
							value={state?.description}
							onChange={textChange}
							name={"description"}
						/>
					</div>

					<div className=" flex items-center gap-5">
						{posts.map(i => (
							<div
								onClick={handleImage}
								className=" flex items-center gap-3 cursor-pointer">
								<img className=" w-4 h-4" src={i.img} alt="" />
								<p className=" font-Nunito text-[#000] text-sm font-medium">
									{i.text}
								</p>
							</div>
						))}
					</div>
				</div>

				<div>
					{/* <button
						onClick={handleCard}
						className=" w-44 h-8 rounded-md animate-pulse text-white bg-gradient-to-r from-[#0811D4] to-[#384295] font-Nunito text-sm mt-10">
						Post
					</button> */}
					<Button
						css={
							" w-44 h-8 rounded-md animate-pulse text-white bg-gradient-to-r from-[#0811D4] to-[#384295] font-Nunito text-sm mt-10"
						}
						children={"Post"}
						onClick={handleSubmit}
						loading={loading}
					/>
					{showCard && <ActionCompleted onClose={closeCard} />}

					{addImage && (
						<div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
							<div className=" bg-white w-[716px] h-auto p-6    ">
								<div className=" flex items-center justify-between">
									<p className=" text-xl font-Kumbh font-bold">Add Image</p>
									<p
										onClick={closeImage}
										className=" cursor-pointer text-xl font-bold">
										&times;
									</p>
								</div>
								<input
									title="Upload file"
									type="file"
									name="file"
									id="file"
									className="hidden"
									accept="image/*"
									onChange={handleChangeImage}
									ref={ref}
									multiple
								/>
								<div
									onClick={() => ref?.current?.click()}
									className=" grid justify-center gap-1 py-20">
									<p className=" text-center text-[#898989] text-xs font-Nunito">
										Not More Than 2MB
									</p>
									<div className=" grid justify-center ">
										<button className=" bg-[#EAF1F6] w-80 h-12 rounded-xl Satoshi text-[#2A72A8] text-base font-bold">
											Choose from your device
										</button>
									</div>
								</div>
								{state?.image && (
									<>
										<div className="grid gap-3 grid-cols-4">
											{state?.image?.map((it, i) => (
												<div className="relative" key={i}>
													<img
														className=" w-60"
														src={URL.createObjectURL(it)}
														alt=""
													/>
													<BiTrashAlt
														className="cursor-pointer absolute right-0 top-0"
														onClick={() => {
															setState(prev => {
																let newPrev = { ...prev },
																	newImg = [...newPrev?.image];
																newImg?.splice(i, 1);
																newPrev.image = newImg;
																return newPrev;
															});
														}}
													/>
												</div>
											))}
										</div>
									</>
								)}
								<div className=" flex items-center justify-center gap-5">
									<button
										onClick={() => {
											setState(prev => {
												let newPrev = { ...prev, image: null };
												return newPrev;
											});
											handleImage();
										}}
										className=" w-36 h-10 rounded-md border border-[#0811D4] text-[#0811D4] text-sm font-bold">
										Cancel
									</button>
									<button
										onClick={handleImage}
										className=" w-36 h-10 rounded-md bg-gradient-to-b from-[#0811D4] to-[#384295] text-white font-Nunito text-sm">
										Done
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CreatePost;
