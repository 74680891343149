import React, { useContext } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import { EvaluationDetails } from "..";
import { useLocation } from "react-router-dom";
import useAuthStore from "../../../data/Stores/Authstore";
import { GlobalState } from "../../../data/Context";

const ManageEvaluationsDetails = () => {
	let isEvaluate = useLocation()?.state,
		auth = useAuthStore(),
		{ canManager2, canManager1 } = useContext(GlobalState);

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-white w-full  ">
				<EvaluationDetails
					type={
						auth?.user?.isAdmin ||
						(canManager2 &&
							auth?.user?.gradeForPosition2?.includes(isEvaluate?.position))
							? "manager_score2"
							: auth?.user?.isAdmin ||
							  (canManager1 &&
									auth?.user?.gradeForPosition1?.includes(isEvaluate?.position))
							? "manager_score"
							: ""
					}
					user={isEvaluate?._id}
					isEvaluate={isEvaluate}
					manager_score={
						auth?.user?.isAdmin ||
						(canManager1 &&
							auth?.user?.gradeForPosition1?.includes(isEvaluate?.position))
					}
					manager_score2={
						auth?.user?.isAdmin ||
						(canManager2 &&
							auth?.user?.gradeForPosition2?.includes(isEvaluate?.position))
					}
				/>
			</div>
		</div>
	);
};

export default ManageEvaluationsDetails;
