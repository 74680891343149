import React from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { ActionsFile } from "../resources/Actions";

export default function DocumentsCard({
  id,
  title,
  folderImg,
  lastModified,
  owner,
  fileType,
  toggleModal,
  isOpen,
}) {
  return (
    <div className="relative w-full md:w-full lg:w-[256px] h-[300px] bg-white shadow-sm rounded-lg border px-4 py-3 hover:shadow-md transition-shadow">
      <div className="h-[180px] overflow-hidden rounded-md">
        <img
          src={folderImg}
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>

      <div className="font-bold font-Nunito text-sm text-[#101828] truncate mt-3">
        {title}
      </div>
      <div className="mt-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <div>
              <img src={fileType} alt={title} className="w-8 h-8" />
            </div>
            <div className="text-[10px] font-[300] text-[#667085] mt-1">
              <p>Last modified: {lastModified}</p>
              <p>Owned by: {owner}</p>
            </div>
          </div>
          <HiOutlineDotsVertical
            className="cursor-pointer text-[#667085]"
            onClick={(event) => toggleModal(id, event)}
          />
        </div>
      </div>

      {/* Modal */}
      {isOpen && <ActionsFile onClose={() => toggleModal(null)} />}
    </div>
  );
}
