import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import SideBar from "../../components/cards/SideBar";
import UploadFile from "../../components/cards/tast/UploadFile";
import GradientBtn from "../../components/buttons/gradientbtn";
import AssignMembers from "../../components/cards/tast/AssignMembers";
import AssignMember from "../../components/cards/tast/AssignMember";
import { Link } from "react-router-dom";
import NewProject from "../../components/cards/tast/NewProject";

const Projects = () => {
  const [isCreate, setIsCreate] = useState(false);

  const handleCreate = () => {
    setIsCreate(!isCreate);
  };
  const closeCreate = () => {
    setIsCreate(false);
  };
  const project = [
    {
      id: 1,
      imgone: require("../../assets/projectarrrow.png"),
      ProjectTitle: "Project Title",
      Ongoing: "Ongoing",
      desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
      date: "11th - 15th April 2024",
      TeamProject: "Team Project",
    },
    {
      id: 2,
      imgone: require("../../assets/project-yellowimportant.png"),
      ProjectTitle: "Project Title",
      Ongoing: "To Do",
      desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
      date: "11th - 15th April 2024",
      TeamProject: "Team Project",
    },
    {
      id: 3,
      imgone: require("../../assets/projectgreenimportant.png"),
      ProjectTitle: "Project Title",
      Ongoing: "Done",
      desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
      date: "11th - 15th April 2024",
      TeamProject: "Team Project",
    },
    {
      id: 4,
      imgone: require("../../assets/projectarrrow.png"),
      ProjectTitle: "Project Title",
      Ongoing: "Ongoing",
      desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
      date: "11th - 15th April 2024",
      TeamProject: "Team Project",
    },
    {
      id: 5,
      imgone: require("../../assets/project-yellowimportant.png"),
      ProjectTitle: "Project Title",
      Ongoing: "To Do",
      desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
      date: "11th - 15th April 2024",
      TeamProject: "Team Project",
    },
    {
      id: 6,
      imgone: require("../../assets/projectgreenimportant.png"),
      ProjectTitle: "Project Title",
      Ongoing: "Done",
      desc: "Lorem ipsum dolor sit amet consectetur. Arcu congue sagittis.",
      date: "11th - 15th April 2024",
      TeamProject: "Team Project",
    },
  ];
  return (
    <div className=" bg-[#EFF6FC] pb-10">
      <Navbar />
      <PagesList />

      <div className=" flex justify-between items-center px-12 pt-4">
        <p className=" font-Kumbh text-[#000] text-base font-bold">
          Tasks management
        </p>
      </div>

      <div className=" bg-white mx-12 p-5 mt-5">
        <div className=" flex gap-5">
          <div>
            <SideBar />
          </div>
          <div className=" w-full">
            <div className=" flex justify-between items-center">
              <p className=" Satoshi text-xl text-[#000] font-medium">
                My Projects
              </p>
              <div
                onClick={handleCreate}
                className=" cursor-pointer flex justify-center items-center bg-[#000] w-28 h-9 rounded-lg text-white gap-2"
              >
                <img
                  className=" w-4 h-4"
                  src={require("../../assets/ic_baseline-add-task.png")}
                  alt=""
                />
                <p className=" text-sm Satishi text-[#fff]">Create</p>
              </div>
              {isCreate && <NewProject onclose={closeCreate} />}
            </div>

            <div className=" flex items-center gap-10 mt-3">
              <p className=" text-[#636E72] text-sm Satoshi">All</p>
              <p className=" text-[#636E72] text-sm Satoshi">To Do</p>
              <p className=" text-[#636E72] text-sm Satoshi">Ongoing</p>
              <p className=" text-[#636E72] text-sm Satoshi">Done</p>
            </div>
            <div className=" mt-5 grid lg:grid-cols-3 md:grid-cols-2 gap-y-5">
              {project.map((i) => (
                <div
                  key={i.id}
                  className=" bg-white shadow-lg w-72 h-auto rounded-lg px-5 pt-5 pb-2"
                >
                  <div className=" flex justify-between items-center">
                    <div className=" flex items-center gap-2">
                      <img className=" w-4 h-4" src={i.imgone} alt="" />
                      <p className=" font-bold text-base text-[#000] Satoshi">
                        {i.ProjectTitle}
                      </p>
                    </div>
                    <div>
                      <div className=" bg-[#a0bce0] text-[#2F80ED] cursor-pointer flex justify-center items-center rounded-full px-2 h-8 gap-1">
                        <p className=" text-xs Satoshi">{i.Ongoing}</p>
                        <img
                          className=" w-6 h-6"
                          src={require("../../assets/projectgridicons_dropdown.png")}
                          alt=""
                        />{" "}
                      </div>
                    </div>
                  </div>

                  {/*  */}
                  <div className=" mt-2">
                    <p className="Satoshi text-[#000] text-sm">{i.desc}</p>
                  </div>
                  {/*  */}
                  <div className=" flex items-center gap-2 py-3">
                    <img
                      className=" w-3 h-3"
                      src={require("../../assets/projectSchedule.png")}
                      alt=""
                    />
                    <div className=" flex items-center gap-2">
                      <p className=" text-[10px] text-[#EB5757] Inter">
                        Deadline:
                      </p>
                      <p className=" text-[10px] font-Nunito text-[#636E72]">
                        {i.date}
                      </p>
                    </div>
                  </div>
                  {/*  */}
                  <div className=" flex items-center justify-between">
                    <p className=" font-Kumbh text-[#5608D4] text-sm font-bold">
                      {i.TeamProject}
                    </p>
                    <Link to="/project-details" className=" font-bold text-sm">
                      ...
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
