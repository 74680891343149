import React, { useState } from "react";
import italic from "../../assets/italic.png";
import jobfile from "../../assets/jobfile.png";
import { useNavigate } from "react-router-dom";
import JobCard from "./JobCard";
import setup from "../../assets/setup.png";

const JobDetailsImage = () => {
  const navigate = useNavigate();
  const [save, setSave] = useState(false);
  return (
    <div>
      <div className=" gap-4 flex items-center">
        <img
          onClick={() => navigate("/job-details")}
          className=" w-10 h-10 cursor-pointer"
          src={italic}
          alt=""
        />
        <img
          onClick={() => setSave(!save)}
          className=" w-10 h-10"
          src={jobfile}
          alt=""
        />
        {save && (
          <div>
            <div
              onClick={() => setSave(false)}
              className="z-20 h-screen top-0 w-full left-0 justify-center grid items-center fixed"
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>
              <JobCard
                image={setup}
                title="Applied Successfully!"
                description="You have successfully applied to job ‘Job Title”!"
                buttonText="Close"
                onClick={() => navigate(false)} // Pass function to show Account component
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobDetailsImage;
