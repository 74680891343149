import React from "react";

const JobDescription = () => {
  return (
    <div className=" p-5">
      <p className="text-base text-[#000] font-Kumbh font-bold">
        Job Description
      </p>
      <p className=" mt-2 text-base text-[#000] font-Kumbh font-medium ">
        Role: Senior Software Engineer
      </p>

      <div>
        <div className=" flex items-center gap-2 mt-2">
          <div
            className="
             w-1 h-1 rounded-full bg-[#000]"
          ></div>
          <p className=" text-base font-Nunito text-[#000] ">
            Role & Responsibilities: Senior Software Engineer
          </p>
        </div>
        <div className=" pl-4 mt-1 space-y-1">
          <p className=" text-[#4D4D4D] text-sm font-Nunito">
            - Lorem ipsum dolor sit amet consectetur. Viverra orci orci vel sed
            urna condimentum vitae eget non. Scelerisque molestie ut ut duis
            risus sed. Amet interdum tellus neque tempus mauris varius.{" "}
          </p>
          <p className=" text-[#4D4D4D] text-sm font-Nunito">
            - Lorem ipsum dolor sit amet consectetur. Viverra orci orci vel sed
            urna condimentum vitae eget non. Scelerisque molestie ut ut duis
            risus sed.{" "}
          </p>
          <p className=" text-[#4D4D4D] text-sm font-Nunito">
            - Lorem ipsum dolor sit amet consectetur. Viverra orci orci vel sed
            urna condimentum vitae eget non. Scelerisque molestie ut ut duis
            risus sed.{" "}
          </p>
          <p className=" text-[#4D4D4D] text-sm font-Nunito">
            - Lorem ipsum dolor sit amet consectetur. Viverra orci orci vel sed
            urna condimentum vitae eget non. Scelerisque molestie ut ut duis
            risus sed.{" "}
          </p>
        </div>
      </div>
      <div className=" pt-4">
        <div className=" flex items-center gap-2 mt-2">
          <div
            className="
             w-1 h-1 rounded-full bg-[#000]"
          ></div>
          <p className=" text-base font-Nunito text-[#000] ">What to Bring:</p>
        </div>
        <div className=" pl-4 mt-1 space-y-1">
          <p className=" text-[#4D4D4D] text-sm font-Nunito">
            - Lorem ipsum dolor sit amet consectetur. Viverra orci orci vel sed
            urna condimentum vitae eget non. Scelerisque molestie ut ut duis
            risus sed. Amet interdum tellus neque tempus mauris varius.{" "}
          </p>
          <p className=" text-[#4D4D4D] text-sm font-Nunito">
            - Lorem ipsum dolor sit amet consectetur. Viverra orci orci vel sed
            urna condimentum vitae eget non. Scelerisque molestie ut ut duis
            risus sed.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default JobDescription;
