import React from "react";

const EducationForm = () => {
  return (
    <div>
      <p>EducationForm</p>
    </div>
  );
};

export default EducationForm;
