import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import { Link, useLocation } from "react-router-dom";
import { apiCall } from "../../../data/useGenFetcher";
import { usePerformanceStore } from "../../../data/Stores/LoggerStore";

const SelectPosition = () => {
	const card = [
		{
			id: 1,
			img: require("../../../assets/white3dicons.png"),
			text: "Mobile App Developer",
		},
		{
			id: 2,
			img: require("../../../assets/red3dicons.png"),
			text: "Mobile App Developer",
		},
		{
			id: 3,
			img: require("../../../assets/white3dicons.png"),
			text: "Mobile App Developer",
		},
		{
			id: 4,
			img: require("../../../assets/red3dicons.png"),
			text: "Mobile App Developer",
		},
		{
			id: 5,
			img: require("../../../assets/white3dicons.png"),
			text: "Mobile App Developer",
		},
	];

	let { getLogger, data } = usePerformanceStore(),
		items = useLocation()?.state,
		[state, setState] = useState("");

	useEffect(() => {
		apiCall({
			type: "get",
			url: "/api/v1/performance?_populate=department&_populate=position&_limit=0",
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">Add New</p>
				</div>

				<div className=" bg-white p-5 mx-12 mt-5">
					<p className=" text-sm font-Kumbh text-[#000]">
						Select a performance to clone from
					</p>

					<div className=" grid lg:grid-cols-5 md:grid-cols-4 grid-cols-2 mt-5 gap-5">
						{data?.docs?.map((i, indx) => (
							<div
								key={indx}
								onClick={() => setState(i)}
								className={`  relative hover:bg-slate-200 justify-center items-center grid gap-4 py-5 rounded-lg lg:w-52 md:w-56 w-full shadow-lg cursor-pointer ${
									state?._id === i?._id ? "bg-[#EFF6FC]" : "bg-white"
								}`}>
								<div className=" flex justify-end">
									<p className=" absolute top-8 right-3 text-[#5608D4] font-bold text-sm  font-Nunito">
										Clone
									</p>
								</div>
								<div className=" grid justify-center items-center">
									<img
										className=" w-8 h-12"
										src={i?.url || card?.[indx % card?.length]?.img}
										alt=""
									/>
								</div>
								<p className=" text-center w-28 font-Kumbh text-sm text-[#000] font-bold">
									{i?.name}
								</p>
							</div>
						))}
					</div>
					{state && (
						<div className="flex items-center justify-center py-4">
							<Link
								to="/performance/add-performance"
								state={{
									...items,
									cloner: state,
								}}
								className={`text-sm Satoshi grid justify-center items-center text-white bg-black rounded-md w-32 h-9 animate-pulse`}>
								Next
							</Link>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default SelectPosition;
