import React, { useEffect } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import { FilesTable, ToolsAssetManagement } from ".";

const Onboarding = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<ToolsAssetManagement />

				<div className=" bg-white mx-12 mt-5 p-5">
					<FilesTable />
				</div>
			</div>
		</div>
	);
};

export default Onboarding;
