import React from "react";

const ShortAnswer = ({ text }) => {
  return (
    <div>
      <div className=" flex items-center gap-2 justify-center border border-[#CCCCCC] rounded-md w-40 h-7">
        <p className=" text-xs text-[#1B1B1B] font-Nunito">
          {text}
          {/* Short answer text */}
        </p>
        <img
          className=" w-3 h-3"
          src={require("../../assets/uiw_down.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default ShortAnswer;
