import React, { useState } from "react";
import GradientBtn from "../../buttons/gradientbtn";
import UploadFile from "./UploadFile";
import AssignMembers from "./AssignMembers";
import AddTask from "./AddTask";

const ListViewCard = () => {
  const [showTask, setShowTask] = useState(false);

  const handleTask = () => {
    setShowTask(!showTask);
  };
  const closeTask = () => {
    setShowTask(false);
  };
  const tableHead = [
    { key: "", image: require("../../../assets/GroupUp.png") },
    { key: "Task 1", image: "" },
    { key: "15 Tasks", image: "" },
    {
      key: "",
      image: require("../../../assets/ic_round-label-important.png"),
    },
    {
      key: "Start date: Apr 20, 11am",
      image: require("../../../assets/arcticons_clock.png"),
    },
    {
      key: "End date: Apr 30, 11am",
      image: require("../../../assets/arcticons_clock.png"),
    },
  ];

  const tableData = [
    {
      SN: "",

      Contact: "Design a Lo-Fi Wireframe for E--commerce App Project",
      IssueDate: "",
      Status: "Due: Apr 22, 11am",
      Total: "NGN 50,000",
    },
    {
      SN: "",

      Contact: "Design a Lo-Fi Wireframe for E--commerce App Project",
      IssueDate: "",
      Status: "Due: Apr 22, 11am",
      Total: "NGN 50,000",
    },
    {
      SN: "",

      Contact: "Design a Lo-Fi Wireframe for E--commerce App Project",
      IssueDate: "",
      Status: "Due: Apr 22, 11am",
      Total: "NGN 50,000",
    },
  ];

  const card = [
    {
      id: 1,
      task: "Task 2",
      taskNum: "15 Tasks",
      imgone: require("../../../assets/taskcheck.png"),
      imgtwo: require("../../../assets/taskimportant.png"),
      imgthree: require("../../../assets/taskarcticons_clock.png"),
      start: " Start date: Apr 20, 11am",
      imgfour: require("../../../assets/taskarcticons_clock.png"),
      end: "End date: Apr 30, 11am",
    },
    {
      id: 2,
      task: "Task 2",
      taskNum: "15 Tasks",
      imgone: require("../../../assets/taskcheck.png"),
      imgtwo: require("../../../assets/taskimportant.png"),
      imgthree: require("../../../assets/taskarcticons_clock.png"),
      start: " Start date: Apr 20, 11am",
      imgfour: require("../../../assets/taskarcticons_clock.png"),
      end: "End date: Apr 30, 11am",
    },
    {
      id: 3,
      task: "Task 2",
      taskNum: "15 Tasks",
      imgone: require("../../../assets/taskcheck.png"),
      imgtwo: require("../../../assets/taskimportant.png"),
      imgthree: require("../../../assets/taskarcticons_clock.png"),
      start: " Start date: Apr 20, 11am",
      imgfour: require("../../../assets/taskarcticons_clock.png"),
      end: "End date: Apr 30, 11am",
    },
  ];
  return (
    <div className=" w-full">
      <div className="overflow-x-auto bg-white shadow-lg rounded-lg py-5">
        <table className="table-auto w-full">
          <thead>
            <tr>
              {tableHead.map((head, index) => (
                <th
                  key={index}
                  className="px-4 py-4  text-left text-xs font-semibold text-[#787474] font-Nunito"
                >
                  {head.image ? (
                    <img
                      src={head.image}
                      alt={head.key}
                      className=" h-4 w-4 mr-2 mb-[0.6px] inline"
                    />
                  ) : null}
                  {head.key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, index) => (
              <tr key={index} className="">
                <td className="px-4 py-2 text-xs text-gray-600">{data.SN}</td>
                <td className="px-4 py-2 text-xs text-gray-600 grid justify-center">
                  <input className=" rounded-full" type="checkbox" />
                </td>
                <td className=" py-2 text-xs text-gray-600">{data.Contact}</td>
                <td className="px-4 py-2 text-xs text-gray-600">
                  {data.IssueDate}
                </td>
                <td className="px-4 py-2 text-xs text-gray-600 flex items-center ">
                  <img
                    className=" w-4 h-4"
                    src={require("../../../assets/arcticons_clock.png")}
                    alt=""
                  />
                  <p className=" pl-2"> {data.Status}</p>
                  <img
                    className=" w-4 h-4 ml-5"
                    src={require("../../../assets/ic_round-label-important.png")}
                    alt=""
                  />
                </td>
                <td className="px-4 py-2 text-xs text-gray-600 ">
                  <div className=" flex items-center gap-2">
                    <img
                      className=" w-20 h-8"
                      src={require("../../../assets/taskAvatars.png")}
                      alt=""
                    />
                    <img
                      className=" w-8 h-8"
                      src={require("../../../assets/taskAddButton.png")}
                      alt=""
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className=" justify-center items-center grid mt-5">
          <div
            onClick={handleTask}
            className=" w-64 h-8 rounded-lg cursor-pointer border border-[#F2F4F7] flex justify-center items-center"
          >
            {/*  */}
            <div className=" flex items-center gap-2">
              <img
                className=" w-5 h-5"
                src={require("../../../assets/AddTaskIcon.png")}
                alt=""
              />
              <p className=" font-Nunito text-sm text-[#000]">Add Task</p>
            </div>
          </div>
          {showTask && <AddTask onClose={closeTask} />}
        </div>
      </div>

      <div className=" mt-5 space-y-3">
        {card.map((i) => (
          <div
            key={i.id}
            className=" flex items-center justify-between bg-white py-4 px-5 rounded-lg shadow-lg"
          >
            <div className=" flex items-center gap-5">
              <img src="" alt="" />
              <p className=" text-[#000] font-Nunito text-sm">{i.task}</p>
              <p className=" font-Nunito text-xs text-[#787474]">{i.taskNum}</p>
              <img className=" w-4 h-4 ml-5" src={i.imgone} alt="" />
            </div>
            <div className=" flex items-center gap-10">
              <div className=" flex items-center gap-5">
                <img className=" w-4 h-4 " src={i.imgtwo} alt="" />
                <div className=" flex items-center gap-2">
                  <img className=" w-4 h-4" src={i.imgthree} alt="" />
                  <p className=" font-Nunito text-sm text-[#787474]">
                    {i.start}
                  </p>
                </div>
              </div>
              <div className=" flex items-center gap-2">
                <img className=" w-4 h-4" src={i.imgfour} alt="" />
                <p className=" font-Nunito text-sm text-[#787474]">{i.end}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListViewCard;
