import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import IdCardRequest from "../../components/cards/IdCardRequest";
import { useAttendanceStore } from "../../data/Stores/LoggerStore";
import { apiCall } from "../../data/useGenFetcher";
import useCompanyStore from "../../data/Stores/CompanyStore";
import moment from "moment";
import { MainPaginate } from "../../components/buttons/Send";

const AttendanceManagement = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// const [addFile, setAddFile] = useState(false);
	// const [addAction, setAddAction] = useState(false);
	const [showAttendance, setShowAttendance] = useState(true);
	const [showQrCode, setShowQrCode] = useState(false);
	const [topText, setTopText] = useState("attendance");
	const [showId, setShowId] = useState(false);

	const handleId = () => {
		setShowId(!showId);
	};
	const closeId = () => {
		setShowId(false);
	};

	const handleRequestsClick = () => {
		setShowAttendance(true);
		setShowQrCode(false);
		setTopText("attendance");
	};

	const handleMyRequestsClick = () => {
		setShowAttendance(false);
		setShowQrCode(true);
		setTopText("requests");
	};

	// const handleFile = () => {
	//   setAddFile(!addFile);
	// };
	// const handleAction = () => {
	//   setAddAction(!addAction);
	// };
	// const closeAction = () => {
	//   setAddAction(false);
	// };
	// const closeFile = () => {
	//   setAddFile(false);
	// };

	const tableHead = [
		"S/N",
		"Name",
		"Employee ID",
		"Email address",
		"Sign In",
		"Sign Out",
	];
	const tableData = [
		{
			SN: "1",
			Name: "Catherine James",
			EmployeeID: "CeHR/001A",
			Emailaddress: "catherinejames@gmail.com",
			SignIn: "09:00:18 am",
			SignOut: "05:00:18 pm",
		},
		{
			SN: "2",
			Name: "Catherine James",
			EmployeeID: "CeHR/001A",
			Emailaddress: "catherinejames@gmail.com",
			SignIn: "09:00:18 am",
			SignOut: "05:00:18 pm",
		},
		{
			SN: "3",
			Name: "Catherine James",
			EmployeeID: "CeHR/001A",
			Emailaddress: "catherinejames@gmail.com",
			SignIn: "09:00:18 am",
			SignOut: "05:00:18 pm",
		},
		{
			SN: "4",
			Name: "Catherine James",
			EmployeeID: "CeHR/001A",
			Emailaddress: "catherinejames@gmail.com",
			SignIn: "09:00:18 am",
			SignOut: "05:00:18 pm",
		},
		{
			SN: "5",
			Name: "Catherine James",
			EmployeeID: "CeHR/001A",
			Emailaddress: "catherinejames@gmail.com",
			SignIn: "09:00:18 am",
			SignOut: "05:00:18 pm",
		},
		{
			SN: "6",
			Name: "Catherine James",
			EmployeeID: "CeHR/001A",
			Emailaddress: "catherinejames@gmail.com",
			SignIn: "09:00:18 am",
			SignOut: "05:00:18 pm",
		},
		{
			SN: "7",
			Name: "Catherine James",
			EmployeeID: "CeHR/001A",
			Emailaddress: "catherinejames@gmail.com",
			SignIn: "09:00:18 am",
			SignOut: "05:00:18 pm",
		},
	];
	const tablesHead = ["S/N", "Date", "Sign In Scans", "Sign Out Scans"];
	const tablesData = [
		{
			tableSN: "1",

			tableDate: "Thursday, 4th April, 2024",
			tableSignInScans: "20",
			tableSignOutScans: "20",
		},
		{
			tableSN: "2",

			tableDate: "Thursday, 4th April, 2024",
			tableSignInScans: "20",
			tableSignOutScans: "20",
		},
		{
			tableSN: "3",

			tableDate: "Thursday, 4th April, 2024",
			tableSignInScans: 20,
			tableSignOutScans: "20",
		},
		{
			tableSN: "4",

			tableDate: "Thursday, 4th April, 2024",
			tableSignInScans: "20",
			tableSignOutScans: "20",
		},
		{
			tableSN: "5",

			tableDate: "Thursday, 4th April, 2024",
			tableSignInScans: "20",
			tableSignOutScans: "20",
		},
		{
			tableSN: "6",

			tableDate: "Thursday, 4th April, 2024",
			tableSignInScans: 20,
			tableSignOutScans: "20",
		},
		{
			tableSN: "7",

			tableDate: "Thursday, 4th April, 2024",
			tableSignInScans: "20",
			tableSignOutScans: "20",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData || tablesData }),
		{ getLogger, mainSearch, isFound, data, getDynamicLogger } =
			useAttendanceStore(),
		{ currentSelected } = useCompanyStore();

	useEffect(() => {
		let thisData = [];
		if (isFound) thisData = mainSearch;
		else thisData = data;

		if (showAttendance)
			thisData = {
				...thisData,
				docs: thisData?.docs?.filter(
					item => item?.purpose?.toLowerCase() === "check-in"
				),
			};
		if (showQrCode) {
			let groups = thisData?.docs?.reduce((groups, game) => {
				let date = moment(game?.createdAt).format("MM/DD/YYYY");
				if (!groups[date]) {
					groups[date] = [];
				}
				groups[date].push(game);
				return groups;
			}, {});
			const groupArrays = Object.keys(groups).map(date => {
				return {
					date,
					signin: groups[date]?.filter(item => item?.purpose === "check-in"),
					signout: groups[date]?.filter(item => item?.purpose === "check-out"),
				};
			});

			thisData = { ...thisData, docs: groupArrays };
		}

		setDatum(thisData);
	}, [data, isFound, mainSearch, showAttendance, showQrCode]);

	useEffect(() => {
		if (!currentSelected) return;
		apiCall({
			type: "get",
			url: `/api/v1/qr/${currentSelected}?_populate=user&_limit=0`,
			getter: d => getLogger(d),
		});
		apiCall({
			type: "post",
			url: `/api/v1/qr/${currentSelected}?_populate=user&_limit=0`,
			getter: d => getDynamicLogger(d, "qr"),
			noToast: true,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSelected]);

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	console.log(currentItems);

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Attendance Management
					</p>
				</div>

				<div className=" bg-white mx-12 mt-5 p-5">
					<div>
						<div className=" bg-white  ">
							<div className=" flex items-center justify-between">
								<div className=" flex items-center gap-5">
									<div className=" flex items-center gap-5">
										<p
											onClick={handleRequestsClick}
											className={` Satoshi font-normal cursor-pointer text-sm text-[#000] ${
												showAttendance === true
													? " text-[#5608D4]"
													: "bg-white text-[#1C1C1C]  "
											}`}>
											Attendance List
										</p>
										<p
											onClick={handleMyRequestsClick}
											className={`Satoshi font-normal cursor-pointer text-sm text-[#000] ${
												showQrCode === true
													? " text-[#5608D4]"
													: "bg-white text-[#1C1C1C]  "
											}`}>
											QR Code
										</p>
									</div>

									<div className=" flex items-center gap-3 ml-5">
										<p className=" text-[#121212]  text-sm font-light font-Nunito">
											Search {topText}
										</p>
										<div className="relative">
											<input
												placeholder="Search"
												className="w-48 h-9 pr-10 pl-3 placeholder:text-xs outline-none bg-[#F5F7F8] rounded-md"
												type="text"
											/>
											<div className="absolute inset-y-0 right-3 flex items-center pl-3">
												<FaSearch className="text-gray-500" />
											</div>
										</div>
									</div>
								</div>
								<div className=" cursor-pointer">
									<button
										onClick={handleId}
										// onClick={handleAction}
										className={`text-sm Satoshi text-white bg-black rounded-md w-32 h-9 animate-pulse ${
											showQrCode ? "" : "hidden"
										}`}>
										Generate code
									</button>
									{showId && <IdCardRequest onClose={closeId} />}
									{/* {addAction && <ActionCompleted onClose={closeAction} />}
                </div>
                {addFile && <AddAsset closeFile={closeFile} />} */}

									<div
										className={`flex items-center gap-5 ${
											showAttendance ? "" : "hidden"
										}`}>
										<div className=" flex items-center gap-2">
											<p className=" text-[#121212] font-Nunito text-xs">
												Choose date
											</p>
											<img
												className=" w-4 h-4"
												src={require("../../assets/Calendars.png")}
												alt=""
											/>
										</div>
										<div className=" flex items-center gap-2">
											<div className=" flex items-center justify-between border border-[#D0D5DD] rounded-md w-28 h-8 p-1">
												<input
													placeholder="04"
													className=" w-full h-full placeholder:text-sm placeholder-[#667085] px-2"
													type="text"
													value=""
												/>
												<img
													className=" h-4 w-4"
													src={require("../../assets/iconamoon_arrow-down-2-thin.png")}
													alt=""
												/>
											</div>
											<div className=" flex items-center justify-between border border-[#D0D5DD] rounded-md w-28 h-8 p-1">
												<input
													placeholder="April"
													className=" w-full h-full placeholder:text-sm placeholder-[#667085] px-2"
													type="text"
													value=""
												/>
												<img
													className=" h-4 w-4"
													src={require("../../assets/iconamoon_arrow-down-2-thin.png")}
													alt=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* Cards */}
							{showAttendance && (
								<div className="card">
									<table className="min-w-full  mt-5">
										<thead className="bg-gray-50 border-b-[0.5px] border-b-gray-200">
											<tr>
												{tableHead.map((head, index) => (
													<th
														key={index}
														className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
														{head}
													</th>
												))}
											</tr>
										</thead>
										<tbody className="bg-white ">
											{currentItems?.map((rowData, rowIndex) => (
												<tr key={rowIndex} className="">
													<td className="px-4 py-4  text-xs  text-gray-800">
														{rowIndex + 1}
													</td>
													<td className="px-4 py-4  text-xs  text-gray-800">
														{rowData?.user?.firstName ||
															rowData?.user?.profile?.firstName}{" "}
														{rowData?.user?.lastName ||
															rowData?.user?.profile?.lastName}
													</td>
													<td className="px-4 py-4 text-xs  text-gray-800">
														{rowData?.user?.profile?.employeeId ||
															rowData?.user?.employeeId}
													</td>
													<td className="px-4 py-4 text-xs  text-gray-800">
														{rowData?.user?.profile?.email ||
															rowData?.user?.email}
													</td>
													<td className="px-4 py-4 text-xs  text-gray-800">
														{moment(rowData?.createdAt).format(
															"DD/MM/YYYY hh:mm A"
														)}
													</td>
													<Link
														to=""
														className="px-4 py-4 text-xs  text-gray-800 cursor-pointer">
														{moment(
															datum?.docs
																?.filter(
																	item =>
																		item?.purpose?.toLowerCase() === "check-out"
																)
																?.find(
																	da => da?.user?._id === rowData?.user?._id
																)?.createdAt
														).format("DD/MM/YYYY hh:mm A")}
													</Link>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}

							{showQrCode && (
								<div className="card">
									<table className="min-w-full  mt-5">
										<thead className="bg-gray-50 border-b-[0.5px] border-b-gray-200">
											<tr>
												{tablesHead.map((head, index) => (
													<th
														key={index}
														className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
														{head}
													</th>
												))}
											</tr>
										</thead>
										<tbody className="bg-white ">
											{currentItems?.map((rowData, rowIndex) => (
												<tr key={rowIndex} className="">
													<td className="px-4 py-4  text-xs  text-gray-800">
														{rowIndex + 1}
													</td>

													<td className="px-4 py-4 text-xs  text-gray-800">
														{moment(rowData?.date).format("ddd Do, MMM")}
													</td>

													<td className="px-4 py-4 text-xs  text-gray-800">
														{rowData?.signin?.length}
													</td>
													<Link
														to="/request-details"
														className="px-4 py-4 text-xs  text-gray-800 cursor-pointer">
														{rowData?.signout?.length}
													</Link>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}

							{pageCount > 1 && (
								<MainPaginate
									pageCount={pageCount}
									handlePageClick={handlePageClick}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AttendanceManagement;
