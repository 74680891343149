import React from "react";

const FileTable = ({ children }) => {
  return (
    <div className="overflow-x-auto overflow-y-scroll">
      <table className="w-full bg-white">
        <thead>
          <tr className="bg-[#F7F9FC] text-[#667085] text-[13px] font-medium font-Nunito px-5">
            <th className="py-3 px-4 text-left">Name</th>
            <th className="py-3 px-4 text-left">Owner</th>
            <th className="py-3 px-4 text-left">Created on</th>
            <th className="py-3 px-4 text-left">Last modified</th>
            <th className="py-3 px-4 text-left">Modified by</th>
            <th className="py-3 px-4 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default FileTable;
