import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { Link } from "react-router-dom";
import AddRequests from "../../components/cards/AddRequests";
import { useRequestStore } from "../../data/Stores/LoggerStore";
import { apiCall } from "../../data/useGenFetcher";
import moment from "moment";
import { LoadMore, MainPaginate } from "../../components/buttons/Send";
import { createMarkup } from "../../components/cards/ReviewSubmit";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import Delete from "../../components/cards/Delete";

const Requests = () => {
	const [createNew, setCreateNew] = useState(false),
		[isDelete, setIsDelete] = useState(null);

	const requestcards = [
		{
			id: 1,
			img: require("../../assets/Studentcard.png"),
			idcard: "ID Card Request",
			desc: " Lorem ipsum dolor sit amet consectetur. Volutpat cras amet eros odio at facilisis.",
			date: " 04/04/2024",
			edit: require("../../assets/Edittext.png"),
			delete: require("../../assets/Bin.png"),
		},
		{
			id: 2,
			img: require("../../assets/Studentcard2.png"),
			idcard: "Cash Request",
			desc: " Lorem ipsum dolor sit amet consectetur. Volutpat cras amet eros odio at facilisis.",
			date: " 04/04/2024",
			edit: require("../../assets/Edittext.png"),
			delete: require("../../assets/Bin.png"),
		},
		{
			id: 3,
			img: require("../../assets/Studentcard3.png"),
			idcard: "Appraisal Request",
			desc: " Lorem ipsum dolor sit amet consectetur. Volutpat cras amet eros odio at facilisis.",
			date: " 04/04/2024",
			edit: require("../../assets/Edittext.png"),
			delete: require("../../assets/Bin.png"),
		},
		{
			id: 4,
			img: require("../../assets/Studentcard4.png"),
			idcard: "Requisition Request",
			desc: " Lorem ipsum dolor sit amet consectetur. Volutpat cras amet eros odio at facilisis.",
			date: " 04/04/2024",
			edit: require("../../assets/Edittext.png"),
			delete: require("../../assets/Bin.png"),
		},
		{
			id: 5,
			img: require("../../assets/Studentcard5.png"),
			idcard: "ID Card Request",
			desc: " Lorem ipsum dolor sit amet consectetur. Volutpat cras amet eros odio at facilisis.",
			date: " 04/04/2024",
			edit: require("../../assets/Edittext.png"),
			delete: require("../../assets/Bin.png"),
		},
		{
			id: 6,
			img: require("../../assets/Studentcard6.png"),
			idcard: "ID Card Request",
			desc: " Lorem ipsum dolor sit amet consectetur. Volutpat cras amet eros odio at facilisis.",
			date: " 04/04/2024",
			edit: require("../../assets/Edittext.png"),
			delete: require("../../assets/Bin.png"),
		},
	];

	let [datum, setDatum] = useState({ docs: requestcards }),
		{ getLogger, mainSearch, isFound, data, deleteLogger } = useRequestStore(),
		[items, setItems] = useState(null);

	const handleCreateNew = () => {
		setCreateNew(!createNew);
	};
	const handleCloseNew = () => {
		setItems(null);
		setCreateNew(false);
	};

	let [loading, setLoading] = useState(false),
		{ returnErrors } = useErrorStore();

	let handleSubmit = async e => {
		// e?.preventDefault();

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/request/${items?._id}`,
			type: "delete",
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response || response === "") {
			deleteLogger(items);
			setItems(null);
			setIsDelete(null);
			return;
		}
		setLoading(false);
	};

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/request`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/request?_limit=${data?.limit * data?.nextPage}`,
			getter: d => getLogger(d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Requests
					</p>
				</div>

				<div className=" bg-white mx-12 py-5 px-2 mt-5 pb-60  ">
					<div className=" flex justify-between items-center">
						<p className=" text-sm Satoshi font-bold text-[#000]">
							All Requests{" "}
							<span className=" text-sm Satoshi font-bold text-[#5608D4]">
								({data?.totalDocs})
							</span>{" "}
						</p>
						<button
							onClick={handleCreateNew}
							className=" Satoshi text-sm text-white bg-black w-28 h-10 rounded-md">
							Create new
						</button>
					</div>
					<div className=" grid grid-cols-6 justify-center mt-5 gap-6">
						{currentItems?.map((i, ind) => (
							<div
								className="w-[185px] h-auto rounded-md hover:bg-slate-300  shadow-lg space-y-2 bg-white px-3 pt-2 pb-1"
								key={ind}>
								<Link state={i} to="id-requests" className="">
									<img
										className=" w-6 h-6"
										src={requestcards?.[ind % requestcards?.length]?.img}
										alt=""
									/>
									<div className=" space-y-1">
										<p className=" font-bold Satoshi text-xs text-[#101828]">
											{i?.name}
										</p>
										<p
											className=" text-[10px] text-[#101828] Satoshi font-light"
											dangerouslySetInnerHTML={createMarkup(
												i?.description || ""
											)}
										/>
									</div>
								</Link>
								<div className=" flex justify-between items-center">
									<p className=" font-Nunito text-[10px] text-[#667085] font-light">
										{moment(i?.createdAt).format("DD/MM/YYYY")}
									</p>
									<div className=" flex items-center gap-4">
										<img
											onClick={() => {
												setItems(i);
												handleCreateNew();
											}}
											className=" w-3 h-3 cursor-pointer"
											src={requestcards?.[ind % requestcards?.length]?.edit}
											alt=""
										/>
										<img
											onClick={() => {
												setItems(i);
												setIsDelete(true);
											}}
											className=" w-3 h-3 cursor-pointer"
											src={requestcards?.[ind % requestcards?.length]?.delete}
											alt=""
										/>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="mt-10 flex justify-center w-44 items-center mx-auto">
						<LoadMore
							next={data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
					{isDelete && (
						<Delete
							onClose={() => {
								setIsDelete(null);
								setItems(null);
							}}
							deleteFunc={() => handleSubmit()}
							title={items?.name}
							loading={loading}
						/>
					)}
					{createNew && <AddRequests onClose={handleCloseNew} items={items} />}
				</div>
			</div>
		</div>
	);
};

export default Requests;
