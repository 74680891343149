import React, { useEffect, useState } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import PagesList from "../../../../components/pages-list/PagesList";
import { Link, useLocation } from "react-router-dom";
import Decline from "../../../../components/cards/Decline";
import Approve from "../../../../components/cards/Approve";
import { useResignationStore } from "../../../../data/Stores/LoggerStore";

const Resignation = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [isDecline, setIsDecline] = useState(false);
	const [isAccept, setIsAccept] = useState(false);

	const handleDecline = () => {
		setIsDecline(!isDecline);
	};
	const closeDecline = () => {
		setIsDecline(false);
	};
	const handleAccept = () => {
		setIsAccept(!isAccept);
	};
	const closeAccept = () => {
		setIsAccept(false);
	};

	let items = useLocation()?.state;
	let [state, setState] = useState(items),
		{ data } = useResignationStore();

	useEffect(() => {
		data?.docs?.map(it => it?._id === items?._id && setState(it));
	}, [data, items]);

	return (
		<div className=" bg-[#EFF6FC]">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Employee’s Details
					</p>
				</div>
				<div className=" flex items-center justify-between bg-[#EFF6FC] mx-12 p-5 mt-5">
					<div className=" flex items-center gap-20">
						<div className="grid gap-5">
							<div>
								<p className=" text-[#071C50] text-xl font-bold Satoshi">
									Catherine James
								</p>
								<div className=" flex items-center gap-3">
									<p className=" text-[#071C50] text-sm font-Nunito">
										catjames@gmail.com
									</p>
									<p className="text-[#071C50] text-sm font-Nunito">|</p>
									<p className="text-[#071C50] text-sm font-Nunito">
										+234 901 222 3333
									</p>
								</div>
								<div className=" flex items-center gap-3 mt-3">
									<img
										className=" w-4 h-4"
										src={require("../../../../assets/Linkedin.png")}
										alt=""
									/>
									<img
										className=" w-4 h-4"
										src={require("../../../../assets/Twitter.png")}
										alt=""
									/>
									<img
										className=" w-4 h-4"
										src={require("../../../../assets/Global.png")}
										alt=""
									/>
								</div>
							</div>
							<div>
								<Link
									to=""
									className=" underline text-[#F7AC25] font-Nunito font-bold text-base">
									Update
								</Link>
							</div>
						</div>
						<div></div>
					</div>
					<div className=" ">
						<img
							className=" h-24"
							src={require("../../../../assets/ELine.png")}
							alt=""
						/>
					</div>
					<div className=" flex items-center gap-20">
						<div className=" grid gap-4">
							<div className=" flex items-center justify-between gap-8">
								<p className="  text-gray-400 text-base Satoshi">Role</p>
								<p className=" bg-[#DDEAFB] text-[#071C50] text-xs grid justify-center items-center rounded-lg font-Nunito w-32 h-5">
									{state?.profile?.lastName}
								</p>
							</div>
							<div className=" flex items-center justify-between gap-8">
								<p className=" text-gray-400 text-base Satoshi font-light">
									Level
								</p>
								<p className=" bg-[#DDEAFB] text-xs font-Nunito text-[#071C50] w-32 h-5 rounded-lg grid justify-center items-center ">
									Intermediate
								</p>
							</div>
							<div className=" flex items-center gap-8">
								<p className=" text-gray-400 text-base Satoshi font-light">
									Employee ID
								</p>
								<p className="  text-xs font-Nunito text-[#071C50] w-32 h-5 grid justify-center items-start ">
									CEPH/OGBM/24/001
								</p>
							</div>
						</div>
						<div>
							<div className=" w-24 h-24 justify-center items-center grid rounded-full border-[0.5px] border-[#0811D4] bg-[] text-[#071C50] text-3xl font-Poppins font-bold">
								CJ
							</div>
						</div>
					</div>
				</div>

				<div className=" justify-end flex items-center mx-12 gap-10 bg-white p-5">
					<button
						onClick={handleDecline}
						className=" w-28 h-9 rounded-md text-sm font-Nunito border border-[#000] text-[#000]">
						Decline
					</button>
					{isDecline && <Decline onClose={closeDecline} />}
					<button
						onClick={handleAccept}
						className=" w-28 h-9 rounded-md text-sm font-Nunito bg-[#000] text-[#fff]">
						Accept
					</button>
					{isAccept && <Approve onClose={closeAccept} />}
				</div>
			</div>
		</div>
	);
};

export default Resignation;
