import { useEffect, useState } from "react";
import { MainAllCandidates } from "../../all-candidates";
import useCompanyStore from "../../../../data/Stores/CompanyStore";
import { useLocation } from "react-router-dom";
import { apiCall } from "../../../../data/useGenFetcher";

const AllCandidates = () => {
	const tableData = [
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "3janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "3janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "3janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "3janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "3janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "No Tools Assigned",
			StagesPassed: "12",
			Emailaddress: "janevic@gmail.com",
			Actions: "...",
		},
		{
			Name: "Jane Victor A.",
			JobDepartment: "IT/Design",

			DateApplied: "3rd April, 2024",
			StagesPassed: "12",
			Emailaddress: "janevic@gmail.com",
			Actions: "...",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData }),
		{ currentSelected } = useCompanyStore(),
		items = useLocation()?.state,
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (!currentSelected) return;
		apiCall({
			type: "get",
			url: `/api/v1/job-application/${currentSelected}?_populate=job&job=${items?.state?._id}`,
			getter: d => setDatum(d?.data || d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSelected, items]);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/job-application/${currentSelected}?_populate=job&job=${
				items?.state?._id
			}&_limit=${datum?.limit * datum?.nextPage}`,
			getter: d => setDatum(d?.data || d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<MainAllCandidates
			currentItems={currentItems}
			pageCount={pageCount}
			handlePageClick={handlePageClick}
			handleLoadMore={handleLoadMore}
			loading={loading}
			data={datum}
		/>
	);
};

export default AllCandidates;
