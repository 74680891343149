// ProgressChart.js
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register the elements with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const ProgressChart = ({ progress }) => {
  const data = {
    labels: ["Completed", "Remaining"],
    datasets: [
      {
        data: [progress, 100 - progress],
        backgroundColor: ["#5608d4", "#f5eeff"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className="w-28 h-28 relative">
      <Doughnut data={data} options={options} />
      <div className="absolute inset-0 flex justify-center items-center text-[#5608d4] text-lg font-bold">
        {progress}%
      </div>
    </div>
  );
};

export default ProgressChart;
