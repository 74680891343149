import React from "react";

const TimelineViewCard = () => {
  return (
    <div>
      <p>Timeline</p>
    </div>
  );
};

export default TimelineViewCard;
