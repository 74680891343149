import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./Authstore";

const AnnoucementStore = set => ({
	data: null,
	status: "",
	getSearchAnnoucement: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchAnnoucement"
		);
	},
	getSearch: payload => {
		set({ data: payload?.data || payload }, false, "getSearch");
	},
	resetAnnoucementSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getAnnoucement: payload => {
		set({ data: payload?.data || payload }, false, "getAnnoucement");
	},
	setCurrentAnnoucement: payload => {
		set(
			() => ({
				currentSelected: payload?._id || payload,
			}),
			false,
			"setCurrentAnnoucement"
		);
	},
	addAnnoucement: payload => {
		let data = payload?.data || payload;

		set(
			state => ({
				data: {
					...state?.data,
					docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
					totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
					docsTotal: state?.data?.docsTotal ? 1 + state?.data?.docsTotal : 1,
				},
				status: "added",
			}),
			false,
			"addAnnoucement"
		);
	},
	deleteAnnoucement: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: DeleteData(state?.data?.docs, data),
					totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
					docsTotal: state?.data?.docsTotal ? state?.data?.docsTotal - 1 : 0,
				},
				status: "deleted",
			}),
			false,
			"deleteAnnoucement"
		);
	},
	updateAnnoucement: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: EditData(state?.data?.docs, data),
				},
				status: "updated",
			}),
			false,
			"editAnnoucement"
		);
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutAnnoucement: () => {
		set({
			status: "",
			isFound: null,
			data: null,
			mainSearch: null,
			allAnnoucement: null,
		});
	},
});

const useAnnoucementStore = create(
	devtools(
		persist(AnnoucementStore, {
			name: "annoucement",
		})
	)
);

export default useAnnoucementStore;
