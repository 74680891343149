import React, { useEffect, useState } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import PagesList from "../../../../components/pages-list/PagesList";
// import DownloadProfile from "../../../../components/buttons/DownloadProfile";
import { Link } from "react-router-dom";
// import Delete from "../../../../components/cards/Delete";

export const CircularProgress = ({ progress }) => {
	const radius = 50; // Radius of the circle
	const circumference = 2 * Math.PI * radius; // Circumference of the circle
	const strokeDashoffset = circumference - (progress / 100) * circumference; // Stroke dash offset for the progress

	return (
		<div className=" w-20 h-20 relative">
			<svg className="w-full h-full" viewBox="0 0 100 100">
				{/* Gray circle */}
				<circle
					cx="50"
					cy="50"
					r={radius}
					fill="none"
					stroke="#E5E7EB"
					strokeWidth="8"
				/>
				{/* Colored circle representing progress */}
				<circle
					cx="50"
					cy="50"
					r={radius}
					fill="none"
					stroke="#10B981"
					strokeWidth="8"
					strokeDasharray={circumference}
					strokeDashoffset={strokeDashoffset}
					strokeLinecap="round"
					transform="rotate(-90 50 50)"
				/>
				{/* Progress text */}
				<text
					x="50"
					y="50"
					textAnchor="middle"
					dy="0.3em"
					className="text-gray-700 text-xs font-semibold">
					{`${progress}% Completed`}
				</text>
			</svg>
		</div>
	);
};

const RequestDetails = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [selectedReport] = useState("basic");
	// const [isDelete, setIsDelete] = useState(false);

	// const handleDelete = () => {
	//   setIsDelete(!isDelete);
	// };
	// const closeDelete = () => {
	//   setIsDelete(false);
	// };

	const basics = [
		{
			id: 1,

			text: "Type",
			dash: "-",
			desc: "Sick Unpaid Leave",
		},
		{
			id: 2,

			text: "Grade",
			dash: "-",
			desc: "Jane Victor A.",
		},
		{
			id: 3,

			text: "Days Left ",
			dash: "-",
			desc: "  30 days",
		},
		{
			id: 4,

			text: "Duration ",
			dash: "-",
			desc: " 30 days (3rd April - 2nd May 2024)",
		},
	];

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Leave Details
					</p>
				</div>
				<div className=" flex items-center justify-between bg-[#EFF6FC] mx-12 p-5 mt-5">
					<div className=" flex items-center">
						<div className="grid gap-5">
							<div>
								<p className=" text-[#071C50] text-xl font-bold Satoshi">
									Catherine James
								</p>
								<div className=" flex items-center gap-3">
									<p className=" text-[#071C50] text-sm font-Nunito">
										catjames@gmail.com
									</p>
									<p className="text-[#071C50] text-sm font-Nunito">|</p>
									<p className="text-[#071C50] text-sm font-Nunito">
										+234 901 222 3333
									</p>
								</div>
								<div className=" flex items-center gap-3 mt-3">
									<img
										className=" w-4 h-4"
										src={require("../../../../assets/Linkedin.png")}
										alt=""
									/>
									<img
										className=" w-4 h-4"
										src={require("../../../../assets/Twitter.png")}
										alt=""
									/>
									<img
										className=" w-4 h-4"
										src={require("../../../../assets/Global.png")}
										alt=""
									/>
								</div>
							</div>
							<div>
								<Link
									to=""
									className=" underline text-[#F7AC25] font-Nunito font-bold text-base">
									Declined
								</Link>
							</div>
						</div>
					</div>
					<div className=" ">
						<img
							className=" h-24"
							src={require("../../../../assets/ELine.png")}
							alt=""
						/>
					</div>
					<div className=" pr-10">
						<img
							className=" w-24 h-24"
							src={require("../../../../assets/Ellipse929.png")}
							alt=""
						/>
					</div>
					{/* <div className=" flex items-center gap-20">
            <div className=" grid gap-4">
              <div className=" flex items-center justify-between gap-8">
                <p className="  text-gray-400 text-base Satoshi">Role</p>
                <p className=" bg-[#DDEAFB] text-[#071C50] text-xs grid justify-center items-center rounded-lg font-Nunito w-32 h-5">
                  Technical
                </p>
              </div>
              <div className=" flex items-center justify-between gap-8">
                <p className=" text-gray-400 text-base Satoshi font-light">
                  Level
                </p>
                <p className=" bg-[#DDEAFB] text-xs font-Nunito text-[#071C50] w-32 h-5 rounded-lg grid justify-center items-center ">
                  Intermediate
                </p>
              </div>
              <div className=" flex items-center gap-8">
                <p className=" text-gray-400 text-base Satoshi font-light">
                  Employee ID
                </p>
                <p className="  text-xs font-Nunito text-[#071C50] w-32 h-5 grid justify-center items-start ">
                  CEPH/OGBM/24/001
                </p>
              </div>
            </div>
            <div>
              <CircularProgress progress={50} />
            </div>
          </div> */}
				</div>
				<div className=" bg-white mx-12 p-5 flex justify-between">
					{selectedReport === "basic" && (
						<div className="grid gap-4 w-1/2">
							{basics.map(i => (
								<div className=" flex justify-between">
									<div className=" flex items-center gap-2">
										<div className=" flex gap-1">
											<p className=" Satoshi text-[#071C50] text-sm font-bold">
												{i.text}
											</p>
											<p className="Satoshi text-[#071C50] text-sm font-bold">
												{i.dash}
											</p>
										</div>
									</div>
									<div className=" w-96 text-start">{i.desc}</div>
								</div>
							))}
						</div>
					)}

					<div className=" flex items-center gap-5 ">
						<div className=" bg-[#EFF6FC] w-28 h-6 flex gap-2 items-center justify-center">
							<p className=" font-Nunito text-sm text-[#262626]">Approve</p>
							<img
								className=" w-4 h-4"
								src={require("../../../../assets/Approve2.png")}
								alt=""
							/>
						</div>
						<div className=" bg-[#FCEFFA] w-28 h-6 flex gap-2 items-center justify-center">
							<p className=" font-Nunito text-sm text-[#262626]">Decline</p>
							<img
								className=" w-4 h-4"
								src={require("../../../../assets/Decline.png")}
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RequestDetails;
