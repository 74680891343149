import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import { Link } from "react-router-dom";
import Delete from "../../components/cards/Delete";
import CreatePost from "../../components/cards/CreatePost";

const Announcements = () => {
  const [isDeleteAnnouncement, setIsDeleteAnnouncement] = useState(false);
  const [isEditAnnouncement, setIsEditAnnouncement] = useState(false);

  const handleDeleteAnnouncement = () => {
    setIsDeleteAnnouncement(!isDeleteAnnouncement);
  };
  const closeDeleteAnnouncement = () => {
    setIsDeleteAnnouncement(!isDeleteAnnouncement);
  };

  const handleEditAnnouncement = () => {
    setIsEditAnnouncement(!isEditAnnouncement);
  };
  const closeditAnnouncement = () => {
    setIsEditAnnouncement(false);
  };

  return (
    <div className=" bg-[#fff] pb-10">
      <Navbar />
      <PagesList />
      <div className="bg-[#F5F7F8] w-full  ">
        <div className=" mx-12 pt-4 flex gap-5">
          <Link
            to="/onboarding"
            className="  font-Kumbh cursor-pointer text-base font-medium text-[#000]"
          >
            Announcement
          </Link>
        </div>

        <div className=" mx-12 bg-white p-5">
          <div className=" grid h-auto items-end">
            <div className=" flex mt-10 items-center gap-10 ">
              <button
                onClick={handleDeleteAnnouncement}
                className="  w-44  h-11 rounded-md border border-[#D4082D] text-[#D4082D] animate-pulse"
              >
                Delete announcement
              </button>
              {isDeleteAnnouncement && (
                <Delete onClose={closeDeleteAnnouncement} />
              )}
              <button
                onClick={handleEditAnnouncement}
                className="  w-44  h-11 rounded-md bg-gradient-to-r from-[#0811D4] to-[#04096E] text-white animate-pulse"
              >
                Edit Announcement
              </button>
              {isEditAnnouncement && (
                <CreatePost onClose={closeditAnnouncement} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcements;
