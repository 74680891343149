import React from "react";
import { FaSearch } from "react-icons/fa";

const SearchInput = () => {
  return (
    <div>
      <div className=" flex items-center gap-3">
        <div className="relative">
          <input
            className="w-48 h-9 pr-10 pl-3 outline-none bg-[#fff] border border-[#ACC3E7] rounded-md"
            type="text"
          />
          <div className="absolute inset-y-0 right-3 flex items-center pl-3">
            <FaSearch className="text-gray-500" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
