import React from "react";
import jobdetailsimg from "../../assets/jobdetailsimg.png";

const JobTitleComponent = () => {
  return (
    <div>
      <div className=" flex items-center gap-5 p-10 bg-gradient-to-r from-[#AC84FF]  to-[#674F99]">
        <img className=" w-32 h-32" src={jobdetailsimg} alt="" />
        <div className=" grid gap-2">
          <p className="text-white Satoshi text-3xl font-bold">
            Senior Software Engineer
            <span className=" text-white Satoshi text-3xl font-medium">
              {" "}
              - Javascript, NodeJs, ReactJs, Golang
            </span>
          </p>
          <div className=" flex items-center gap-10">
            <div className=" flex items-center gap-2">
              <div className=" bg-white w-1 h-1 rounded-full"></div>
              <small className=" font-Nunito text-xl text-white">
                CodeRight Company & Co
              </small>
            </div>
            <div className=" flex items-center gap-2">
              <div className=" bg-white w-1 h-1 rounded-full"></div>
              <small className=" font-Nunito text-xl text-white">
                3 - 5 years
              </small>
            </div>
            <div className=" flex items-center gap-2">
              <div className=" bg-white w-1 h-1 rounded-full"></div>
              <small className=" font-Nunito text-xl text-white">
                Full - Time
              </small>
            </div>
            <div className=" flex items-center gap-2">
              <div className=" bg-white w-1 h-1 rounded-full"></div>
              <small className=" font-Nunito text-xl text-white">Remote</small>
            </div>
          </div>
          <div className=" mt-8">
            <p className=" text-white text-base font-normal font-Nunito">
              Posted at:{" "}
              <span className="text-white text-base font-medium font-Nunito">
                23rd Apr, 2024
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobTitleComponent;
