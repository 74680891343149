import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import AllChats from "../../components/chats/AllChats";
import PersonalChats from "../../components/chats/PersonalChats";
import GroupChats from "../../components/chats/GroupChats";
import DepartmentChats from "../../components/chats/DepartmentChats";
import AddPhotos from "../../assets/AddPhotos.png";

const Chats = () => {
  const [selected, setSelected] = useState("All");
  const [isNewChat, setIsNewChat] = useState(false);

  const members = [
    {
      image: "",
      name: "Ella James",
      ceo: "- CEO",
      number: "+234 800 111 2222",
    },
    {
      image: "",
      name: "Ella James",
      ceo: "- CEO",
      number: "+234 800 111 2222",
    },
    {
      image: "",
      name: "Ella James",
      ceo: "- CEO",
      number: "+234 800 111 2222",
    },
  ];

  return (
    <div className=" bg-[#EFF6FC] pb-10 ">
      <Navbar />
      <PagesList />

      <div className=" flex justify-between items-center lg:px-12 md:px-10 px-8  pt-4">
        <p className=" font-Kumbh text-[#000] text-base font-semibold">Chats</p>
      </div>

      <div className=" lg:mx-12 md:mx-8 mx-0 mt-5 bg-white py-5 pl-5">
        <div className="lg:flex md:flex grid grid-cols-3 items-center gap-10">
          {/* All */}
          <div
            className="grid justify-center"
            onClick={() => setSelected("All")}
          >
            <div className="flex justify-center">
              {selected === "All" && (
                <p className="w-1 h-1 rounded-full bg-[#5608D4]"></p>
              )}
            </div>
            <div className="flex justify-center">
              <p
                className={`text-sm Satoshi cursor-pointer ${
                  selected === "All" ? "text-[#5608D4]" : "text-[#636E72]"
                }`}
              >
                All
              </p>
            </div>
          </div>

          {/* Personal */}
          <div
            className="grid justify-center"
            onClick={() => setSelected("Personal")}
          >
            <div className="flex justify-center">
              {selected === "Personal" && (
                <p className="w-1 h-1 rounded-full bg-[#5608D4]"></p>
              )}
            </div>
            <div className="flex justify-center gap-2">
              <p
                className={`text-sm Satoshi cursor-pointer ${
                  selected === "Personal" ? "text-[#5608D4]" : "text-[#636E72]"
                }`}
              >
                Personal
              </p>
              {/* <div className="">
                {selected === "Personal" && (
                  <img
                    className=" w-5 h-5"
                    src={require("../../assets/personalVector.png")}
                    alt=""
                  />
                  // <p className="w-1 h-1 rounded-full bg-[#5608D4]"></p>
                )}
              </div> */}
            </div>
          </div>
          {/* Department*/}
          <div
            className="grid justify-center"
            onClick={() => setSelected("Department")}
          >
            <div className="flex justify-center">
              {selected === "Department" && (
                <p className="w-1 h-1 rounded-full bg-[#5608D4]"></p>
              )}
            </div>
            <div className="flex justify-center gap-2">
              <p
                className={`text-sm Satoshi cursor-pointer ${
                  selected === "Department"
                    ? "text-[#5608D4]"
                    : "text-[#636E72]"
                }`}
              >
                Department
              </p>
              {/* <div className="">
                {selected === "Department" && (
                  <img
                    className=" w-5 h-5"
                    src={require("../../assets/personalVector.png")}
                    alt=""
                  />
                  // <p className="w-1 h-1 rounded-full bg-[#5608D4]"></p>
                )}
              </div> */}
            </div>
          </div>

          {/* Groups */}
          <div
            className="grid justify-center"
            onClick={() => setSelected("Groups")}
          >
            <div className="flex justify-center">
              {selected === "Groups" && (
                <p className="w-1 h-1 rounded-full bg-[#5608D4]"></p>
              )}
            </div>
            <div className="flex justify-center gap-2">
              <p
                className={`text-sm Satoshi cursor-pointer ${
                  selected === "Groups" ? "text-[#5608D4]" : "text-[#636E72]"
                }`}
              >
                Groups
              </p>
              <div className="flex justify-center">
                {selected === "Groups" && (
                  <img
                    onClick={() => setIsNewChat(!isNewChat)}
                    className=" w-5 h-5"
                    src={require("../../assets/personalVector.png")}
                    alt=""
                  />
                )}
              </div>
              {isNewChat && (
                <div className=" absolute bg-white w-[384px]  z-50 mt-10 px-5 pt-5 pb-5 shadow-lg">
                  <div className=" flex justify-between">
                    <p className=" font-Kumbh text-[20px] text-[#000] font-semibold">
                      New Group
                    </p>
                    <p
                      onClick={() => setIsNewChat(false)}
                      className="  font-Kumbh text-[20px] font-semibold text-[#000] cursor-pointer"
                    >
                      &times;
                    </p>
                  </div>
                  <div className=" h-[300px] w-full overflow-y-scroll">
                    <div className="flex items-center relative w-52 mt-4">
                      <input
                        className="pl-2 pr-5 outline-none text-xs border border-[#ACC3E7] w-52 h-9 rounded-lg"
                        type="text"
                      />
                      <img
                        className="absolute right-3 w-4 h-4"
                        src={require("../../assets/searchicon.png")}
                        alt="Search"
                      />
                    </div>

                    <div className=" justify-center grid items-center mt-4">
                      <img className=" w-40 h-40" src={AddPhotos} alt="" />
                    </div>

                    <div className=" grid gap-1 mt-4">
                      <label
                        className=" text-[#121212] font-Nunito text-sm"
                        htmlFor=""
                      >
                        Group Name
                      </label>
                      <input
                        className="pl-2 pr-5 outline-none text-xs border border-[#ACC3E7] h-9 rounded-lg"
                        type="text"
                      />
                    </div>
                    <div className=" mt-5">
                      <div>
                        <p className=" font-Kumbh font-medium text-sm text-[#000]">
                          Add members
                        </p>

                        <div className=" mt-4 grid gap-2">
                          {members.map((i) => (
                            <div className=" flex items-center gap-2 shadow-lg h-16 rounded-md px-2">
                              <img className=" w-10 h-10" src={i.img} alt="" />
                              <div>
                                <p className=" text-[13px] text-[#000] Satoshi">
                                  {i.name}
                                  <span className=" text-[#B0ADB4] text-[13px] Satoshi ">
                                    {" "}
                                    {i.ceo}
                                  </span>
                                </p>
                                <p className=" font-thin font-Nunito text-[12px]">
                                  {i.number}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className=" mt-5">
                      <button className=" w-44 h-8 rounded-full bg-gradient-to-b from-[#5608d4] to-[#384285] font-Nunito text-sm text-white ">
                        Create Group
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center gap-5">
            <p className="text-xs font-Nunito text-[#121212]">Search Chats</p>
            <div className="flex items-center relative">
              <input
                className="pl-2 pr-5 outline-none text-xs border border-[#ACC3E7] w-52 h-9 rounded-lg"
                type="text"
              />
              <img
                className="absolute right-3 w-4 h-4"
                src={require("../../assets/searchicon.png")}
                alt="Search"
              />
            </div>
          </div>
        </div>

        {/* Conditional rendering of content based on selected item */}
        <div className="mt-5">
          {selected === "All" && <AllChats />}
          {selected === "Personal" && <PersonalChats />}
          {selected === "Department" && <DepartmentChats />}
          {selected === "Groups" && <GroupChats />}
        </div>
      </div>
    </div>
  );
};

export default Chats;
