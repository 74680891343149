import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
// import IdCardRequest from "../../../components/cards/IdCardRequest";
import NewAdmin from "../../../components/cards/NewAdmin";
import useCompanyStore from "../../../data/Stores/CompanyStore";
import { useManageAdminsStore } from "../../../data/Stores/LoggerStore";
import { apiCall } from "../../../data/useGenFetcher";
import usePositionStore from "../../../data/Stores/PositionStore";
import {
	Button,
	LoadMore,
	MainPaginate,
	ModalContainer,
} from "../../../components/buttons/Send";
import { toast } from "react-toastify";
import useErrorStore from "../../../data/Stores/errorstore";

const Table = ({ tableHead, tableData, gradeforPosition, setItems }) => (
	<div className="card">
		<table className="min-w-full  mt-5">
			<thead className="bg-gray-50 border-b-[0.5px] border-b-gray-200">
				<tr>
					{tableHead.map((head, index) => (
						<th
							key={index}
							className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
							{head}
						</th>
					))}
				</tr>
			</thead>
			<tbody className="bg-white divide-y divide-gray-200">
				{tableData?.map((rowData, rowIndex) => (
					<tr key={rowIndex} className="border-t-[0.5px] border-t-gray-200">
						<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
							{rowIndex + 1}
						</td>
						<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
							{rowData?.firstName} {rowData?.lastName}
						</td>
						<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800 capitalize">
							{rowData?.type}
						</td>
						{gradeforPosition && (
							<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
								{rowData?.[gradeforPosition]?.map((it, x) => (
									<span key={x}>
										{it?.name}
										{x === rowData?.[gradeforPosition]?.length - 1 ? "" : ", "}
									</span>
								))}
							</td>
						)}
						<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
							{rowData?.email}
						</td>
						<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
							{rowData?.employeeId}
						</td>
						<td className="px-4 py-4 text-xs border-b-[0.5] text-[#0811D4] boray-200 ">
							<div className="flex gap-4 justify-center items-center">
								<Link
									state={rowData}
									to="/manage-employees/employee’s-details"
									className="text-blue-500 hover:underline">
									...
								</Link>

								<img
									onClick={() => {
										setItems(rowData);
									}}
									className=" w-3 h-3 cursor-pointer"
									src={require("../../../assets/Bin.png")}
									alt=""
								/>
							</div>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

const Admins = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [showAdminsCard, setShowAdminsCard] = useState(true);
	const [showExecutivesCard, setShowExecutivesCard] = useState(false);
	const [showManagersCard, setShowManagersCard] = useState(false);
	const [showDM1Card, setShowDM1Card] = useState(false);
	const [showDM2Card, setShowDM2Card] = useState(false);

	const [showAdminsCreateNew, setShowAdminsCreateNew] = useState(false);
	const [showExecutivesCreateNew, setShowExecutivesCreateNew] = useState(false);
	const [showManagersCreateNew, setShowManagersCreateNew] = useState(false);
	const [showDM1CreateNew, setShowDM1CreateNew] = useState(false);
	const [showDM2CreateNew, setShowDM2CreateNew] = useState(false);

	const closeAdminsCreateNew = () => {
		setShowAdminsCreateNew(false);
	};
	const closeExecutivesCreateNew = () => {
		setShowExecutivesCreateNew(false);
	};
	const closeManagersCreateNew = () => {
		setShowManagersCreateNew(false);
	};
	const closeDM1CreateNew = () => {
		setShowDM1CreateNew(false);
	};
	const closeDM2CreateNew = () => {
		setShowDM2CreateNew(false);
	};

	const handleAdminsClick = () => {
		setShowAdminsCard(true);
		setShowExecutivesCard(false);
		setShowManagersCard(false);
		setShowDM1Card(false);
		setShowDM2Card(false);
	};

	const handleExecutivesClick = () => {
		setShowAdminsCard(false);
		setShowExecutivesCard(true);
		setShowManagersCard(false);
		setShowDM1Card(false);
		setShowDM2Card(false);
	};

	const handleManagersClick = () => {
		setShowAdminsCard(false);
		setShowExecutivesCard(false);
		setShowManagersCard(true);
		setShowDM1Card(false);
		setShowDM2Card(false);
	};

	const handleDM1Click = () => {
		setShowAdminsCard(false);
		setShowExecutivesCard(false);
		setShowManagersCard(false);
		setShowDM1Card(true);
		setShowDM2Card(false);
	};

	const handleDM2Click = () => {
		setShowAdminsCard(false);
		setShowExecutivesCard(false);
		setShowManagersCard(false);
		setShowDM1Card(false);
		setShowDM2Card(true);
	};

	const tableHeadAdmins = [
		"S/N",
		"Name",
		"Admin Type",
		"Email Address",
		"Employee ID",
		"Actions",
	];

	const tableHeadDM = [
		"S/N",
		"Name",
		"Admin Type",
		"Grade for Position",
		"Email Address",
		"Employee ID",
		"Actions",
	];

	const tableDataDM1 = [
		{
			SN: "1",
			Name: "Catherine James",
			AdminType: "Staff",
			GradeforPosition: "Grade for Position",
			EmailAddress: "cathyjames@gmail.com",
			EmployeeID: "CeHR/001A",
			Actions: "...",
		},
		{
			SN: "2",
			Name: "Catherine James",
			AdminType: "Staff",
			GradeforPosition: "Grade for Position",
			EmailAddress: "cathyjames@gmail.com",
			EmployeeID: "CeHR/001A",
			Actions: "...",
		},
		{
			SN: "3",
			Name: "Catherine James",
			AdminType: "Staff",
			GradeforPosition: "Grade for Position",
			EmailAddress: "cathyjames@gmail.com",
			EmployeeID: "CeHR/001A",
			Actions: "...",
		},
		{
			SN: "4",
			Name: "Catherine James",
			AdminType: "Staff",
			GradeforPosition: "Grade for Position",
			EmailAddress: "cathyjames@gmail.com",
			EmployeeID: "CeHR/001A",
			Actions: "...",
		},
		{
			SN: "5",
			Name: "Catherine James",
			AdminType: "Staff",
			GradeforPosition: "Grade for Position",
			EmailAddress: "cathyjames@gmail.com",
			EmployeeID: "CeHR/001A",
			Actions: "...",
		},
		{
			SN: "6",
			Name: "Catherine James",
			AdminType: "Staff",
			GradeforPosition: "Grade for Position",
			EmailAddress: "cathyjames@gmail.com",
			EmployeeID: "CeHR/001A",
			Actions: "...",
		},
		{
			SN: "7",
			Name: "Catherine James",
			AdminType: "Staff",
			GradeforPosition: "Grade for Position",
			EmailAddress: "cathyjames@gmail.com",
			EmployeeID: "CeHR/001A",
			Actions: "...",
		},
	];

	let [datum, setDatum] = useState({ docs: tableDataDM1 }),
		{
			getDynamicLogger,
			mainSearch,
			isFound,
			admins,
			executive,
			manager1,
			manager2,
			managers,
			deleteDynamicLogger,
		} = useManageAdminsStore(),
		position = usePositionStore(),
		{ currentSelected, data: companyData, getCompany } = useCompanyStore(),
		[loading, setLoading] = useState(false),
		[findCompMain, setFindComp] = useState(null),
		[userId, setUser] = useState(""),
		[gradeForPosition, setGradeForPosition] = useState(null),
		{ returnErrors } = useErrorStore();

	useEffect(() => {
		if (currentSelected) {
			let findComp = companyData?.docs?.find(
					item => item?._id === currentSelected
				),
				mainPostion = position?.data?.docs || [];
			setFindComp(findComp);
			// console.log({ findComp });
			apiCall({
				type: "get",
				getter: d => getDynamicLogger(d, "admins"),
				url: `/api/v1/user/users/${currentSelected}?_id===${findComp?.admins?.toString()}`,
			});
			apiCall({
				type: "get",
				getter: d => getDynamicLogger(d, "managers"),
				url: `/api/v1/user/users/${currentSelected}?_id===${findComp?.managers?.toString()}`,
			});
			apiCall({
				type: "get",
				getter: d => getDynamicLogger(d, "manager1"),
				url: `/api/v1/user/users/${currentSelected}?gradeForPosition1===${mainPostion
					?.map(it => it?._id)
					?.toString()}&_populate=gradeForPosition1&_populate=gradeForPosition2`,
			});
			apiCall({
				type: "get",
				getter: d => getDynamicLogger(d, "manager2"),
				url: `/api/v1/user/users/${currentSelected}?gradeForPosition2===${mainPostion
					?.map(it => it?._id)
					?.toString()}&_populate=gradeForPosition1&_populate=gradeForPosition2`,
			});
			apiCall({
				type: "get",
				getter: d => getDynamicLogger(d, "executive"),
				url: `/api/v1/user/users/${currentSelected}?_id===${findComp?.executive?.toString()}`,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyData, currentSelected, position?.data]);

	useEffect(() => {
		if (userId) {
			if (showDM2Card === 4) setGradeForPosition(userId?.gradeForPosition2);
			else setGradeForPosition(userId?.gradeForPosition1);
		}
	}, [userId, showDM2Card]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: "/api/v1/position?_populate=department&_limit=0",
			getter: d => position?.getPosition(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isFound) setDatum(mainSearch || { docs: [] });
		else if (showDM2Card) setDatum(manager2 || { docs: [] });
		else if (showDM1Card) setDatum(manager1 || { docs: [] });
		else if (showManagersCard) setDatum(managers || { docs: [] });
		else if (showExecutivesCard) setDatum(executive || { docs: [] });
		else setDatum(admins || { docs: [] });
	}, [
		admins,
		mainSearch,
		isFound,
		managers,
		manager1,
		manager2,
		executive,
		showAdminsCard,
		showExecutivesCard,
		showDM1Card,
		showDM2Card,
		showManagersCard,
	]);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		if (showAdminsCard)
			apiCall({
				type: "get",
				getter: d => getDynamicLogger(d, "admins"),
				url: `/api/v1/user/users/${currentSelected}?_id===${findCompMain?.admins?.toString()}&_limit=${
					datum?.limit * datum?.nextPage
				}`,
			});
		if (showManagersCard)
			apiCall({
				type: "get",
				getter: d => getDynamicLogger(d, "managers"),
				url: `/api/v1/user/users/${currentSelected}?_id===${findCompMain?.managers?.toString()}&_limit=${
					datum?.limit * datum?.nextPage
				}`,
			});
		if (showDM1Card)
			apiCall({
				type: "get",
				getter: d => getDynamicLogger(d, "manager1"),
				url: `/api/v1/user/users/${currentSelected}?gradeForPosition1===${position?.data?.docs
					?.map(it => it?._id)
					?.toString()}&_populate=gradeForPosition1&_populate=gradeForPosition2&_limit=${
					datum?.limit * datum?.nextPage
				}`,
			});
		if (showDM2Card)
			apiCall({
				type: "get",
				getter: d => getDynamicLogger(d, "manager2"),
				url: `/api/v1/user/users/${currentSelected}?gradeForPosition2===${position?.data?.docs
					?.map(it => it?._id)
					?.toString()}&_populate=gradeForPosition1&_populate=gradeForPosition2&_limit=${
					datum?.limit * datum?.nextPage
				}`,
			});
		if (showExecutivesCard)
			apiCall({
				type: "get",
				getter: d => getDynamicLogger(d, "executive"),
				url: `/api/v1/user/users/${currentSelected}?_id===${findCompMain?.executive?.toString()}&_limit=${
					datum?.limit * datum?.nextPage
				}`,
			});
		setLoading(false);
	};

	const handleSubmit = type => async e => {
		e?.preventDefault();
		if (!userId) return;

		let newG = gradeForPosition
			?.filter(it => it?.isChecked)
			?.map(it => it?._id);
		if (showDM2Card || showDM1Card) {
			if (newG?.length === 0)
				return toast.info("Please select position(s) to remove");
		}

		setLoading(type);
		let dd = {};
		if (showDM2Card || showDM1Card) {
			dd.gradeForPosition = newG;
		}
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/company/remove/${type}/${currentSelected}/${userId?._id}`,
			type: "put",
			data: dd,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			deleteDynamicLogger(
				response,
				["admin", "manager"]?.includes(type) ? `${type}s` : type
			);
			setUser(null);
			setGradeForPosition(null);
			apiCall({
				type: "get",
				url: "/api/v1/company",
				getter: d => getCompany(d),
			});
			return;
		}
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Company Settings
					</p>
				</div>

				<div className=" bg-white mx-12 mt-5 p-5">
					<div className=" bg-white  ">
						<div className=" flex items-center justify-between">
							<div className=" flex items-center gap-5">
								<div className="flex items-center gap-5">
									<p
										onClick={handleAdminsClick}
										className={`Satoshi font-normal cursor-pointer text-sm text-[#000] ${
											showAdminsCard
												? "text-[#5608D4]"
												: "bg-white text-[#1C1C1C]"
										}`}>
										Admins
									</p>
									<p
										onClick={handleExecutivesClick}
										className={`Satoshi font-normal cursor-pointer text-sm text-[#000] ${
											showExecutivesCard
												? "text-[#5608D4]"
												: "bg-white text-[#1C1C1C]"
										}`}>
										Executives
									</p>
									<p
										onClick={handleManagersClick}
										className={`Satoshi font-normal cursor-pointer text-sm text-[#000] ${
											showManagersCard
												? "text-[#5608D4]"
												: "bg-white text-[#1C1C1C]"
										}`}>
										Managers
									</p>
									<p
										onClick={handleDM1Click}
										className={`Satoshi font-normal cursor-pointer text-sm text-[#000] ${
											showDM1Card ? "text-[#5608D4]" : "bg-white text-[#1C1C1C]"
										}`}>
										DM 1
									</p>
									<p
										onClick={handleDM2Click}
										className={`Satoshi font-normal cursor-pointer text-sm text-[#000] ${
											showDM2Card ? "text-[#5608D4]" : "bg-white text-[#1C1C1C]"
										}`}>
										DM 2
									</p>
								</div>

								<div className=" flex items-center gap-3">
									<p className=" text-[#121212]  text-sm font-light font-Nunito">
										Search requests
									</p>
									<div className="relative">
										<input
											placeholder="Search"
											className="w-48 h-9 pr-10 pl-3 placeholder:text-xs outline-none bg-[#F5F7F8] rounded-md"
											type="text"
										/>
										<div className="absolute inset-y-0 right-3 flex items-center pl-3">
											<FaSearch className="text-gray-500" />
										</div>
									</div>
								</div>
							</div>
							<div className=" cursor-pointer">
								{/* Create new buttons */}
								<button
									onClick={() => setShowAdminsCreateNew(true)}
									className={`text-sm Satoshi text-white bg-black rounded-md w-28 h-9 animate-pulse ${
										showAdminsCard ? "block" : "hidden"
									}`}>
									Create new
								</button>
								{showAdminsCreateNew && (
									<NewAdmin
										title="Admin"
										onClose={closeAdminsCreateNew}
										type="admin"
									/>
								)}
								<button
									onClick={() => setShowExecutivesCreateNew(true)}
									className={`text-sm Satoshi text-white bg-black rounded-md w-28 h-9 animate-pulse ${
										showExecutivesCard ? "block" : "hidden"
									}`}>
									Create new
								</button>
								{showExecutivesCreateNew && (
									<NewAdmin
										title="Executive"
										onClose={closeExecutivesCreateNew}
										type="executive"
									/>
								)}
								<button
									onClick={() => setShowManagersCreateNew(true)}
									className={`text-sm Satoshi text-white bg-black rounded-md w-28 h-9 animate-pulse ${
										showManagersCard ? "block" : "hidden"
									}`}>
									Create new
								</button>
								{showManagersCreateNew && (
									<NewAdmin
										title="Manager"
										onClose={closeManagersCreateNew}
										type="manager"
									/>
								)}
								<button
									onClick={() => setShowDM1CreateNew(true)}
									className={`text-sm Satoshi text-white bg-black rounded-md w-28 h-9 animate-pulse ${
										showDM1Card ? "block" : "hidden"
									}`}>
									Create new
								</button>
								{showDM1CreateNew && (
									<NewAdmin
										title="DM 1"
										onClose={closeDM1CreateNew}
										type="manager1"
									/>
								)}
								<button
									onClick={() => setShowDM2CreateNew(true)}
									className={`text-sm Satoshi text-white bg-black rounded-md w-28 h-9 animate-pulse ${
										showDM2Card ? "block" : "hidden"
									}`}>
									Create new
								</button>
								{showDM2CreateNew && (
									<NewAdmin
										title="DM 2"
										onClose={closeDM2CreateNew}
										type="manager2"
									/>
								)}

								{/* {showId && <IdCardRequest onClose={() => setShowId(false)} />} */}
							</div>
						</div>

						{/* Cards */}
						{showAdminsCard && (
							<Table
								tableHead={tableHeadAdmins}
								tableData={currentItems}
								setItems={setUser}
							/>
						)}

						{/* card2 */}
						{showExecutivesCard && (
							<Table
								tableHead={tableHeadAdmins}
								tableData={currentItems}
								setItems={setUser}
							/>
						)}
						{/* card3 */}
						{showManagersCard && (
							<Table
								tableHead={tableHeadAdmins}
								tableData={currentItems}
								setItems={setUser}
							/>
						)}
						{/* card4 */}
						{showDM1Card && (
							<Table
								tableHead={tableHeadDM}
								tableData={currentItems}
								gradeforPosition={"gradeForPosition1"}
								setItems={setUser}
							/>
						)}
						{/* card5 */}
						{showDM2Card && (
							<Table
								tableHead={tableHeadDM}
								tableData={currentItems}
								gradeforPosition={"gradeForPosition2"}
								setItems={setUser}
							/>
						)}
						<div className="mt-10 flex justify-center w-44 items-center mx-auto">
							<LoadMore
								next={datum?.hasNextPage}
								loading={loading === "loadmore"}
								handleLoadMore={handleLoadMore}
							/>
						</div>
						{pageCount > 1 && (
							<MainPaginate
								pageCount={pageCount}
								handlePageClick={handlePageClick}
							/>
						)}
						<ModalContainer
							title={`Remove ${
								showDM2Card
									? "DM 2"
									: showDM1Card
									? "DM"
									: showManagersCard
									? "Manager"
									: showExecutivesCard
									? "Executive"
									: "Admin"
							}`}
							width={"max-w-sm"}
							show={userId ? true : false}
							close={() => {
								setUser(null);
								setGradeForPosition(null);
							}}>
							<div className={showDM2Card || showDM1Card ? "" : "mx-20"}>
								<form className="space-y-4">
									<div className="my-auto w-100">
										<p className="text2 Lexend text-center">
											{showDM2Card || showDM1Card ? (
												<>Please select position(s) to remove</>
											) : (
												<>
													Do you want to remove{" "}
													{userId?.lastName || userId?.profile?.lastName}?
												</>
											)}
										</p>
										{showDM2Card || showDM1Card ? (
											<div className="grid grid-cols-1 py-5 gap-2">
												{gradeForPosition?.map((it, i) => (
													<div
														onClick={() => {
															let newM = gradeForPosition;
															setGradeForPosition([
																...newM?.map(ic =>
																	ic?._id === it?._id
																		? {
																				...ic,
																				isChecked: ic?.isChecked ? false : true,
																		  }
																		: ic
																),
															]);
														}}
														className={`bg flex justify-center items-center  w-full  2xl:w-[270px] rounded-xl text-sm py-2 h-[80px] shadow-xl p-2 cursor-pointer mb-2 ${
															it?.isChecked ? "bg-[#EFF6FC]" : "bg-white"
														}`}
														key={i}>
														{it?.name}
													</div>
												))}
											</div>
										) : null}
										<div className="pt-4 flex gap-3">
											<Button
												children={showDM2Card || showDM1Card ? "Remove" : "Yes"}
												type="button"
												width={"w-fit me-2"}
												loading={loading}
												onClick={handleSubmit(
													showDM2Card
														? "manager2"
														: showDM1Card
														? "manager1"
														: showManagersCard
														? "manager"
														: setShowExecutivesCard
														? "executive"
														: "admin"
												)}
											/>
											<Button
												buttonType={"secondary"}
												children={showDM2Card || showDM1Card ? "Cancel" : "No"}
												type="button"
												width={"w-fit ms-2"}
												onClick={() => {
													setUser(null);
													setGradeForPosition(null);
												}}
											/>
										</div>
									</div>
								</form>
							</div>
						</ModalContainer>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Admins;
