import React from "react";
import JobNavbar from "../../components/job/JobNavbar";
import group from "../../assets/Group.png";
import jobhero from "../../assets/jobhero.png";
import Elli from "../../assets/Elli.png";
import { useNavigate } from "react-router-dom";

const JobPortal = () => {
  const navigate = useNavigate();
  return (
    <div className=" bg-[#F2EFFC]">
      <JobNavbar />

      <div className="lg:px-12 md:px-10 px-8 py-4">
        <div className=" px-10 py-5 bg-[#fcf7ff] shadow-lg relative z-30 ">
          <p className=" Satoshi text-xl text-[#000]">
            To continue to apply for job, you need to setup your account now. If
            you have an account,{" "}
            <span className=" text-[#5608d4] Satoshi text-xl">Login</span>
            now to apply for job. If you don’t have an account.{" "}
            <span className=" text-[#5608d4] Satoshi text-xl">
              Register{" "}
            </span>{" "}
            now!
          </p>

          <button
            onClick={() => navigate("/account-setup")}
            className=" w-[190px] h-[38px] rounded-lg mt-5 text-sm bg-[#000] text-[#fff] Satoshi"
          >
            Setup my account
          </button>
        </div>

        <div className="pl-10 py-5 bg-white">
          <div className=" flex justify-end pr-10">
            <img className=" h-[32.19px] w-[100px]" src={group} alt="" />
          </div>

          <div className=" flex items-center mt-5">
            <div className=" w-[65%]">
              <img className="" src={jobhero} alt="" />
            </div>
            <div className=" w-[35%]">
              <img src={Elli} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPortal;
