import React from "react";
import { Link } from "react-router-dom";

const Table = ({ tableHead, tableData }) => (
  <div className="card">
    <table className="min-w-full mt-5">
      <thead className="bg-gray-50 border-b-[0.5px] border-b-gray-200">
        <tr>
          {tableHead.map((head, index) => (
            <th
              key={index}
              className="px-4 py-4 font-Nunito text-left text-xs Satoshi font-medium text-gray-500 tracking-wider"
            >
              {head}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {tableData.map((rowData, rowIndex) => (
          <tr key={rowIndex}>
            {Object.entries(rowData).map(([key, value], index) => (
              <td
                key={index}
                className="px-4 py-4 text-xs Satoshi text-gray-800"
              >
                {key === "Actions" ? (
                  <Link
                    to="/employee-details"
                    className="text-blue-500 hover:underline"
                  >
                    {value}
                  </Link>
                ) : (
                  value
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default Table;
