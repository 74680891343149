import React, { useState } from "react";
import joblogo from "../../assets/joblogo.png";
import notifi from "../../assets/notifi.png";
import box from "../../assets/box.png";
import jobuserimage from "../../assets/jobuserimage.png";
import { useNavigate } from "react-router-dom";

const JobNavbar = () => {
  const navigate = useNavigate();
  const [notification, setNotification] = useState(false);
  const notifications = [
    {
      img: box,
      desc: "Lorem ipsum dolor sit amet consectetur. Lorem aliquam massa nisi egestas aenean nisl malesuada netus. Ac venenatis aliquam morbi nec nunc amet. Volutpat nisl varius dui viverra. Tristique dolor.",
      date: "3 days ago",
    },
    {
      img: box,
      desc: "Lorem ipsum dolor sit amet consectetur. Lorem aliquam massa nisi egestas aenean nisl malesuada netus. Ac venenatis aliquam morbi nec nunc amet. Volutpat nisl varius dui viverra. Tristique dolor.",
      date: "3 days ago",
    },
    {
      img: box,
      desc: "Lorem ipsum dolor sit amet consectetur. Lorem aliquam massa nisi egestas aenean nisl malesuada netus. Ac venenatis aliquam morbi nec nunc amet. Volutpat nisl varius dui viverra. Tristique dolor.",
      date: "3 days ago",
    },
    {
      img: box,
      desc: "Lorem ipsum dolor sit amet consectetur. Lorem aliquam massa nisi egestas aenean nisl malesuada netus. Ac venenatis aliquam morbi nec nunc amet. Volutpat nisl varius dui viverra. Tristique dolor.",
      date: "3 days ago",
    },
    {
      img: box,
      desc: "Lorem ipsum dolor sit amet consectetur. Lorem aliquam massa nisi egestas aenean nisl malesuada netus. Ac venenatis aliquam morbi nec nunc amet. Volutpat nisl varius dui viverra. Tristique dolor.",
      date: "3 days ago",
    },
    {
      img: box,
      desc: "Lorem ipsum dolor sit amet consectetur. Lorem aliquam massa nisi egestas aenean nisl malesuada netus. Ac venenatis aliquam morbi nec nunc amet. Volutpat nisl varius dui viverra. Tristique dolor.",
      date: "3 days ago",
    },
  ];
  return (
    <div className=" flex items-center justify-between bg-[#5608d4] lg:px-12 md:px-10 px-8 py-4">
      <div className="flex items-center gap-10">
        <img className=" w-12 h-12" src={joblogo} alt="" />
        <p className=" text-base text-[#fff] ">
          Welcome to CeHR Jobs! Let’s get Started
        </p>
      </div>
      <div className=" flex items-center gap-5">
        <img
          onClick={() => setNotification(!notification)}
          className=" w-10 h-10 cursor-pointer"
          src={notifi}
          alt=""
        />
        {notification && (
          <div className=" absolute top-20 right-10 bg-[#fff] w-[460px]  ">
            <div className=" grid justify-center items-center border-b py-2">
              <p className=" text-center text-[#5608d4] text-base Satoshi font-semibold ">
                Notifications
              </p>
            </div>

            <div className=" p-8 overflow-y-scroll h-[440px] grid gap-8">
              {notifications.map((i) => (
                <div
                  onClick={() => navigate("/job-notification")}
                  className=" flex gap-2 cursor-pointer"
                >
                  <img className=" w-12 h-12" src={i.img} alt="" />
                  <div>
                    <p className=" text-sm Satoshi text-[#4D4D4D]">{i.desc}</p>
                    <p className=" text-[10px] Satoshi font-light text-[#5608d4] mt-5">
                      {i.date}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <img
          onClick={() => navigate("/account-profile")}
          className=" w-16 h-10 cursor-pointer"
          src={jobuserimage}
          alt=""
        />
      </div>
    </div>
  );
};

export default JobNavbar;
