import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import FolderCard from "../../components/cards/FolderCard";
import Folder1 from "../../assets/folder-1.png";
import Folder2 from "../../assets/folder-2.png";
import {AllDocuments, AllFiles} from "../../components/resources/AllFiles";
import AddFolderModal from "../../components/resources/AddFolderModal";

export default function Resources() {
  const [openModalId, setOpenModalId] = useState(null);
  let [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(!isOpen);
  }

  function close() {
    setIsOpen(!isOpen);
  }

  const toggleModal = (id) => {
    setOpenModalId(openModalId === id ? null : id);
  };

  const folders = [
    {
      id: 1,
      folderImg: Folder1,
      label: "Public",
      labelColor: "#7A12B9",
      labelBgColor: "#FCF3FF",
      date: "26/03/2024",
      fileCount: 5,
    },
    {
      id: 2,
      folderImg: Folder2,
      label: "UI/UX",
      labelColor: "#12B91E",
      labelBgColor: "#F7FFF3",
      date: "26/03/2024",
      fileCount: 12,
    },
    {
      id: 3,
      folderImg: Folder1,
      label: "Public",
      labelColor: "#7A12B9",
      labelBgColor: "#FCF3FF",
      date: "26/03/2024",
      fileCount: 8,
    },
    {
      id: 4,
      folderImg: Folder2,
      label: "Design",
      labelColor: "#121BB9",
      labelBgColor: "#F3F9FF",
      date: "26/03/2024",
      fileCount: 15,
    },
    {
      id: 5,
      folderImg: Folder1,
      label: "Public",
      labelColor: "#7A12B9",
      labelBgColor: "#FCF3FF",
      date: "26/03/2024",
      fileCount: 3,
    },
    {
      id: 6,
      folderImg: Folder2,
      label: "Public",
      labelColor: "#7A12B9",
      labelBgColor: "#FCF3FF",
      date: "26/03/2024",
      fileCount: 20,
    },
  ];

  return (
    <div className="bg-[#F5F7F8]">
      <Navbar />
      <PagesList />
      <div className="flex justify-between items-center px-6 lg:px-12 pt-4 ">
        <p className="font-Kumbh text-[#5608D4] text-base font-bold">
          Documents
        </p>
      </div>
      <div className="mx-auto lg:mx-12 bg-white  mt-5">
        <div className="flex items-center gap-6 p-5">
          <p className="font-Satoshi text-[#000000] text-base font-medium">
            All Folder (6)
          </p>
          <p
            onClick={open}
            className="font-Satoshi text-[#5608D4] text-[14px] font-bold cursor-pointer"
          >
            + Add new
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 place-content-center lg:place-content-start py-3 px-5">
          {folders.map((folder) => (
            <FolderCard
              key={folder.id}
              folderImg={folder.folderImg}
              label={folder.label}
              labelColor={folder.labelColor}
              labelBgColor={folder.labelBgColor}
              date={folder.date}
              fileCount={folder.fileCount}
              isOpen={openModalId === folder.id}
              toggleModal={() => toggleModal(folder.id)}
            />
          ))}
        </div>
        {/* AllFolders  */}
        <AllFiles />
        
        {/*  */}
        <AddFolderModal isOpen={isOpen} onClose={close} title="New Folder">
          {/* Folder Name and Send to */}
          <div className="flex flex-col lg:flex-row gap-4 mb-4">
            {/* Folder Name */}
            <div className="w-full">
              <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                Folder Name
              </label>
              <input
                type="text"
                placeholder="Name"
                className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]"
              />
            </div>

            {/* Send to */}
            <div className="w-full">
              <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                Send to
              </label>
              <select className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]">
                <option value="">Choose recipient</option>
                <option value="team">Team</option>
                <option value="department">Department</option>
              </select>
            </div>
          </div>

          {/* Folder Description */}
          <div className="mb-4">
            <label className="text-sm text-[#121212] font-Nunito mb-1 block">
              Folder Description
            </label>
            <textarea
              placeholder="Description"
              rows={4}
              className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]"
            ></textarea>
          </div>

          {/* Share by Options */}
          <div className="mb-6">
            <p className="text-sm text-[#121212] font-Nunito mb-1 block">
              Share by:
            </p>
            <div className="flex flex-col gap-2">
              {["Department", "Level", "Grade", "Position"].map((option) => (
                <label key={option} className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="shareBy"
                    value={option.toLowerCase()}
                    className="form-radio text-[#5608D4]"
                  />
                  <span className="text-[12px] text-[#121212] font-Nunito">
                    {option}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </AddFolderModal>
      </div>
    </div>
  );
}
