import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import Navbar from "../../../../components/navbar/Navbar";
import PagesList from "../../../../components/pages-list/PagesList";
import { useResignationStore } from "../../../../data/Stores/LoggerStore";
import useCompanyStore from "../../../../data/Stores/CompanyStore";
import useErrorStore from "../../../../data/Stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../../../data/useGenFetcher";
import { Button } from "../../../../components/buttons/Send";

const TenderResignation = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let init = {},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ addLogger } = useResignationStore(),
		{ currentSelected } = useCompanyStore(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate();

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.name) return toast.info("Level name is required");
		let newState = state;

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			url: `/api/v1/resignation/${currentSelected}`,
			type: "post",
			data: newState,
		});
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			addLogger(response);
			setState(init);
			navigate(-1);
			return;
		}
		setLoading(false);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4 flex gap-5">
					<Link
						to="/onboarding"
						className="  font-Kumbh cursor-pointer text-base font-medium text-[#000]">
						Tender Resignation
					</Link>
				</div>

				<div className=" mt-5 mx-12 p-5 grid justify-center bg-white">
					<div className="w-[724px] h-auto">
						<div className=" w-[724px] h-auto grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									First name
								</label>
								<input
									placeholder="Enter first name"
									className=" border-[0.5px] placeholder-[#A3A3A3] placeholder:text-xs  pl-2 border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
									value={state?.firstName}
									onChange={textChange}
									name={"firstName"}
								/>
							</div>
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Last name
								</label>
								<input
									placeholder="Enter last name"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
									value={state?.lastName}
									onChange={textChange}
									name={"lastName"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Personal email address
								</label>
								<input
									placeholder="Enter email address"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="email"
									value={state?.personalEmail}
									onChange={textChange}
									name={"personalEmail"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Official Email
								</label>
								<input
									placeholder="Official email"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="email"
									value={state?.email}
									onChange={textChange}
									name={"email"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Department
								</label>
								<div className="relative">
									<input
										placeholder="Select department"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
										type="text"
									/>
									<div className="absolute inset-y-0 right-0 flex items-center px-2">
										<FiChevronDown className="text-gray-500 cursor-pointer" />
									</div>
								</div>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Phone number
								</label>
								<input
									placeholder="Enter phone number"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
									value={state?.personalPhone}
									onChange={textChange}
									name={"personalPhone"}
								/>
							</div>
						</div>

						<div className=" py-5">
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Reason for exit
								</label>
								<input
									placeholder="Write"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
									value={state?.reason}
									onChange={textChange}
									name={"reason"}
								/>
							</div>
						</div>
						<div className=" w-[724px] h-auto grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
							{/*  */}
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Date employed
								</label>
								<div className="relative">
									<input
										placeholder="dd/mm/yyyy"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] rounded-md outline-none w-full h-12"
										type="date"
										value={state?.dateEmployed}
										onChange={textChange}
										name={"dateEmployed"}
										max={moment().format("YYYY-MM-DD")}
									/>
									<div className="absolute inset-y-0 right-0 hidden items-center px-2">
										<img
											className=" w-4 h-4 cursor-pointer"
											src={require("../../../../assets/calender.png")}
											alt=""
										/>
									</div>
								</div>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Resignation date
								</label>
								<div className="relative">
									<input
										placeholder="dd/mm/yyyy"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] rounded-md outline-none w-full h-12"
										type="date"
										value={state?.lastWorkingDate}
										onChange={textChange}
										name={"lastWorkingDate"}
										min={moment().format("YYYY-MM-DD")}
									/>
									<div className="absolute inset-y-0 right-0 hidden items-center px-2">
										<img
											className=" w-4 h-4 cursor-pointer"
											src={require("../../../../assets/calender.png")}
											alt=""
										/>
									</div>
								</div>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Company seconded to
								</label>
								<input
									placeholder="Answer"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
									value={state?.companySecondedTo}
									onChange={textChange}
									name={"companySecondedTo"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									How do you want your outstanding to be paid?
								</label>
								<input
									placeholder="Answer"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
									value={state?.outstandingPayment}
									onChange={textChange}
									name={"outstandingPayment"}
								/>
							</div>
							{/*  */}
							<div className=" space-y-3">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Do you have any outstanding obligation?
								</label>
								<div className=" flex items-center gap-5">
									<div className="flex itc gap-2">
										<input type="radio" />
										<p className=" font-Nunito text-sm font-light text-[#000]">
											Yes
										</p>
									</div>
									<div className="flex itc gap-2">
										<input type="radio" />
										<p className=" font-Nunito text-sm font-light text-[#000]">
											No
										</p>
									</div>
								</div>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									If yes, how do you plan to repay it?
								</label>
								<div className="relative">
									<input
										placeholder="Answer"
										className="border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] pr-9 rounded-md outline-none w-full h-12"
										type="text"
									/>
									<div className="absolute inset-y-0 right-0 flex items-center px-2">
										<FiChevronDown className="text-gray-500 cursor-pointer" />
									</div>
								</div>
							</div>

							{/*  */}
							<div className=" space-y-3">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									Do you have any outstanding issue?
								</label>
								<div className=" flex items-center gap-5">
									<div className="flex itc gap-2">
										<input type="radio" />
										<p className=" font-Nunito text-sm font-light text-[#000]">
											Yes
										</p>
									</div>
									<div className="flex itc gap-2">
										<input type="radio" />
										<p className=" font-Nunito text-sm font-light text-[#000]">
											No
										</p>
									</div>
								</div>
							</div>
							{/*  */}
							<div className=" space-y-2">
								<label
									htmlFor=""
									className=" text-[#121212] text-sm font-Nunito">
									If yes, how do you plan to resolve it?
								</label>
								<input
									placeholder="Answer"
									className=" border-[0.5px] pl-2 placeholder-[#A3A3A3] placeholder:text-xs  border-[#D0D0D0] rounded-md outline-none w-full h-12"
									type="text"
								/>
							</div>
						</div>
					</div>
					<div className=" flex gap-5 justify-center items-center pt-10">
						<button
							onClick={() => navigate(-1)}
							className=" w-36 h-10 font-Nunito rounded-md text-sm border border-[#0811D4] text-[#0811D4]">
							Cancel
						</button>
						{/* <button className=" w-36 h-10 font-Nunito rounded-md text-sm bg-gradient-to-b from-[#0811D4] to-[#384295]  text-white">
							Next
						</button> */}
						<Button
							css={
								" w-36 h-10 font-Nunito rounded-md text-sm bg-gradient-to-b from-[#0811D4] to-[#384295]  text-white"
							}
							children={"Next"}
							onClick={handleSubmit}
							loading={loading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TenderResignation;
