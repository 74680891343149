import React from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
// import { Link } from "react-router-dom";

export const MainCandidatesDetails = () => {
	const lists = [
		{
			id: 1,
			text: "Qualifications and Skills",
			img: require("../../../assets/mark.png"),
		},
		{
			id: 2,
			text: "Experience Relevance",
			img: require("../../../assets/mark.png"),
		},
		{
			id: 3,
			text: "Education",
			img: require("../../../assets/mark.png"),
		},
		{
			id: 4,
			text: "Keywords Match",
			img: require("../../../assets/CrossComponent.png"),
		},
		{
			id: 5,
			text: "Years of Experience",
			img: require("../../../assets/mark.png"),
		},
		{
			id: 6,
			text: "Interview Performance",
			img: require("../../../assets/mark.png"),
		},
		{
			id: 7,
			text: "Cultural Fit",
			img: require("../../../assets/mark.png"),
		},
	];
	return (
		<div className=" bg-[#EFF6FC]">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4">
					<p className=" font-Kumbh text-base font-bold text-[#000]">
						Candidates Details
					</p>
				</div>
				<div className=" bg-white mx-12">
					<div className=" flex items-center justify-between bg-[#EFF6FC] p-5 mt-5">
						<div>
							<div className=" flex items-center gap-5">
								<div>
									<div className=" w-16 h-16 justify-center items-center grid rounded-full text-[#071C50] bg-[#c6d5f7] text-3xl font-Poppins font-bold">
										J V
									</div>
								</div>
								<div className="grid gap-5">
									<div className=" pt-4">
										<p className=" text-[#071C50] text-xl font-bold Satoshi">
											Jane Victor
										</p>
										<div className=" flex items-center gap-3">
											<p className=" text-[#071C50] text-sm font-Nunito">
												janevic@gmail.com
											</p>
											<p className="text-[#071C50] text-sm font-Nunito">|</p>
											<p className="text-[#071C50] text-sm font-Nunito">
												+234 901 222 3333
											</p>
										</div>
									</div>
									<div>
										{/* <Link
                  to="/update-employee"
                  className=" underline text-[#F7AC25] font-Nunito font-bold text-base"
                >
                  Update
                </Link> */}
									</div>
								</div>
							</div>
							<div className=" flex items-center gap-3 mt-3 pl-20">
								<img
									className=" w-4 h-4"
									src={require("../../../assets/Linkedin.png")}
									alt=""
								/>
								<img
									className=" w-4 h-4"
									src={require("../../../assets/Twitter.png")}
									alt=""
								/>
								<img
									className=" w-4 h-4"
									src={require("../../../assets/Global.png")}
									alt=""
								/>
							</div>
						</div>
						<div className=" ">
							<img
								className=" h-24"
								src={require("../../../assets/ELine.png")}
								alt=""
							/>
						</div>
						<div className=" flex items-center pr-52">
							<div className=" grid gap-4">
								<div className=" flex items-center justify-between gap-8">
									<p className="  text-[#071C50] text-base Satoshi">
										Current Status
									</p>
									{/* <p className=" bg-[#DDEAFB] text-[#071C50] text-xs grid justify-center items-center rounded-lg font-Nunito w-32 h-5">
                  Current Status
                </p> */}
								</div>
								<div className=" flex items-center justify-between gap-8">
									<p className=" text-gray-400 text-base Satoshi font-light">
										Status
									</p>
									<p className=" bg-[#DDFBF9] text-xs font-Nunito text-[#071C50] w-32 h-5 rounded-lg grid justify-center items-center ">
										Interviewed
									</p>
								</div>
								<div className=" flex items-center gap-8">
									<p className=" text-gray-400 text-base Satoshi font-light">
										Interview Date
									</p>
									<p className="  text-xs font-Nunito text-[#071C50] w-32 h-5 grid justify-center items-start ">
										Mar 30, 2024
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className=" grid p-5">
						<div className=" flex items-center gap-10">
							<div className=" w-9/12">
								<div className=" space-y-2">
									<p className=" text-[#071C50] font-Kumbh text-base font-medium">
										Candidate Files
									</p>
									<div className=" flex items-center gap-5">
										<div className=" items-center flex justify-center gap-2 h-10 w-60 rounded-md bg-[#E7F1FF] border border-[#071C50]">
											<img
												className=" w-3 h-4"
												src={require("../../../assets/icon _pdf.png")}
												alt=""
											/>
											<p className=" text-[#071C50] font-Poppins text-base">
												Cover_letter.pdf
											</p>
											<p className=" text-gray-300 text-xs font-Poppins">
												2d ago
											</p>
										</div>
										<div className=" items-center flex justify-center gap-2 h-10 w-60 rounded-md bg-[#F2FFF0] border border-[#071C50]">
											<img
												className=" w-3 h-4"
												src={require("../../../assets/icon _doc.png")}
												alt=""
											/>
											<p className=" text-[#071C50] font-Poppins text-base">
												My_resume.pdf
											</p>
											<p className=" text-gray-300 text-xs font-Poppins">
												2d ago
											</p>
										</div>
									</div>
								</div>

								<div className=" mt-5">
									<p className="font-Nunito text-base font-bold text-[#071C50]">
										Last Experience
									</p>
									<div className=" mt-3 grid gap-10">
										<div>
											<div className="flex items-center gap-2">
												<img
													className=" h-11 w-3"
													src={require("../../../assets/Rectanglejob.png")}
													alt=""
												/>
												<div>
													<p className=" font-Nunito text-base font-bold text-[#071C50]">
														Basic Information
													</p>
													<div className=" flex items-center gap-5">
														<p className=" text-[#071C50] font-Nunito text-sm">
															Senior Data Analyst
														</p>
														<p className=" text-sm text-gray-400 font-light font-Nunito">
															(Dec 2021 - Present)
														</p>
													</div>
												</div>
											</div>
											<div className=" pl-5 mt-3">
												<p className=" text-sm text-gray-400 font-light font-Nunito">
													Responsible for;{" "}
												</p>
												<p className="text-sm text-gray-400 font-light font-Nunito">
													1. Data Exploration and Analysis: They perform
													exploratory data analysis to uncover insights, trends,
													and patterns in the data, often using statistical and
													visualisation techniques.{" "}
												</p>
												<p className="text-sm text-gray-400 font-light font-Nunito">
													2. Data Cleaning and Preprocessing: Data analysts are
													responsible for cleaning and preparing raw renewable
													data to ensure its accuracy and reliability for
													analysis.{" "}
												</p>
												<p className="text-sm text-gray-400 font-light font-Nunito">
													3. Reporting and Communication: Data analysts
													communicate their findings through reports,
													dashboards, and presentations to help stakeholders
													make informed decisions based on the data-driven
													insights.{" "}
												</p>
											</div>
										</div>
										{/*  */}
										<div>
											<div className="flex items-center gap-2">
												<img
													className=" h-11 w-3"
													src={require("../../../assets/Rectanglejob.png")}
													alt=""
												/>
												<div>
													<p className=" font-Nunito text-base font-bold text-[#071C50]">
														Junior Data Analyst
													</p>
													<div className=" flex items-center gap-5">
														<p className=" text-[#071C50] font-Nunito text-sm">
															Microsoft
														</p>
														<p className=" text-sm text-gray-400 font-light font-Nunito">
															(May 2020 - May 2021)
														</p>
													</div>
												</div>
											</div>
											<div className=" pl-5 mt-3">
												<p className=" text-sm text-gray-400 font-light font-Nunito">
													Responsible for;{" "}
												</p>
												<p className="text-sm text-gray-400 font-light font-Nunito">
													1. Data Exploration and Analysis: They perform
													exploratory data analysis to uncover insights, trends,
													and patterns in the data, often using statistical and
													visualisation techniques.{" "}
												</p>
												<p className="text-sm text-gray-400 font-light font-Nunito">
													2. Data Cleaning and Preprocessing: Data analysts are
													responsible for cleaning and preparing raw renewable
													data to ensure its accuracy and reliability for
													analysis.{" "}
												</p>
												<p className="text-sm text-gray-400 font-light font-Nunito">
													3. Reporting and Communication: Data analysts
													communicate their findings through reports,
													dashboards, and presentations to help stakeholders
													make informed decisions based on the data-driven
													insights.{" "}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className=" w-4/12">
								<div className=" w-full h-auto rounded-md bg-[#F3F8FF] p-5 border border-[#E5EDF9]">
									<div className=" grid justify-center py-5">
										<div className=" bg-white border border-[#E5EDF9] grid items-center rounded-md w-52 h-36">
											<div className=" space-y-4">
												<div className=" grid justify-center">
													<img
														className=" w-12 h-12"
														src={require("../../../assets/loading.png")}
														alt=""
													/>
												</div>
												<div className=" flex justify-center">
													<p className=" font-Nunito text-base text-[#071C50] font-normal">
														Score:
													</p>
													<p className="font-Nunito text-base text-[#63BB1E] font-normal">
														Potential Fit
													</p>
												</div>
											</div>
										</div>
									</div>

									<div className=" grid gap-4 mt-8">
										{lists.map(i => (
											<div className=" flex justify-between items-center">
												<p className=" text-[#071C50] text-sm font-Nunito">
													{i.text}
												</p>
												<img className=" w-4 h-4" src={i.img} alt="" />
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
						<div className=" flex items-center gap-10 mt-10">
							<div>
								{" "}
								<div className="flex items-center gap-2">
									<img
										className=" h-11 w-3"
										src={require("../../../assets/Rectanglejob.png")}
										alt=""
									/>
									<div>
										<p className=" font-Nunito text-base font-bold text-[#071C50]">
											Expected Salary{" "}
											<span className=" font-Nunito text-base text-gray-400 font-light">
												(Annual)
											</span>
										</p>
										<div className=" flex items-center gap-5">
											<p className=" text-[#071C50] font-Nunito text-sm">
												3.6 million (NGN)
											</p>
										</div>
									</div>
								</div>
							</div>
							<div>
								{" "}
								<div className="flex items-center gap-2">
									<div>
										<p className=" font-Nunito text-base font-bold text-[#071C50]">
											Available date to start
										</p>
										<div className=" flex items-center gap-5">
											<p className=" text-[#071C50] font-Nunito text-sm">
												15th April, 2024
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const CandidatesDetails = ()=> <MainCandidatesDetails />


export default CandidatesDetails;
