import React, { useState } from "react";
import DeletedSuccessfully from "./deletedSuccessfully";
import ActionCompleted from "./ActionCompleted";

const Decline = ({ onClose }) => {
  const [isDeleted, setIsDeleted] = useState(false);

  const handleDeleted = () => {
    setIsDeleted(!isDeleted);
  };
  const closeDeleted = () => {
    setIsDeleted(false);
  };

  return (
    <div className=" fixed left-0 top-0 w-full h-full bg-black bg-opacity-45 grid justify-center items-center">
      <div className=" bg-white w-72 h-auto rounded-md p-5">
        <div className=" grid justify-center items-center py-5">
          <img
            className=" w-36 h-16"
            src={require("../../assets/Delete.png")}
            alt=""
          />
        </div>
        <div className=" space-y-2">
          <label htmlFor="">Reason for declining</label>
          <input
            className=" w-full outline-none px-2 h-11 border border-[#CCCCCC] rounded-md"
            type="text"
          />
        </div>
        <div className=" justify-center items-center flex gap-5 pt-10 pb-5">
          <button
            onClick={onClose}
            className=" border-[0.5px] text-[#0811D4] font-Nunito text-sm border-[#0811D4] rounded-md w-16 h-7"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleted}
            className="rounded-md w-16 h-7 text-white font-Nunito text-sm bg-gradient-to-b from-[#1E27AB] to-[#384295]"
          >
            Decline
          </button>
          {isDeleted && <ActionCompleted onClose={closeDeleted} />}
        </div>
      </div>
    </div>
  );
};

export default Decline;
