import React, { useEffect, useState } from "react";
import PagesList from "../../../components/pages-list/PagesList";
import Navbar from "../../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { useResignationStore } from "../../../data/Stores/LoggerStore";
import useCompanyStore from "../../../data/Stores/CompanyStore";
import { apiCall } from "../../../data/useGenFetcher";
import { LoadMore, MainPaginate } from "../../../components/buttons/Send";
// import FilesDetails from "../../../components/cards/File’sDetails";

const Offboarding = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// const [addAction, setAddAction] = useState(false);

	// const handleAction = () => {
	// 	setAddAction(!addAction);
	// };
	// const closeAction = () => {
	// 	setAddAction(false);
	// };

	const tableHead = [
		"",
		"Name",
		"Date Sent",
		"Notice Period",
		"Office Tool",
		"Letter Attached",
		"Status",
		"Action",
	];
	const tableData = [
		{
			Name: "Catherine James",
			DateSent: "26th Mar, 2024x",

			NoticePeriod: "30 days",
			OfficeTool: "Laptop, Printer",
			LetterAttached: "Present",
			Status: "Accepted",
			Actions: "View details",
		},
		{
			Name: "Catherine James",
			DateSent: "26th Mar, 2024x",

			NoticePeriod: "30 days",
			OfficeTool: "Laptop, Printer",
			LetterAttached: "Present",
			Status: "Pending",
			Actions: "View details",
		},
		{
			Name: "Catherine James",
			DateSent: "26th Mar, 2024x",

			NoticePeriod: "30 days",
			OfficeTool: "Laptop, Printer",
			LetterAttached: "Present",
			Status: "Accepted",
			Actions: "View details",
		},
		{
			Name: "Catherine James",
			DateSent: "26th Mar, 2024x",

			NoticePeriod: "30 days",
			OfficeTool: "Laptop, Printer",
			LetterAttached: "Present",
			Status: "Accepted",
			Actions: "View details",
		},
		{
			Name: "Catherine James",
			DateSent: "26th Mar, 2024x",

			NoticePeriod: "30 days",
			OfficeTool: "Laptop, Printer",
			LetterAttached: "Present",
			Status: "Pending",
			Actions: "View details",
		},
		{
			Name: "Catherine James",
			DateSent: "26th Mar, 2024x",

			NoticePeriod: "30 days",
			OfficeTool: "Laptop, Printer",
			LetterAttached: "Present",
			Status: "Accepted",
			Actions: "View details",
		},
		{
			Name: "Catherine James",
			DateSent: "26th Mar, 2024x",

			NoticePeriod: "30 days",
			OfficeTool: "Laptop, Printer",
			LetterAttached: "Present",
			Status: "Pending",
			Actions: "View details",
		},
	];

	let [datum, setDatum] = useState({ docs: tableData }),
		{ getLogger, mainSearch, isFound, data } = useResignationStore(),
		{ currentSelected } = useCompanyStore(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall({
			type: "get",
			url: `/api/v1/resignation/${currentSelected}`,
			getter: d => getLogger(d),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadMore = async () => {
		setLoading("loadmore");
		await apiCall({
			type: "get",
			url: `/api/v1/resignation/${currentSelected}?_limit=${
				data?.limit * data?.nextPage
			}`,
			getter: d => getLogger(d),
		});
		setLoading(false);
	};

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className=" bg-[#EFF6FC] pb-10">
			<Navbar />
			<PagesList />
			<div className="bg-[#F5F7F8] w-full  ">
				<div className=" mx-12 pt-4 flex gap-5">
					<Link className="  font-Kumbh cursor-pointer text-base font-medium text-[#000]">
						Offboarding
					</Link>
				</div>

				<div className=" mx-12">
					<div className=" bg-white p-5 mt-5  ">
						<div className=" flex items-center justify-between">
							<div className=" flex items-center gap-20">
								<div className=" flex items-center">
									<Link
										to=""
										className=" Satoshi cursor-pointer animate-pulse font-normal text-sm text-[#000]">
										All Resignation
									</Link>
								</div>

								<div className=" flex items-center gap-3">
									<div className="relative">
										<input
											className="w-48 h-9 pr-10 pl-3 outline-none bg-[#F5F7F8] rounded-md"
											type="text"
										/>
										<div className="absolute inset-y-0 right-3 flex items-center pl-3">
											<FaSearch className="text-gray-500" />
										</div>
									</div>
								</div>
							</div>
							<div className=" cursor-pointer">
								<Link
									to="tender-resignation"
									className=" bg-black text-white grid justify-center items-center rounded-md w-40 h-8 Satoshi">
									Submit resignation
								</Link>
							</div>
						</div>
					</div>
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50 ">
							<tr>
								{tableHead.map((head, index) => (
									<th
										key={index}
										className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider">
										{head}
									</th>
								))}
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{currentItems?.map((rowData, rowIndex) => (
								<tr
									key={rowIndex}
									className="border-t-[0.5px] border-t-gray-200">
									<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										<Link>
											{" "}
											<input type="checkbox" />
										</Link>
									</td>
									<td className="px-4 py-4 flex items-center gap-3 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										<Link className=" border border-[#0811D4] grid justify-center items-center w-6 h-6 rounded-full">
											<p className=" Satoshi text-[11px] text-[#000]">CJ</p>
										</Link>
										{rowData?.Name}
									</td>
									<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										{rowData?.DateSent}
									</td>
									<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										{rowData?.NoticePeriod}
									</td>
									<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										{rowData?.OfficeTool}
									</td>
									<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										{rowData?.LetterAttached}
									</td>
									<td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
										{rowData?.Status}
									</td>
									<td className="px-4 py-4 text-[#0811D4] text-xs border-b-[0.5] border-b-gray-200 ">
										<Link
											className=" animate-pulse"
											to="resignation"
											state={rowData}>
											...
										</Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="mt-10 flex justify-center w-44 items-center mx-auto">
						<LoadMore
							next={data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Offboarding;
