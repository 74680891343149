import React, { useState } from "react";
import AddFolderModal from "./AddFolderModal";
import { IoMdMenu } from "react-icons/io";
import { RxDashboard } from "react-icons/rx";
import { BsSearch } from "react-icons/bs";
import FileUploadPreview from "./FileUploadPreview";
import ListView from "./ListView";
import BoardView from "./BoardView";

export function AllFiles({ folders, toggleModal }) {
  let [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("list-view");
  const [searchQuery, setSearchQuery] = useState("")


  function open() {
    setIsOpen(!isOpen);
  }

  function close() {
    setIsOpen(!isOpen);
  }

  function handleSwitchTab(tab) {
    setActiveTab(tab);
  }

  // const filteredItems = folders.filter((item) =>
  //    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  //  );

  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between items-center mt-10 gap-4 lg:gap-0 px-5">
        {/* Title and View Options */}
        <div className="flex flex-col lg:flex-row justify-between items-center gap-4 lg:gap-x-36 w-full lg:w-auto">
          <div className="w-full lg:w-auto text-left lg:text-center">
            <h2 className="text-[15px] text-[#000000] font-[500]">All Files</h2>
          </div>

          {/* List and Board View Toggle for Desktop */}
          <div className="hidden lg:flex items-center ">
            {/* List View */}
            <button
              onClick={() => handleSwitchTab("list-view")}
              className={`flex items-center gap-2 bg-[#FFFFFF] border-2 border-[#EBEEF2] px-2 py-3 w-[117px] rounded-e-lg cursor-pointer ${
                activeTab === "list-view" ? "bg-[#F7F8FA]" : "bg-[#FFFFFF]"
              }`}
            >
              <IoMdMenu sizes={24} className="text-[#C7CED9]" />
              <span className="text-[15px] text-[#606C80] font-[500]">
                List View
              </span>
            </button>

            {/* Board View */}
            <button
              onClick={() => handleSwitchTab("board-view")}
              className={`flex items-center gap-2 bg-[#FFFFFF] border-2 border-[#EBEEF2] px-2 py-3 w-[130px] rounded-s-lg cursor-pointer ${
                activeTab === "board-view" ? "bg-[#F7F8FA]" : "bg-[#FFFFFF]"
              }`}
            >
              <RxDashboard className="text-[#C7CED9]" />
              <span className="text-[#606C80] font-[500]">Board View</span>
            </button>
          </div>

          {/* Dropdown for Mobile View */}
          <div className="block lg:hidden w-full">
            <select
              className="w-full border border-[#EBEEF2] px-2 py-3 rounded-lg text-[#606C80] font-[500]"
              onChange={(e) => handleSwitchTab(e.target.value)} 
            >
              <option value="list-view">List View</option>
              <option value="board-view">Board View</option>
            </select>
          </div>

          {/* Search Bar */}
          <div className="flex items-center gap-4 w-full lg:w-auto">
            <span className="text-[#121212] font-Nunito hidden lg:flex">
              Search documents
            </span>
            <div className="flex items-center justify-between gap-2 border border-[#ACC3E7] px-3 py-2 rounded-lg w-full lg:w-auto">
              <input
                type="text"
                className="hidden lg:block   border-none focus:outline-none text-[#A3A3A3] placeholder-[#A3A3A3] focus:text-[#121212] w-full lg:w-auto"
                placeholder="Search"
                onChange={(ev) => setSearchQuery(ev.target.value)}
              />
              <input
                type="text"
                className="flex lg:hidden border-none focus:outline-none text-[#A3A3A3] placeholder-[#A3A3A3] focus:text-[#121212] w-full lg:w-auto"
                placeholder="Search for a document"
                onChange={(ev) => setSearchQuery(ev.target.value)}
              />
              <BsSearch className="text-[#000000]" />
            </div>
          </div>
        </div>

        {/* Add New Button */}
        <div className="flex items-center justify-end lg:justify-start w-full lg:w-auto mr-0 lg:mr-20">
          <button
            onClick={open}
            className="text-[#5608D4] border border-[#5608D4] hover:bg-[#5608D4] hover:text-white transition ease-in-out focus:ring-2 text-base font-[500] px-4 py-2 rounded-lg w-full lg:w-auto"
          >
            + Add new
          </button>

          {/* Add New Folder Modal */}
          <AddFolderModal isOpen={isOpen} onClose={close} title="Add File">
            <div className="flex flex-col lg:flex-row gap-4 mb-4">
              <div className="w-full">
                <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                  Folder Name
                </label>
                <input
                  type="text"
                  placeholder="Name"
                  className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]"
                />
              </div>

              {/* Send to */}
              <div className="w-full">
                <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                  Send to
                </label>
                <select className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]">
                  <option value="">Choose recipient</option>
                  <option value="team">Team</option>
                  <option value="department">Department</option>
                </select>
              </div>
            </div>

            {/* Folder Description */}
            <div className="mb-4">
              <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                Folder Description
              </label>
              <textarea
                placeholder="Description"
                rows={4}
                className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]"
              ></textarea>
            </div>

            {/* Share by Options */}
            <div className="mb-6">
              <p className="text-sm text-[#121212] font-Nunito mb-1 block">
                Share by:
              </p>
              <div className="flex flex-col gap-2">
                {["Department", "Level", "Grade", "Position"].map((option) => (
                  <label key={option} className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="shareBy"
                      value={option.toLowerCase()}
                      className="form-radio text-[#5608D4]"
                    />
                    <span className="text-[12px] text-[#121212] font-Nunito">
                      {option}
                    </span>
                  </label>
                ))}
              </div>

              {/* File Upload Section */}
              <div className="my-6">
                <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                  Upload Files
                </label>
                <FileUploadPreview />
              </div>
            </div>
          </AddFolderModal>
        </div>
      </div>

      {/* Tab Content */}
      <div className="mt-6">
        {activeTab === "list-view" && <ListView folders={folders} />}
        {activeTab === "board-view" && <BoardView folders={folders} />}
      </div>
    </>
  );
}



export function AllDocuments({ files, toggleModal }) {
  let [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("list-view");
  const [searchQuery, setSearchQuery] = useState('')


  function open() {
    setIsOpen(!isOpen);
  }

  function close() {
    setIsOpen(!isOpen);
  }

  function handleSwitchTab(tab) {
    setActiveTab(tab);
  }

  // const filteredItems = files.filter((item) =>
  //   item.title.toLowerCase().includes(searchQuery.toLowerCase())
  // );
 
  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between items-center gap-4 lg:gap-0 px-5">
        {/* Title and View Options */}
        <div className="flex flex-col lg:flex-row justify-between items-center my-10 gap-4 lg:gap-x-36 w-full lg:w-auto">
          <div className="w-full lg:w-auto text-left lg:text-center">
            <h2 className="text-[15px] text-[#000000] font-[500]">All Documents</h2>
          </div>
        </div>

        {/* Search Bar */}
        <div className="flex items-center gap-4 w-full lg:w-auto">
          <span className="text-[#121212] font-Nunito hidden lg:flex">
            Search documents
          </span>
          <div className="flex items-center justify-between gap-2 border border-[#ACC3E7] px-3 py-2 rounded-lg w-full lg:w-auto">
            <input
              type="text"
              className="hidden lg:block border-none focus:outline-none text-[#A3A3A3] placeholder-[#A3A3A3] focus:text-[#121212] w-full lg:w-auto"
              placeholder="Search"
              onChange={(ev)=> setSearchQuery(ev.target.value)}
            />
            <input
              type="text"
              className="flex lg:hidden border-none focus:outline-none text-[#A3A3A3] placeholder-[#A3A3A3] focus:text-[#121212] w-full lg:w-auto"
              placeholder="Search for a document"
            />
            <BsSearch className="text-[#000000]" />
          </div>
        </div>

        {/* Add New Button */}
        <div className="flex items-center justify-end lg:justify-start w-full lg:w-auto mr-0 lg:mr-20">
          <button
            // onClick={open}
            className="text-[#5608D4] border border-[#5608D4] hover:bg-[#5608D4] hover:text-white transition ease-in-out focus:ring-2 text-base font-[500] px-4 py-2 rounded-lg w-full lg:w-auto"
          >
            + Add new
          </button>

          {/* Add New Folder Modal */}
          <AddFolderModal isOpen={isOpen} onClose={close} title="Add File">
            <div className="flex flex-col lg:flex-row gap-4 mb-4">
              <div className="w-full">
                <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                  Folder Name
                </label>
                <input
                  type="text"
                  placeholder="Name"
                  className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]"
                />
              </div>

              {/* Send to */}
              <div className="w-full">
                <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                  Send to
                </label>
                <select className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]">
                  <option value="">Choose recipient</option>
                  <option value="team">Team</option>
                  <option value="department">Department</option>
                </select>
              </div>
            </div>

            {/* Folder Description */}
            <div className="mb-4">
              <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                Folder Description
              </label>
              <textarea
                placeholder="Description"
                rows={4}
                className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]"
              ></textarea>
            </div>

            {/* Share by Options */}
            <div className="mb-6">
              <p className="text-sm text-[#121212] font-Nunito mb-1 block">
                Share by:
              </p>
              <div className="flex flex-col gap-2">
                {["Department", "Level", "Grade", "Position"].map((option) => (
                  <label key={option} className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="shareBy"
                      value={option.toLowerCase()}
                      className="form-radio text-[#5608D4]"
                    />
                    <span className="text-[12px] text-[#121212] font-Nunito">
                      {option}
                    </span>
                  </label>
                ))}
              </div>

              {/* File Upload Section */}
              <div className="my-6">
                <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                  Upload Files
                </label>
                <FileUploadPreview />
              </div>
            </div>
          </AddFolderModal>
        </div>
      </div>

      {/* Tab Content */}
      {/* <div className="mt-6">
        {activeTab === "list-view" && <ListView folders={folders} />}
        {activeTab === "board-view" && <BoardView folders={folders} />}
      </div> */}
    </>
  );
}