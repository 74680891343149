import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";

import { FaSearch } from "react-icons/fa";
import AddNewBtn from "../../../components/buttons/AddNewBtn";

import AddFile from "../../../components/cards/AddFile";
import { Link } from "react-router-dom";
import FilesDetails from "../../../components/cards/File’sDetails";
import AddAsset from "../../../components/cards/AddAsset";

const Weekly = () => {
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  const [addFile, setAddFile] = useState(false);
  const [addAction, setAddAction] = useState(false);

  const handleFile = () => {
    setAddFile(!addFile);
  };
  const handleAction = () => {
    setAddAction(!addAction);
  };
  const closeAction = () => {
    setAddAction(false);
  };
  const closeFile = () => {
    setAddFile(false);
  };

  const tableHead = [
    "",
    "Name",
    "Weekly",
    "Asset ID",
    "Description",
    "Actions",
  ];
  const tableData = [
    {
      Name: "Instructions - Setting up your Working space",
      AssetName: "Laptop",
      img: require("../../../assets/Laptop.png"),
      AssetID: "Tool Details",
      Description: "HP ELITEBOOK, 16GB RAM, SSD",
      Actions: "View details",
    },
    {
      Name: "Instructions - Setting up your Working space",
      AssetName: "Laptop",
      img: require("../../../assets/Laptop.png"),
      AssetID: "Tool Details",
      Description: "HP ELITEBOOK, 16GB RAM, SSD",
      Actions: "View details",
    },
    {
      Name: "Instructions - Setting up your Working space",
      AssetName: "Laptop",
      img: require("../../../assets/Laptop.png"),
      AssetID: "Tool Details",
      Description: "HP ELITEBOOK, 16GB RAM, SSD",
      Actions: "View details",
    },
    {
      Name: "Instructions - Setting up your Working space",
      AssetName: "Laptop",
      img: require("../../../assets/Laptop.png"),
      AssetID: "Tool Details",
      Description: "HP ELITEBOOK, 16GB RAM, SSD",
      Actions: "View details",
    },
    {
      Name: "Instructions - Setting up your Working space",
      AssetName: "Laptop",
      img: require("../../../assets/Laptop.png"),
      AssetID: "Tool Details",
      Description: "HP ELITEBOOK, 16GB RAM, SSD",
      Actions: "View details",
    },
    {
      Name: "Instructions - Setting up your Working space",
      AssetName: "Laptop",
      img: require("../../../assets/Laptop.png"),
      AssetID: "Tool Details",
      Description: "HP ELITEBOOK, 16GB RAM, SSD",
      Actions: "View details",
    },
    {
      Name: "Instructions - Setting up your Working space",
      AssetName: "Laptop",
      img: require("../../../assets/Laptop.png"),
      AssetID: "Tool Details",
      Description: "HP ELITEBOOK, 16GB RAM, SSD",
      Actions: "View details",
    },
  ];

  return (
    <div className=" bg-white mt-5 p-5">
      <div>
        <div className=" bg-white  ">
          <div className=" flex items-center justify-between">
            <div className=" flex items-center gap-5">
              <div className=" flex items-center">
                <p className=" Satoshi font-normal text-sm text-[#000]">
                  Reports
                </p>
              </div>

              <div className=" flex items-center gap-3">
                <p className=" text-[#121212]  text-sm font-light font-Nunito">
                  Search documents
                </p>
                <div className="relative">
                  <input
                    className="w-48 h-9 pr-10 pl-3 outline-none bg-[#F5F7F8] rounded-md"
                    type="text"
                  />
                  <div className="absolute inset-y-0 right-3 flex items-center pl-3">
                    <FaSearch className="text-gray-500" />
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex items-center gap-2 w-28 h-10 rounded-lg bg-black justify-center">
              <img
                className=" w-4 h-4"
                src={require("../../../assets/mdi_report-box-outline.png")}
                alt=""
              />
              <p className=" text-white font-Nunito text-sm">My reports</p>
            </div>
            {/* <div onClick={handleFile} className=" cursor-pointer">
              <AddNewBtn text="My reports" />
            </div> */}
            {/* {addFile && <AddAsset closeFile={closeFile} />} */}
          </div>
        </div>
        <table className="min-w-full divide-y divide-gray-200 mt-5">
          <thead className="bg-gray-50">
            <tr>
              {tableHead.map((head, index) => (
                <th
                  key={index}
                  className=" px-4 py-4 font-Nunito text-left text-xs font-medium text-gray-500 tracking-wider"
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData.map((rowData, rowIndex) => (
              <tr key={rowIndex} className="border-t-[0.5px] border-t-gray-200">
                <td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
                  <Link>
                    {" "}
                    <input type="checkbox" />
                  </Link>
                </td>
                <td className="px-4 py-4 flex items-center gap-3 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
                  <img className=" w-7 h-7" src={rowData.img} alt="" />
                </td>
                <td className="px-4 py-4  text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
                  {rowData.AssetName}
                </td>
                <td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
                  {rowData.AssetID}
                </td>
                <td className="px-4 py-4 text-xs border-b-[0.5] border-b-gray-200 text-gray-800">
                  {rowData.Description}
                </td>
                <td className="px-4 py-4 text-[#0811D4] text-xs border-b-[0.5] border-b-gray-200 ">
                  <Link onClick={handleAction} className=" animate-pulse" to="">
                    {rowData.Actions}
                  </Link>
                </td>
                {addAction && <FilesDetails closeDetails={closeAction} />}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Weekly;
