import React from "react";

const RecentDocumentsTable = () => {
  const tableHead = ["S/N", "Name", "Sharing", "Modified"],
    tableData = [
      {
        SN: 1,
        img: require("../../assets/TableFile.png"),
        Name: "Business/1234A",
        Sharing: "Public",
        Modified: "25th March, 2024",
      },
      {
        SN: 2,
        img: require("../../assets/TableFolder.png"),
        Name: "Business/1234A",
        Sharing: "Public",
        Modified: "25th March, 2024",
      },
      {
        SN: 3,
        img: require("../../assets/TableFile.png"),
        Name: "Business/1234A",
        Sharing: "Public",
        Modified: "25th March, 2024",
      },
    ];
  return (
    <div className=" grid ">
      <div className=" flex items-center justify-between">
        <p className=" text-sm font-bold text-[#000] Satoshi">
          Recent Documents
        </p>
        <div className="bg-[#DFFFE2] rounded-full px-2 flex gap-2 items-center cursor-pointer">
          <img
            className=" w-4 h-4"
            src={require("../../assets/TFile.png")}
            alt=""
          />
          <p className=" text-[#07B4B4] text-sm font-thin Satoshi">View All</p>
        </div>
      </div>
      <div className=" w-full overflow-x-scroll ">
        <table className="min-w-full table divide-y-2 divide-yellow-[#7E3AF2] h-full">
          <thead className="h-16 w-full ">
            <tr className="text-black">
              {tableHead?.map((it, index) => (
                <th className=" font-Nunito text-[11px] font-light" key={index}>
                  <p className=" text-start pl-1">{it}</p>
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="divide-y">
            {tableData?.map((it, i) => (
              <tr key={i} className=" bg-white py-4 h-16    ">
                <td className=" px-4  font-Nunito text-[11px] Satoshi font-light  ">
                  {it?.SN}
                </td>
                <td className="  font-Nunito flex items-center gap-3 pt-5 ">
                  <img className=" w-6 h-6" src={it?.img} alt="" />
                  <p className=" text-[11px] Satoshi font-light text-center font-Nunito">
                    {it?.Name}
                  </p>
                </td>
                <td className="  font-Nunito text-[11px] Satoshi font-light ">
                  <p className=" bg-[#F1F1F1] w-14 h-7 rounded-xl grid justify-center items-center">
                    {it?.Sharing}
                  </p>
                </td>
                <td className="  font-Nunito text-[11px] Satoshi font-light ">
                  {it?.Modified}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentDocumentsTable;
